import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useArticleContext } from "../../../../context/ArticleContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Keyword() {
  const { t } = useTranslation();
  const { keywords, setKeywords } = useArticleContext();

  return (
    <>
      {/* キーワード */}
      <Box>
        <Box display={"flex"} flexDirection={"row"} mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("article.keyword")}
          </StyledTypography>
          <Typography color={"error"}>&nbsp;*</Typography>
        </Box>
        <Box>
          <TextField
            required
            placeholder={t("article.keywordPlaceholder")}
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
            variant="standard"
            fullWidth
            autoFocus
            autoComplete={"off"}
            inputProps={{ maxLength: 100 }}
          />
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography variant={"body2"}>{keywords.length}/100</Typography>
        </Box>
      </Box>
    </>
  );
}
