import { Route, Routes } from "react-router-dom";
import React from "react";
import Summarize from "../pages/WritingAI/Summarize/Summarize";
import { ArticleProvider } from "../context/ArticleContext";
import ArticleGenerator from "../pages/WritingAI/ArticleGenerator/ArticleGenerator";
import { SlideProvider } from "../context/SlideContext";
import SlideGenerator from "../pages/WritingAI/SlideGenerator/SlideGenerator";
import { VideoScriptProvider } from "../context/VideoScriptContext";
import VideoScriptGenerator from "../pages/WritingAI/VideoScript/VideoScriptGenerator";

const WritingAiRouter = () => {
  return (
    <Routes>
      <Route path="summarizer" element={<Summarize />} />
      <Route
        path="article"
        element={
          <ArticleProvider>
            <ArticleGenerator />
          </ArticleProvider>
        }
      />
      <Route
        path="slide"
        element={
          <SlideProvider>
            <SlideGenerator />
          </SlideProvider>
        }
      />
      <Route
        path="video-script"
        element={
          <VideoScriptProvider>
            <VideoScriptGenerator />
          </VideoScriptProvider>
        }
      />
    </Routes>
  );
};
export default WritingAiRouter;