import { useInView } from "react-intersection-observer";
import { Fade } from "@mui/material";

const FadeInSection = ({ children, timeout = 1000 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div ref={ref} style={{ height: "100%" }}>
      <Fade in={inView} timeout={timeout}>
        <div style={{ height: "100%" }}>{children}</div>
      </Fade>
    </div>
  );
};
export default FadeInSection;
