import React, { createContext, useContext, useEffect, useState } from "react";

const EventContext = createContext({});

export const EventProvider = ({ children }) => {
  const [events, setEvents] = useState({});

  useEffect(() => {
    const eventNames = ["CustomEventName"];

    const handleEvent = (event) => {
      setEvents((prevEvents) => ({
        ...prevEvents,
        [event.type]: event.detail,
      }));
    };

    eventNames.forEach((eventName) => {
      window.addEventListener(eventName, handleEvent);
    });

    return () => {
      eventNames.forEach((eventName) => {
        window.removeEventListener(eventName, handleEvent);
      });
    };
  }, []);

  return <EventContext.Provider value={events}>{children}</EventContext.Provider>;
};

export const useEvent = (eventName) => {
  const events = useContext(EventContext);
  return events[eventName];
};

export const getDataLayer = (eventName) => {
  return window.dataLayer.find((x) => x.event === eventName);
};
