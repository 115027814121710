import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { BoxProps } from "@mui/material/Box";

export const RowCenteredBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export const RowBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  width: "100%",
}));

export const ColumnCenteredBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export const ColumnBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));

export const CustomPaper = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.background.paper,
  borderRadius: "8px",
  // padding: theme.spacing(2, 4, 3),
  height: "100%",
  gap: theme.spacing(4),
  display: "flex",
  flexDirection: "column",
}));
