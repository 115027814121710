const article = {
    library: "Library",
    title: 'SEO | Powerful Article',
    description: 'Tenak AI conducts Google searches and generates articles referencing SEO-rated articles',
    keyword: 'Keyword',
    keywordPlaceholder: 'e.g. iPhone camera',
    target: 'Target Audience',
    targetPlaceholder: 'e.g. 20-30s photographers',
    source: 'Source',
    sourceDescription: 'Please select a reference source.',
    heading: 'Headline Structure',
    headingPlaceholder: 'e.g. \n# heading1\n## heading2\n### heading3',
    headingDescription: 'Enter a headline of over 30 characters, or have AI generate one. (One headline per line)',
    tone: 'Tone of Text',
    additional: 'Additional Instructions (Optional)',
    additionalPlaceholder: 'If there are additional instructions, please enter them here. (Up to 400 characters)',
    useGoogle: 'Use Google search results to generate headlines (If not used, refer to the source)',
    srcType: {
        url: 'URL',
        urlPlaceholder: 'https://www.example.com',
        urlDescription: 'Enter the URL of the website for reference.',
        youtube: 'YouTube',
        youtubePlaceholder: 'https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )',
        youtubeDescription: 'Enter the URL of the YouTube video for reference.',
        google: 'Google Search',
        googleDescription: 'Referencing the top results, related questions, and keywords from Google search.',
        text: 'Text',
        textPlaceholder: 'Text (up to 4,000 characters)',
        textDescription: 'Enter the text for reference.',
        none: 'None',
        noneDescription: 'Tenak AI will generate the text.',
    },
    generateHeadings: 'Generate Headings with Tenak AI',
    generateHeadingsDescription: 'Tenak AI generates headlines. Keywords must be entered before execution.',
    createWithoutText: 'Create File with Headings Only',
    submit: 'Generate Article',
    error: {
        createHeading: 'Please enter a headline of over 30 characters, or have AI generate one.',
        invalidYoutubeUrl: 'Please enter a valid YouTube URL.',
        invalidUrl: 'Please enter a valid URL.',
        invalidText: 'Please enter text. (Up to 4,000 characters)',
        invalidHeadings: 'Please enter a headline of over 30 characters, or have AI generate one.',
        invalidKeywords: 'Please enter keywords.',
        invalidHeadingsLength: 'Please enter up to 20 headlines.',
    }
}
export default article;