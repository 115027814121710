import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface ValidationTextFieldProps extends Omit<TextFieldProps, "someProp"> {
  tooltipMessage?: string;
  toolTipPlacement?:
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | "top";
  isRevealPassword?: boolean;
  onTogglePasswordVisibility?: () => void;
}

const ValidationTextField: React.FC<ValidationTextFieldProps> = ({
  tooltipMessage,
  toolTipPlacement = "left",
  isRevealPassword,
  onTogglePasswordVisibility,
  ...textFieldProps // 残りの TextFieldProps を受け取る
}) => {
  const [focused, setFocused] = React.useState(false);
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true);
    textFieldProps.onFocus?.(e);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false);
    textFieldProps.onBlur?.(e);
  };

  const inputProps = onTogglePasswordVisibility
    ? {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onTogglePasswordVisibility} edge="end">
              {isRevealPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }
    : {};

  return (
    <Tooltip open={focused} title={tooltipMessage} placement={toolTipPlacement} arrow>
      <TextField
        {...textFieldProps}
        type={isRevealPassword ? "text" : textFieldProps.type}
        error={textFieldProps.error}
        helperText={textFieldProps.error ? textFieldProps.helperText : ""}
        onFocus={handleFocus}
        onBlur={handleBlur}
        InputProps={{ ...inputProps, ...textFieldProps.InputProps }}
      />
    </Tooltip>
  );
};
export default ValidationTextField;