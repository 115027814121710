import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";
import { useVideoScriptContext } from "../../../../context/VideoScriptContext";
import file_sidebar from "../../../../json/file_sidebar.json";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Tone() {
  const { t } = useTranslation();
  const toneList = file_sidebar.toneList;
  const context = useVideoScriptContext();

  return (
    <>
      {/* トーン */}
      <Box>
        <Box mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("videoScript.tone")}
          </StyledTypography>
        </Box>
        <Box>
          <FormControl variant="outlined" fullWidth>
            <Select
              variant="standard"
              value={context.tone}
              label={t("videoScript.tone")}
              onChange={(e) => context.setTone(e.target.value)}
            >
              <MenuItem value="">&nbsp;</MenuItem>
              {toneList.map((item, index) => (
                <MenuItem value={item} key={index}>
                  {t(`textEditor.sidebar.toneType.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
