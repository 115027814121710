const drawer = {
  home: "Home",
  notesNebula: "Notes Nebula",
  notesNebulaDescription: "記事投稿プラットフォーム",
  browsing: "ブラウジング",
  library: "ライブラリ",
  images: "Image",
  text: "Text",
  mindmap: "Mind map",
  voice: "Voice",
  writingAI: "ライティング AI",
  AIs: "Tenak AI",
  imageGenerator: " 画像生成 AI",
  voiceGenerator: "音声\n生成 AI",
  colorGenerator: "カラーパレット\n生成 AI",
  mindmapGenerator: "マインドマップ\n生成 AI",
  templates: "プロンプト\nテンプレート",
  aiEditor: "AIテキストエディター",
  createText: "テキストを作成",
  createCarousel: "カルーセルを作成",
  speedMode: "速度＆節約モード",
  intelligentMode: "高知能モード",
  modeDescription:
    "Tenak AI のテキスト生成のAIモードを変更できます。スピードモードは、AIの処理速度の最適化とクレジットを削減し、インテリジェントモードは知能を優先します。",
  plan: "プランの管理",
};
export default drawer;
