import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

const DarkModeContext = createContext<{
  darkMode: boolean;
  toggleDarkMode: () => void;
} | null>(null);

export const DarkModeProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const savedDarkMode = Cookies.get("darkMode");
  const initialDarkMode = savedDarkMode === undefined ? true : savedDarkMode === "true";
  const [darkMode, setDarkMode] = useState(initialDarkMode);

  const toggleDarkMode = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    Cookies.set("darkMode", newDarkMode.toString(), { expires: 365 });
  };

  return <DarkModeContext.Provider value={{ darkMode, toggleDarkMode }}>{children}</DarkModeContext.Provider>;
};

export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};
