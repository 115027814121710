const textEditor = {
  title: "Tenak AI テキストエディター",
  library: "ライブラリ（保存していない内容は失われます）",
  thumbnail: "サムネイル画像を設定すると表示されます",
  preview: "プレビュー",
  sidebar: {
    aiWritingAssistant: "Writing Assistant",
    aiWritingAssistantDescription: "AIライティングアシスタントを使用して、文章を作成します。",
    publicSettings: "公開設定",
    publicSettingsDescription: "公開設定を変更して、他のユーザーと共有します。",
    publicStatus: {
      public: "公開",
      publicDescription: "ノーツ・ネビュラで公開されます。",
      private: "非公開",
      privateDescription: "誰にも公開されません。",
      shared: "共有リンク",
      sharedDescription: "共有リンクを知っている人だけに公開されます。",
    },
    thumbnail: "サムネイル画像",
    thumbnailDescription: "サムネイル画像は記事のトップに表示されます。(1200 * 630px 推奨)",
    upload: "画像をアップロード",
    generated: "生成画像から選択",
    unsplash: "Unsplashから選択",
    tags: "ハッシュタグ",
    tagsPlaceholder: "Enterキーで追加",
    language: "言語設定",
    languageDescription: "記事の言語を選択してください。これはターゲットユーザーの言語です。",
    tagsDescription: "タグを追加して、他のユーザーと共有します。",
    target: "ターゲット（オプション）",
    additional: "補足メッセージ（オプション）",
    createTitles: "タイトル",
    createNarration: "ナレーション",
    createIntroduction: "導入文",
    createConclusion: "まとめ・結論",
    createTags: "ハッシュタグ",
    createCustom: "カスタムメッセージ",
    createContinue: "文章の続き",
    createSummary: "要約",
    createVoice: "音声",
    createRephrase: "リライト",
    createLonger: "長文化",
    createImages: "画像生成",
    createTranslate: "AI 翻訳",
    createTone: "トーン変更",
    createText: "文章作成",
    createCopyWriting: "コピーライティング",
    toneType: {
      typeFormal: "フォーマル",
      typeCasual: "カジュアル",
      typeHumorous: "ユーモア",
      typeProfessional: "プロフェッショナル",
      typePassionate: "情熱的",
      typeMen: "男性的",
      typeWomen: "女性的",
    },
    tagType: {
      instagram: "Instagram",
      twitter: "Twitter",
      facebook: "Facebook",
      linkedin: "LinkedIn",
      tiktok: "TikTok",
      youtube: "YouTube",
      pinterest: "Pinterest",
      x: "X (Twitter)",
      threads: "Threads",
    },
    customLabel: "メッセージ",
    createTitlesDescription: "タイトルのアイディアを複数生成",
    createNarrationDescription: "文章から動画向けのナレーションを生成",
    createIntroductionDescription: "記事の導入文を生成",
    createConclusionDescription: "記事の要点のまとめ・結論を生成",
    createTagsDescription: "作成するハッシュタグを掲載するSNSを選択して下さい。",
    createCustomDescription: "Tenak AIに文章を生成させることができます。",
    createContinueDescription: "エディターのカーソル位置から続きを生成します。",
    createContinueDescription2: "続きを生成するカルーセルを選択して下さい。",
    createSummaryDescription: "長文の要約は要約文生成ツールの方が高精度です。",
    createRephraseDescription: "リライトする文章を以下に入力して下さい。",
    createTextDescription: "見出し、キーワードから本文を生成します。見出しやキーワードを以下に入力して下さい。",
    createLongerDescription: "短い文章（約1,000文字以内）を長文にします。",
    createTranslateDescription: "従来の翻訳と異なり、文脈を理解します。翻訳後の言語を選択して下さい。",
    createToneDescription: "変更後の文章のトーンを選択して下さい。",
    createCheckText: "文章校正",
    createCheckTextDescription: "文章の誤字脱字をチェックし、読みやすい文章に校正します。",
    createFAQ: "FAQ",
    createFAQDescription: "文章で言及していないが、よくある質問を想定し生成します。",
    createCopyWritingDescription: "文章から幾つかの見出しやコピーライティングを生成",
    createVoiceDescription: "音声化したいテキストを入力してください。",
    createImagesDescription: "文章から自動的に画像生成用のプロンプトを生成し、画像生成を生成します。",
    createVoiceButton: "保存して音声ジェネレーターに移動",
    createImagesButton: "画像生成に移動",
    maxLengthInfo: "高精度な結果をもたらすために、1,000文字以内のメッセージを推奨",
    copy: "クリップボードにコピー",
    replace: "選択範囲と置換",
    selection: "対象の文章",
    response: "AI ANSWER",
    copyFromSelection: "選択範囲を自動挿入",
    generate: "Tenak AI で生成",
    generateImage: "STUDIO JUNO で画像生成",
    imageModel: "画像生成AIのモデル",
    imageAnswer: {
      info: "過去に生成した画像は、エディター > ツールバー > 生成画像から確認できます。",
      info2: "詳細な画像生成はSTUDIO JUNOで行え、生成された画像はいつでもエディターで利用可能です。",
      goJuno: "保存して「STUDIO JUNO」へ",
      insert: "カーソル位置に挿入",
    },
    error: {
      message: "必要な項目が入力、選択されていません。",
    },
    clear: "クリア",
  },
  kreaVerse: {
    title: "KreaVerse",
    description:
      "KreaVerse は文章補完AIです。[Alt + /]でカーソル位置から文脈を理解し、AIが補完文章を生成します。(クレジットを消費)\n生成された文章は[TAB]もしくは[Enter]キーで挿入。",
    ready: "KreaVerse Is Ready!",
    generating: "KreaVerse Is Generating...",
    waiting: "KreaVerse Generation Complete!",
    error: "KreaVerseは1文以上の文章を入力してからご利用ください。",
  },
  unsplash: {
    title: "Unsplashから画像を追加",
    description: "1,000,000点以上の無料画像",
    search: "検索",
    noResults: "検索結果がありませんでした。",
  },
  toolbars: {
    heading: {
      lg: "大見出し",
      md: "中見出し",
      sm: "小見出し",
      normal: "標準",
    },
    linkCard: "リンクカードを作成",
    upload: "画像をアップロード",
    unsplash: "Unsplashで画像を検索",
    generated: "生成画像からアップロード",
    video: "YouTube/動画 のURL",
  },
  processing: {
    title: "生成中...",
    description: "生成が完了すると自動的に表示されます。",
  },
  save: "保存 (cmd + s)",
};
export default textEditor;
