import React from "react";
import { useAlert } from "../../context/AlertContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

// MuiAlert コンポーネントを Snackbar で使うためのラッパー
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = () => {
  const { alertType, alertMessage, showAlert, closeAlert } = useAlert();

  return (
    <Snackbar
      open={showAlert}
      autoHideDuration={6000}
      onClose={closeAlert}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <Alert onClose={closeAlert} severity={alertType as AlertColor} sx={{ whiteSpace: "break-spaces" }}>
        {alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
