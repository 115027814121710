import BoltIcon from "@mui/icons-material/Bolt";
import GppBadIcon from "@mui/icons-material/GppBad";
import GppGoodIcon from "@mui/icons-material/GppGood";
import { Divider, Paper, useTheme } from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Meta from "../../../components/common/Meta";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import useSubscription from "../../../hooks/useSubscription";
import { GradationButton } from "../../../utils/gradationButton";
import { GradientTypography } from "../../../utils/gradientTypography";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import UpgradeModal from "../../Preferences/components/UpgradeModal";
import AboutCredit from "./components/AboutCredit";
import FAQ from "./components/FAQ";

const Pricing = () => {
  const { t } = useTranslation();
  const plan = { free: 0, basic: 1, standard: 2, premium: 3 };
  const { isLoggedIn } = useLoginStatus();
  const loginContext = useLoginModalContext();
  const [currentPlan, setCurrentPlan] = useState("free");
  const [open, setOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useCustomNavigate();
  const theme = useTheme();
  const { getSubscription } = useSubscription();

  const LabelBox = (props: BoxProps) => {
    return (
      <Box
        {...props}
        sx={{
          display: "flex",
          alignItems: "flex-end",
          flexDirection: "row",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        {props.children}
      </Box>
    );
  };

  const handleSubscribeClick = async (plan: string) => {
    if (isLoggedIn) {
      // if (plan === 'free') return;
      setSelectedPlan(plan);
      setOpen(true);
    } else {
      loginContext.setActiveModal("login");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const res = await getSubscription();
          if (res.subscription) {
            setCurrentPlan(res.subscription.lookup_key);
          }
          setLoading(false);
        } catch (error) {
          console.error("Subscription data fetching failed", error);
        }
      };
      fetchData();
    }
  }, []);

  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "description", content: t("meta.pricing.description") },
  ];

  return (
    <>
      <Meta title={t("meta.pricing.title")} meta={meta} />
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          minHeight: "100vh",
          backgroundImage: `url("/images/pricing/top.jpg")`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          "&::before": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(1, 1, 1, 0.5)",
            backdropFilter: "blur(10px)",
          },
        }}
      >
        <Box
          sx={{
            zIndex: 1,
            textAlign: "center",
            color: "white",
            width: "100%",
          }}
        >
          <Container maxWidth={"xl"} sx={{ mb: 8, mt: { xs: 8, md: 4 } }}>
            <ColumnCenteredBox>
              <DrawerHeader />
              <Box mb={8}>
                <Typography variant="h2">{t("title")}</Typography>
                <Typography variant="h5" component={"p"}>
                  {t("pricing.description")}
                </Typography>
              </Box>
              <Grid container spacing={2} justifyContent={"center"} sx={{ width: "100%" }}>
                {Object.keys(plan).map((p, i) => {
                  return (
                    <Grow in={!loading} timeout={(i + 1) * 500} key={i}>
                      <Grid item xs={12} md={3}>
                        <Paper key={i} sx={{ p: 2, textAlign: "center" }}>
                          <Box sx={{ width: "100%" }}>
                            <GradientTypography variant="h5" mb={2}>
                              {t(`pricing.${p}.title`)}
                            </GradientTypography>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-end",
                                flexDirection: "row",
                                justifyContent: "center",
                                mb: 2,
                              }}
                            >
                              <Typography variant="h4" component={"p"} mr={1}>
                                ${t(`pricing.${p}.price`)}
                              </Typography>
                              <Typography variant="subtitle1" component={"p"} sx={{ mr: 1 }}>
                                USD
                              </Typography>
                              <Typography variant="subtitle1" component={"p"}>
                                {t(`pricing.perMonth`)}
                              </Typography>
                            </Box>

                            <Divider sx={{ mb: 2 }} />

                            <Box mb={2} gap={1}>
                              {/* クレジット */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.${p}.credit`)}&nbsp;
                                  {t(`pricing.credit`)}
                                </Typography>
                              </LabelBox>

                              {/* AI エンジン */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.aiEngine`)}
                                </Typography>
                              </LabelBox>

                              {/* 画像生成 */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.juno`)}
                                </Typography>
                              </LabelBox>

                              {/* 生成画像の非公開設定 */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  {["free", "basic"].includes(p) ? (
                                    <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                                  ) : (
                                    <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  )}
                                  {t(`pricing.junoPrivate`)}
                                </Typography>
                              </LabelBox>

                              {/* 全ての画像生成エンジン */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  {["free"].includes(p) ? (
                                    <>
                                      <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                                      {t(`pricing.junoEnginePart`)}
                                    </>
                                  ) : (
                                    <>
                                      <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                      {t(`pricing.junoEngine`)}
                                    </>
                                  )}
                                </Typography>
                              </LabelBox>

                              {/* 画像生成モデル */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.junoModel`)}
                                </Typography>
                              </LabelBox>

                              {/* MindMap */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.mindmap`)}
                                </Typography>
                              </LabelBox>

                              {/* MindMap */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  {["free"].includes(p) ? (
                                    <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                                  ) : (
                                    <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  )}
                                  {t(`pricing.export`)}
                                </Typography>
                              </LabelBox>

                              {/* 音声生成 */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.voice`)}
                                </Typography>
                              </LabelBox>

                              {/* Template */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.template`)}
                                </Typography>
                              </LabelBox>

                              {/* AI Text editor */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                  {t(`pricing.textEditor`)}
                                </Typography>
                              </LabelBox>

                              {/* サポート */}
                              <LabelBox>
                                <Typography
                                  variant="body1"
                                  component={"p"}
                                  mr={1}
                                  alignItems={"center"}
                                  display={"inline-flex"}
                                >
                                  {["free"].includes(p) ? (
                                    <>
                                      <GppBadIcon fontSize={"small"} color={"error"} sx={{ mx: 1 }} />
                                      {t(`pricing.supportNo`)}
                                    </>
                                  ) : ["basic"].includes(p) ? (
                                    <>
                                      <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                      {t(`pricing.support`)}
                                    </>
                                  ) : (
                                    <>
                                      <GppGoodIcon fontSize={"small"} color={"success"} sx={{ mx: 1 }} />
                                      {t(`pricing.supportPriority`)}
                                    </>
                                  )}
                                </Typography>
                              </LabelBox>
                            </Box>

                            {isLoggedIn && currentPlan === p ? (
                              <Button variant="contained" disabled fullWidth>
                                {t("preferences.planAndBillings.currentPlan")}
                              </Button>
                            ) : (
                              <>
                                {plan[currentPlan] >= plan[p] ? (
                                  <Box height={36.5} />
                                ) : (
                                  <GradationButton
                                    fullWidth
                                    onClick={() => {
                                      handleSubscribeClick(p);
                                    }}
                                  >
                                    <BoltIcon sx={{ mr: 1 }} />
                                    {isLoggedIn ? t("preferences.planAndBillings.upgrade") : t("pricing.subscribe")}
                                  </GradationButton>
                                )}
                              </>
                            )}
                          </Box>
                        </Paper>
                      </Grid>
                    </Grow>
                  );
                })}
              </Grid>
            </ColumnCenteredBox>
          </Container>
        </Box>
      </Box>

      {/* クレジットについて */}
      <AboutCredit />
      <FAQ />
      <UpgradeModal
        open={open}
        handleClose={() => setOpen(false)}
        selectedPlan={selectedPlan}
        currentPlan={currentPlan}
      />

      <ColumnCenteredBox>
        <Button
          variant="text"
          size="small"
          sx={{ color: theme.palette.mode === "dark" ? "common.white" : "common.black" }}
          onClick={() => navigate("/legal-notice")}
        >
          {t("pricing.legalNotice")}
        </Button>
      </ColumnCenteredBox>
    </>
  );
};

export default Pricing;
