import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Fade } from "@mui/material";
import Fab from "@mui/material/Fab";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { useLocation } from "react-router-dom";

const ScrollToTopButton: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isMounted, setIsMounted] = useState(true); // このstateを追加
  const theme = useTheme();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const checkScrollPosition = () => {
    const scrolled = window.scrollY;
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.scrollHeight;
    const threshold = 600;

    if (scrolled > threshold && scrolled < docHeight - windowHeight - threshold) {
      setIsVisible(true);
      setIsMounted(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    if (!isVisible && isMounted) {
      const timer = setTimeout(() => {
        setIsMounted(false); // 500ms後にコンポーネントをアンマウント
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [isVisible, isMounted]);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollPosition);
    return () => {
      window.removeEventListener("scroll", checkScrollPosition);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (!isMounted) return null;

  return (
    <Fade in={isVisible} timeout={500}>
      <Fab
        aria-label="scroll down"
        sx={{
          position: "fixed",
          bottom: "16px",
          right: "16px",
          color: theme.palette.grey[400],
          backgroundColor: theme.palette.grey[800],
          "&:hover": {
            color: theme.palette.grey[200],
            backgroundColor: theme.palette.grey[700],
          },
        }}
        onClick={scrollToTop}
      >
        <ArrowUpwardIcon />
      </Fab>
    </Fade>
  );
};
export default ScrollToTopButton;