import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Fade,
  FormControlLabel,
  Hidden,
  lighten,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InfoIcon from "@mui/icons-material/Info";
import { useParams } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { setBrowsingMode, setInputQuery, setSendOnCtrlEnter } from "../../../redux/slices/browsingSlice";
import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import { streamStop } from "../../../utils/stream_stop";
import Grow from "@mui/material/Grow";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import debounce from "lodash/debounce";

import { scrollToBottom } from "../../../utils/utils";
import Paper from "@mui/material/Paper";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import { alpha } from "@mui/material/styles";

interface InputBoxProps {
  sendMessage: (message: string) => Promise<void>;
  historyData: any[];
  stopStreamController: () => void;
  historyRef: any;
}

function InputBox(props: InputBoxProps) {
  const { sendMessage, historyData, stopStreamController, historyRef } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { workspaceUuid } = useParams();
  const browsingMode = useSelector((state: RootState) => state.browsing.browsingMode[workspaceUuid!]) || false;
  const sendOnCtrlEnter = useSelector((state: RootState) => state.browsing.sendOnCtrlEnter[workspaceUuid!]) || false;
  const processing = useSelector((state: RootState) => state.browsing.processing[workspaceUuid!]);
  const inputQuery = useSelector((state: RootState) => state.browsing.inputQuery[workspaceUuid!] || "");
  const theme = useTheme();
  const [inputError, setInputError] = useState(false);

  const [fadeKey, setFadeKey] = useState(0);

  // ショートカットキーで送信
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !processing &&
      sendOnCtrlEnter &&
      ((event.ctrlKey && event.key === "Enter") || (event.metaKey && event.key === "Enter"))
    ) {
      sendMessage(inputQuery);
      dispatch(setInputQuery({ workspaceUuid: workspaceUuid!, inputQuery: "" }));
    }
  };

  // 最下部のフッターと重ならないようにリッスン
  const [atBottom, setAtBottom] = React.useState(true);
  const handleScroll = () => {
    const isAtBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight - 100;
    setAtBottom(isAtBottom);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [workspaceUuid]);

  // 設定取得
  useEffect(() => {
    const fetchData = async () => {
      const url = "/api/v1/workspace/" + workspaceUuid + "/settings";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const data = await response.json();
      dispatch(setBrowsingMode({ workspaceUuid: workspaceUuid!, browsingMode: data["browsing_mode"] }));
      dispatch(setSendOnCtrlEnter({ workspaceUuid: workspaceUuid!, sendOnCtrlEnter: data["key_mode"] }));
    };
    fetchData();
    setFadeKey((prevKey) => prevKey + 1); // Fadeのキーを更新
  }, [workspaceUuid]);

  // 設定変更
  type BodyType = {
    browsing_mode?: boolean | null;
    key_mode?: boolean | null;
  };

  const handleSettingsChange = async (browsingMode: boolean | null, sendOnCtrlEnter: boolean | null) => {
    const url = "/api/v1/workspace/" + workspaceUuid + "/settings";
    const csrftoken = Cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    });
    const body: BodyType = {};
    if (browsingMode !== null) {
      body["browsing_mode"] = browsingMode;
    }
    if (sendOnCtrlEnter !== null) {
      body["key_mode"] = sendOnCtrlEnter;
    }
    const response = await fetch(url, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(body),
    });
    const data = await response.json();
    dispatch(setBrowsingMode({ workspaceUuid: workspaceUuid!, browsingMode: data["browsing_mode"] }));
    dispatch(setSendOnCtrlEnter({ workspaceUuid: workspaceUuid!, sendOnCtrlEnter: data["key_mode"] }));
  };

  // chatResponseが変更されるたびに実行されるuseEffect
  React.useEffect(() => {
    if (atBottom) {
      scrollToBottom(200);
    }
  }, [document.body.offsetHeight]);

  // Workspaceが変更されたら最下部にスクロール
  React.useEffect(() => {
    scrollToBottom(200);
  }, [workspaceUuid]);

  // Fixed Box のメッセージ送信用の横幅を取得
  const elementRef = useRef<HTMLDivElement | null>(null);

  const send = () => {
    sendMessage(inputQuery);
    dispatch(setInputQuery({ workspaceUuid: workspaceUuid!, inputQuery: "" }));
  };

  // ユーザーの入力バリデーション
  const handleInputQueryChange = (value: string) => {
    dispatch(setInputQuery({ workspaceUuid: workspaceUuid!, inputQuery: value }));
    if (value.length > 6000) {
      setInputError(true);
    } else {
      setInputError(false);
    }
  };

  const historyExists = historyData.length > 0;
  const bgcolor = () => {
    if (!historyExists) return "transparent";
    return theme.palette.mode === "dark"
      ? alpha(lighten(theme.palette.background.paper, 0.08), 0.8)
      : atBottom
        ? "white"
        : "rgba(255, 255, 255, 0.5)";
  };

  const handleStreamStop = () => {
    stopStreamController();
    streamStop(workspaceUuid!);
  };
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const updateWidth = () => {
      if (historyRef?.current) {
        setWidth(historyRef.current.offsetWidth);
      }
    };

    const debouncedUpdateWidth = debounce(updateWidth, 0);

    updateWidth();

    // ResizeObserverで親のサイズ変更を監視
    const resizeObserver = new ResizeObserver(debouncedUpdateWidth);
    if (historyRef?.current) {
      resizeObserver.observe(historyRef.current);
    }

    return () => {
      if (historyRef?.current) {
        resizeObserver.unobserve(historyRef.current);
      }
    };
  }, [historyRef]);

  return (
    <ColumnCenteredBox sx={{ width: "inherit" }}>
      {/* input message */}
      <Fade in={true} key={fadeKey} timeout={1500}>
        <Paper
          elevation={0}
          ref={elementRef}
          sx={{
            width: historyExists ? width : "100%",
            mb: 1,
            borderRadius: atBottom && historyExists ? 2 : 0,
            position: historyExists ? "fixed" : "relative",
            bottom: 0,
            backgroundColor: bgcolor,
            backdropFilter: "blur(5px)",
            zIndex: 100,
            px: { xs: 2, sm: 4 },
            pt: 3,
            pb: { xs: 1, sm: 1 },
            transition: "all 0.3s ease",
          }}
        >
          <Box sx={{ mb: 0.5, width: "100%" }}>
            <TextField
              sx={{ width: "100%" }}
              value={inputQuery}
              onChange={(e) => handleInputQueryChange(e.target.value)}
              variant="outlined"
              label={t("browsing.searchPlaceholder")}
              type={"text"}
              maxRows={20}
              autoComplete={"off"}
              autoFocus={true}
              color={"primary"}
              onKeyDown={handleKeyDown}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    color="secondary"
                    onClick={() => {
                      if (inputQuery.length > 0 && !processing) {
                        send();
                      }
                    }}
                  >
                    <IconButton>
                      {processing ? (
                        <CircularProgress size={20} color="primary" />
                      ) : (
                        <SendIcon color={inputQuery.length > 0 ? "secondary" : "disabled"} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: { borderRadius: 5, backgroundColor: theme.palette.background.paper },
              }}
              inputProps={{ maxLength: 6000 }}
              error={inputError}
            />
          </Box>

          {/* option bar */}
          <Box sx={{ display: "flex", width: "100%", alignItems: "center", px: { md: 2 } }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                mr: { sx: 0, md: 2 },
              }}
            >
              <Typography variant="body1" color="textprimary" sx={{ whiteSpace: "nowrap" }}>
                {inputQuery.length} / 6000
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <Grow in={processing} timeout={1000}>
                <Button
                  disableElevation
                  size={"small"}
                  onClick={() => handleStreamStop()}
                  color={"error"}
                  sx={{ alignItems: "center", display: "flex" }}
                >
                  <StopCircleIcon fontSize={"small"} sx={{ mr: { sx: 0, md: 1 } }} />
                  <Hidden smDown>
                    <Typography variant={"button"}>{t("browsing.stopGenerating")}</Typography>
                  </Hidden>
                </Button>
              </Grow>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <FormControlLabel
                sx={{ mr: 0 }}
                label={
                  <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="body2" color="textprimary">
                      {t("browsing.browsingMode")}
                    </Typography>
                    <Tooltip title={t("browsing.browsingModeDescription")}>
                      <IconButton size="small" sx={{ ml: 1, color: "textprimary" }}>
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                }
                control={<Switch checked={browsingMode} onChange={() => handleSettingsChange(!browsingMode, null)} />}
              />
              <Hidden smDown>
                <FormControlLabel
                  sx={{ mr: 0, ml: 1 }}
                  label={
                    <Typography variant="body2" color="textprimary">
                      {t("browsing.send")}
                    </Typography>
                  }
                  control={
                    <Switch checked={sendOnCtrlEnter} onChange={() => handleSettingsChange(null, !sendOnCtrlEnter)} />
                  }
                />
              </Hidden>
            </Box>
          </Box>
        </Paper>
      </Fade>
    </ColumnCenteredBox>
  );
}

export default InputBox;
