import ArticleIcon from "@mui/icons-material/Article";
import FolderIcon from "@mui/icons-material/Folder";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import ShortTextIcon from "@mui/icons-material/ShortText";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { Grid, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import WritingAiRouter from "../../../routes/WritingAiRouter";
import { GradientTypography } from "../../../utils/gradientTypography";
import { ColumnBox } from "../../../utils/styledBox";
import { uiHeight } from "../../../utils/uiHeight";
import Container from "@mui/material/Container";

const tabConfig = [
  {
    value: "article",
    icon: <ArticleIcon />,
    titleKey: "article.title",
    descriptionKey: "article.description",
    path: "/writing-ai/article",
  },
  {
    value: "slide",
    icon: <ViewModuleIcon />,
    titleKey: "slide.title",
    descriptionKey: "slide.description",
    path: "/writing-ai/slide",
  },
  {
    value: "video-script",
    icon: <MovieFilterIcon />,
    titleKey: "videoScript.title",
    descriptionKey: "videoScript.description",
    path: "/writing-ai/video-script",
  },
  {
    value: "summarizer",
    icon: <ShortTextIcon />,
    titleKey: "summarize.title",
    descriptionKey: "summarize.description",
    path: "/writing-ai/summarizer",
  },
];

export default function Layout() {
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState("article");
  const navigate = useCustomNavigate();

  useEffect(() => {
    const currentTab = tabConfig.find((tab) => location.pathname.includes(tab.value));
    setTabValue(currentTab ? currentTab.value : "summarizer");
  }, [location.pathname]);

  return (
    <Container maxWidth={"xl"}>
      <DrawerHeader />

      <Paper
        elevation={0}
        sx={{
          mx: { xs: 0, sm: 2 },
          backgroundColor: "background.paper",
        }}
      >
        <Grid
          container
          spacing={0}
          sx={{
            mb: 2,
            height: "100%",
          }}
        >
          <Grid item xs={12} sm={3}>
            <Paper
              elevation={0}
              sx={{
                backgroundColor: "background.custom1",
                minHeight: `calc(${uiHeight()} - 16px)`,
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ p: 2 }}>
                <GradientTypography
                  variant={"h6"}
                  component={"h2"}
                  sx={{ ml: 1, display: "flex", alignItems: "center" }}
                >
                  Writing AI
                </GradientTypography>
              </Box>

              <Tabs value={tabValue} variant="scrollable" orientation="vertical">
                {tabConfig.map((tab) => (
                  <Tab
                    key={tab.value}
                    value={tab.value}
                    onClick={() => navigate(tab.path)}
                    icon={
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        width={"100%"}
                        gap={1}
                      >
                        {tab.icon}
                        <ColumnBox sx={{ textAlign: "left" }}>
                          <Typography variant={"button"} whiteSpace={"nowrap"}>
                            {t(tab.titleKey)}
                          </Typography>
                          <Typography variant={"caption"}>{t(tab.descriptionKey)}</Typography>
                        </ColumnBox>
                      </Box>
                    }
                  />
                ))}
              </Tabs>

              <Box flexGrow={1} />
              <Button
                disableElevation={true}
                onClick={() => navigate("/library/text?page=1&query=&sort=-updated_at")}
                variant={"contained"}
              >
                <FolderIcon sx={{ mr: 1 }} />
                <Typography variant={"button"}>{t("article.library")}</Typography>
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={9} sx={{ overflow: "auto", height: `calc(${uiHeight()} - 16px)` }}>
            <Box sx={{ p: { xs: 2, sm: 4 } }}>
              <WritingAiRouter />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
