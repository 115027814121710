import Cookies from "js-cookie";

export const streamStop = async (uuid: string) => {
  console.log("stop");
  const url = "/api/v1/workspace/" + uuid + "/create";
  const csrftoken = Cookies.get("csrftoken");
  const headers = new Headers({
    "Content-Type": "application/json",
    "X-CSRFToken": csrftoken!,
  });

  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
};
