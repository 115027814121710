import React, { useState } from "react";
import { Skeleton } from "@mui/material";

const ImageWithSkeleton = ({ src, alt, style, skeltonStyle }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <div style={{ position: "relative", ...style }}>
      {!imageLoaded && <Skeleton variant={"rectangular"} sx={{ ...skeltonStyle }} />}
      <img
        src={src}
        alt={alt}
        style={{
          display: imageLoaded ? "block" : "none",
          ...style,
        }}
        onLoad={() => setImageLoaded(true)}
      />
    </div>
  );
};
export default ImageWithSkeleton;
