import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import Crop75Icon from "@mui/icons-material/Crop75";
import CropDinIcon from "@mui/icons-material/CropDin";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiEngineList, useJunoImageGeneratorContext } from "../../../context/ImageGeneratorContext";

const ParamsSDSize = () => {
  const { t } = useTranslation();
  const context = useJunoImageGeneratorContext();
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu") || "img2img";
  const ai_engine = (params.get("ai_engine") as AiEngineList) || "";
  let upscale_line = 832;
  if (ai_engine === "SDXL" && menu === "txt2img") {
    upscale_line = 1408;
  }

  const handleAspectRatioChange = (aspect: number) => {
    if (ai_engine === "SD") {
      if (aspect === 169) {
        context.setWidthPartial("SD", 896);
        context.setHeightPartial("SD", 512);
      }
      if (aspect === 54) {
        context.setWidthPartial("SD", 768);
        context.setHeightPartial("SD", 616);
      }
      if (aspect === 45) {
        context.setWidthPartial("SD", 616);
        context.setHeightPartial("SD", 768);
      }
      if (aspect === 32) {
        context.setWidthPartial("SD", 768);
        context.setHeightPartial("SD", 512);
      }
      if (aspect === 916) {
        context.setWidthPartial("SD", 512);
        context.setHeightPartial("SD", 896);
      }
      if (aspect === 23) {
        context.setWidthPartial("SD", 512);
        context.setHeightPartial("SD", 768);
      }
      if (aspect === 11) {
        context.setWidthPartial("SD", 768);
        context.setHeightPartial("SD", 768);
      }
    } else if (ai_engine === "SDXL") {
      if (aspect === 169) {
        context.setWidthPartial("SDXL", 1344);
        context.setHeightPartial("SDXL", 832);
      }
      if (aspect === 54) {
        context.setWidthPartial("SDXL", 1280);
        context.setHeightPartial("SDXL", 1024);
      }
      if (aspect === 45) {
        context.setWidthPartial("SDXL", 1024);
        context.setHeightPartial("SDXL", 1280);
      }
      if (aspect === 32) {
        context.setWidthPartial("SDXL", 1216);
        context.setHeightPartial("SDXL", 832);
      }
      if (aspect === 916) {
        context.setWidthPartial("SDXL", 768);
        context.setHeightPartial("SDXL", 1344);
      }
      if (aspect === 23) {
        context.setWidthPartial("SDXL", 832);
        context.setHeightPartial("SDXL", 1216);
      }
      if (aspect === 11) {
        context.setWidthPartial("SDXL", 1024);
        context.setHeightPartial("SDXL", 1024);
      }
    }
  };

  const selectedAspectRatio = () => {
    if (ai_engine === "SD") {
      if (context.width.SD === 768 && context.height.SD === 768) {
        return 11;
      }
      if (context.width.SD === 768 && context.height.SD === 512) {
        return 32;
      }
      if (context.width.SD === 512 && context.height.SD === 768) {
        return 23;
      }
      if (context.width.SD === 768 && context.height.SD === 616) {
        return 54;
      }
      if (context.width.SD === 616 && context.height.SD === 768) {
        return 45;
      }
      if (context.width.SD === 896 && context.height.SD === 512) {
        return 169;
      }
      if (context.width.SD === 512 && context.height.SD === 896) {
        return 916;
      }
    } else if (ai_engine === "SDXL") {
      if (context.width.SDXL === 1024 && context.height.SDXL === 1024) {
        return 11;
      }
      if (context.width.SDXL === 1216 && context.height.SDXL === 832) {
        return 32;
      }
      if (context.width.SDXL === 832 && context.height.SDXL === 1216) {
        return 23;
      }
      if (context.width.SDXL === 1280 && context.height.SDXL === 1024) {
        return 54;
      }
      if (context.width.SDXL === 1024 && context.height.SDXL === 1280) {
        return 45;
      }
      if (context.width.SDXL === 1344 && context.height.SDXL === 832) {
        return 169;
      }
      if (context.width.SDXL === 768 && context.height.SDXL === 1344) {
        return 916;
      }
    }
  };

  useEffect(() => {
    if (ai_engine === "SD") {
      if (context.width.SD && context.width.SD > 1080) {
        context.setWidthPartial("SD", 1080);
      }
      if (context.height.SD && context.height.SD > 1080) {
        context.setHeightPartial("SD", 1080);
      }
    } else if (ai_engine === "SDXL") {
      if (context.width.SDXL && context.width.SDXL > 1984) {
        context.setWidthPartial("SDXL", 1984);
      }
      if (context.height.SDXL && context.height.SDXL > 1984) {
        context.setHeightPartial("SDXL", 1984);
      }
    }
  }, []);

  return (
    <>
      {/* アスペクト比をボタンで選択肢、横幅、縦幅をセット */}
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
        <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(11)}
            color={selectedAspectRatio() === 11 ? "primary" : "default"}
          >
            <CropDinIcon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(11)} sx={{ cursor: "pointer" }}>
            1 : 1
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(169)}
            color={selectedAspectRatio() === 169 ? "primary" : "default"}
          >
            <Crop169Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(169)} sx={{ cursor: "pointer" }}>
            16 : 9
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(32)}
            color={selectedAspectRatio() === 32 ? "primary" : "default"}
          >
            <Crop32Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(32)} sx={{ cursor: "pointer" }}>
            3 : 2
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(54)}
            color={selectedAspectRatio() === 54 ? "primary" : "default"}
          >
            <Crop75Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(54)} sx={{ cursor: "pointer" }}>
            5 : 4
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(916)}
            color={selectedAspectRatio() === 916 ? "primary" : "default"}
          >
            <Crop169Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(916)} sx={{ cursor: "pointer" }}>
            9 : 16
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(23)}
            color={selectedAspectRatio() === 23 ? "primary" : "default"}
          >
            <Crop32Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(23)} sx={{ cursor: "pointer" }}>
            2 : 3
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(45)}
            color={selectedAspectRatio() === 45 ? "primary" : "default"}
          >
            <Crop75Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(45)} sx={{ cursor: "pointer" }}>
            4 : 5
          </Typography>
        </Box>
      </Box>

      {/* 横幅 */}
      {/* <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography variant={"body2"}>{t("juno.input.width")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2}>
          <Slider
            defaultValue={1080}
            value={ai_engine === "SD" ? context.width.SD : context.width.SDXL}
            onChange={(e, value) => context.setWidthPartial(ai_engine as AiEngineList, value as number)}
            min={512}
            max={menu === "img2img" ? 1080 : 1984}
            step={menu === "img2img" ? 8 : 64}
          />
          <Box
            sx={{
              bgcolor: "rgba(0, 0, 0, 0.1)",
              px: 2,
              py: 1,
              justifyContent: "center",
              display: "flex",
              borderRadius: "5px",
            }}
          >
            <TextField
              size={"small"}
              sx={{ width: "100px" }}
              variant={"standard"}
              value={ai_engine === "SD" ? context.width.SD : context.width.SDXL}
              onChange={(e) => context.setWidthPartial(ai_engine as AiEngineList, Number(e.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                readOnly: true,
              }}
            />
          </Box>
        </Box>
      </Box> */}

      {/* 縦幅 */}
      {/* <Box sx={{ display: "flex", flexDirection: "column" }} mb={2}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Typography variant={"body2"}>{t("juno.input.height")}</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2}>
          <Slider
            defaultValue={1080}
            value={ai_engine === "SD" ? context.height.SD : context.height.SDXL}
            onChange={(e, value) => context.setHeightPartial(ai_engine as AiEngineList, value as number)}
            min={512}
            max={menu === "img2img" ? 1080 : 1984}
            step={menu === "img2img" ? 8 : 64}
          />
          <Box
            sx={{
              bgcolor: "rgba(0, 0, 0, 0.1)",
              px: 2,
              py: 1,
              justifyContent: "center",
              display: "flex",
              borderRadius: "5px",
            }}
          >
            <TextField
              sx={{ width: "100px" }}
              size={"small"}
              variant={"standard"}
              value={ai_engine === "SD" ? context.height.SD : context.height.SDXL}
              onChange={(e) => context.setHeightPartial(ai_engine as AiEngineList, Number(e.target.value))}
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
                readOnly: true,
              }}
            />
          </Box>
        </Box>
      </Box> */}
    </>
  );
};
export default ParamsSDSize;
