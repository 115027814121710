const aboutJuno = {
  title: "STUDIO JUNO - 画像生成AI",
  subtitle: "画像生成AIプラットフォーム - Studio Juno",
  description: "STUDIO JUNOで快適な画像生成体験を",
  meta: {
    description: "あなたのアイデアを、AIが美しい画像に。テキストから画像生成、画像編集まで、幅広い機能を搭載。初心者でも簡単に高品質な画像が作成できます。",
  },
  section1: {
    title: "どんな画像でも思い通りに生成",
    t2i: {
      title: "Text to Image",
      description: "文章から画像を生成します。あなたが思い描いたアイデアやコンセプトを、視覚的に美しい高品質な画像として具現化します。",
    },
    i2i: {
      title: "Image to Image",
      description: "画像をもとに新たな画像を生成します。スタイルの共通点を保ちながら、新しい画像を作成することができ、類似度の調整も可能です。"
    },
    upscale: {
      title: "Upscale and Enhance Image",
      description: "古い写真やイラストを鮮明な画像にアップスケールし、欠落した画素を補完して新しい画像として強化します。"
    },
  },
  section2: {
    title: "豊富なモデルで、無限の可能性を",
    card1: {
      title: '人気モデルを網羅',
      description: 'DALL-E 3、Stable Diffusion 3、FLUX.1などの多様なモデルのスタイルと表現力で、あなたの創造性を刺激します。',
    },
    card2: {
      title: '最新かつ革新的な機能',
      description: 'AuraSRなどの最新の技術・モデルを駆使し、より高品質な作品を実現します。',
    },
    card3: {
      title: 'クイックAIテンプレート',
      description: '難しいパラメータの設定などを除外したテンプレートで、簡単にAI画像生成を体験できます。',
    },
  },
  section3: {
    title: "「思い描く画像を、瞬時に現実へ。」",
  },
  try: "STUDIO JUNOを体験する",
}
export default aboutJuno;