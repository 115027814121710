import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

interface User {
  id: number;
  username: string;
  email: string;
  ai_language: string;
  country: string;
  advanced_ai: boolean;
}

interface LoginStatusContextValue {
  isLoggedIn: boolean | null;
  user: User | null;
  loading: boolean;
  checkLoginStatus: () => Promise<{ isLoggedIn: boolean; user: User | null }>;
  refreshUser: () => Promise<void>;
}

const LoginStatusContext = createContext<LoginStatusContextValue | undefined>(undefined);

interface LoginStatusProviderProps {
  children: React.ReactNode;
}

export const LoginStatusProvider: React.FC<LoginStatusProviderProps> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const checkLoginStatus = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/api/v1/auth/check-login-status");
      setIsLoggedIn(response.data.isLoggedIn);
      setUser(response.data.user);
      setLoading(false);
      return { isLoggedIn: response.data.isLoggedIn, user: response.data.user };
    } catch (error) {
      console.error("Failed to check login status:", error);
      setLoading(false);
      return { isLoggedIn: false, user: null };
    }
  };

  // userのai_languageを変更
  const refreshUser = async () => {
    try {
      const response = await axios.get("/api/v1/auth/check-login-status");
      setUser(response.data.user);
    } catch (error) {
      console.error("Failed to change ai_language:", error);
    }
  };

  useEffect(() => {
    checkLoginStatus();
  }, []); // 空の依存配列を使って、コンポーネントのマウント時に一度だけ実行する

  return (
    <LoginStatusContext.Provider value={{ isLoggedIn, user, loading, checkLoginStatus, refreshUser }}>
      {children}
    </LoginStatusContext.Provider>
  );
};

export const useLoginStatus = () => {
  const context = useContext(LoginStatusContext);
  if (!context) {
    throw new Error("useLoginStatus must be used within a LoginStatusProvider");
  }
  return context;
};
