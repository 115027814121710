import React from "react";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PhotoSizeSelectLargeIcon from "@mui/icons-material/PhotoSizeSelectLarge";
import ImageIcon from "@mui/icons-material/Image";
import { useTranslation } from "react-i18next";
import FontDownloadIcon from "@mui/icons-material/FontDownload";
import BoltIcon from "@mui/icons-material/Bolt";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import { aiEngineColor } from "../../../utils/aiEngineColor";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";

const ActionLabels = ({ params }: any) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  if (!params) return null;

  return (
    <>
      <Typography
        sx={{
          py: 1,
          px: 2,
          backgroundColor: theme.palette.grey[800],
          color: theme.palette.grey[50],
          display: "flex",
          borderRadius: 2,
          whiteSpace: "nowrap",
        }}
        variant={"body2"}
      >
        {params["action"] === "txt2img" ? (
          <FontDownloadIcon fontSize="small" sx={{ mr: 1 }} />
        ) : params["action"] === "img2img" ? (
          <ImageIcon fontSize="small" sx={{ mr: 1 }} />
        ) : (
          <PhotoSizeSelectLargeIcon fontSize="small" sx={{ mr: 1 }} />
        )}

        {t(`juno.menu.${params["action"]}`)}
      </Typography>
      <Typography
        sx={{
          py: 1,
          px: 2,
          backgroundColor: aiEngineColor(params["ai_engine"]),
          color: theme.palette.grey[50],
          display: "flex",
          borderRadius: 2,
          whiteSpace: "nowrap",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => navigate(`/juno?ai_engine=${params["ai_engine"]}`)}
        variant={"body2"}
      >
        <BoltIcon fontSize="small" sx={{ mr: 1 }} />
        {t(`juno.input.engine.${params["ai_engine"]}`)}
      </Typography>
      {params["model"]?.name && (
        <Typography
          sx={{
            py: 1,
            px: 2,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.grey[50],
            display: "inline-block",
            borderRadius: 2,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            maxWidth: "100%",
          }}
          variant={"body2"}
        >
          <BlurOnIcon fontSize="small" sx={{ mr: 1, float: "inline-start" }} />
          {params["model"]?.name}
        </Typography>
      )}
    </>
  );
};

export default ActionLabels;
