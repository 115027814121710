const template = {
  title: "Tenak AI Templates",
  description: "Tenak AI が提供するパワフルでクイックなテンプレート",
  rankings: "TOP 5 Templates",
  all: "All Templates",
  label: {
    promptGenerator: {
      title: "プロンプト\nジェネレーター",
      description:
        "目標を入力するだけで、最適なプロンプトを生成。出力されたプロンプトをブラウジングに貼り付けて使用することが可能。",
      arg1: "目標",
      arg1Placeholder: "プログラマーになる方法",
    },
    imagePromptGenerator: {
      title: "画像生成用プロンプト\nジェネレーター",
      description:
        "テーマを入力するだけで、最適なプロンプトを生成。出力されたプロンプトをSTUDIO JUNOで使用することが可能。（SD3以上，DALL-E3, FLUX.1 推奨）",
      arg1: "テーマ",
      arg1Placeholder: "例：魔法の森",
    },
    simpleExplanation: {
      title: "難しい文章を簡易化",
      description: "専門用語を含む難しい文章を、中学生でもわかるように解説。",
      arg1: "文章",
      arg1Placeholder: "専門的な文章などを入力",
    },
    emailCreation: {
      title: "Eメールの文章",
      description: "Eメールの文章をユーザーに合わせて作成。",
      arg1: "宛先",
      arg1Placeholder: "お客様",
      arg2: "Eメール内容",
      arg2Placeholder: "発注ミスを謝罪したい",
    },
    rephrase: {
      title: "文章の書き換え\nリライト",
      description: "文章を言い換えて、新しい視点での文章を作成。",
      arg1: "文章",
      arg1Placeholder: "入力文章",
      arg2: "書き換え方",
      arg2Placeholder: "説得力がある口調。体言止め。",
    },
    translation: {
      title: "AI 翻訳",
      description: "従来の翻訳と異なり、文脈を理解して文章を他の言語に翻訳することで、意訳にも対応。",
      arg1: "翻訳後の言語",
      arg1Placeholder: "英語",
      arg2: "文章",
      arg2Placeholder: "入力文章",
    },
    ideaGeneration: {
      title: "アイディア出し（SCAMPER法）",
      description: "SCAMPER法を用いた既成概念に囚われない多様性のあるアイディア出し。",
      arg1: "何についでのアイディア",
      arg1Placeholder: "例：AIで文章を生成するツールの収益化",
    },
    copyWriting: {
      title: "コピーライティング",
      description: "商品やサービスのコピーライティングを作成。",
      arg1: "商品・サービスの概要",
      arg1Placeholder: "例：AIで文章を生成するツールの収益化",
    },
    taskAssignment: {
      title: "タスクの割り当てと計画",
      description: "タスクと目標や期間、計画を作成。",
      arg1: "目標",
      arg1Placeholder: "例：AIで文章を生成するツールのWebサービスの開発",
    },
    landingPage: {
      title: "ランディングページ\n全体構成",
      description: "ランディングページの構成を作成。",
      arg1: "ターゲット",
      arg2: "商品・サービス名",
      arg3: "商品・サービスの概要",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：Tenak AI",
      arg3Placeholder: "例：AIで文章を生成するツール",
    },
    landingPageProblemSolution: {
      title: "ランディングページ\n課題解決セクション",
      description: "商品に対して、どのような悩みが、どのように解決するのかといったLPセクションを作成。",
      arg1: "ターゲット",
      arg2: "商品・サービスの概要",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：AIで文章を生成するツール",
    },
    landingPageFAQ: {
      title: "ランディングページ\nFAQセクション",
      description: "商品のLPのFAQセクションを作成。",
      arg1: "ターゲット",
      arg2: "商品・サービスの概要",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：AIで文章を生成するツール",
    },
    landingPageReview: {
      title: "ランディングページ\nユーザーレビュー\nセクション",
      description: "商品のLPのユーザーレビューセクションを作成。",
      arg1: "ターゲット",
      arg2: "商品・サービスの概要",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：AIで文章を生成するツール",
    },
    persona: {
      title: "ペルソナ\nカスタマージャーニー",
      description: "ペルソナとカスタマージャーニーを作成。",
      arg1: "商品・サービスの概要",
      arg2: "目標",
      arg1Placeholder: "例: Tenak AI は、AIを活用した文章生成ツールです。",
      arg2Placeholder: "例：フリーライターのサブスクリプション登録",
    },
    findUserProblem: {
      title: "顧客の問題を特定",
      description: "商品の潜在的なユーザーの問題を特定し、商品の必要性を訴求。問題解決型のマーケティング戦略を構築。",
      arg1: "商品・サービスの概要",
      arg1Placeholder: "例：AIを活用した文章生成ツール",
    },
    perceptionFlowModel: {
      title: "パーセプションフロー型\nマーケティング",
      description: "パーセプションフローモデルを使ったマーケティング戦略の構築。",
      arg1: "ターゲットと現状",
      arg2: "商品・サービスの概要",
      arg1Placeholder: "例：競合商品を使用しているが、満足していないクリエイター。",
      arg2Placeholder: "例：Tenak AIというライティングツール",
    },
    problemSolving: {
      title: "課題解決",
      description: "課題の明確化とその解決へのステップ。",
      arg1: "ターゲット",
      arg2: "課題",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：記事を書くのが面倒",
    },
    goal: {
      title: "夢・目標へのプロセス",
      description: "夢や目標への必要なプロセスを具体的に作成。",
      arg1: "ターゲット",
      arg2: "夢・目標",
      arg1Placeholder: "例：20〜30代 プログラミング初心者",
      arg2Placeholder: "例：Googleで働きたい",
    },
    ideaReview: {
      title: "専門家による\nアイディアレビュー",
      description: "様々な専門家がアイディアを異なる視点でレビュー。",
      arg1: "アイディア",
      arg1Placeholder: "例：映画を紹介するYouTubeチャンネルを始める",
    },
    swotAnalysis: {
      id: "swotAnalysis",
      title: "SWOT分析",
      description: "与えられた文章からSWOT分析（強み、弱み、機会、脅威）を作成。",
      arg1: "ターゲット",
      arg2: "文章",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：株式会社Diocodeが開発したTenak AIというライティングツール",
    },
    prepMethod: {
      id: "prepMethod",
      title: "PREP法を使った\n文章作成",
      description: "与えられたテーマについてPREP法を用いた文章を作成。SNS投稿やブログ記事などに活用。",
      arg1: "ターゲット",
      arg2: "テーマ",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：新しいライティングツールの紹介",
    },
    brandName: {
      id: "brandName",
      title: "ブランド名",
      description: "提供された情報をもとに、魅力的で覚えやすいブランド名を考える。",
      arg1: "ターゲット",
      arg2: "提供された情報",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：新しいライティングツールの紹介",
    },
    presentationOutline: {
      id: "presentationOutline",
      title: "プレゼンテーション\nアウトライン",
      description: "文章を元に、効果的なプレゼンテーションのアウトラインを作成。PowerPointやKeynoteなどに活用。",
      arg1: "ターゲット",
      arg2: "文章",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：新しいライティングツールの紹介",
    },

    pressRelease: {
      title: "プレスリリース",
      description: "商品・サービスのプレスリリース記事を作成。",
      arg1: "商品・サービスの概要",
      arg1Placeholder: "例：株式会社Diocodeが開発したTenak AIというライティングツール",
    },
    marketingStrategy: {
      title: "マーケティング戦略",
      description: "商品・サービスのマーケティング戦略を作成。",
      arg1: "ターゲット",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2: "商品・サービスの概要",
      arg2Placeholder: "例：株式会社Diocodeが開発したTenak AIというライティングツール",
    },
    dailyReport: {
      title: "日報",
      description: "日々の業務内容などの日報の作成。",
      arg1: "業務内容",
      arg2: "テンプレート",
      arg1Placeholder: "例：新機能の開発",
      arg2Placeholder: "日報のテンプレートがあれば入力してください。",
    },
    conversation: {
      title: "文章を会話に変換",
      description: "難しい文章などを会話形式にわかりやすくします。",
      arg1: "文章",
      arg1Placeholder: "会話に変換したい文章を入力してください。",
    },
    story: {
      title: "物語",
      description: "起承転結のある物語（ショートストーリー）を生成。",
      arg1: "テーマ",
      arg1Placeholder: "例：ドラゴンと少年の物語",
    },
    //////////////////////////
    // Social Media
    //////////////////////////
    snsAd: {
      title: "SNS広告",
      description: "SNS広告の作成。",
      arg1: "ターゲット",
      arg2: "商品・サービスの概要",
      arg3: "広告の目的",
      arg1Placeholder: "例：インスタグラムユーザー・20〜30代 ライター",
      arg2Placeholder: "例：株式会社Diocodeが開発したTenak AIというライティングツール",
      arg3Placeholder: "例：ユーザー獲得",
    },
    socialMediaStrategy: {
      title: "SNS戦略",
      description: "SNSの戦略を作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg3: "SNSの目的",
      arg1Placeholder: "例：20〜30代 ライター",
      arg2Placeholder: "例：Instagramでのライティングツールの紹介",
      arg3Placeholder: "例：ユーザー獲得",
    },
    youtubeComposition: {
      title: "YouTube 動画構成",
      description: "10分程度のYouTube動画の構成を作成。",
      arg1: "ターゲット",
      arg2: "チャンネルの概要",
      arg3: "希望動画",
      arg1Placeholder: "例：フォトグラファー",
      arg2Placeholder: "例：カメラの解説やレタッチ方法などを解説",
      arg3Placeholder: "例：新型カメラxxxの性能とレビュー",
    },
    youtubeShorts: {
      title: "YouTube\nショート構成",
      description: "30秒程度のYouTube動画の構成を制作。",
      arg1: "ターゲット",
      arg2: "チャンネルの概要",
      arg1Placeholder: "例：フォトグラファー",
      arg2Placeholder: "例：メイン動画ではカメラの解説などを解説しているチャンネル",
    },
    youtubeTitleDescription: {
      title: "YouTube\nタイトル・説明文",
      description: "YouTube動画のタイトルと説明文を作成。",
      arg1: "ターゲット",
      arg2: "チャンネルの概要",
      arg3: "動画内容",
      arg1Placeholder: "例：フォトグラファー",
      arg2Placeholder: "例：カメラの解説やレタッチ方法などを解説",
      arg3Placeholder: "例：新型カメラxxxの性能とレビュー",
    },
    instagramPost: {
      title: "Instagram\n投稿コンテンツ",
      description: "Instagramにポストする投稿のアイディア、スクリプト、タグを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    instagramReel: {
      title: "Instagram\nリール構成",
      description: "Instagramにポストするリールのアイディア、スクリプト、タグを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    instagramStory: {
      title: "インスタグラム\nストーリー",
      description: "Instagramのストーリーアイディアとスクリプトを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 イラストレーター",
      arg2Placeholder: "例：イラストのみポストしている",
    },
    instagramCaption: {
      title: "Instagram\nキャプション",
      description: "Instagramにポストする投稿のキャプション（説明文）を作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg3: "投稿写真概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン",
      arg3Placeholder: "例：サロン内の新商品の写真",
    },
    instagramProfile: {
      title: "Instagram\nプロフィール",
      description: "Instagramアカウントのプロフィールを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    tiktokIdeas: {
      title: "TikTok\nアイデア",
      description: "TikTokにポストするためのアイデアを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    tiktokComposition: {
      title: "TikTok\n動画構成",
      description: "TikTok動画の構成を作成。",
      arg1: "ターゲット",
      arg2: "チャンネルの概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    tiktokCaption: {
      title: "TikTok\nキャプション",
      description: "TikTokにポストする投稿のキャプション（説明文）を作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    tiktokProfile: {
      title: "TikTok\nプロフィール",
      description: "TikTokアカウントのプロフィールを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    threadsPost: {
      title: "Threads\n投稿コンテンツ",
      description: "Threadsにポストするためのコンテンツを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    threadsMultiPost: {
      title: "Threads\n 投稿コンテンツ\n（スレッド形式）",
      description: "Threadsにポストするためのコンテンツを作成。Xには文字数制限があるため、スレッド形式にまとめます。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
      arg3: "投稿概要",
      arg3Placeholder: "例：新商品の紹介",
    },
    threadsProfile: {
      title: "Threads\nプロフィール",
      description: "Threadsアカウントのプロフィールを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    xPost: {
      title: "X 投稿コンテンツ",
      description: "X(Twitter)にポストするためのコンテンツを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    xMultiPost: {
      title: "X 投稿コンテンツ\n（スレッド形式）",
      description:
        "X(Twitter)にポストするためのコンテンツを作成。Xには文字数制限があるため、スレッド形式にまとめます。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
      arg3: "投稿概要",
      arg3Placeholder: "例：新商品の紹介",
    },
    xProfile: {
      title: "X プロフィール",
      description: "X(Twitter)アカウントのプロフィールを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    facebookPost: {
      title: "Facebook\n投稿コンテンツ",
      description: "Facebookにポストするアイディア、スクリプト、タグを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    facebookStory: {
      title: "Facebook\nストーリーズ",
      description: "Facebookのストーリーアイディアとスクリプトを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 イラストレーター",
      arg2Placeholder: "例：イラストのみポストしている",
    },
    facebookReel: {
      title: "Facebook\nリール構成",
      description: "Facebookにポストするリールのアイディア、スクリプト、タグを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    facebookCaption: {
      title: "Facebook\nキャプション",
      description: "Facebookにポストする投稿のキャプション（説明文）を作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
    facebookProfile: {
      title: "Facebook\nプロフィール",
      description: "Facebookアカウントのプロフィールを作成。",
      arg1: "ターゲット",
      arg2: "アカウント概要",
      arg1Placeholder: "例：20〜30代 女性",
      arg2Placeholder: "例：サロン経営者",
    },
  },
  inputDescription: "以下の必要な情報を入力してください。",
  url: "参考 URL（YouTube対応）",
  urlPlaceholder: "例：https://www.example.com",
  result: "TEXT",
  createFile: "ライブラリに保存",
  additionalInstructions: "追加指示",
  additionalInstructionsPlaceholder: "従うべきガイドラインや注意点があれば入力してください。",
};
export default template;
