import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useVideoScriptContext } from "../../../../context/VideoScriptContext";
import Typography from "@mui/material/Typography";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Target() {
  const { t } = useTranslation();
  const context = useVideoScriptContext();

  return (
    <>
      {/*ターゲット */}
      <Box>
        <Box mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("videoScript.target")}
          </StyledTypography>
        </Box>
        <Box sx={{ maxWidth: 600 }}>
          <TextField
            placeholder={t("videoScript.targetPlaceholder")}
            value={context.target}
            onChange={(e) => context.setTarget(e.target.value)}
            variant="standard"
            fullWidth
            autoComplete={"off"}
            inputProps={{ maxLength: 100 }}
          />
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography variant={"body2"}>{context.target.length}/100</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
