import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type BrowsingState = {
  triggerFetch: boolean;
  processing: { [workspaceUuid: string]: boolean };
  browsingMode: { [workspaceUuid: string]: boolean };
  sendOnCtrlEnter: { [workspaceUuid: string]: boolean };
  inputQuery: { [workspaceUuid: string]: string };
};

const initialState: BrowsingState = {
  triggerFetch: false,
  processing: {},
  browsingMode: {},
  sendOnCtrlEnter: {},
  inputQuery: {},
};

const browsingSlice = createSlice({
  name: "browsing",
  initialState,
  reducers: {
    triggerWorkspaceFetch: (state) => {
      state.triggerFetch = !state.triggerFetch;
    },
    setProcessing: (state, action: PayloadAction<{ workspaceUuid: string; processing: boolean }>) => {
      const { workspaceUuid, processing } = action.payload;
      state.processing[workspaceUuid] = processing;
    },
    setBrowsingMode: (state, action: PayloadAction<{ workspaceUuid: string; browsingMode: boolean }>) => {
      const { workspaceUuid, browsingMode } = action.payload;
      state.browsingMode[workspaceUuid] = browsingMode;
    },
    setSendOnCtrlEnter: (state, action: PayloadAction<{ workspaceUuid: string; sendOnCtrlEnter: boolean }>) => {
      const { workspaceUuid, sendOnCtrlEnter } = action.payload;
      state.sendOnCtrlEnter[workspaceUuid] = sendOnCtrlEnter;
    },
    setInputQuery: (state, action: PayloadAction<{ workspaceUuid: string; inputQuery: string }>) => {
      const { workspaceUuid, inputQuery } = action.payload;
      state.inputQuery[workspaceUuid] = inputQuery;
    },
  },
});

export const { triggerWorkspaceFetch, setProcessing, setBrowsingMode, setSendOnCtrlEnter, setInputQuery } =
  browsingSlice.actions;
export const browsingReducer = browsingSlice.reducer;
