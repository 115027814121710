import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Fade, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Cookies from "js-cookie";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImageInfo, useJunoImageGeneratorContext } from "../../context/ImageGeneratorContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import ImageModal from "./components/ImageModal";

import Meta from "../../components/common/Meta";
import { aiEngineColor } from "../../utils/aiEngineColor";
import { uiHeight } from "../../utils/uiHeight";
import Like from "./components/Like";

export default function Explore() {
  const { t } = useTranslation();
  const [generatedImages, setGeneratedImages] = useState<ImageInfo[]>([]);
  const context = useJunoImageGeneratorContext();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const pageParam = new URLSearchParams(window.location.search).get("page");
  const [page, setPage] = useState(pageParam ? parseInt(pageParam) : 1);
  const containerRef = useRef<HTMLDivElement>(null);
  const queryParam = new URLSearchParams(window.location.search).get("q") ?? "";
  const modelParam = new URLSearchParams(window.location.search).get("model");
  const loraParam = new URLSearchParams(window.location.search).get("lora");
  const aiEngineParam = new URLSearchParams(window.location.search).get("ai_engine");
  const navigate = useCustomNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const [gridNum, setGridNum] = useState(3);
  const urlParams = new URLSearchParams(window.location.search);
  const uuid = urlParams.get("uuid");

  // 画像一覧取得 & 検索
  const fetchImageList = async (pageNum: number) => {
    try {
      const height = containerRef.current?.offsetHeight;
      const width = containerRef.current?.offsetWidth;
      let num = 0;

      if (height && width) {
        if (width > 600) {
          const widthNum = Math.floor(width / 230);
          const heightNum = Math.floor(height / 210);
          setGridNum(12 / widthNum);
          num = widthNum * heightNum;
        } else {
          setGridNum(4);
          num = 12;
        }
      }

      let url = "/api/v1/juno/images";
      if (uuid) {
        url += `?uuid=${uuid}&num=${num}`;
      } else {
        if (queryParam) {
          url += `?page=${pageNum}&q=${queryParam}&num=${num}`;
        } else if (modelParam) {
          url += `?page=${pageNum}&model=${modelParam}&num=${num}`;
        } else if (loraParam) {
          url += `?page=${pageNum}&lora=${loraParam}&num=${num}`;
        } else if (aiEngineParam) {
          url += `?page=${pageNum}&ai_engine=${aiEngineParam}&num=${num}`;
        } else {
          url += `?page=${pageNum}&num=${num}`;
        }
      }
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({ "Content-Type": "application/json", "X-CSRFToken": csrftoken! });
      const response = await fetch(url, { method: "GET", headers: headers });
      const newImages = await response.json();
      setSelectedImages(newImages);
      setTotalPages(newImages[0].total_pages);
      return newImages;
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // ページ変更時の処理
  const handleChangePage = async (newPage: number) => {
    setLoading(true);
    const newImages = await fetchImageList(newPage);
    if (newImages && newImages.length > 0) {
      setGeneratedImages(newImages);
      setPage(newPage);
      if (queryParam) {
        navigate(`/juno?page=${newPage}&q=${queryParam}`);
      } else if (modelParam) {
        navigate(`/juno?page=${newPage}&model=${modelParam}`);
      } else if (loraParam) {
        navigate(`/juno?page=${newPage}&lora=${loraParam}`);
      } else if (aiEngineParam) {
        navigate(`/juno?page=${newPage}&ai_engine=${aiEngineParam}`);
      } else {
        navigate(`/juno?page=${newPage}`);
      }
    }
    setLoading(false);
  };

  const [selectedImages, setSelectedImages] = useState<ImageInfo[]>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [imageUrls, setImageUrls] = useState<Record<string, string>>({});

  // 画像URLの取得
  useEffect(() => {
    const urls = {};
    generatedImages.forEach((req) => {
      urls[req.uuid] = `${req.url}public`;
    });
    setImageUrls(urls);
  }, [generatedImages]);

  // URLパラメーターによる画像一覧取得
  useEffect(() => {
    setLoading(true);
    setGeneratedImages([]);
    setPage(Number(pageParam) || 1);
    fetchImageList(Number(pageParam) || 1).then((newImages) => {
      if (newImages && newImages.length > 0) {
        setGeneratedImages(newImages);
      } else {
      }
      setLoading(false);
    });
  }, [uuid, modelParam, loraParam, queryParam, aiEngineParam]);

  useEffect(() => {
    if (uuid && generatedImages.length > 0) {
      setSelectedImages(generatedImages);
      setOpenModal(true);
    }
  }, [uuid, generatedImages]);

  // 画像の読み込み状態を管理するステート
  const [loaded, setLoaded] = useState({});

  // 画像の読み込み
  useEffect(() => {
    generatedImages.forEach((req) => {
      const img = new Image();
      img.src = imageUrls[req.uuid] ?? `${req.url}public`;
      img.onload = () => {
        setLoaded((prev) => ({
          ...prev,
          [req.uuid]: true,
        }));
      };
    });
  }, [imageUrls]);

  const height = queryParam ? 145 : 115;
  const [hover, setHover] = useState<number | null>(null);

  return (
    <>
      <Meta
        title={t("juno.explore")}
        meta={[{ name: "robots", content: "noindex, nofollow" }]}
        route={"Studio JUNO by Tenak AI"}
      />
      {queryParam !== "" && (
        <Box sx={{ display: "flex", justifyContent: "center", mb: 1, alignItems: "center" }}>
          <Typography variant={"body1"} component={"p"}>
            {t("juno.searchResult")}
            <strong> {queryParam}</strong>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: {
            xs: 600,
            sm: `calc(${uiHeight()} - ${height}px)`,
          },
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", height: "100%", mx: 1 }} ref={containerRef}>
          <Grid container spacing={1} sx={{ px: 1, width: "100%" }}>
            {loading && generatedImages.length === 0 ? (
              // ロード中のインジケーターを表示
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            ) : generatedImages.length > 0 ? (
              generatedImages.map((image, index) => (
                <Grid item xs={gridNum} key={`${page}-${index}`}>
                  <Grow in={true} timeout={Math.min(index * 100, 1500)}>
                    <Box sx={{ width: "100%", height: "100%" }}>
                      <Box
                        sx={{
                          position: "relative",
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          boxSizing: "border-box",
                          maxHeight: { xs: 150, sm: 300 },
                          overflow: "hidden",
                          transition: "transform 0.5s ease",
                          borderRadius: 1,
                        }}
                        onClick={() => {
                          setActiveIndex(index);
                          setOpenModal(true);
                        }}
                        onMouseOver={(e) => {
                          e.currentTarget.style.transform = "scale(1.05)";
                          e.currentTarget.style.zIndex = "100";
                          e.currentTarget.style.outline = `solid 2px ${theme.palette.primary.main}`;
                          setHover(index);
                        }}
                        onMouseOut={(e) => {
                          e.currentTarget.style.transform = "scale(1)";
                          e.currentTarget.style.zIndex = "1";
                          e.currentTarget.style.outline = "none";
                          setHover(null);
                        }}
                      >
                        <Fade in={hover === index} timeout={300}>
                          <Box sx={{ position: "absolute", bottom: 8, right: 8, zIndex: 2 }}>
                            <Like uuid={image.uuid} />
                          </Box>
                        </Fade>
                        <Fade in={hover === index} timeout={300}>
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: 12,
                              left: 12,
                              px: 0.6,
                              py: 0.2,
                              background: alpha(aiEngineColor(image["params"]["ai_engine"]), 0.8),
                              borderRadius: 1,
                              zIndex: 2,
                            }}
                          >
                            <Typography variant={"caption"} component={"p"} sx={{ color: "white" }}>
                              {t(`juno.input.engine.${image["params"]["ai_engine"]}` as const)}
                            </Typography>
                          </Box>
                        </Fade>
                        <img
                          src={imageUrls[image.uuid] ?? `${image.url}public`}
                          alt=""
                          loading="lazy"
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            filter: image.safe_level > 2 && context.filtered ? "blur(20px)" : "none",
                            transform: image.safe_level > 2 && context.filtered ? "scale(1.5)" : "none",
                            transition: "transform 0.2s ease, filter 0.2s ease",
                            display: loaded[image.uuid] ? "block" : "none",
                          }}
                        />
                        {loaded[image.uuid] ? (
                          image.safe_level > 2 &&
                          context.filtered && (
                            <Box
                              sx={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                color: "white",
                                zIndex: 2,
                                width: "100%",
                                p: 4,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    mb: 2,
                                  }}
                                >
                                  <ReportIcon sx={{ mr: 1 }} color={"warning"} />
                                  <Typography variant={"body1"}>{t("juno.input.filtered")}</Typography>
                                </Box>
                                <Typography variant={"caption"} whiteSpace={"break-spaces"}>
                                  {t("juno.input.filteredDescription")}
                                </Typography>
                              </Box>
                            </Box>
                          )
                        ) : (
                          <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
                        )}

                        {image.safe_level > 2 && (
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              context.setFiltered(!context.filtered);
                            }}
                            sx={{ position: "absolute", top: 8, right: 8, color: "white" }}
                            aria-label="toggle blur"
                          >
                            {context.filtered ? <VisibilityIcon /> : <VisibilityOffIcon />}
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Grow>
                </Grid>
              ))
            ) : queryParam.length > 0 ? (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
                <Fade in={true} timeout={1000}>
                  <Typography sx={{ mt: 1 }} variant={"body1"} component={"p"} color={"error"}>
                    {t("juno.noResult")}
                  </Typography>
                </Fade>
              </Box>
            ) : (
              // ロード中のインジケーターを表示
              <Box sx={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "center" }}>
                <Typography variant={"body1"} fontWeight={"500"} sx={{ mb: 2 }}>
                  No Images Found
                </Typography>
              </Box>
            )}
          </Grid>
        </Box>
      </Box>
      {page > 0 && (
        <Box sx={{ display: "flex", justifyContent: "center", my: 1 }}>
          <Pagination count={totalPages} page={page} onChange={(event, newPage) => handleChangePage(newPage)} />
        </Box>
      )}
      <ImageModal
        images={selectedImages}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
        open={openModal}
        setOpen={setOpenModal}
      />
    </>
  );
}
