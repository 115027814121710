import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import MoneyIcon from "@mui/icons-material/Money";
import SubjectIcon from "@mui/icons-material/Subject";
import ButtonBase from "@mui/material/ButtonBase";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAlert } from "../../../context/AlertContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { ColumnCenteredBox, RowBox } from "../../../utils/styledBox";
import AIEditor from "./AIEditor";
import BrowsingList from "./BrowsingList";

const AIList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useCustomNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { setAlert } = useAlert();

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const handleNewFile = async (type: number) => {
    try {
      handlePopoverClose();
      if (type === 1 || type === 2) {
        axios.defaults.withCredentials = true;
        const csrftoken = Cookies.get("csrftoken");
        const config = {
          headers: { "X-CSRFToken": csrftoken },
        };
        const res = await axios.post("/api/v1/text-file/", { type: type }, config);
        navigate("/file/" + res.data.uuid);
        setAlert("success", t("library.text.message.create"));
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const libraryItems = [
    {
      key: "images",
      path: "/juno",
      icon: <ImageOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.imageGenerator",
    },
    {
      key: "text",
      path: "/writing-ai/article",
      icon: <SubjectIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.writingAI",
    },
    {
      key: "voices",
      path: "/voice-generator",
      icon: <GraphicEqIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.voiceGenerator",
    },
    {
      key: "mind-maps",
      path: "/mind-map-generator",
      icon: <AccountTreeOutlinedIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.mindmapGenerator",
    },
    {
      key: "color-generator",
      path: "/color-generator",
      icon: <ColorLensIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.colorGenerator",
    },
    {
      key: "templates",
      path: "/template",
      icon: <MoneyIcon fontSize={"small"} color={"primary"} sx={{ my: 1 }} />,
      label: "drawer.templates",
    },
  ];

  return (
    <>
      <Paper
        sx={{
          borderRadius: 0,
          pt: 1,
          pb: 1,
          backgroundColor: "transparent",
        }}
        elevation={0}
      >
        <RowBox sx={{ ml: 2, mb: 0.5, width: "inherit" }}>
          <AutoAwesomeIcon fontSize={"small"} color={"secondary"} sx={{ mr: 1 }} />
          <Typography variant={"caption"} component={"span"}>
            {t("drawer.AIs")}
          </Typography>
        </RowBox>
        <Grid container spacing={0.5} sx={{ px: 1, py: 0.5 }}>
          <Grid item xs={12}>
            <BrowsingList />
          </Grid>
          {libraryItems.map((item) => (
            <Grid item xs={6} key={item.key}>
              <ButtonBase
                onClick={() => navigate(item.path)}
                sx={{
                  borderRadius: 1,
                  width: "100%",
                  height: "100%",
                  alignItems: "flex-start",
                  backgroundColor: (theme) =>
                    location.pathname.includes(item.path)
                      ? alpha(theme.palette.primary.main, 0.2)
                      : alpha(theme.palette.action.selected, 0.05),
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                  },
                }}
              >
                <ColumnCenteredBox sx={{ m: 1, whiteSpace: "break-spaces" }}>
                  {item.icon}
                  <Typography
                    variant={"caption"}
                    component={"span"}
                    whiteSpace={"nowrap"}
                    sx={{ fontWeight: location.pathname.includes(item.path) ? "bold" : "normal" }}
                  >
                    {t(item.label)}
                  </Typography>
                </ColumnCenteredBox>
              </ButtonBase>
            </Grid>
          ))}
          <Grid item xs={12}>
            <AIEditor />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default AIList;
