import React from "react";
import Meta from "../components/common/Meta";
import { DrawerHeader } from "../components/layout/Drawer/DrawerMain";
import { Container, Grow, Typography } from "@mui/material";
import { ColumnCenteredBox, RowCenteredBox } from "../utils/styledBox";
import { GradientTypography } from "../utils/gradientTypography";

const Maintenance = () => {
  return (
    <>
      <Meta meta={[{ name: "robots", content: "index, follow" }]} />
      <Container maxWidth={"sm"}>
        <DrawerHeader />
        <Grow in={true}>
          <ColumnCenteredBox mb={8}>
            <RowCenteredBox>
              <Typography variant={"h2"} component={"h1"}>
                Maintenance
              </Typography>
            </RowCenteredBox>
            <img src={"/images/logo/logo.png"} alt={"logo"} style={{ width: "96px", height: "auto" }} />
            <Typography variant={"h5"} sx={{ mt: 1, mb: 2 }}>
              We are currently performing maintenance.
            </Typography>
            <Typography variant={"subtitle1"} sx={{ mb: 2 }}>
              We apologize for the inconvenience.
            </Typography>
            <GradientTypography variant={"h6"} sx={{ mb: 4 }}>
              Tenak.ai
            </GradientTypography>
            <img
              src={"/images/maintenance.jpg"}
              alt={"maintenance"}
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
          </ColumnCenteredBox>
        </Grow>
      </Container>
    </>
  );
};
export default Maintenance;