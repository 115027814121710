import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useAlert } from "../../context/AlertContext";
import Meta from "../../components/common/Meta";
import { useLoginModalContext } from "../../context/LoginModalContext";

function ActivateUser() {
  const loginStatus = useLoginStatus();
  const navigate = useCustomNavigate();
  const { uidb64, token } = useParams();
  const { setAlert } = useAlert();
  const context = useLoginModalContext();

  useEffect(() => {
    // 非同期処理を行うための内部関数を定義
    const activateAccount = async () => {
      try {
        const response = await axios.get(`/api/v1/auth/activate/${uidb64}/${token}`);
        if (response.data.success) {
          await loginStatus.checkLoginStatus();
          navigate(context.getRequestPathFromCookie());
        } else {
          setAlert("error", "Activation failed");
          navigate(context.getRequestPathFromCookie());
        }
      } catch (error) {
        setAlert("error", "Activation failed");
        navigate(context.getRequestPathFromCookie());
      } finally {
        context.setRequestPathToCookie("");
      }
    };

    // 非同期関数を呼び出す
    activateAccount();
  }, [uidb64, token]);

  return (
    <>
      <Meta title={"Activate User"} />
    </>
  );
}

export default ActivateUser;
