import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import axios from "axios";
import Cookies from "js-cookie";
import BoltIcon from "@mui/icons-material/Bolt";
import Modal from "@mui/material/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { FaCrown } from "react-icons/fa6";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import { GradationButton } from "../../../utils/gradationButton";
import { GradientTypography } from "../../../utils/gradientTypography";

interface UpgradeModalProps {
  open: boolean;
  handleClose: () => void;
  selectedPlan: string;
  currentPlan: string;
}

const UpgradeModal = ({ open, handleClose, selectedPlan, currentPlan }: UpgradeModalProps) => {
  const { t } = useTranslation();
  const { isLoggedIn } = useLoginStatus();
  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [gap, setGap] = useState(0);

  useEffect(() => {
    setPrice(Number(t(`pricing.${selectedPlan}.price`)));
    setGap(Number(t(`pricing.${selectedPlan}.price`)) - Number(t(`pricing.${currentPlan}.price`)));
  }, [selectedPlan, currentPlan]);

  const subscribe = async (plan: string) => {
    if (isLoggedIn) {
      setLoading(true);
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const data = { plan: plan };
      const res = await axios.post("/api/v1/users/subscribe", data, config);
      const url = res.data.url;
      if (url) {
        window.location.href = url;
      } else {
        console.error("Failed to obtain subscription URL");
      }
      setLoading(false);
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 800,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", mb: 4, gap: 4 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <Typography variant={"h5"} component={"h3"} color={"primary.main"}>
                <FaCrown style={{ marginRight: "16px" }} />
              </Typography>
              <Typography variant={"h5"} component={"h3"}>
                {t("preferences.planAndBillings.upgradeModal.title")}
              </Typography>
            </Box>
            <Typography>{t("preferences.planAndBillings.upgradeModal.description")}</Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", mb: 4, gap: 2 }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <CheckCircleIcon sx={{ mr: 2 }} />
              <Typography variant={"body2"}>{t("preferences.planAndBillings.upgradeModal.caution1")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <CheckCircleIcon sx={{ mr: 2 }} />
              <Typography variant={"body2"}>{t("preferences.planAndBillings.upgradeModal.caution2")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <CheckCircleIcon sx={{ mr: 2 }} />
              <Typography variant={"body2"}>{t("preferences.planAndBillings.upgradeModal.caution3")}</Typography>
            </Box>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon sx={{ mr: 2 }} />
                <Typography variant={"body2"}>{t("preferences.planAndBillings.upgradeModal.caution4")}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "flex-start", flexDirection: "row", ml: 4 }}>
                <Link href={"/terms-of-service"} sx={{ mr: 2 }} target={"_blank"}>
                  <Button
                    disableElevation
                    variant={"text"}
                    color={"primary"}
                    endIcon={<LaunchIcon fontSize={"small"} />}
                  >
                    {t("preferences.misc.termsOfService")}
                  </Button>
                </Link>
                <Link href={"/privacy-policy"} target={"_blank"}>
                  <Button
                    disableElevation
                    variant={"text"}
                    color={"primary"}
                    endIcon={<LaunchIcon fontSize={"small"} />}
                  >
                    {t("preferences.misc.privacyPolicy")}
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", mb: 4, gap: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "background.custom1",
                p: 4,
                borderRadius: 1,
              }}
            >
              {/* New Plan */}
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
                <Typography variant={"subtitle1"} fontWeight={400} sx={{ mr: 1 }}>
                  {t("preferences.planAndBillings.upgradeModal.newPlan")}:
                </Typography>
                <GradientTypography
                  variant={"h6"}
                  component={"p"}
                  sx={{ textTransform: "capitalize", color: "secondary.main", mr: 4 }}
                >
                  {selectedPlan} Plan
                </GradientTypography>
              </Box>

              {/* 価格 */}
              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <Typography variant={"subtitle1"} component={"p"} fontWeight={400} sx={{ mr: 1 }}>
                  {t("preferences.planAndBillings.upgradeModal.price")}:
                </Typography>
                <Typography variant={"h6"} component={"p"} sx={{ color: "secondary.main", mr: 4 }}>
                  USD {gap}
                </Typography>
                <Typography variant={"subtitle1"} component={"p"} fontWeight={400} sx={{ mr: 1 }}>
                  {t("preferences.planAndBillings.upgradeModal.nextPrice")}:
                </Typography>
                <Typography variant={"h6"} component={"p"} sx={{ color: "secondary.main", mr: 1 }}>
                  USD {price}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>
            <Button disableElevation onClick={handleClose} sx={{ mr: 2 }}>
              {t("common.close")}
            </Button>
            <GradationButton onClick={() => subscribe(selectedPlan)} disabled={loading}>
              <BoltIcon sx={{ mr: 1 }} />
              {t("preferences.planAndBillings.upgradeModal.upgrade")}
            </GradationButton>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default UpgradeModal;
