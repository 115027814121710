import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import { IconButton } from "@mui/material";
import { ColumnBox, RowBox, RowCenteredBox } from "../../../utils/styledBox";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import { ContentCopy } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import Avatar from "@mui/material/Avatar";

interface Model {
  model: any;
  bg?: boolean;
  size?: "small" | "medium" | "large";
}

export default function ModelPopover({ model, bg, size = "small" }: Model) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleStopPropagation = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "model-popover" : undefined;
  const [copied, setCopied] = useState<boolean[]>([]);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied((prev) => {
          const newCopied = [...prev];
          newCopied.fill(false);
          return newCopied;
        });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <Box onClick={handleStopPropagation}>
      <IconButton
        aria-describedby={id}
        size={size}
        onClick={handleClick}
        sx={{
          backgroundColor: bg ? "rgba(0, 0, 0, 0.3)" : "",
          color: bg ? "rgba(255, 255, 255, 0.87)" : "",
          "&:hover": {
            backgroundColor: bg ? "rgba(0, 0, 0, 0.5)" : "",
            color: bg ? "rgba(255, 255, 255, 0.8)" : "",
          },
        }}
      >
        <InfoIcon fontSize={size} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            bgcolor: (theme) => theme.palette.background.default,
            p: 2,
            width: "auto",
            maxWidth: 300,
            minWidth: 400,
          }}
        >
          <IconButton
            onClick={handleClose}
            size="small"
            sx={{ position: "absolute", top: 4, right: 4, cursor: "pointer" }}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>
          <RowCenteredBox mb={2}>
            <InfoIcon fontSize="small" color="info" sx={{ mr: 1 }} />
            <Typography variant="subtitle1" noWrap>
              {"Model Information"}
            </Typography>
          </RowCenteredBox>
          <ColumnBox>
            <RowBox mb={1}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  width: "fit-content",
                  backgroundColor: "#2c3480",
                  px: 1,
                  py: 0.5,
                  mr: 1,
                  borderRadius: 1,
                  whiteSpace: "normal",
                }}
              >
                {model?.base_model}
              </Typography>
              <Link
                href={model?.civitai_url}
                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none", color: theme.palette.text.primary }}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                  {model?.model_name}
                </Typography>
              </Link>
            </RowBox>

            <RowBox mb={1}>
              <Typography
                variant="body2"
                sx={{
                  color: "white",
                  width: "fit-content",
                  backgroundColor: "#2c3480",
                  px: 1,
                  py: 0.5,
                  mr: 1,
                  borderRadius: 1,
                  whiteSpace: "normal",
                }}
              >
                Creator
              </Typography>
              <Avatar alt={model?.creator} src={model?.avatar} sx={{ width: 24, height: 24, mr: 1 }} />
              <Typography variant="body2" sx={{ whiteSpace: "normal", wordBreak: "break-word" }}>
                @{model?.creator}
              </Typography>
            </RowBox>

            {model?.trigger_word.length > 0 && (
              <RowBox mb={1}>
                <Typography
                  variant="body2"
                  sx={{
                    bgcolor: "#2c3480",
                    color: "white",
                    px: 1,
                    mr: 1,
                    borderRadius: 1,
                    py: 0.5,
                  }}
                >
                  Trigger Words
                </Typography>
              </RowBox>
            )}
            <Box mb={2} sx={{ display: "flex", flexWrap: "wrap" }}>
              {model?.trigger_word.map((word, index) => (
                <RowBox
                  key={index}
                  sx={{
                    mb: 0.5,
                    mr: 0.5,
                    bgcolor: theme.palette.grey[800],
                    color: "white",
                    px: 0.5,
                    borderRadius: 1,
                    py: 0.2,
                    width: "fit-content",
                    gap: 1,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(word);
                    setCopied((prev) => {
                      const newCopied = [...prev];
                      newCopied[index] = true;
                      return newCopied;
                    });
                  }}
                >
                  <Typography variant="caption">{word}</Typography>
                  {copied[index] ? <CheckIcon sx={{ fontSize: 12 }} /> : <ContentCopy sx={{ fontSize: 12 }} />}
                </RowBox>
              ))}
            </Box>
            <Button variant="contained" color="primary" onClick={handleClose} size="small">
              {t("common.close")}
            </Button>
          </ColumnBox>
        </Box>
      </Popover>
    </Box>
  );
}