const notesNebula = {
  title: "Notes Nebula",
  description: "A platform for easily posting articles with an AI editor",
  post: "Post an article",
  latest: "Latest articles",
  popular: "Popular articles",
  official: "Tenak AI official articles",
  popularDescription: "See popular articles posted with Tenak AI",
  readMore: "Read more",
  article: {
    like: "Like",
    comment: "Comment",
    x: "Share with X",
    facebook: "Share on Facebook",
    hatena: "Hatena Bookmark",
    whatsapp: "Share on WhatsApp",
    line: "Share on LINE",
    toc: "Table of Contents",
    author: "Author",
    status: {
      public: "This article is public.\nThis message will not be displayed to others.",
      private: "This article is private.\nThis message will not be displayed to others.",
      shared:
        "This article is available only to users who know the URL.\nThis message will not be displayed to others.",
    },
    edit: "Edit",
    about: "About this article",
    description:
      'This article has been published through the free article posting service "Notes Nebula". Notes Nebula allows for the creation of articles using the "Tenak AI" text editor, which is equipped with the latest AI technology. Due to the use of AI technology, careful verification of the information\'s accuracy is necessary.',
    goToTenak: "Write an article with Tenak AI",
  },
};
export default notesNebula;
