import AddLinkIcon from "@mui/icons-material/AddLink";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EditNoteIcon from "@mui/icons-material/EditNote";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSlideContext } from "../../../../context/SlideContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Source() {
  const { t } = useTranslation();
  const { srcType, setSrcType, content, setContent } = useSlideContext();

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"}>
        <StyledTypography variant="body1" gutterBottom>
          {t("slide.source")}
        </StyledTypography>
        <Typography color={"error"}>&nbsp;*</Typography>
      </Box>
      <Box>
        <FormControl sx={{ mb: 1 }}>
          <RadioGroup row value={srcType} onChange={(event) => setSrcType(event.target.value)}>
            <FormControlLabel value="url" control={<Radio />} label={t("slide.srcType.url")} />
            <FormControlLabel value="youtube" control={<Radio />} label={t("slide.srcType.youtube")} />
            <FormControlLabel value="text" control={<Radio />} label={t("slide.srcType.text")} />
            <FormControlLabel value="keyword" control={<Radio />} label={t("slide.srcType.keyword")} />
          </RadioGroup>
        </FormControl>
        {srcType === "keyword" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <AutoAwesomeIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("slide.srcType.keywordDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("slide.srcType.keyword")}
              placeholder={t("slide.srcType.keywordPlaceholder")}
              variant="standard"
              autoComplete={"off"}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              fullWidth
            />
          </>
        )}
        {srcType === "url" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <AddLinkIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("slide.srcType.urlDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("slide.srcType.url")}
              type={"url"}
              autoComplete={"off"}
              placeholder={t("slide.srcType.urlPlaceholder")}
              variant="standard"
              value={content}
              onChange={(e) => setContent(e.target.value)}
              fullWidth
            />
          </>
        )}
        {srcType === "youtube" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <YouTubeIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("slide.srcType.youtubeDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("slide.srcType.url")}
              placeholder={t("slide.srcType.youtubePlaceholder")}
              variant="standard"
              autoComplete={"off"}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              fullWidth
            />
          </>
        )}
        {srcType === "text" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <EditNoteIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("slide.srcType.textDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("slide.srcType.text")}
              placeholder={t("slide.srcType.textPlaceholder")}
              variant="standard"
              multiline
              maxRows={8}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              fullWidth
              inputProps={{ maxLength: 4000 }}
            />
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Typography variant={"body2"}>{content.length}/4,000</Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
