import React, { useState } from "react";
import { Avatar, Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAvatar } from "../../../context/AvatarContext";
import useTheme from "@mui/material/styles/useTheme";
import axios from "axios";
import Cookies from "js-cookie";

interface User {
  id: number;
  username: string;
  email: string;
}

interface AvatarUploadProps {
  user: User | null;
  isAvatar?: boolean;
  editable?: boolean;
}

export default function AvatarUpload({ isAvatar, user, editable }: AvatarUploadProps) {
  const [uploading, setUploading] = useState(false);
  const { avatarUrl, isAvatarExist, triggerReload } = useAvatar();
  const theme = useTheme();

  const useAvatarUpload = (isAvatar?: boolean) => {
    const handleUpload = async (uploadFile: File) => {
      if (uploadFile) {
        try {
          const url = "/api/v1/users/avatar";
          axios.defaults.withCredentials = true;

          // CSRFトークンの取得
          const csrftoken = Cookies.get("csrftoken");

          // リクエストヘッダーにCSRFトークンを追加
          const config = { headers: { "X-CSRFToken": csrftoken } };

          const formData = new FormData();
          formData.append("file", uploadFile);
          if (isAvatar) {
            formData.append("isAvatar", "True");
          }

          const response = await axios.post(url, formData, config);
          console.log("アップロード成功", response.data);
          return response.data;
        } catch (error) {
          console.error("アップロード失敗", error);
          throw error;
        }
      }
    };

    return { handleUpload };
  };
  const { handleUpload } = useAvatarUpload(isAvatar);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setUploading(true);
    if (file) {
      await handleUpload(file);
    }
    event.target.value = "";
    setUploading(false);
    triggerReload();
  };

  return (
    <>
      <Box style={{ position: "relative", display: "inline-block" }}>
        {isAvatarExist ? (
          <Avatar alt="User avatar" src={avatarUrl ? avatarUrl[0] : ""} sx={{ width: 96, height: 96 }} />
        ) : (
          <Avatar sx={{ width: 96, height: 96 }} color="primary">
            {user?.username.slice(0, 2)}
          </Avatar>
        )}
        {editable ? (
          <>
            <label htmlFor="upload-avatar">
              <IconButton
                aria-label="upload picture"
                component="span"
                sx={{
                  position: "absolute",
                  right: 0,
                  bottom: 0,
                  backgroundColor: theme.palette.background.default,
                  opacity: 0.8,
                  "&:hover": {
                    backgroundColor: theme.palette.background.default,
                    opacity: 1,
                  },
                }}
                disabled={uploading}
                size={"small"}
              >
                <EditIcon fontSize={"inherit"} />
              </IconButton>
            </label>
            <input
              id="upload-avatar"
              type="file"
              accept="image/jpeg, image/png"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </>
        ) : null}
      </Box>
    </>
  );
}
