import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useSlideContext } from "../../../../context/SlideContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function AdditionalText() {
  const { t } = useTranslation();
  const { additionalText, setAdditionalText } = useSlideContext();

  return (
    <>
      {/* 追加情報 */}
      <Box>
        <Box mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("slide.additional")}
          </StyledTypography>
        </Box>
        <Box>
          <TextField
            id="outlined-multiline-static"
            // label={t('slide.additional')}
            placeholder={t("slide.additionalPlaceholder")}
            variant="standard"
            maxRows={4}
            value={additionalText}
            onChange={(e) => setAdditionalText(e.target.value)}
            fullWidth
            multiline
            inputProps={{ maxLength: 400 }}
          />
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography variant={"body2"}>{additionalText.length}/400</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
