import Crop169Icon from "@mui/icons-material/Crop169";
import Crop32Icon from "@mui/icons-material/Crop32";
import CropDinIcon from "@mui/icons-material/CropDin";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import {
  Box,
  Button,
  ButtonGroup,
  Collapse,
  Divider,
  IconButton,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useJunoImageGeneratorContext } from "../../../context/ImageGeneratorContext";
import ImageUpload from "./ImageUpload";
import SelectPublic from "./SelectPublic";

const aspectRatios = [
  { ratio: "1:1", icon: CropDinIcon },
  { ratio: "16:9", icon: Crop169Icon },
  { ratio: "4:3", icon: Crop32Icon },
  { ratio: "9:16", icon: Crop169Icon, rotate: "90deg" },
  { ratio: "3:4", icon: Crop32Icon, rotate: "90deg" },
];

export default function ParamsFlux() {
  const { t } = useTranslation();
  const context = useJunoImageGeneratorContext();
  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(true);

  const params = new URLSearchParams(window.location.search);
  const ai_engine = params.get("ai_engine") || "";
  const menu = params.get("menu") || "txt2img";
  const icons = Array.from({ length: 4 }, (_, index) => index + 1);

  const renderAspectRatioIcons = () => {
    return aspectRatios.map(({ ratio, icon: Icon, rotate }) => (
      <Box key={ratio} sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <IconButton
          onClick={() => context.setAspectRatioFlux(ratio)}
          color={context.aspectRatioFlux === ratio ? "primary" : "default"}
        >
          <Icon sx={{ rotate: rotate || "0deg" }} />
        </IconButton>
        <Typography variant={"caption"} onClick={() => context.setAspectRatioFlux(ratio)} sx={{ cursor: "pointer" }}>
          {ratio}
        </Typography>
      </Box>
    ));
  };

  const handleSampleChange = (index: number) => {
    context.setSample({ ...context.sample, [ai_engine]: index + 1 });
  };

  const [disableChangeSample, setDisableChangeSample] = useState(false);

  useEffect(() => {
    if (ai_engine === "Flux1Pro" || ai_engine === "Flux1RealismLoRA") {
      setDisableChangeSample(true);
    } else {
      setDisableChangeSample(false);
    }
  }, [ai_engine]);

  return (
    <>
      <Stack spacing={1} mb={3}>
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant={"body2"}>{t("juno.input.sample")}</Typography>
          </Box>
          <Box sx={{ display: "inline-flex", flexDirection: "row" }} gap={1}>
            <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
              {icons.map((icon, index) => (
                <Button
                  disableElevation
                  key={index}
                  onClick={() => handleSampleChange(index)}
                  variant={context.sample[ai_engine] === index + 1 ? "contained" : "outlined"}
                  disabled={disableChangeSample}
                  sx={{ width: "100%" }}
                >
                  <Typography variant={"button"}>{icon}</Typography>
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Box>
      </Stack>

      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
        <SettingsSuggestIcon sx={{ mr: 1 }} />
        <Typography variant={"subtitle1"} component={"p"}>
          {t("juno.input.basicSettings")}
        </Typography>
      </Box>

      {menu === "img2img" && (
        <Box mb={2}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.image")}</Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 1 }}>
            <ImageUpload />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
              <Typography variant={"body2"}>{t("juno.input.imageStrength")}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row", ml: 0 }} gap={2}>
              <Slider
                value={context.imageStrength}
                onChange={(e, value) => context.setImageStrength(value as number)}
                step={0.01}
                min={0.1}
                max={1}
              />
              <Box
                sx={{
                  width: "120px",
                  bgcolor: "rgba(0, 0, 0, 0.1)",
                  px: 1,
                  py: 0.5,
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "5px",
                }}
              >
                <Typography variant={"body2"}>{context.imageStrength}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {menu === "txt2img" && (
        <>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
            <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }} gap={1}>
            {renderAspectRatioIcons()}
          </Box>
        </>
      )}

      <Box>
        <Divider sx={{ my: 2 }} />
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems={"center"}
          sx={{ mb: 2, cursor: "pointer" }}
          onClick={() => setOpenAdvancedSettings(!openAdvancedSettings)}
        >
          {openAdvancedSettings ? <ExpandLess sx={{ mr: 1 }} /> : <ExpandMore sx={{ mr: 1 }} />}
          <Typography variant={"subtitle1"} component={"p"}>
            {t("juno.input.advancedSettings")}
          </Typography>
        </Box>
      </Box>

      <Collapse in={openAdvancedSettings} sx={{ mb: 4 }}>
        {/* ステップ */}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
            <Typography variant={"body2"}>{t("juno.input.step")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            <Slider
              value={context.step[ai_engine]}
              onChange={(e, value) => context.setStep({ ...context.step, [ai_engine]: value as number })}
              min={ai_engine === "Flux1Schnell" ? 1 : 10}
              max={ai_engine === "Flux1Schnell" ? 12 : 50}
              step={1}
            />
            <Box
              sx={{
                width: "120px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant={"body2"}>{context.step[ai_engine]}</Typography>
            </Box>
          </Box>
        </Box>

        {/* CFG Scale */}
        <Box sx={{ display: "flex", flexDirection: "column", mb: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 1 }}>
            <Typography variant={"body2"}>{t("juno.input.guidanceScale")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row" }} gap={2}>
            <Slider
              getAriaValueText={(value) => `${value}px`}
              value={context.guidanceScaleFlux}
              onChange={(e, value) => context.setGuidanceScaleFlux(value as number)}
              step={0.5}
              min={1}
              max={20}
            />
            <Box
              sx={{
                width: "120px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant={"body2"}>{context.guidanceScaleFlux}</Typography>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
          <TextField
            sx={{ mt: 1, width: "100%" }}
            label={t("juno.input.seedSD")}
            size={"small"}
            variant={"outlined"}
            value={context.seedFlux}
            onChange={(event) => {
              if (event.target.value.match(/^[0-9]+$/)) {
                context.setSeedFlux(Number(event.target.value));
              }
            }}
          />
          <Box>
            <IconButton onClick={() => context.setSeedFlux(0)}>
              <RestartAltIcon />
            </IconButton>
          </Box>
        </Box>
      </Collapse>

      <SelectPublic />
    </>
  );
}
