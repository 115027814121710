import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useAlert } from "../../../../context/AlertContext";
import { useSlideContext } from "../../../../context/SlideContext";
import { useCheckCredit } from "../../../../hooks/useCreditCheck";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Heading() {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [processing, setProcessing] = React.useState(false);
  const { headings, setHeadings, content, srcType, target, additionalText, tone } = useSlideContext();

  const dispatch = useDispatch();
  const { setAlert } = useAlert();

  const createHeadings = async () => {
    try {
      if (!(await checkCredit())) return;
      setProcessing(true);
      const post_url = "/api/v1/slide/headings";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const data = {
        content: content,
        srcType: srcType,
        target: target,
        additionalText: additionalText,
        tone: tone,
      };
      const response = await fetch(post_url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData.success) {
        dispatch(setCreditTrigger(true));
        setHeadings(responseData.data);
      } else {
        setAlert("error", responseData.data);
      }
    } catch (e) {
    } finally {
      setProcessing(false);
    }
  };

  // 見出しだけのファイル作成
  const handleNewFile = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      await axios.post("/api/v1/text-file/", { type: 2, content: headings }, config);
      setAlert("success", t("file.createFile"));
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} mb={2}>
        <StyledTypography variant="body1" gutterBottom>
          {t("slide.heading")}
        </StyledTypography>
      </Box>
      <Box mb={1}>
        <Box display={"flex"} alignItems={"center"} mb={4}>
          <FormatListBulletedIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
          <Typography variant={"body2"}>{t("slide.headingDescription")}</Typography>
        </Box>
        <TextField
          placeholder={t("slide.headingPlaceholder")}
          variant="outlined"
          fullWidth
          value={headings}
          onChange={(e) => {
            setHeadings(e.target.value);
          }}
          multiline
          maxRows={10}
          disabled={processing}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {processing ? (
                  <CircularProgress size={30} />
                ) : (
                  <Tooltip title={t("slide.generateHeadingsDescription")}>
                    <span>
                      <IconButton onClick={createHeadings} disabled={processing}>
                        <AutoAwesomeIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title={t("slide.createWithoutText")}>
                  <span>
                    <IconButton onClick={handleNewFile} disabled={headings.trim().length === 0}>
                      <SaveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </InputAdornment>
            ),
          }}
          inputProps={{ maxLength: 1000 }}
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography variant={"body2"}>Lines {headings.split("\n").length}/10</Typography>
        </Box>
      </Box>
    </Box>
  );
}
