import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import IconButton from "@mui/material/IconButton";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Link from "@mui/material/Link";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import axios from "axios";
import Cookies from "js-cookie";
import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaUnsplash } from "react-icons/fa";
import CloseIcon from "@mui/icons-material/Close";

type CustomToolbarProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setImage: (image: string) => void;
};

const UnsplashModal: React.FC<CustomToolbarProps> = ({ open, setOpen, setImage }) => {
  const { t } = useTranslation();
  const handleClose = () => setOpen(false);
  const [noResult, setNoResult] = React.useState(false);
  const [images, setImages] = React.useState<
    {
      id: string;
      url: string;
      description: string;
      name: string;
      link: string;
      small: string;
      downloadLink: string;
    }[]
  >([]);
  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(1);
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getImages = async (page: number, queryOverride?: string) => {
    setLoading(true);
    const effectiveQuery = queryOverride ?? query;
    try {
      const res = await axios.get(`/api/v1/text-file/unsplash?query=${effectiveQuery}&page=${page}`);
      setLoading(false);
      if (res.data.length === 0) {
        setNoResult(true);
      }
      setImages((prevImages) => [...prevImages, ...res.data]);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching images:", error);
    }
  };

  React.useEffect(() => {
    if (page !== 1) {
      getImages(page);
    }
  }, [page]); // ページ番号が変わるたびに新たなデータを取得

  const handleShowMore = () => {
    setPage((prevPage) => prevPage + 1);
  };

  type FormData = { query: string };
  const { register, handleSubmit } = useForm<FormData>();

  const handleNewSearch = (data: FormData) => {
    setNoResult(false);
    const newQuery = data.query;
    setQuery(newQuery);
    setPage(1);
    setImages([]);
    getImages(1, newQuery);
  };

  // ダウンロードリンクをサーバーに送信
  const postDownloadLink = async (url: string) => {
    const csrftoken = Cookies.get("csrftoken");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    };
    await axios.post(`/api/v1/text-file/unsplash`, { downloadLink: url }, { headers });
  };

  const [loading, setLoading] = React.useState(false);
  const noImage = images.length === 0;

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Paper
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90vw", md: noImage ? 400 : 1080 },
            height: { xs: "100%", md: noImage ? 300 : 700 },
            bgcolor: theme.palette.background.paper + "D9",
            backdropFilter: "blur(5px)",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transition: "all 0.3s ease-in-out",
          }}
        >
          <IconButton sx={{ position: "absolute", top: 8, right: 8 }} size={"small"} onClick={() => setOpen(false)}>
            <CloseIcon fontSize={"small"} />
          </IconButton>

          <Typography variant="h6" component="h2">
            {t("textEditor.unsplash.title")}
          </Typography>
          <Box my={2} color={theme.palette.primary.main}>
            <FaUnsplash size={24} />
          </Box>
          <Typography>{t("textEditor.unsplash.description")}</Typography>
          <Box display={"flex"} flexDirection={"row"} alignItems={"baseline"} gap={1} mt={2}>
            <form
              onSubmit={handleSubmit(handleNewSearch)}
              style={{ display: "flex", flexWrap: "wrap", width: "100%", justifyContent: "center" }}
            >
              <TextField
                sx={{ mr: 1 }}
                variant={"standard"}
                autoComplete={"off"}
                autoFocus
                {...register("query")}
                name="query"
                inputProps={{ style: { minWidth: "250px" } }}
                InputProps={{
                  endAdornment: (
                    <IconButton type="submit" disabled={loading}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </form>
          </Box>
          {noResult && (
            <Typography variant={"body1"} fontSize={"small"} color={"error"} sx={{ mt: 2 }}>
              {t("textEditor.unsplash.noResults")}
            </Typography>
          )}

          {images && images.length > 0 && (
            <Box ref={scrollRef} sx={{ mt: 2, px: 2, overflowY: "scroll", position: "relative", width: "100%" }}>
              <ImageList variant="standard" cols={isXsScreen ? 2 : 5} gap={8} sx={{ m: 0 }}>
                {images.map((item) => (
                  <Grow in={true} timeout={1000} key={item.id}>
                    <ImageListItem
                      style={{
                        position: "relative",
                        backgroundColor: theme.palette.grey[100],
                        maxWidth: "100%",
                        maxHeight: 200,
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src={item.small}
                        alt={item.description}
                        style={{
                          position: "absolute",
                          top: -10,
                          left: -10,
                          width: "110%",
                          height: "110%",
                          objectFit: "cover",
                          filter: "blur(8px)",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // 黒いオーバーレイ、透明度調整で暗さを制御
                        }}
                      ></div>
                      <img
                        src={item.small}
                        alt={item.description}
                        loading="lazy"
                        onClick={() => {
                          postDownloadLink(item.downloadLink).then(() => {
                            setImage(item.url);
                            handleClose();
                          });
                        }}
                        style={{
                          maxWidth: 240,
                          maxHeight: 200,
                          position: "relative",
                          objectFit: "contain",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.border = "2px solid white";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.border = "none";
                        }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                          px: 1,
                          pb: 0.5,
                          backgroundColor: "rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        <Link
                          href={item.link + "?utm_source=jupiterAI&utm_medium=referral"}
                          underline="none"
                          color="white"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          <Typography variant={"caption"}>{item.name}</Typography>
                        </Link>
                      </Box>
                    </ImageListItem>
                  </Grow>
                ))}
                <Button
                  disableElevation
                  sx={{ maxWidth: 240, maxHeight: 260 }}
                  variant={"outlined"}
                  onClick={handleShowMore}
                >
                  Show more
                </Button>
              </ImageList>
            </Box>
          )}
        </Paper>
      </Modal>
    </>
  );
};
export default UnsplashModal;
