import { Box, Container, Fade, Grid, useTheme } from "@mui/material";
import Grow from "@mui/material/Grow";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { getDataLayer } from "../../context/EventContext";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { MaskedText } from "../../utils/maskedText";
import AIs from "./components/AIs";
import Browsing from "./components/Browsing";
import Image from "./components/Image";
import Mindmap from "./components/Mindmap";
import Text from "./components/Text";
import Voice from "./components/Voice";

const MemoizedBrowsing = React.memo(Browsing);
const MemoizedText = React.memo(Text);
const MemoizedMindmap = React.memo(Mindmap);
const MemoizedImage = React.memo(Image);
const MemoizedVoice = React.memo(Voice);
const MemoizedAIs = React.memo(AIs);

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { user } = useLoginStatus();
  const [data, setData] = React.useState<any>(null);
  const massage = getDataLayer("DashboadMessage")?.message;

  const getData = useCallback(async () => {
    try {
      const csrfToken = Cookies.get("csrftoken");
      const response = await axios.get("/api/v1/dash-board/", {
        headers: {
          "X-CSRFToken": csrfToken,
        },
      });
      if (response.status === 200) {
        setData(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    getData();
  }, [getData]);

  const StyledBox = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 16,
    backgroundColor: theme.palette.background.custom1,
    borderRadius: 8,
    width: "100%",
    height: "100%",
  }));

  const browsingProps = useMemo(() => ({ data, getData }), [data, getData]);
  const textProps = useMemo(() => ({ data }), [data]);
  const mindmapProps = useMemo(() => ({ data }), [data]);

  return (
    <Box sx={{ bgcolor: theme.palette.background.default, my: { xs: 2, md: 4 } }}>
      <Meta title={t("dashboard.title")} />
      <Fade in={true} timeout={1500}>
        <Container maxWidth="lg">
          <Meta title={t("dashboard.title")} />
          <DrawerHeader />
          {/* Title */}
          <Grid
            container
            spacing={0}
            sx={{
              bgcolor: theme.palette.background.custom1,
              mb: 2,
              borderRadius: 2,
            }}
          >
            <Grid item xs={12} sm={7}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.2), rgba(0, 0, 0, 0.2)), url(/images/dashBoard/dash_board.jpg)`,
                    textAlign: "center",
                    height: 320,
                    width: "100%",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: { xs: "8px 8px 0 0", sm: "8px 0 0 8px" },
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant={"h2"}
                    component={"h2"}
                    sx={{ color: "white", fontFamily: "'Pacifico', cursive", fontWeight: 500 }}
                  >
                    {t("title2")}
                  </Typography>
                  <img src="/images/logo/logoZI-small.png" style={{ width: 56, height: 56, margin: 16 }} alt="logo" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  py: 2,
                }}
              >
                <MaskedText
                  imageurl={"/images/dashBoard/blur.jpg"}
                  variant="h4"
                  sx={{
                    fontWeight: 900,
                    mb: 2,
                    filter: theme.palette.mode === "dark" ? "brightness(2)" : "brightness(1.2)",
                  }}
                >
                  {t("dashboard.greeting", { name: user?.username })}
                </MaskedText>
                <MaskedText
                  imageurl={"/images/top/fv.jpg"}
                  variant="h6"
                  sx={{
                    fontWeight: 900,
                    filter: theme.palette.mode === "dark" ? "brightness(2)" : "",
                  }}
                >
                  {t("dashboard.welcome")}
                </MaskedText>
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={2} mb={4}>
            {massage && (
              <Grid item xs={12} sm={12}>
                <Grow in={true} timeout={1000}>
                  <StyledBox>
                    <Typography variant={"body1"}>{getDataLayer("DashboadMessage")?.message || "Dashboard"}</Typography>
                  </StyledBox>
                </Grow>
              </Grid>
            )}
            <Grid item xs={12} sm={9.5}>
              {/* Browsing */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  height: "100%",
                }}
              >
                <StyledBox>{data && <MemoizedBrowsing {...browsingProps} />}</StyledBox>

                {/* Text Files */}
                <StyledBox>{data && <MemoizedText {...textProps} />}</StyledBox>

                {/* Mind Map */}
                <StyledBox>{data && <MemoizedMindmap {...mindmapProps} />}</StyledBox>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2.5}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  height: "100%",
                }}
              >
                <StyledBox>{data && <MemoizedImage />}</StyledBox>
                <StyledBox>{data && <MemoizedVoice />}</StyledBox>
                <StyledBox>{data && <MemoizedAIs />}</StyledBox>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Fade>
    </Box>
  );
};

export default React.memo(Dashboard);
