import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import * as React from "react";
import { useSlideContext } from "../../../../context/SlideContext";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Target() {
  const { t } = useTranslation();
  const { target, setTarget } = useSlideContext();

  return (
    <>
      {/*ターゲット */}
      <Box>
        <Box mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("slide.target")}
          </StyledTypography>
        </Box>
        <Box>
          <TextField
            placeholder={t("slide.targetPlaceholder")}
            value={target}
            onChange={(e) => setTarget(e.target.value)}
            variant="standard"
            fullWidth
            autoComplete={"off"}
            inputProps={{ maxLength: 100 }}
          />
          <Box display={"flex"} justifyContent={"flex-end"}>
            <Typography variant={"body2"}>{target.length}/100</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
