const junoTemplate = {
  title: "クイックAI\nテンプレート",
  description:
    "複雑なStable Diffusion (XL) のモデルとLoRAや、DallE3のプロンプトのテンプレートです。選択したあと、好みの変更をしてください。",
  set: "画像生成にセット",
  label: {
    dalle3: {
      title: "DALL·E 3",
      description:
        "OpenAIのDALL·E 3 モデルを使用して画像生成を行います。柔軟なプロンプトで、希望の画像を簡単に生成できます。",
      placeholder: "生成したい画像のテキスト",
    },
    flux1Pro: {
      title: "FLUX.1 Pro",
      description:
        "FLUX.1 シリーズ内で高解像度の画像を生成できる FLUX.1 Pro は、高いプロンプトの柔軟性を持っています。また、MidjourneyV6 や SD Ultra よりも高解像度の画像を生成することも可能です。",
      placeholder: "生成したい画像のテキスト",
    },
    flux1Dev: {
      title: "FLUX.1 Dev",
      description: "速度と画質を高クオリティでバランスさせた FLUX.1 Dev を使用します。",
      placeholder: "生成したい画像のテキスト",
    },
    flux1Schnell: {
      title: "FLUX.1 Schnell",
      description: "画質は FLUX.1 Dev よりも低いが、生成速度が最も速い FLUX.1 Schnell を使用します。",
      placeholder: "生成したい画像のテキスト",
    },
    flux1RealismLoRA: {
      title: "FLUX.1 with Realism LoRA",
      description: "FLUX.1 シリーズのリアリズムモデル。リアリズムを強調した画像を生成することができます。",
      placeholder: "生成したい画像のテキスト",
    },
    Ideogram2: {
      title: "Ideogram 2.0",
      description: "文字入れを得意とするモデルで、他のモデルでは不可能な文字をデザインした画像を生成することが可能。",
      placeholder: "生成したい画像のテキスト",
    },
    Ideogram2Turbo: {
      title: "Ideogram 2.0 Turbo",
      description: "Ideogram 2.0 よりも高速で、品質は下がる。簡単な文字入れを行う際に使用することが可能。",
      placeholder: "生成したい画像のテキスト",
    },
    sdultra: {
      title: "Stable Image Ultra",
      description:
        "Stable Diffusion 3 を含む最も高度なモデルから作成された Ultra は、タイポグラフィ、複雑な構成、ダイナミックな照明、鮮やかな色彩、芸術作品の全体的なまとまりと構造に優れています。",
      placeholder: "生成したい画像のテキスト",
    },
    sd3: {
      title: "Stable Diffusion 3",
      description:
        "Stability AI 開発している最新モデル、Stable diffusion 3。XLよりも高画質になり、プロンプトが最も柔軟になりました。また、画像生成AIが苦手とする文字入れも大幅に改善。",
      placeholder: "生成したい画像のテキスト",
    },
    sdxl: {
      title: "SDXL 1.0",
      description:
        "Stability AI 開発している、Stable diffusion XL 1.0 モデルを使用して画像生成を行います。最も汎用的で、一般的なモデル。",
      placeholder: "生成したい画像のテキスト",
    },
    art: {
      title: "2.5D コンセプトアート\nイラスト",
      description: "素晴らしいコンセプトアートを生成するために設計された2.5Dモデル",
      placeholder: "たくさんのクラゲ、カラフル",
    },
    real: {
      title: "フォトリアル",
      description: "素晴らしく精細な画像生成を目的としたモデル。写真のような画像生成を得意とします。",
      placeholder: "目のクローズアップ、木のテクスチャ",
    },
    cinematic: {
      title: "シネマティック",
      description: "本物の映画のようなテイストを目指したモデルとLoRaを使用したテンプレート。",
      placeholder: "宇宙飛行士がきれいな惑星を見上げている",
    },
    midjourney: {
      title: "Midjourney mimic",
      description:
        "Midjourney は、写真、アート、アニメ、マンガなど、あらゆるものをうまく処理することを目的とした生成AIです。このモデルでは、忠実にMidjourneyを再現し、SDXLの長所も持ち合わせたモデルです。",
      placeholder: "生成したい画像のテキスト",
    },
    dreamShaperXL: {
      title: "オールマイティ - \nDream Shaper XL",
      description:
        "DreamShaper は、写真、アート、アニメ、マンガなど、あらゆるものをうまく処理することを目的とした汎用SDモデルです。これは、Midjourney や DALL-E などの他の汎用モデルやパイプラインに対抗するように設計されています。\n",
      placeholder: "生成したい画像のテキスト",
    },
    juggernautXL: {
      title: "シネマティック\nポートレート",
      description:
        "フォトリアルを得意とし、SDXLで最も人気のモデルを使用。多くのシネマティックな画像を学習しており、シネマティックな画像生成も得意とする。",
      placeholder: "カフェに居る女性",
    },
    film: {
      title: "1980年代風の\nフィルムカメラスタイル",
      description: "フィルムカメラで撮影されたような画像を生成します。",
      placeholder: "薄暗い街灯、後ろ姿",
    },
    logo: {
      title: "ロゴ",
      description:
        "ロゴ・アイコンを生成します。具体的なプロンプトを指定しない場合、非常にシンプルなロゴが生成されます。",
      placeholder: "宇宙企業のロゴ、紫と黒と暗い青",
    },
    textLogo: {
      title: "ロゴ with テキスト",
      description:
        "テキストを挿入したり、テキストを自体を生成するLoRaでロゴを生成。失敗も多いため、短い英単語を推奨。logo 'JUNO' のように入力する必要がある。",
      placeholder: "(例）:  ((logo 'JUNO')), planet, jupiter, vector, simple",
    },
    text: {
      title: "テキスト",
      description:
        "テキストを挿入したり、テキストを自体を生成するLoRaを使用しています。失敗も多いため、短い英単語を推奨。the word 'MONSTER' のように入力する必要がある。",
      placeholder:
        "(例）: ((A wall inscribed with the word 'MONSTER' written in blood)), cinematic lighting, raw photo",
    },
    pixelArt: {
      title: "ピクセルアート",
      description:
        "90年代ゲーム風のピクセルアートを生成します。好きなモデルと組み合わせて、最終的にピクセルアートに仕上げてください。",
      placeholder: "シンセウェーブ, 木星",
    },
    anime: {
      title: "アニメ Animagine",
      description:
        "SDXLで、定番のアニメ・イラストモデルで画像を生成します。有名な幅広いアニメキャラクターに最適されたデータセットであり、プロンプトに以下のように設定します。\n'1girl/1boy, character name, from what series'",
      placeholder: "女性、浴衣",
    },
    anime2: {
      title: "アニメ Ghost XL",
      description: "精細なアニメ画像を生成します。SD1.5で人気のGhostMixのSDXL版である、GhostXLを使用しています。",
      placeholder: "女性、カラフル、黒と金",
    },
    anime3: {
      title: "アニメ anima pencil XL",
      description: "人気モデルの Animagine と Blue pencilをマージしたモデル。",
      placeholder: "女性、カラフル、黒と金",
    },
    flat: {
      title: "フラットカラー\nイラストレーション",
      description: "イラストレーターで描いたようなフラットなイラストを生成します。",
      placeholder: "狼、青のテーマ",
    },
    utagawa: {
      title: "歌川国芳スタイル",
      description: "日本の有名な画家、歌川国芳のスタイルで画像を生成します。",
      placeholder: "風景、富士山",
    },
    katsushika: {
      title: "葛飾北斎スタイル",
      description: "日本の有名な画家、葛飾北斎のスタイルで画像を生成します。",
      placeholder: "波、富士山",
    },
    minimalist: {
      title: "イラストレーター風の風景",
      description: "イラストレーターで描いたミニマムなデザインの風景",
      placeholder: "木、山、湖、青いテーマ",
    },
    japaneseWoman1: {
      title: "美しい日本人風の女性",
      description: "汎用モデルでは出力の難しい、ナチュラルな美しい日本人風の女性",
      placeholder: "浴衣姿、神社",
    },
    japaneseWoman2: {
      title: "シネマティック\nポートレート（アジアン）",
      description: "日本製モデル。コントラストのある印象的な美しい日本人風の女性",
      placeholder: "浴衣姿、神社",
    },
    japaneseWoman3: {
      title: "都会的な日本人女性",
      description: "都会に馴染む、美しい日本人風の女性",
      placeholder: "Tシャツ、ミディアムヘア",
    },
    beautiful3D: {
      title: "3Dの美しい女性",
      description: "生成AIでよく使われているモデルを使用して、3DCGの美しい女性を生成します。",
      placeholder: "Tシャツ、ミディアムヘア",
    },
    ghibli: {
      title: "スタジオジブリ風の風景",
      description:
        "スタジオジブリ風の背景を生成します。人物も入れたい場合、ネガティブプロンプトの((human))を除外することを忘れないようにしてください。",
      placeholder: "日本",
    },
    ghibli2: {
      title: "スタジオジブリ風の\nアニメスタイル",
      description: "スタジオジブリ風のアニメスタイルです。SDXLのため、より柔軟なプロンプトが可能。",
      placeholder: "カメラから目を背ける女性",
    },
    octans: {
      title: "神秘的な美しい空",
      description:
        "アニメ調の美しい空を生成します。人物も入れたい場合、ネガティブプロンプトの((human, girl))を除外することを忘れないようにしてください。",
      placeholder: "日本",
    },
  },
};
export default junoTemplate;
