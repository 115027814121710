import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import React from "react";

interface ImgModalProps {
  src: string;
  alt: string;
  open: boolean;
  onClose: () => void;
}

const ImageModal: React.FC<ImgModalProps> = ({ src, alt, open, onClose }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ maxWidth: "100%", maxHeight: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}
      onClick={onClose}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.default",
          boxShadow: 24,
          p: { xs: 2, md: 4 },
          outline: 0,
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
          size={"small"}
          onClick={onClose}
        >
          <CloseIcon fontSize={"small"} />
        </IconButton>
        <img src={src} alt={alt} style={{ maxWidth: "100%", maxHeight: "calc(100vh - 112px)", objectFit: "contain" }} />
      </Box>
    </Modal>
  );
};

export default ImageModal;
