import React from "react";
import LoginModal from "./LoginModal";
import SignupModal from "./SignupModal";
import PasswordResetModal from "./PasswordResetModal";

const ModalsManager = () => {
  return (
    <>
      <LoginModal />
      <SignupModal />
      <PasswordResetModal />
    </>
  );
};

export default ModalsManager;
