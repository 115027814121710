const library = {
    voice: {
        title: 'ボイス',
        description: '最新の50件',
        name: 'File ID',
        speakingRate: '速度',
        seconds: '秒',
        pitch: 'ピッチ',
        language: '言語',
        text: 'テキスト',
        voice_name: 'ボイス',
        created_at: '作成日',
        action: '操作',
        size: 'サイズ',
        delete: {
            title: '音声ファイルを削除しますか？',
            description: '音声ファイルを削除されます。この操作は取り消せません。',
            success: '音声ファイルが削除されました。',
            error: '音声ファイルの削除に失敗しました。',
        },
    },
    mindmap: {
        title: 'マインドマップ',
        create: '空のマインドマップを追加',
        generator: 'マインドマップジェネレーター',
        message: {
            create: '空のマインドマップを作成しました。',
        }
    },
    text: {
        title: 'テキスト・カルーセル',
        fileTitle: 'タイトル',
        create: '空のファイルを追加',
        type: {
            title: 'タイプ',
            text: 'テキスト',
            slide: 'カルーセル',
        },
        publicStatus: {
            title: '公開状態',
            public: '公開済',
            private: '非公開',
            shared: '共有中',
        },
        createdAt: '作成日',
        updatedAt: '更新日',
        action: '操作',
        delete: {
            title: 'テキスト（カルーセル）を削除しますか？',
            description: 'テキスト（カルーセル）が削除されます。この操作は取り消せません。',
            success: 'テキスト（カルーセル）が削除されました。',
            error: 'テキスト（カルーセル）の削除に失敗しました。',
        },
        message: {
            create: '空のテキスト（カルーセル）を作成しました。',
        }
    }

}
export default library;