import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useArticleContext } from "../../../../context/ArticleContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function AdditionalText() {
  const { t } = useTranslation();
  const { additionalText, setAdditionalText } = useArticleContext();

  return (
    <Box>
      {/* 追加情報 */}
      <Box mb={2}>
        <StyledTypography variant="body1" gutterBottom>
          {t("article.additional")}
        </StyledTypography>
      </Box>
      <Box>
        <TextField
          id="outlined-multiline-static"
          placeholder={t("article.additionalPlaceholder")}
          variant="standard"
          maxRows={6}
          value={additionalText}
          onChange={(e) => setAdditionalText(e.target.value)}
          fullWidth
          multiline
          inputProps={{ maxLength: 400 }}
        />
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography variant={"body2"}>{additionalText.length}/400</Typography>
        </Box>
      </Box>
    </Box>
  );
}
