import drawer from "./autenticated/drawer";
import top from "./unauthenticated/top";
import notesNebula from "./unauthenticated/notesNebula";
import pricing from "./unauthenticated/pricing";
import dashboard from "./autenticated/dashboard";
import browsing from "./autenticated/browsing";
import library from "./autenticated/library";
import juno from "./autenticated/juno";
import voice from "./autenticated/voice";
import file from "./autenticated/file";
import mindmap from "./autenticated/mindmap";
import summarize from "./autenticated/summarize";
import colorPalette from "./autenticated/colorPalette";
import article from "./autenticated/article";
import slide from "./autenticated/slide";
import videoScript from "./autenticated/videoScript";
import textEditor from "./autenticated/textEditor";
import template from "./autenticated/template";
import preference from "./autenticated/preference";
import common from "./autenticated/common";
import validation from "./autenticated/validation";
import aboutJuno from "./unauthenticated/aboutJuno";
import message from "./autenticated/message";
import meta from "./unauthenticated/meta";

export const japanese = {
  translations: {
    title: "Tenak AI",
    title2: "Tenak.ai",
    company: "Diocode, Inc.",
    drawer: drawer,
    top: top,
    notesNebula: notesNebula,
    aboutJuno: aboutJuno,
    pricing: pricing,
    dashboard: dashboard,
    browsing: browsing,
    library: library,
    juno: juno,
    voice: voice,
    file: file,
    mindmap: mindmap,
    summarize: summarize,
    colorPalette: colorPalette,
    article: article,
    slide: slide,
    videoScript: videoScript,
    textEditor: textEditor,
    template: template,
    preferences: preference,
    common: common,
    validation: validation,
    message: message,
    meta: meta,
  },
};
