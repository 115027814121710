import axios from "axios";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAlert } from "../context/AlertContext";
import { useLoginStatus } from "../context/LoginStatusContext";

// カスタムフックとして定義
export const useCheckCredit = () => {
  const { isLoggedIn } = useLoginStatus();
  const [credits, setCredits] = React.useState<number>();

  // ユーザーのクレジットを取得
  const fetchUserCredits = useCallback(async () => {
    try {
      if (!isLoggedIn) return;
      const res = await axios.get("/api/v1/credit/");
      setCredits(res.data.credits);
      return res.data.credits;
    } catch (error) {
      console.error("Failed to obtain credits:", error);
    }
  }, [isLoggedIn]);

  const { t } = useTranslation();
  const { setAlert } = useAlert();

  // クレジットチェックのロジックをカプセル化した関数を提供
  const checkCredit = () => {
    return fetchUserCredits().then((res) => {
      const credit = Number(res);
      if (!isNaN(credit) && credit <= 0) {
        console.log("credit", credit);
        setAlert("error", t("validation.noCredit"));
        return false;
      } else if (!isNaN(credit) && credit > 0) {
        return true;
      } else {
        return false;
      }
    });
  };

  // このカスタムフックを使用するコンポーネントにチェック関数を提供
  return { checkCredit, credits, fetchUserCredits };
};
