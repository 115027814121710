import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useVideoScriptContext } from "../../../../context/VideoScriptContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function DesiredVideo() {
  const { t } = useTranslation();
  const context = useVideoScriptContext();

  return (
    <>
      {/* 希望動画テイスト */}
      <Box>
        <Box mb={2}>
          <StyledTypography variant="body1" gutterBottom>
            {t("videoScript.desiredVideo")}
          </StyledTypography>
        </Box>
        <Box>
          <TextField
            placeholder={t("videoScript.desiredVideoPlaceholder")}
            value={context.desiredVideo}
            onChange={(e) => context.setDesiredVideo(e.target.value)}
            variant="standard"
            fullWidth
            multiline
            maxRows={4}
            autoComplete={"off"}
            inputProps={{ maxLength: 400 }}
          />
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <Typography variant={"body2"}>{context.desiredVideo.length}/400</Typography>
        </Box>
      </Box>
    </>
  );
}
