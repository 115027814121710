import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { marked } from "marked";
import Container from "@mui/material/Container";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import { Fade } from "@mui/material";
import i18n from "i18next";
import Meta from "../../components/common/Meta";

const Document = () => {
  const location = useLocation();
  const [document, setDocument] = useState("");
  const theme = useTheme();
  const [lang, setLang] = useState(i18n.language); // 現在の言語状態

  useEffect(() => {
    const fetchDocument = () => {
      const markdownPath = `/documents/${location.pathname}.md`;

      fetch(markdownPath)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((text) => {
          const html = marked(text);
          setDocument(html);
        })
        .catch((error) => {
          console.error("Failed to fetch markdown:", error);
          setDocument("<p>Error loading document.</p>");
        });
    };

    // 言語変更イベントのリスナーを設定
    const onLanguageChanged = (newLang) => {
      setLang(newLang);
      fetchDocument();
    };

    // 初回および言語変更時にドキュメントをフェッチ
    fetchDocument();

    // i18nextのlanguageChangedイベントにリスナーを登録
    i18n.on("languageChanged", onLanguageChanged);

    // コンポーネントアンマウント時にイベントリスナーをクリーンアップ
    return () => {
      i18n.off("languageChanged", onLanguageChanged);
    };
  }, [location.pathname, lang]); // 依存配列にlangを追加

  // title　一行目
  const title = document ? document.replace(/<[^>]*>?/gm, "").split("\n")[0] : "Document";
  const meta = [
    { name: "robots", content: "index, follow" },
    { name: "description", content: "" },
  ];

  return (
    <>
      <Meta title={title} meta={meta} />
      <Container maxWidth={"md"}>
        <DrawerHeader />
        <Fade in={true} timeout={1500}>
          <Paper
            sx={{
              mb: 6,
              px: { xs: 2, sm: 6 },
              py: 8,
              bgcolor: theme.palette.background.paper,
              "& a": {
                color: theme.palette.primary.main,
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              },
              color: theme.palette.text.primary,
            }}
            elevation={0}
          >
            {document && <div dangerouslySetInnerHTML={{ __html: document }} />}
          </Paper>
        </Fade>
      </Container>
    </>
  );
};

export default Document;
