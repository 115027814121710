const file = {
    title: 'File',
    deleteFile: {
        title: 'Are you sure you want to delete the file?',
        description: 'Deleting the file will remove all data within the file. This action cannot be undone.',
        success: 'The file has been deleted.',
    },
    saveFile: "Saved File",
    createFile: "Created File",
    alert: {
        title: 'Text was not confirmed.',
        description: 'Please drag the text you want to modify in the text editor, then press the button.',
    },

}
export default file;