import React, { createContext, useContext, useState } from "react";

type NodeAction = "delete" | "add" | "";

type ActionTriggerState = {
  id: string;
  node_action: NodeAction;
  triggerFetch: boolean;
};

type MindMapContextType = {
  actionTrigger: ActionTriggerState;
  setActionTrigger: (id: string, node_action: NodeAction) => void;
  selectedNode: string;
  setSelectedNode: (id: string) => void;
};

const MindMapContext = createContext<MindMapContextType | undefined>(undefined);

export const useMindMap = () => {
  const context = useContext(MindMapContext);
  if (!context) {
    throw new Error("useMindMap must be used within a MindMapProvider");
  }
  return context;
};

export const MindMapProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [actionTrigger, setActionTriggerState] = useState<ActionTriggerState>({
    id: "",
    node_action: "",
    triggerFetch: false,
  });
  const [selectedNode, setSelectedNode] = useState<string>("");

  // triggerFetchを反転させるsetActionTrigger関数
  const setActionTrigger = (id: string, node_action: NodeAction) => {
    setActionTriggerState((prevState) => ({
      ...prevState,
      id,
      node_action,
      triggerFetch: !prevState.triggerFetch,
    }));
  };

  return (
    <MindMapContext.Provider value={{ actionTrigger, setActionTrigger, selectedNode, setSelectedNode }}>
      {children}
    </MindMapContext.Provider>
  );
};
