// 出力時のオーバーレイで画面を操作不可にする
import React from "react";
import Box from "@mui/material/Box";
import { alpha, keyframes } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { Fade } from "@mui/material";
import Typography from "@mui/material/Typography";

interface GeneratingLockProps {
  show: boolean;
}

// 回転アニメーションの定義
const rotateGradient = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const GeneratingLock = ({ show }: GeneratingLockProps) => {
  return (
    <Fade in={show} timeout={300}>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: (theme) => alpha(theme.palette.background.default, 0.8),
          zIndex: 10000,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            px: 10,
            py: 4,
            background: (theme) => theme.custom.gradient.light,
            backgroundSize: "150% 150%",
            animation: `${rotateGradient} 2s ease infinite`,
            borderRadius: 4,
            boxShadow: (theme) => `0px 0px 8px 0px ${theme.palette.primary.main}`,
          }}
        >
          <CircularProgress size={20} sx={{ color: "white" }} />
          <Box sx={{ ml: 2 }}>
            <Typography variant="button">Exporting...</Typography>
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default GeneratingLock;
