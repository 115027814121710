import React from "react";
import { Button, Hidden, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { alpha, useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import styled from "@mui/material/styles/styled";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ImageIcon from "@mui/icons-material/Image";
import ArticleIcon from "@mui/icons-material/Article";
import { useLoginStatus } from "../../../context/LoginStatusContext";

const CenterContents = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const location = useLocation();
  const darkColor = theme.palette.text.primary;
  const { isLoggedIn } = useLoginStatus();

  const getPathPart = (index: number) => location.pathname.split("/")[index];

  const StyledSwitchButtonContainer = styled(Stack)({
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    backdropFilter: "blur(8px)",
    padding: "4px 4px",
    borderRadius: "18px",
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: alpha(theme.palette.background.paper, 0.4),
    height: 36,
  });

  interface ButtonConfig {
    path: string;
    label: string;
    icon: React.ReactElement;
  }

  const buttons: ButtonConfig[] = [
    { path: "", label: "title", icon: <AutoAwesomeIcon fontSize={"small"} color={"secondary"} /> },
    { path: "juno", label: "juno.title", icon: <ImageIcon fontSize={"small"} color={"secondary"} /> },
    { path: "notes-nebula", label: "notesNebula.title", icon: <ArticleIcon fontSize={"small"} color={"secondary"} /> },
  ];

  const CustomButton: React.FC<ButtonConfig> = ({ path, label, icon }) => {
    const currentPath = getPathPart(2);
    const isActive = () => {
      if (path === "") {
        return !["juno", "notes-nebula"].includes(currentPath);
      }
      return path === currentPath;
    };

    return (
      <Button
        onClick={() => navigate(path === "" ? "" : `/${path}`)}
        variant="text"
        startIcon={icon}
        sx={{
          color: isActive() ? "primary.light" : darkColor,
          backgroundColor: isActive() ? alpha(theme.palette.primary.main, 0.2) : "transparent",
          borderRadius: "14px",
          width: 160,
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
          },
          transition: "background-color 0.5s",
        }}
      >
        <Typography fontWeight={"bold"} variant={"caption"}>
          {t(label)}
        </Typography>
      </Button>
    );
  };

  return (
    <Hidden lgDown>
      {
        // 未ログインかつ記事ページの場合は表示しない
        getPathPart(3) === "articles" && !isLoggedIn ? null : (
          <StyledSwitchButtonContainer direction="row">
            {buttons.map((button, index) => (
              <CustomButton key={index} {...button} />
            ))}
          </StyledSwitchButtonContainer>
        )
      }
    </Hidden>
  );
};

export default CenterContents;
