import React from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const Section2 = () => {
  const { t } = useTranslation();

  const items = [
    {
      title: t("aboutJuno.section2.card1.title"),
      description: t("aboutJuno.section2.card1.description"),
      src: "/images/juno/section2-1.jpg",
    },
    {
      title: t("aboutJuno.section2.card2.title"),
      description: t("aboutJuno.section2.card2.description"),
      src: "/images/juno/section2-2.jpg",
    },
    {
      title: t("aboutJuno.section2.card3.title"),
      description: t("aboutJuno.section2.card3.description"),
      src: "/images/juno/section2-3.jpg",
    },
  ];

  return (
    <>
      <Box component={"section"} sx={{ mt: -4, width: "100%" }}>
        <Grid container spacing={{ xs: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              component={"h3"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              豊富なモデルで、無限の可能性を
            </Typography>
          </Grid>

          {items.map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <ColumnCenteredBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundImage: `url(${item.src})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    height: 200,
                    width: "100%",
                    borderRadius: 2,
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      translateX: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      borderRadius: 2,
                      p: 2,
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h6"
                      component="h4"
                      fontWeight={"bold"}
                      fontFamily={"'Noto Serif JP', roboto"}
                      m={2}
                    >
                      {item.title}
                    </Typography>
                    <Typography variant="body1" component="p" m={2}>
                      {item.description}
                    </Typography>
                  </Box>
                </Box>
              </ColumnCenteredBox>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};
export default Section2;