import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import { useLoginStatus } from "./LoginStatusContext";

type AvatarContextType = {
  avatarUrl: string | null;
  isAvatarExist: boolean;
  triggerReload: () => void;
};

const AvatarContext = createContext<AvatarContextType | undefined>(undefined);

export const AvatarProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [isAvatarExist, setIsAvatarExist] = useState(false);
  const [reloadAvatar, setReloadAvatar] = useState(false);
  const { isLoggedIn } = useLoginStatus();

  const getAvatar = async () => {
    try {
      const response = await axios.get("/api/v1/users/avatar");
      if (response.data.url !== null) {
        setIsAvatarExist(true);
        setAvatarUrl(response.data.url);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const triggerReload = () => {
    setReloadAvatar(!reloadAvatar); // トリガーの値を切り替える
  };

  useEffect(() => {
    if (isLoggedIn) {
      getAvatar();
    } else {
      setIsAvatarExist(false);
      setAvatarUrl(null);
    }
  }, [reloadAvatar, isLoggedIn]); // 依存配列にトリガーを追加

  return (
    <AvatarContext.Provider value={{ avatarUrl, isAvatarExist, triggerReload }}>{children}</AvatarContext.Provider>
  );
};

export const useAvatar = () => {
  const context = useContext(AvatarContext);
  if (context === undefined) {
    throw new Error("useAvatar must be used within an AvatarProvider");
  }
  return context;
};
