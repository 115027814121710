import React from "react";
import { Box, Button, Fade, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { CustomSection } from "../../components/common/CustomSection";
import SupportFormModal from "./components/SupportFormModal";

const Misc = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const params = new URLSearchParams(window.location.search);
  const supportForm = params.get("supportForm");
  const [open, setOpen] = React.useState(supportForm === "open");

  return (
    <>
      <Box component="div">
        <Typography component={"h3"} variant="h5" gutterBottom my={2}>
          {t("preferences.misc.title")}
        </Typography>

        <Fade in={true} timeout={500}>
          <Paper elevation={0} sx={{ width: "100%", height: "100%" }}>
            <CustomSection>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">{t("preferences.misc.privacyPolicy")}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="text" onClick={() => navigate("/privacy-policy")}>
                      {t("preferences.misc.confirm")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CustomSection>
            <CustomSection>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">{t("preferences.misc.termsOfService")}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="text" onClick={() => navigate("/terms-of-service")}>
                      {t("preferences.misc.confirm")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CustomSection>
            <CustomSection>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">{t("pricing.legalNotice")}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="text" onClick={() => navigate("/legal-notice")}>
                      {t("preferences.misc.confirm")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CustomSection>
            <CustomSection>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item xs={5}>
                  <Typography variant="subtitle1">{t("preferences.misc.contact")}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Box display="flex" justifyContent="flex-end">
                    <Button variant="text" onClick={() => setOpen(true)}>
                      {t("preferences.misc.confirm")}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CustomSection>
          </Paper>
        </Fade>
      </Box>
      <SupportFormModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};

export default Misc;
