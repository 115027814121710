import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Fade } from "@mui/material";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import PaletteIcon from "@mui/icons-material/Palette";
import { GradientTypography } from "../../../../utils/gradientTypography";
import { ColumnBox } from "../../../../utils/styledBox";

const Misc = () => {
  const { t } = useTranslation();

  const ais = [
    {
      title: t("top.fv.misc.voice"),
      desc: t("top.fv.misc.voiceDesc"),
      icon: <GraphicEqIcon fontSize={"small"} sx={{ mr: 1 }} />,
      image: "voice",
    },
    {
      title: t("top.fv.misc.color"),
      desc: t("top.fv.misc.colorDesc"),
      icon: <PaletteIcon fontSize={"small"} sx={{ mr: 1 }} />,
      image: "color",
    },
  ];

  return (
    <Box sx={{ height: "100%", p: { xs: 2, md: 4 } }}>
      <GradientTypography variant={"h5"} component={"h2"} gutterBottom>
        {t("top.fv.misc.title")}
      </GradientTypography>
      <Typography>{t("top.fv.misc.description")}</Typography>
      <ColumnBox gap={2} sx={{ mt: 4 }}>
        {ais.map((ai, index) => {
          return (
            <Fade in={true} timeout={index * 500} key={index}>
              <ColumnBox>
                <Box display="flex" alignItems="center" color="primary.main" mb={1}>
                  {ai.icon}
                  <Typography variant="subtitle1" component="p" ml={1}>
                    {ai.title}
                  </Typography>
                </Box>
                <Typography paragraph>{ai.desc}</Typography>
              </ColumnBox>
            </Fade>
          );
        })}
      </ColumnBox>
    </Box>
  );
};

export default Misc;
