const slide = {
  library: "Library",
  title: "Carousel Script for Social Media",
  description: "Generate carousel scripts optimized for Instagram, Facebook, and other social media platforms.",
  target: "Target Audience",
  targetPlaceholder: "e.g., Photographers aged 20-30",
  source: "Source",
  sourceDescription: "Select a reference source.",
  heading: "Headline Structure",
  headingPlaceholder: "e.g.,\n# Carousel1 Heading\n# Carousel2 Heading\n# Carousel3 Heading",
  headingDescription: "Enter up to 10 headings. (One headline per line)",
  tone: "Tone of Text",
  additional: "Additional Instructions (Optional)",
  additionalPlaceholder:
    "Enter any specific instructions or guidelines. These will also apply to AI-generated headlines.",
  srcType: {
    url: "URL",
    urlPlaceholder: "https://www.example.com",
    urlDescription: "Enter the URL of the reference website.",
    youtube: "YouTube",
    youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx (supports YouTube Shorts)",
    youtubeDescription: "Enter the URL of the YouTube video for reference.",
    text: "Text",
    textPlaceholder: "Enter text (up to 4,000 characters)",
    textDescription: "Enter the text for reference.",
    keyword: "Keyword",
    keywordPlaceholder: "e.g., iPhone camera",
    keywordDescription: "Enter keywords for reference.",
  },
  generateHeadings: "Generate Headings with Tenak AI",
  generateHeadingsDescription:
    'Tenak AI generates headlines based on highly-rated Google articles, related questions, and searches when "Google Search" is selected as "Source".',
  createWithoutText: "Create CSV with Headings Only",
  submit: "Generate Carousel Script",
  error: {
    createHeading: "Please enter a headline of more than 30 characters, or have AI generate one.",
    invalidYoutubeUrl: "Please enter a valid YouTube URL.",
    invalidUrl: "Please enter a valid URL.",
    invalidText: "Please enter valid text. (Up to 4,000 characters)",
    invalidContent: "Please select a source",
    invalidKeywords: "Please enter valid keywords.",
    invalidHeadings: "You can enter up to 10 headings.",
  },
  download: "Download CSV for Canva",
  moveUp: "Move Slide Up",
  moveDown: "Move Slide Down",
  addSlide: "Add New Slide to Carousel",
  deleteSlide: "Remove Slide from Carousel",
  titleInput: "Title",
  headingInput: "Heading",
  textInput: "Text",
  coverPage: "Cover Page",
};
export default slide;
