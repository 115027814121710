const dashboard = {
    title: "ダッシュボード",
    greeting: "こんにちは、{{name}}。",
    welcome: "ようこそ、Tenak AIへ。",
    search: "調べる、会話する",
    addWorkspace: "ワークスペース作成",
    text: "文書を作成する",
    addText: "白紙の文書",
    addSlide: "白紙のカルーセル",
    addMindmap: "白紙のマインドマップ",
    image: "画像生成",
    mindmap: "マインドマップ / ブレインストーミングを作る",
    voice: "音声生成",
    misc: "MISC AI",
    library: "ライブラリ",
}
export default dashboard;