import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LanguageIcon from "@mui/icons-material/Language";
import Cookies from "js-cookie";
import i18n from "i18next";
import Button from "@mui/material/Button";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";

export default function LanguageSwitcher({ color }: { color?: string }) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { isLoggedIn } = useLoginStatus();
  const navigate = useNavigate();
  const customNavigate = useCustomNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const switchLanguage = (language: string) => {
    setLoading(true);
    i18n.changeLanguage(language);
    Cookies.set("ui_language", language, { expires: 180 });
    handleClose();

    const currentPath = location.pathname.split("/").slice(2).join("/");
    let newPath = `/${language}/${currentPath}`;
    if (newPath.slice(-1) === "/") {
      newPath = newPath.slice(0, -1);
    }
    navigate(newPath, { replace: true });

    setLoading(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-haspopup="true"
        aria-controls="language-menu"
        onClick={handleClick}
        sx={{ color: color }}
      >
        <LanguageIcon />
      </IconButton>
      <Menu id="language-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => switchLanguage("en")}>English (English)</MenuItem>
        <MenuItem onClick={() => switchLanguage("ja")}>Japanese (日本語)</MenuItem>
        {isLoggedIn && (
          <Box>
            <Divider sx={{ my: 1 }} />
            <Box display={"flex"} p={1} justifyContent={"center"}>
              <Button
                onClick={() => customNavigate("/preferences/settings")}
                variant={"outlined"}
                color={"primary"}
                sx={{ width: "100%", m: 1 }}
              >
                <Typography variant={"button"}>{t("preferences.settings.aiLanguageButton")}</Typography>
              </Button>
            </Box>
          </Box>
        )}
      </Menu>
    </>
  );
}

export const changeUILanguage = (language: string) => {
  i18n.changeLanguage(language);
  Cookies.set("ui_language", language, { expires: 180 });
};
