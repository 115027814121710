const top = {
  login: "ログイン",
  logout: "ログアウト",
  delete: "アカウント削除",
  signupHeader: "無料新規登録",
  signup: "アカウント作成",
  createAccount: "アカウント作成",
  resetPassword: "パスワードリセット",
  resetSuccess: "パスワードリセット成功しました。ログインしてください。",
  resetError: "パスワードリセットに失敗しました。もう一度お試しいただくか、support@tenak.aiまでお問い合わせください。",
  forgotPassword: "パスワードを忘れた場合",
  check_email: "E-mailが送信されました。アカウントを有効化してください。",
  agree:
    "アカウントを作成することにより、以下の文書に同意したとみなされます。サービス利用前に、これらの文書をよく読み、ご理解ください。",
  tooltip: {
    username: "ユーザーネームは、4から16文字の小文字、数字、アンダースコアで構成してください。",
    email: "メールアドレスは有効な形式で入力してください。",
    password:
      "パスワードは8から15文字で、少なくとも一つの小文字、一つの大文字、一つの数字、一つの記号（@$!%*?&）を含めてください。",
    resetPassword: "登録されたメールアドレスを正確に入力してください。登録されていないメールアドレスには届きません。",
  },
  googleLogin: "Googleでログイン・サインイン",
  fv: {
    welcome: "ようこそ、Tenak AIへ",
    catchCopy: "創造という星の海を、Tenak AIと共に。",
    description: "日常からクリエイティブまでサポートする万能AIアシスタント",
    whatIsTenak: "全てを Tenak AI ひとつで。",
    detail:
      "Tenak AIは、多機能なAIツールでデジタルコンテンツ作成を支援します。例えば「STUDIO JUNO」で高品質な画像を生成し、ライティングAIで10,000文字以上のSEOに強い記事を簡単に作成できます。また、マインドマップ、ボイスやカラーパレットも生成可能です。作成したコンテンツは「Notes Nebula」で簡単に公開できます。Tenak AIは、あなたの創造力を最大限に引き出します。",
    juno: {
      title: "Stable Diffusion 3 や DALL-E3 を初めとした、画像生成AI - STUDIO JUNO",
      description: "STUDIO JUNOは、様々な画像生成AIを使用できる画像生成AIのプラットフォームです。",
      feature1: "最新のAIエンジンを使用可能",
      feature1Desc: "STUDIO JUNOは、DALL-E 3、Stable Diffusion 3、Stable Diffusion (XL)、FLUX.1 を使用可能。",
      feature2: "500以上のコミュニティモデル",
      feature2Desc: "Stable Diffusion（XL）の500以上のコミュニティモデルを提供。様々な画像に特化したモデルを選択可能。",
      feature3: "クイック画像生成AIテンプレート",
      feature3Desc: "生成が難しいStable Diffusionの画像生成AIのテンプレートを使用し、初心者でも簡単に画像生成が可能。",
    },
    editor: {
      title: "次世代のAIテキストエディター",
      description:
        "Tenak AIのテキストエディターは、AIアシスタントを搭載し、文章作成をサポートします。また、Studio Junoで生成した画像を簡単に記事に追加しNotes Nebulaで簡単に公開可能。",
    },
    notesNebula: {
      title: "Notes Nebulaで記事の投稿",
      description:
        "Tenak AIの記事公開プラットフォーム「Notes Nebula」は、AIテキストエディターで執筆した記事を簡単に公開可能。",
      button: "Notes Nebula - 記事公開プラットフォーム",
    },
    seamless: {
      title: "Studio Juno とのシームレスな連携",
      description:
        "Tenak AIの画像生成AI「Studio Juno」とのシームレスな連携。Studio Junoで生成した画像を簡単に記事に追加し、Notes Nebulaで簡単に公開可能。またマインドマップにも対応。",
      button: "STUDIO JUNO - 画像生成AIプラットフォーム",
    },
    mindmap: {
      title: "マインドマップ生成",
      description: "AIを使用して、テキストから本格的なマインドマップを生成。YouTube動画や記事URLからも生成可能。",
    },
    browsing: {
      title: "ブラウジング機能搭載 AIチャット",
      description:
        "チャット形式のAI対話システムを導入。Web情報を活用し、最新かつ正確な回答をご提供。また、AIアシスタントをあなた好みにカスタマイズ可能。",
    },
    writingAI: {
      title: "用途に合わせた様々なライティングAI",
      description:
        "Tenak AIでは以下のような、様々なライティングAIをご用意。様々なタスクの効率を底上げします。Google検索、Youtube、Website記事、テキスト、キーワードなどのソースを参考に、Instagram、Facebook、TwitterなどのSNS向けスクリプトやブログ記事を生成します。",
      source: {
        google: "Google検索",
        youtube: "YouTube動画",
        link: "リンク",
        text: "テキスト",
      },
      article: "様々なスクリプト",
      videoScript: "SNS向けショート動画スクリプト",
      videoScriptDesc:
        "AIを使用して、YouTube動画やショート動画のスクリプト（構成、ナレーション）を生成。またハッシュタグや動画説明も生成。",
      summarize: "様々なコンテンツを簡単に要約",
      summarizeDesc:
        "AIを使用して、YouTube動画やブログ記事URLから要約を生成。箇条書きにまとめたり、文章形式でまとめたりと言った設定も可能。",
      slide: "SNSカルーセルスクリプト",
      slideDesc:
        "AIを使用して、SNSカルーセルスクリプトを生成。Instagram、X、Facebookなどに最適化したスクリプトを生成。",
      seo: "SEOに強い10,000文字以上のブログ記事",
      seoDesc:
        "キーワードからGoogleで検索し、実際にSEOで高評価を得ている記事を参考に、構成を提案後、見出しや本文を生成します。",
      template: "ライティングAIテンプレート",
      templateDesc:
        "数多くののライティングAIテンプレートをご用意。少しの入力で求める回答を簡単に生成します。日々、ユーザーの声を参考に、新しいテンプレートを追加中。",
    },
    misc: {
      title: "その他の豊富なAI",
      description: "その他の豊富なAI機能やサービスを提供し、あなたのクリエイティブを向上させます。",
      voice: "音声生成",
      voiceDesc:
        "AIを使用して、テキストから音声を生成します。GoogleやOpenAIのボイスエンジンを使用し、多くの言語に対応。",
      color: "カラーパレット生成",
      colorDesc:
        "AIを使用して、テキストからカラーパレットを生成。例えば、「冬」と入力すると、連想させるパレットを生成。",
    },
    cta: {
      title: "Tenak AIを今すぐ試して、その効果を実感しましょう。",
      description: "Tenak AIは、あなたの創造力を最大限に引き出す最高のパートナー。",
      signupButton: "無料で始める",
      loginButton: "ログイン",
    },
  },
};
export default top;
