import BoltIcon from "@mui/icons-material/Bolt";
import InfoIcon from "@mui/icons-material/Info";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { alpha, Box, ButtonBase, IconButton, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { ColumnCenteredBox, RowCenteredBox } from "../../../utils/styledBox";

interface User {
  advanced_ai?: boolean;
}

const AIEngineSwitch: React.FC = () => {
  const { t } = useTranslation();
  const { user } = useLoginStatus() as { user: User };
  const [advancedAi, setAdvancedAi] = useState<boolean>(user?.advanced_ai || false);

  const changeAiEngine = async (isAdvanced: boolean) => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      await axios.patch("/api/v1/users/user", { advanced_ai: isAdvanced }, config);
      setAdvancedAi(isAdvanced);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const handleToggle = () => {
    changeAiEngine(!advancedAi);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", mb: 1 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ButtonBase onClick={handleToggle} sx={{ borderRadius: 25 }}>
          <RowCenteredBox
            color="primary"
            sx={{
              height: 30,
              // backgroundColor: "primary.light",
              backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.25),
              borderRadius: 25,
              alignItems: "center",
              width: 206,
              position: "relative",
            }}
          >
            <ColumnCenteredBox
              sx={{
                position: "absolute",
                left: 0,
                backgroundColor: "primary.main",
                borderRadius: "50%",
                width: 36,
                height: 36,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {advancedAi ? (
                <SmartToyIcon sx={{ color: "#fff", fontSize: 20 }} />
              ) : (
                <BoltIcon sx={{ color: "#fff", fontSize: 20 }} />
              )}
            </ColumnCenteredBox>
            <Typography variant={"body2"} width={"100%"} sx={{ pl: 4, pr: 1, fontWeight: 600 }}>
              {advancedAi ? t("drawer.intelligentMode") : t("drawer.speedMode")}
            </Typography>
          </RowCenteredBox>
        </ButtonBase>
        <Box>
          <Tooltip title={t("drawer.modeDescription")} placement={"right"}>
            <IconButton size={"small"}>
              <InfoIcon fontSize={"small"} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default AIEngineSwitch;
