import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CollectionsIcon from "@mui/icons-material/Collections";
import ExploreIcon from "@mui/icons-material/Explore";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import Meta from "../../../components/common/Meta";
import { useJunoImageGeneratorContext } from "../../../context/ImageGeneratorContext";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import JunoRouter from "../../../routes/JunoRouter";
import { Search, SearchIconWrapper, StyledInputBase } from "../../../utils/searchBox";
import { uiHeight } from "../../../utils/uiHeight";

export default function Layout() {
  const { t } = useTranslation();
  const location = useLocation();
  const [tabValue, setTabValue] = useState("juno");
  const navigate = useCustomNavigate();
  const context = useJunoImageGeneratorContext();

  const updateTabValue = () => {
    if (location.pathname.split("/")[3]?.startsWith("gallery")) {
      setTabValue("gallery");
      return;
    } else if (location.pathname.split("/")[3]?.startsWith("likes")) {
      setTabValue("likes");
      return;
    } else if (location.pathname.split("/")[3]?.startsWith("quick-generator")) {
      setTabValue("quick-generator");
      return;
    } else if (location.pathname.split("/")[3]?.startsWith("generator")) {
      setTabValue("generator");
      return;
    } else {
      setTabValue("juno");
      return;
    }
  };

  useEffect(() => {
    updateTabValue();
  }, [location.pathname]);

  const search = (q: string) => {
    if (q === "") {
      navigate("/juno");
    } else {
      navigate(`/juno?q=${q}`);
    }
  };

  return (
    <>
      <Meta title={t("juno.goToGenerator")} />
      <Box
        sx={{
          minHeight: uiHeight(),
        }}
      >
        <DrawerHeader />
        {/* title and button container */}
        <Grid
          container
          sx={{
            px: 2,
            mb: 1,
          }}
        >
          {/* title */}
          <Grid
            item
            xs={12}
            md={4}
            lg={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: 4,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Search…"
                  inputProps={{ "aria-label": "search" }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.nativeEvent.isComposing) {
                      search((e.target as HTMLInputElement).value);
                    }
                  }}
                />
              </Search>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={8}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <Tabs
              value={tabValue}
              variant="scrollable"
              aria-label="navigation tabs"
              sx={{ maxWidth: "90vw" }}
              scrollButtons
            >
              <Tab
                value="juno"
                onClick={() => navigate("/juno")}
                icon={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <ExploreIcon sx={{ mr: 1 }} fontSize={"small"} />
                    <Typography variant={"button"}>{t("juno.explore")}</Typography>
                  </Box>
                }
              />
              <Tab
                value="gallery"
                onClick={() => navigate("/juno/gallery")}
                icon={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <CollectionsIcon sx={{ mr: 1 }} fontSize={"small"} />
                    <Typography variant={"button"}>{t("juno.myGallery")}</Typography>
                  </Box>
                }
              />
              <Tab
                value="likes"
                onClick={() => navigate("/juno/likes")}
                icon={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FavoriteIcon sx={{ mr: 1 }} fontSize={"small"} />
                    <Typography variant={"button"}>{t("juno.likes")}</Typography>
                  </Box>
                }
              />
              <Tab
                value="quick-generator"
                onClick={() => navigate("/juno/quick-generator")}
                icon={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <FlashOnIcon sx={{ mr: 1 }} fontSize={"small"} />
                    <Typography variant={"button"}>{t("juno.goToQuickGenerator")}</Typography>
                  </Box>
                }
              />
              <Tab
                value="generator"
                onClick={() => navigate("/juno/generator")}
                icon={
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
                    <Typography variant={"button"}>{t("juno.goToGenerator")}</Typography>
                  </Box>
                }
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={2} lg={2} display={"flex"}>
            <Box flexGrow={1} />
            <Box display={"flex"} flexDirection={"row"} alignItems={"flex-end"} gap={1}>
              <Tooltip title={context.filtered ? t("juno.input.hide") : t("juno.input.show")}>
                <IconButton
                  onClick={(e) => {
                    context.setFiltered(!context.filtered);
                  }}
                  sx={{ position: "relative" }}
                >
                  <VisibilityIcon sx={{ color: "textPrimary" }} />
                  <Box
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      backgroundColor: context.filtered ? "success.main" : "error.main",
                      borderRadius: "50%",
                      width: 16,
                      height: 16,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {context.filtered ? (
                      <CheckIcon sx={{ p: 0.8, color: "white" }} />
                    ) : (
                      <CloseIcon sx={{ p: 0.8, color: "white" }} />
                    )}
                  </Box>
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
        <JunoRouter />
      </Box>
    </>
  );
}
