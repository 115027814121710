export default {
    'col': '列',
    'insColL': '左に列を挿入',
    'insColR': '右に列を挿入',
    'delCol': '列を削除',
    'row': '行',
    'insRowAbv': '上に行を挿入',
    'insRowBlw': '下に行を挿入',
    'delRow': '行を削除',
    'mCells': 'セルを結合',
    'sCell': 'セルを分割',
    'tblProps': '表のプロパティ',
    'cellProps': 'セルのプロパティ',
    'insParaOTbl': '表の外に段落を挿入',
    'insB4': '前に挿入',
    'insAft': '後に挿入',
    'border': '境界線',
    'color': '色',
    'width': '幅',
    'background': '背景',
    'dims': '寸法',
    'height': '高さ',
    'padding': '余白',
    'tblCellTxtAlm': '表のセルのテキストの配置',
    'alCellTxtL': 'セルのテキストを左に配置',
    'alCellTxtC': 'セルのテキストを中央に配置',
    'alCellTxtR': 'セルのテキストを右に配置',
    'jusfCellTxt': 'セルのテキストを両端揃え',
    'alCellTxtT': 'セルのテキストを上に配置',
    'alCellTxtM': 'セルのテキストを中央に配置',
    'alCellTxtB': 'セルのテキストを下に配置',
    'dimsAlm': '寸法と配置',
    'alTblL': '表を左に配置',
    'tblC': '表を中央に配置',
    'alTblR': '表を右に配置',
    'save': '保存',
    'cancel': 'キャンセル',
    'colorMsg': '色が無効です。「#FF0000」や「rgb(255,0,0)」や「red」を試してください。',
    'dimsMsg': '値が無効です。「10px」や「2em」や単に「2」を試してください。',
    'colorPicker': 'カラーピッカー',
    'removeColor': '色を削除',
    'black': '黒',
    'dimGrey': 'ダークグレー',
    'grey': 'グレー',
    'lightGrey': 'ライトグレー',
    'white': '白',
    'red': '赤',
    'orange': 'オレンジ',
    'yellow': '黄色',
    'lightGreen': 'ライトグリーン',
    'green': '緑',
    'aquamarine': 'アクアマリン',
    'turquoise': 'ターコイズ',
    'lightBlue': 'ライトブルー',
    'blue': '青',
    'purple': '紫'
};
