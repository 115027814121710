import React from "react";
import Assistant from "./Assistant/Assistant";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { ButtonBase, Fade } from "@mui/material";
import Typography from "@mui/material/Typography";
import PublicSetting from "./PublicSetting";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useParams } from "react-router-dom";
import { uiHeight } from "../../../utils/uiHeight";
import { GradientTypography } from "../../../utils/gradientTypography";

const Sidebar = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = React.useState("assistant");
  const tabWidth = 200;
  const { fileUuid } = useParams();
  const fileType = useSelector((state: RootState) => state.file.fileType)[fileUuid!];

  return (
    <Paper
      elevation={0}
      sx={{
        maxHeight: uiHeight,
        overflowY: { xs: "", md: "auto" },
        borderRadius: 0,
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            borderRadius: 2,
            bgcolor: alpha(theme.palette.background.custom1, 1),
            overflow: "hidden",
            width: "fit-content",
          }}
        >
          <ButtonBase
            color="primary"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setSelectedTab("assistant");
            }}
            sx={{
              py: 1,
              px: 2,
              transition: "color 0.2s ease-in-out",
              width: { xs: 160, sm: tabWidth },
              bgcolor: selectedTab === "assistant" ? alpha(theme.palette.primary.main, 0.2) : "transparent",
              "&:hover": { color: theme.palette.primary.main },
            }}
          >
            <GradientTypography variant={"button"}>{t("textEditor.sidebar.aiWritingAssistant")}</GradientTypography>
          </ButtonBase>
          {fileType === 1 && (
            <ButtonBase
              color="primary"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                setSelectedTab("publicSetting");
              }}
              sx={{
                py: 1,
                px: 2,
                transition: "color 0.2s ease-in-out",
                bgcolor: selectedTab === "publicSetting" ? alpha(theme.palette.primary.main, 0.2) : "transparent",
                width: { xs: 160, sm: tabWidth },
                "&:hover": { color: theme.palette.primary.main },
              }}
            >
              <Typography variant={"button"}>{t("textEditor.sidebar.publicSettings")}</Typography>
            </ButtonBase>
          )}
        </Box>
      </Box>
      <Fade in={true} timeout={1000}>
        <div>
          {selectedTab === "assistant" && <Assistant />}
          {selectedTab === "publicSetting" && <PublicSetting />}
        </div>
      </Fade>
    </Paper>
  );
};
export default Sidebar;