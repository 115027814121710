import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import UnsplashModal from "../../../components/common/UnsplashModal";
import GeneratedImageModal from "../../../components/common/GeneratedImageModal";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import PreviewImageModal from "../../../components/common/ImageModal";

const ImageModal = ({ isImageModalOpen, setIsImageModalOpen, images, setImages, data }) => {
  const { t } = useTranslation();

  const [openUnsplash, setOpenUnsplash] = React.useState(false);
  const [openGeneratedImageModal, setOpenGeneratedImageModal] = React.useState(false);

  const setImageFromUnsplash = (image) => {
    setImages([...images, image]);
    data.images.push(image);
  };

  const setImageFromGenerated = (image) => {
    setImages([...images, image + "public"]);
    data.images.push(image + "public");
  };

  const fileInputRef = useRef<HTMLInputElement>(null);

  const uploadImage = () => {
    fileInputRef.current?.click();
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    //   "data:image"の形にする
    const reader = new FileReader();
    reader.onload = () => {
      setImages([...images, reader.result]);
      data.images.push(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const [disabledButton, setDisabledButton] = React.useState(false);

  // 画像が4枚以上の場合はボタンを無効化
  useEffect(() => {
    setDisabledButton(images.length >= 4);
  }, [images]);

  // Delete image
  const deleteImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
    data.images = data.images.filter((_, i) => i !== index);
  };
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const handleImagePreview = (index: number) => {
    setSelectedImageIndex(index);
    setOpenImagePreview(true);
  };

  return (
    <>
      <Modal open={isImageModalOpen} onClose={() => setIsImageModalOpen(false)}>
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minWidth: 400,
            maxWidth: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transition: "0.3s",
            gap: 2,
          }}
        >
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            size={"small"}
            onClick={() => setIsImageModalOpen(false)}
          >
            <CloseIcon fontSize={"small"} />
          </IconButton>

          <Typography component={"h3"}>Add Images</Typography>
          <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleChange}
            />
            <Button variant={"contained"} size={"small"} onClick={uploadImage} disabled={disabledButton}>
              <Typography variant={"button"}>{t("textEditor.sidebar.upload")}</Typography>
            </Button>
            <Button
              disableElevation
              disabled={disabledButton}
              variant={"contained"}
              size={"small"}
              onClick={() => setOpenGeneratedImageModal(true)}
            >
              <Typography variant={"button"}>{t("textEditor.sidebar.generated")}</Typography>
            </Button>
            <Button
              disableElevation
              variant={"contained"}
              size={"small"}
              onClick={() => setOpenUnsplash(true)}
              disabled={disabledButton}
            >
              <Typography variant={"button"}>{t("textEditor.sidebar.unsplash")}</Typography>
            </Button>
          </Box>

          <Grid container spacing={1}>
            {images.map((image, index) => (
              <Grid item key={index} xs={3} sx={{ position: "relative" }}>
                <img
                  src={image}
                  alt={"uploaded"}
                  style={{ width: "100%", height: "100%", objectFit: "cover", maxHeight: 200, cursor: "pointer" }}
                  onClick={() => handleImagePreview(index)}
                />
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 12,
                    right: 4,
                    backdropFilter: "blur(5px)",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    transition: "0.3s",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.4)",
                    },
                  }}
                  size={"small"}
                  onClick={() => deleteImage(index)}
                >
                  <CloseIcon style={{ color: "white", fontSize: "small" }} />
                </IconButton>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Modal>
      <UnsplashModal open={openUnsplash} setOpen={setOpenUnsplash} setImage={setImageFromUnsplash} />
      <GeneratedImageModal
        open={openGeneratedImageModal}
        setOpen={setOpenGeneratedImageModal}
        setImage={setImageFromGenerated}
      />
      <PreviewImageModal
        src={images[selectedImageIndex || 0]}
        alt="image"
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
      />
    </>
  );
};

export default ImageModal;
