import React from "react";
import IconButton from "@mui/material/IconButton";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

export default function DarkModeToggle({
  onDarkModeToggle,
  darkMode,
  color,
}: {
  onDarkModeToggle: () => void;
  darkMode: boolean;
  color?: string;
}) {
  return (
    <IconButton
      onClick={onDarkModeToggle}
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      sx={{ color: color }}
    >
      {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
    </IconButton>
  );
}
