import React from "react";
import Meta from "../components/common/Meta";
import { DrawerHeader } from "../components/layout/Drawer/DrawerMain";
import { Container, Grow, Typography } from "@mui/material";
import { ColumnCenteredBox, RowCenteredBox } from "../utils/styledBox";
import { useCustomNavigate } from "../hooks/useCustomNavigate";

const Error404 = () => {
  const navigate = useCustomNavigate();

  return (
    <>
      <Meta title={"404 ERROR"} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container maxWidth={"sm"}>
        <DrawerHeader />
        <Grow in={true}>
          <ColumnCenteredBox mb={8}>
            <RowCenteredBox>
              <Typography variant={"h2"} component={"h1"}>
                404 ERROR
              </Typography>
            </RowCenteredBox>
            <img src={"/images/logo/logo.png"} alt={"logo"} style={{ width: "100px", height: "auto" }} />
            <Typography variant={"h4"}>Page not found</Typography>
            <Typography variant={"body1"} sx={{ mb: 4 }}>
              The page you are looking for does not exist.
            </Typography>
            <img
              src={"/images/error_404.jpg"}
              alt={"404"}
              style={{ width: "100%", height: "auto", borderRadius: "8px" }}
            />
            <Typography variant={"body2"} sx={{ mt: 4 }}>
              Please check the URL or go back to the
              <span style={{ color: "blue", cursor: "pointer" }} onClick={() => navigate("/")}>
                Tenak.ai
              </span>
              .
            </Typography>
          </ColumnCenteredBox>
        </Grow>
      </Container>
    </>
  );
};
export default Error404;
