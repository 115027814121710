import React from "react";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { Box } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";

const ButtonAI = ({ title, icon, path, image }) => {
  const navigate = useCustomNavigate();
  return (
    <ButtonBase
      sx={{
        width: "100%",
        height: 150,
        borderRadius: 2,
        backgroundImage: `url(/images/dashBoard/${image}.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: "hidden",
        transition: "all 0.3s ease",
        "& div": {
          backdropFilter: "blur(25px)",
        },
        "&:hover": {
          transform: "translateY(-5px)",
          // transform: 'scale(1.05)',
          "& div": {
            backdropFilter: "blur(5px)",
          },
        },
      }}
      onClick={() => navigate(path)}
    >
      <Box
        component={"div"}
        sx={{
          transition: "0.3s",
          height: "100%",
          width: "100%",
          p: 2,
          backdropFilter: "brightness(0.8)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box color={"white"} mb={1}>
          {icon}
        </Box>
        <Typography
          variant={"subtitle1"}
          component={"p"}
          sx={{
            color: "white",
            whiteSpace: "break-spaces",
          }}
        >
          {title}
        </Typography>
      </Box>
    </ButtonBase>
  );
};
export default ButtonAI;
