import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Divider, Fade, TextField } from "@mui/material";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "@mui/material/CircularProgress";
import { MarkdownContentBox } from "../../../components/common/MarkdownContentBox";
import axios from "axios";
import { useDispatch } from "react-redux";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import { setCreditTrigger } from "../../../redux/slices/triggerSlice";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import { GradationButton } from "../../../utils/gradationButton";
import { GradientTypography } from "../../../utils/gradientTypography";
import { useAlert } from "../../../context/AlertContext";

interface Template {
  id: number;
  name: string;
  description: string;
  tags: string[];
  cover: string;
  args: any[];
  allow_url: boolean;
}

interface TemplateProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  template: Template;
}

const InputModal = (TemplateProps: TemplateProps) => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const template = TemplateProps.template;
  const argsArray = Object.entries(template.args);
  const [inputValues, setInputValues] = useState({});
  const [inputUrl, setInputUrl] = useState({});
  const [additionalInstructions, setAdditionalInstructions] = useState({});
  const [uuid, setUuid] = useState<string>("");
  const [chatResponse, setChatResponse] = useState({});
  const [processing, setProcessing] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useCustomNavigate();
  const [saving, setSaving] = useState(false);
  const [inputErrors, setInputErrors] = useState({});
  const location = useLocation();
  const { setAlert } = useAlert();

  const handleInputChange = (key, value) => {
    setInputValues((prev) => ({ ...prev, [template.id]: { ...prev[template.id], [key]: value } }));
  };

  const handleInputUrlChange = (key, value) => {
    setInputUrl((prev) => ({ ...prev, [template.id]: { ...prev[template.id], [key]: value } }));
  };

  useEffect(() => {
    // テンプレートのIDが変更されたときにinputValuesを更新
    if (inputValues[template.id]) return;
    setInputValues((prev) => ({
      ...prev,
      [template.id]: argsArray.reduce((obj, [key]) => {
        return {
          ...obj,
          [key]: prev[template.id] && prev[template.id][key] !== undefined ? prev[template.id][key] : "",
        };
      }, {}),
    }));
    setChatResponse((prev) => ({
      ...prev,
      [template.id]: {
        ...prev[template.id],
        content: "",
      },
    }));
    setInputUrl((prev) => ({
      ...prev,
      [template.id]: argsArray.reduce((obj, [key]) => {
        return {
          ...obj,
          [key]: prev[template.id] && prev[template.id][key] !== undefined ? prev[template.id][key] : "",
        };
      }, {}),
    }));
  }, [template.id]);

  const validateInputs = () => {
    let isValid = true;
    const newErrors = { ...inputErrors };
    newErrors[template.id] = {};

    argsArray.forEach(([key, value]) => {
      if (requiredValue(value) && (!inputValues[template.id] || !inputValues[template.id][key])) {
        isValid = false;
        newErrors[template.id][key] = t("validation.required");
      }
    });

    setInputErrors(newErrors);
    return isValid;
  };

  const handleGenerate = async () => {
    if (!(await checkCredit())) return;
    if (!validateInputs()) {
      return;
    }

    setProcessing(true);
    setChatResponse((prev) => ({
      ...prev,
      [template.id]: {
        ...prev[template.id],
        content: "",
      },
    }));
    const csrftoken = Cookies.get("csrftoken");
    const id = uuidv4();
    setUuid(id);
    const headers = { "Content-Type": "application/json", "X-CSRFToken": csrftoken! };
    const response = await fetch("/api/v1/template/generate", {
      method: "POST",
      headers: headers,
      body: JSON.stringify({
        uuid: id,
        template_id: template.id,
        args: inputValues[template.id],
        url: inputUrl[template.id]?.["url"],
        additional_instructions: additionalInstructions[template.id] || "",
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      setAlert("error", errorData.message);
      setProcessing(false);
      return;
    }

    if (response.body !== null) {
      const reader = response.body.getReader();
      new ReadableStream({
        start(controller) {
          console.log("Stream start");

          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              const text = new TextDecoder().decode(value);
              const jsonParts = text.split("\n\n");

              for (const part of jsonParts) {
                if (part.trim() === "") continue; // 空のデータをスキップ
                const parsedData = JSON.parse(part);
                if (parsedData["data"] && parsedData["data"]["uuid"] === id) {
                  setChatResponse((prev) => ({
                    ...prev,
                    [template.id]: {
                      ...prev[template.id],
                      content: (prev[template.id]?.content || "") + parsedData["data"]["content"],
                    },
                  }));
                }
                // メッセージの終了
                if (parsedData["data"] && parsedData["data"]["end"]) {
                  console.log("Stream complete");
                  controller.close();
                  dispatch(setCreditTrigger(true));
                  setProcessing(false);
                  return;
                }
              }
              controller.enqueue(value);
              push();
            });
          }

          push();
        },
      });
    }
  };

  const streamStop = async () => {
    const csrftoken = Cookies.get("csrftoken");
    const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrftoken!,
    };

    const response = await fetch("/api/v1/template/generate?uuid=" + uuid, {
      method: "GET",
      headers: headers,
    });

    if (!response.ok) {
      throw new Error("ReadableStream not supported in this browser.");
    }
    dispatch(setCreditTrigger(true));
    setProcessing(false);
  };

  const handleNewFile = async () => {
    try {
      setSaving(true);

      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      await axios.post(
        "/api/v1/text-file/",
        {
          type: 1,
          content: chatResponse[template.id]?.content,
          title: t(`template.label.${template.id}.title`),
        },
        config
      );
      setAlert("success", t("file.createFile"));
    } catch (error) {
      console.error("An unknown error occurred:", error);
    } finally {
      // 1秒遅延
      setTimeout(() => {
        setSaving(false);
      }, 1000);
    }
  };

  const handleClose = () => {
    if (processing) {
      streamStop();
    }
    TemplateProps.setOpen(false);
    const params = new URLSearchParams(location.search);
    const tag = params.get("tag") || "";
    const url = tag ? `/template/?tag=${tag}` : "/template";
    navigate(url);
  };

  const requiredValue = (value: string) => {
    return value.startsWith("required_");
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        handleGenerate();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputValues, inputUrl, additionalInstructions, template.id]);

  return (
    <Modal open={TemplateProps.open} onClose={() => handleClose()}>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          maxHeight: { xs: "100vh", sm: "80vh" },
          overflow: "scroll",
          width: {
            xs: "100vw",
            sm: chatResponse[template.id]?.content ? "1440px" : "720px",
          },
          maxWidth: "100vw",
          transition: "width 0.5s ease",
        }}
      >
        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: {
              xs: "auto",
              sm: "720px",
            },
            p: 4,
            minWidth: 0,
          }}
        >
          <GradientTypography variant="h6" component="h2" sx={{ mb: 2 }}>
            {t(`template.label.${template.id}.title`)}
          </GradientTypography>
          <Typography sx={{ mb: 4 }}>{t(`template.label.${template.id}.description`)}</Typography>
          <Typography variant={"body2"} sx={{ mb: 2 }}>
            {t(`template.inputDescription`)}
          </Typography>
          <Box sx={{ mb: 4, overflowY: "auto", maxHeight: "calc(100% - 200px)" }}>
            {argsArray.map(([key, value], index) => (
              <Box sx={{ mb: 4 }} key={value}>
                <TextField
                  id={key}
                  name={value}
                  required={requiredValue(value)}
                  error={!!(inputErrors[template.id] && inputErrors[template.id][key])}
                  helperText={inputErrors[template.id] && inputErrors[template.id][key]}
                  autoFocus={index === 0}
                  autoComplete={index === 0 ? "on" : "off"}
                  label={t(`template.label.${template.id}.arg${index + 1}`)}
                  placeholder={t(`template.label.${template.id}.arg${index + 1}Placeholder`)}
                  variant="standard"
                  fullWidth
                  multiline
                  maxRows={10}
                  value={inputValues[template.id]?.[key] || ""}
                  onChange={(e) => handleInputChange(key, e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            ))}
            {/*URL*/}
            {template.allow_url && (
              <TextField
                name={"url"}
                autoComplete={"on"}
                label={t(`template.url`)}
                placeholder={t(`template.urlPlaceholder`)}
                variant="standard"
                fullWidth
                value={inputUrl[template.id]?.["url"] || ""}
                onChange={(e) => handleInputUrlChange("url", e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ mb: 4 }}
              />
            )}

            <TextField
              name={"additionalInstructions"}
              autoComplete={"off"}
              label={t(`template.additionalInstructions`)}
              placeholder={t(`template.additionalInstructionsPlaceholder`)}
              variant="standard"
              fullWidth
              multiline
              maxRows={10}
              value={additionalInstructions[template.id] || ""}
              onChange={(e) => setAdditionalInstructions({ [template.id]: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>

          {/*生成ボタン*/}
          <Box sx={{ display: "flex", justifyContent: "flex-end", flexDirection: "row", gap: 1 }}>
            <Button disableElevation variant="text" onClick={() => handleClose()}>
              {t(`common.close`)}
            </Button>

            <GradationButton
              onClick={() => {
                if (processing) {
                  streamStop();
                } else {
                  handleGenerate();
                }
              }}
            >
              {processing ? (
                <Fade in={processing} timeout={500}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <CircularProgress color="secondary" size={20} sx={{ mr: 1 }} />
                    <Typography variant={"button"}>{t("common.stopGenerating")}</Typography>
                  </Box>
                </Fade>
              ) : (
                <Fade in={!processing} timeout={500}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AutoAwesomeIcon fontSize={"small"} sx={{ mr: 1 }} />
                    <Typography variant={"button"}>{t("common.generate")}</Typography>
                  </Box>
                </Fade>
              )}
            </GradationButton>
          </Box>
        </Box>

        {/*Answer*/}

        <Box
          sx={{
            flexGrow: 0,
            flexShrink: 0,
            flexBasis: {
              xs: "auto", // スマホでは自動
              sm: chatResponse[template.id]?.content ? "720px" : "0px",
            },
            p: 4,
            overflow: "hidden",
            transition: "flex-basis 0.5s ease",
            minWidth: 0,
            display: chatResponse[template.id]?.content ? "block" : { xs: "none", sm: "block" },
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
            <CopyButtonOrIcon
              textToCopy={chatResponse[template.id]?.content}
              displayType={"button"}
              variant={"outlined"}
            />
            <Button
              disableElevation
              variant={"outlined"}
              sx={{ alignItems: "center" }}
              onClick={handleNewFile}
              disabled={saving}
            >
              <Typography variant={"button"} sx={{ display: "flex", alignItems: "center" }}>
                {!saving ? (
                  <SaveIcon fontSize={"small"} sx={{ mr: 1 }} />
                ) : (
                  <CheckIcon fontSize={"small"} sx={{ mr: 1 }} />
                )}
                {t("template.createFile")}
              </Typography>
            </Button>
          </Box>
          <Typography variant={"subtitle1"}>{t(`template.result`)}</Typography>
          <Divider sx={{ mb: 2 }} />
          <Box sx={{ mt: 1, height: "calc(80vh - 140px)", overflowY: "scroll" }}>
            <MarkdownContentBox>{chatResponse[template.id]?.content}</MarkdownContentBox>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
export default InputModal;
