import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { GradientTypography } from "../../../../utils/gradientTypography";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import ReactFlow, { Background, Controls } from "reactflow";
import CustomNode from "../../../MindMapGenerator/components/CustomNode";

const nodeTypes = { custom: CustomNode };

const MindMap = () => {
  const { t } = useTranslation();

  return (
    <ColumnCenteredBox sx={{ gap: 2, p: { xs: 2, md: 4 } }}>
      <GradientTypography variant={"h5"} component={"h2"}>
        {t("top.fv.mindmap.title")}
      </GradientTypography>
      <Typography whiteSpace={"break-spaces"}>{t("top.fv.mindmap.description")}</Typography>
      <Box sx={{ width: "100%", height: 600 }}>
        <ReactFlow
          nodes={nodes}
          nodeTypes={nodeTypes}
          edges={edges}
          fitView
          maxZoom={1.5}
          minZoom={0.1}
          nodesDraggable={true}
          nodesConnectable={true}
          elementsSelectable={false}
          proOptions={{ hideAttribution: true }}
        >
          <Background />
          <Controls showInteractive={false} />
        </ReactFlow>
      </Box>
    </ColumnCenteredBox>
  );
};
export default MindMap;

const nodes = [
  {
    id: "1",
    data: {
      url: [
        {
          url: "https://tenak.ai",
          title: "Tenak AI",
        },
      ],
      size: {
        width: 398,
        height: 377.5,
      },
      color: "yellow",
      label:
        "Mind maps are visual tools that help individuals organize information, ideas, and concepts in a structured manner.",
      images: ["/images/top/mindmap.png"],
    },
    type: "custom",
    width: 400,
    height: 380,
    dragging: false,
    position: {
      x: 100,
      y: 146,
    },
    selected: false,
  },
  {
    id: "2",
    data: {
      url: [],
      size: {
        width: 398,
        height: 48,
      },
      color: "red",
      label: "Understanding the Basics of Mind Maps",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 50,
    dragging: false,
    position: {
      x: 598,
      y: 64,
    },
    selected: false,
    positionAbsolute: {
      x: 600,
      y: 60,
    },
  },
  {
    id: "3",
    data: {
      url: [],
      size: {
        width: 398,
        height: 72,
      },
      label: "A mind map typically starts with a central idea, branching out into related topics and subtopics.",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 74,
    position: {
      x: 1096,
      y: 0,
    },
  },
  {
    id: "4",
    data: {
      url: [],
      size: {
        width: 398,
        height: 96,
      },
      label: "Key components of mind maps include nodes, branches, and keywords that highlight important ideas.",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 98,
    position: {
      x: 1096,
      y: 92,
    },
  },
  {
    id: "5",
    data: {
      url: [],
      size: {
        width: 398,
        height: 48,
      },
      color: "blue",
      label: "Applications of Mind Maps in Various Fields",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 50,
    dragging: false,
    position: {
      x: 598,
      y: 290,
    },
    selected: false,
  },
  {
    id: "6",
    data: {
      url: [],
      size: {
        width: 398,
        height: 96,
      },
      label: "In education, mind maps facilitate learning by creating visual representations of complex information.",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 98,
    position: {
      x: 1096,
      y: 208,
    },
  },
  {
    id: "7",
    data: {
      url: [],
      size: {
        width: 398,
        height: 96,
      },
      label: "In business, mind maps aid in project management by visually displaying tasks and their relationships.",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 98,
    position: {
      x: 1096,
      y: 324,
    },
  },
  {
    id: "8",
    data: {
      url: [],
      size: {
        width: 398,
        height: 48,
      },
      color: "green",
      label: "Techniques for Creating Effective Mind Maps",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 50,
    dragging: false,
    position: {
      x: 598,
      y: 500,
    },
    selected: false,
  },
  {
    id: "9",
    data: {
      url: [],
      size: {
        width: 398,
        height: 96,
      },
      label: "Start with a central idea and use colors and images to enhance memory retention and engagement.",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 98,
    position: {
      x: 1096,
      y: 440,
    },
  },
  {
    id: "10",
    data: {
      url: [],
      size: {
        width: 398,
        height: 72,
      },
      label: "Use keywords instead of full sentences to keep the mind map concise and easy to navigate.",
      images: [],
    },
    type: "custom",
    width: 400,
    height: 74,
    position: {
      x: 1096,
      y: 556,
    },
  },
];

const edges = [
  {
    id: "edge-0-1",
    source: "0",
    target: "1",
  },
  {
    id: "edge-1-2",
    source: "1",
    target: "2",
  },
  {
    id: "edge-2-3",
    source: "2",
    target: "3",
  },
  {
    id: "edge-2-4",
    source: "2",
    target: "4",
  },
  {
    id: "edge-1-5",
    source: "1",
    target: "5",
  },
  {
    id: "edge-5-6",
    source: "5",
    target: "6",
  },
  {
    id: "edge-5-7",
    source: "5",
    target: "7",
  },
  {
    id: "edge-1-8",
    source: "1",
    target: "8",
  },
  {
    id: "edge-8-9",
    source: "8",
    target: "9",
  },
  {
    id: "edge-8-10",
    source: "8",
    target: "10",
  },
];
