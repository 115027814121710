import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { useTranslation } from "react-i18next";
import { GradientTypography } from "../../../../utils/gradientTypography";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import SummarizeIcon from "@mui/icons-material/Summarize";
import ArticleIcon from "@mui/icons-material/Article";
import NoteIcon from "@mui/icons-material/Note";
import MoneyIcon from "@mui/icons-material/Money";
import { ColumnBox } from "../../../../utils/styledBox";
import { GradationButton } from "../../../../utils/gradationButton";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";

const WritingAI = () => {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const cards = [
    {
      title: t("top.fv.writingAI.videoScript"),
      desc: t("top.fv.writingAI.videoScriptDesc"),
      icon: <VideoLibraryIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      title: t("top.fv.writingAI.summarize"),
      desc: t("top.fv.writingAI.summarizeDesc"),
      icon: <SummarizeIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      title: t("top.fv.writingAI.slide"),
      desc: t("top.fv.writingAI.slideDesc"),
      icon: <NoteIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      title: t("top.fv.writingAI.seo"),
      desc: t("top.fv.writingAI.seoDesc"),
      icon: <ArticleIcon sx={{ fontSize: 32, color: "white" }} />,
    },
    {
      title: t("top.fv.writingAI.template"),
      desc: t("top.fv.writingAI.templateDesc"),
      icon: <MoneyIcon sx={{ fontSize: 32, color: "white" }} />,
    },
  ];

  const CardItem = ({ card }) => (
    <Card sx={(theme) => ({ height: 260, width: 280, marginRight: 2 })}>
      <CardMedia
        sx={(theme) => ({
          p: 2,
          display: "flex",
          background: theme.custom.gradient.main,
          justifyContent: "center",
        })}
      >
        {card.icon}
      </CardMedia>
      <CardContent>
        <Box sx={{ height: 60 }}>
          <Typography gutterBottom variant="subtitle1" color="primary" component="span">
            {card.title}
          </Typography>
        </Box>
        <Typography variant="body2">{card.desc}</Typography>
      </CardContent>
    </Card>
  );

  return (
    <Box sx={{ p: { xs: 2, md: 4 }, overflow: "hidden", width: "100%", gap: 2 }}>
      <GradientTypography variant="h5" component="h2" gutterBottom mb={2}>
        {t("top.fv.writingAI.title")}
      </GradientTypography>
      <Typography paragraph>{t("top.fv.writingAI.description")}</Typography>
      <Box sx={{ overflow: "hidden", position: "relative", mb: 3 }}>
        <Box
          sx={{
            display: "flex",
            width: "max-content",
            animation: "scroll 30s linear infinite",
            "&:hover": {
              animationPlayState: "paused",
            },
            "@keyframes scroll": {
              "0%": {
                transform: "translateX(0)",
              },
              "100%": {
                transform: "translateX(-50%)",
              },
            },
          }}
        >
          {[...cards, ...cards].map((card, index) => (
            <CardItem key={index} card={card} />
          ))}
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundImage: "url(/images/top/notes_nebula.jpg)",
              backgroundSize: "cover",
              height: "100%",
              backgroundPosition: "center",
              borderRadius: 1,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ColumnBox justifyContent={"center"} height={"100%"}>
            <GradientTypography variant="h5" gutterBottom component="h3">
              {t("top.fv.notesNebula.title")}
            </GradientTypography>
            <Typography variant="body1" gutterBottom>
              {t("top.fv.notesNebula.description")}
            </Typography>
            <GradationButton onClick={() => navigate("/notes_nebula")} sx={{ mt: 2, width: "100%" }}>
              {t("top.fv.notesNebula.button")}
            </GradationButton>
          </ColumnBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WritingAI;
