import React, { useRef, useState } from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import { GradientTypography } from "../../../../utils/gradientTypography";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import Box from "@mui/material/Box";

const Browsing = () => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // 動画を最初から再生
      videoRef.current.play(); // 再生を開始
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause(); // マウスが離れたら一時停止
    }
  };

  return (
    <ColumnCenteredBox sx={{ gap: 2, p: { xs: 2, md: 4 } }}>
      <GradientTypography variant={"h5"} component={"h2"}>
        {t("top.fv.browsing.title")}
      </GradientTypography>

      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: "relative",
          height: "100%",
          overflow: "hidden",
          borderRadius: 4,
          display: "flex",
        }}
      >
        {/* 画像 */}
        <img
          src="/images/top/browsing.jpg"
          alt="browsing"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            opacity: isHovered ? 0 : 1,
            transition: "opacity 0.5s ease-in-out",
          }}
        />

        {/* MUIの再生アイコンを中央に配置 */}
        {!isHovered && (
          <PlayCircleOutlineIcon
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              fontSize: 64, // アイコンのサイズ
              color: "white",
            }}
          />
        )}

        {/* 動画 */}
        <video
          ref={videoRef}
          muted
          playsInline
          style={{
            width: "100%",
            height: "100%",
            opacity: isHovered ? 1 : 0,
            transition: "opacity 0.5s ease-in-out",
            objectFit: "cover",
          }}
        >
          <source src="/videos/top/browsing.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </Box>

      <Typography whiteSpace={"break-spaces"}>{t("top.fv.browsing.description")}</Typography>
    </ColumnCenteredBox>
  );
};

export default Browsing;
