const slide = {
  library: "ライブラリ",
  title: "SNSカルーセル（スレッド）\nスクリプト",
  description: " Instagram、X などのSNS向けに最適化されたカルーセルスクリプトを生成します。",
  target: "ターゲット",
  targetPlaceholder: "例: 20〜30歳の動画クリエイター",
  source: "参考元",
  sourceDescription: "参考元を選択してください。",
  heading: "見出し構成",
  headingPlaceholder: "# カルーセルスライド1の見出し\n# カルーセルスライド2の見出し\n# カルーセルスライド3の見出し",
  headingDescription: "最大10個まで見出しを入力できます。各見出しは1行に1つです。",
  tone: "テキストのトーン",
  additional: "追加指示（任意）",
  additionalPlaceholder: "従うべき注意点やガイドラインを入力してください。これはAIによる見出し生成にも適用されます。",
  srcType: {
    url: "URL",
    urlPlaceholder: "https://www.example.com",
    urlDescription: "参考にするウェブサイトのURLを入力してください。",
    youtube: "YouTube",
    youtubePlaceholder: "https://www.youtube.com/watch?v=xxxxxxxxxxx（YouTube Shortsも対応）",
    youtubeDescription: "参考にするYouTube動画のURLを入力してください。",
    text: "テキスト",
    textPlaceholder: "テキスト（最大4,000文字）",
    textDescription: "参考にするテキストを入力してください。",
    keyword: "キーワード",
    keywordPlaceholder: "例: iPhoneカメラ性能",
    keywordDescription: "参考にするキーワードを入力してください。これに基づいてTenak AIがテキストを生成します。",
  },
  generateHeadings: "Tenak AIで見出しを生成",
  generateHeadingsDescription: "指定された「参考元」に基づいて、Tenak AIがカルーセルの見出しを生成します。",
  createWithoutText: "見出しのみのファイルを作成",
  submit: "カルーセルスクリプトを生成",
  error: {
    createHeading: "見出しを30文字以上で入力するか、AIによる生成を利用してください。",
    invalidYoutubeUrl: "有効なYouTube URLを入力してください。",
    invalidUrl: "有効なURLを入力してください。",
    invalidText: "テキストを入力してください（最大4,000文字）。",
    invalidContent: "参考元を選択または入力してください。",
    invalidKeywords: "有効なキーワードを入力してください。",
    invalidHeadings: "見出しは10個まで入力可能です。",
  },
  download: "Canva用CSVをダウンロード",
  moveUp: "上に移動",
  moveDown: "下に移動",
  addSlide: "カルーセルに新しいスライドを追加",
  deleteSlide: "このカルーセルを削除",
  titleInput: "タイトル",
  sloganInput: "キャッチコピー",
  headingInput: "見出し",
  textInput: "テキスト",
  coverPage: "表紙",
};
export default slide;
