import React from "react";
import { Box, Grid, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import PaletteIcon from "@mui/icons-material/Palette";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FolderIcon from "@mui/icons-material/Folder";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ButtonAI from "./ButtonAI";

const AIs = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const AIs = [{ title: t("drawer.colorGenerator"), icon: <PaletteIcon />, path: "/color-generator" }];

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container spacing={1}>
        <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant={"h6"}
            component={"h3"}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <AutoAwesomeIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
            {t("dashboard.misc")}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title={t("dashboard.library")} placement="left">
            <IconButton onClick={() => navigate("/juno/gallery")} sx={{ color: theme.palette.primary.main }}>
              <FolderIcon />
            </IconButton>
          </Tooltip>
        </Grid>

        {AIs.map((ai, index) => {
          return (
            <Grid item xs={12} key={index}>
              <ButtonAI title={ai.title} icon={ai.icon} path={ai.path} image={`misc_${index + 1}`} />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
export default AIs;