import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import { ColumnBox } from "../../../../utils/styledBox";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useTranslation } from "react-i18next";

const SidebarAdditionalTextField = () => {
  const { additionalText, setAdditionalText } = useFileSidebarContext();
  const { t } = useTranslation();
  const [rows, setRows] = React.useState(1);

  const handleFocus = () => {
    setRows(8);
  };

  const handleBlur = () => {
    setRows(1);
  };

  return (
    <ColumnBox mb={2}>
      <TextField
        size={"small"}
        value={additionalText}
        onChange={(e) => {
          setAdditionalText(e.target.value);
        }}
        multiline
        rows={rows}
        variant={"outlined"}
        inputProps={{ maxLength: 400 }}
        label={t("textEditor.sidebar.additional")}
        sx={{ width: "100%" }}
        InputLabelProps={{ shrink: true }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Box sx={{ justifyContent: "flex-end", display: "flex", width: "100%" }}>
        <Typography variant={"caption"} color={"textSecondary"} mr={1}>
          {additionalText.length} / 400
        </Typography>
      </Box>
    </ColumnBox>
  );
};
export default SidebarAdditionalTextField;
