import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import KeyboardIcon from "@mui/icons-material/Keyboard";
import LaunchIcon from "@mui/icons-material/Launch";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Fade from "@mui/material/Fade";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import axios from "axios";
import i18n from "i18next";
import Cookies from "js-cookie";
import Quill from "quill";
import MagicUrl from "quill-magic-url";
import QuillTableBetter from "quill-table-better";
import "quill-table-better/dist/quill-table-better.css";
import "quill/dist/quill.snow.css";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAlert } from "../../../../context/AlertContext";
import { useCustomNavigate } from "../../../../hooks/useCustomNavigate";
import CircularProgress from "@mui/material/CircularProgress";
import {
  setFileText,
  setFileTitle,
  setGeneratedImage,
  setLanguage,
  setPublicStatus,
  setPublicUuid,
  setSaveTrigger,
  setSelection,
  setShouldReplace,
  setSidebarGenerateImage,
  setTags,
  setThumbnailImage,
  setUnsplash,
  setUpdateThumbnail,
} from "../../../../redux/slices/fileSlice";
import { RootState } from "../../../../redux/store";
import "../../../../styles/quill.scss";
import CustomToolbar from "./CustomToolbar";
import { LinkCardBlot } from "./LinkCard";
import table_japanese from "./table_japanese";
import { ColumnCenteredBox, RowCenteredBox } from "../../../../utils/styledBox";
import { GradientTypography } from "../../../../utils/gradientTypography";
import { alpha } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import Slide from "@mui/material/Slide";
import { setCreditTrigger } from "../../../../redux/slices/triggerSlice";

interface QuillInterface extends Quill {
  import: (path: string) => any;
}

export default function TextEditor() {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const dispatch = useDispatch();
  const [quill, setQuill] = useState<Quill | null>(null);
  const fileTitle = useSelector((state: RootState) => state.file.fileTitle[fileUuid!]);
  const fileText = useSelector((state: RootState) => state.file.fileText[fileUuid!]);
  const unsplash = useSelector((state: RootState) => state.file.unsplash[fileUuid!]);
  const generatedImage = useSelector((state: RootState) => state.file.generatedImage[fileUuid!]);
  const selection = useSelector((state: RootState) => state.file.selection[fileUuid!]);
  const shouldReplace = useSelector((state: RootState) => state.file.shouldReplace[fileUuid!]);
  const saveTrigger = useSelector((state: RootState) => state.file.saveTrigger);
  const thumbnailImage = useSelector((state: RootState) => state.file.thumbnailImage[fileUuid!]);
  const updateThumbnail = useSelector((state: RootState) => state.file.updateThumbnail);
  const sidebarGenerateImage = useSelector((state: RootState) => state.file.sidebarGenerateImage);
  const [saveIcon, setSaveIcon] = useState(<SaveIcon />);
  const lang = i18n.language;
  const [deleting, setDeleting] = useState(false);
  const quillRef = useRef<Quill | null>(null);

  // Quill初期化
  useEffect(() => {
    try {
      const editor = document.getElementById("editor");
      if (editor) {
        Quill.debug("error");
        const QuillClass = Quill as unknown as QuillInterface;
        const ImageFormat = QuillClass.import("formats/image");

        class ImageBlot extends ImageFormat {
          static create(value: any) {
            const node = super.create();
            if (value && value.src) {
              node.setAttribute("src", value.src);
              node.setAttribute("class", value.class);
              if (value.id) {
                node.setAttribute("data-id", value.id);
              }
            }
            return node;
          }
        }

        Quill.register(ImageBlot);
        Quill.register(LinkCardBlot);
        Quill.register("modules/magicUrl", MagicUrl);
        Quill.register(
          {
            "modules/table-better": QuillTableBetter,
          },
          true
        );

        let l: any = "en_US";
        if (lang === "ja") {
          l = { name: "ja_JP", content: table_japanese };
        }

        // Quillインスタンスを作成
        const q = new Quill(editor, {
          theme: "snow",
          modules: {
            toolbar: "#toolbar",
            history: {
              delay: 2000,
              maxStack: 500,
              userOnly: true,
            },
            magicUrl: {
              urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
              globalRegularExpression: /(https?:\/\/|www\.|tel:)[\S]+/g,
            },
            table: false,
            "table-better": {
              language: l,
              menus: ["column", "row", "merge", "wrap", "delete"],
              toolbarTable: false,
            },
            keyboard: {
              bindings: {
                endKey: {
                  key: "End",
                  handler: function () {
                    const currentPosition = q.getSelection(true)?.index;
                    if (typeof currentPosition === "number") {
                      const [line, offset] = q.getLine(currentPosition);
                      if (line) {
                        const lineLength = line.length();
                        q.setSelection(currentPosition + lineLength - offset - 1, 0);
                        return false;
                      }
                    }
                  },
                },
                save: {
                  key: "s",
                  shortKey: true,
                  handler: function () {
                    dispatch(setSaveTrigger(true));
                  },
                },
                customFunction: {
                  key: 191, // '/'のキーコード
                  altKey: true,
                  handler: function () {
                    kreaVerse();
                    return false;
                  },
                },
                ...QuillTableBetter.keyboardBindings,
              },
            },
          },
        });

        getTextFile().then((res) => {
          if (res) {
            if (q && q.root) {
              // 履歴をリセット
              q.root.innerHTML = res.content;
              setTimeout(() => {
                dispatch(setFileText({ uuid: fileUuid!, content: q.getText() }));
                q.history.clear();
              });
            }
          }
        });
        setQuill(q);
        quillRef.current = q;
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  }, []);

  // プレビューURL用に保存済みの公開状態を保持
  const [savedPublicStatus, setSavedPublicStatus] = useState(0);
  const publicUuid = useSelector((state: RootState) => state.file.publicUuid[fileUuid!]);

  // ファイル取得
  const getTextFile = async () => {
    try {
      if (!fileUuid) return;
      const res = await axios.get("/api/v1/text-file/" + fileUuid);
      if (res.data) {
        dispatch(setFileTitle({ uuid: fileUuid!, title: res.data.title }));
        dispatch(setTags({ uuid: fileUuid!, tags: res.data.tags }));
        dispatch(setLanguage({ uuid: fileUuid!, language: res.data.language || lang }));
        dispatch(setPublicStatus({ uuid: fileUuid!, status: res.data.public_status }));
        setSavedPublicStatus(res.data.public_status);
        dispatch(setPublicUuid({ uuid: fileUuid!, publicUuid: res.data.public_uuid }));
        dispatch(setThumbnailImage({ uuid: fileUuid!, url: res.data.thumbnail }));
        return res.data;
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  // テキスト長の取得・Heading適応時、Topに戻る前のスクロール位置の記憶
  useEffect(() => {
    if (!quill) return;

    const handleTextChange = () => {
      const text = quill.getText() || "";
      const content = quill.getSemanticHTML();
      dispatch(setFileText({ uuid: fileUuid!, content: text }));
    };

    const handleSelectionChange = (range, _oldRange, _source) => {
      if (range === null) return;
    };

    quill.on("text-change", handleTextChange);
    quill.on("selection-change", handleSelectionChange);

    return () => {
      quill.off("text-change", handleTextChange);
      quill.off("selection-change", handleSelectionChange);
    };
  }, [quill, fileUuid, dispatch]);

  // 選択範囲の取得
  useEffect(() => {
    if (!quill) return;

    const handleSelectionChange = (range, _oldRange, _source) => {
      if (range === null) return;
      const text = quill.getText(range.index, range.length);
      dispatch(
        setSelection({
          uuid: fileUuid!,
          index: range.index,
          length: range.length,
          text: text,
        })
      );
    };

    quill.on("selection-change", handleSelectionChange);

    return () => {
      quill.off("selection-change", handleSelectionChange);
    };
  }, [quill, fileUuid, dispatch]);

  // UnsplashのURLから画像挿入
  useEffect(() => {
    if (!quill || !unsplash) return;

    const insertUnsplashImage = () => {
      const range = quill.getSelection(true);
      const index = range ? range.index : 0;
      quill.history.cutoff();
      quill.updateContents([{ retain: index }, { insert: { image: { src: unsplash, class: "unsplash" } } }], "user");
      setAlert("success", "Image uploaded");
      quill.setSelection(index + 1, 0);
      quill.history.cutoff();
    };

    insertUnsplashImage();
  }, [unsplash, quill, dispatch]);

  // 生成された画像の挿入
  useEffect(() => {
    if (!quill || !generatedImage) return;

    const insertGeneratedImage = () => {
      const range = quill.getSelection(true);
      const index = range ? range.index : 0;

      quill.history.cutoff(); // 履歴の区切りを入れる

      quill.updateContents(
        [
          { retain: index },
          {
            insert: {
              image: {
                src: generatedImage,
                "data-type": "temp-image",
                "data-generated": "true",
                class: "generated",
              },
            },
          },
        ],
        "user"
      );

      quill.setSelection(index + 1, 0);
      setAlert("success", "Generated image inserted");
      dispatch(setFileText({ uuid: fileUuid!, content: quill.getText() }));
      dispatch(setGeneratedImage({ uuid: fileUuid!, url: "" }));

      quill.history.cutoff(); // 履歴の区切りを入れる
    };

    insertGeneratedImage();
  }, [generatedImage, quill, fileUuid, dispatch]);

  // サイドバーから生成された画像の挿入
  useEffect(() => {
    if (!quill || !sidebarGenerateImage) return;

    const insertSidebarGeneratedImage = () => {
      const range = quill.getSelection(true);
      const index = range ? range.index : 0;

      quill.history.cutoff(); // 履歴の区切りを入れる

      quill.updateContents(
        [
          { retain: index },
          {
            insert: {
              image: {
                src: sidebarGenerateImage,
                "data-type": "temp-image",
                "data-generated": "true",
                class: "generated",
              },
            },
          },
        ],
        "user"
      );

      quill.setSelection(index + 1, 0);
      setAlert("success", "Generated image inserted");
      dispatch(setFileText({ uuid: fileUuid!, content: quill.getText() }));
      dispatch(setSidebarGenerateImage(""));

      quill.history.cutoff(); // 履歴の区切りを入れる
    };

    insertSidebarGeneratedImage();
  }, [sidebarGenerateImage, quill, fileUuid, dispatch]);

  // 画像貼り付けの処理
  const pasteImage = useCallback(
    (e: ClipboardEvent) => {
      if (!quill) return;

      const items = e.clipboardData?.items;
      if (!items) return;

      const itemsArray = Array.from(items);
      for (const element of itemsArray) {
        if (element.type.indexOf("image") === -1) continue;

        const file = element.getAsFile();
        if (!file) continue;

        if (file.size > 20 * 1024 * 1024) {
          setAlert("error", "File size exceeds 20MB. Please paste a smaller file.");
          return;
        }

        const reader = new FileReader();
        reader.onload = (e) => {
          const base64Image = e.target?.result as string;
          const range = quill.getSelection(true);
          const index = range ? range.index : 0;

          quill.history.cutoff(); // 履歴の区切りを入れる

          quill.updateContents(
            [
              { retain: index },
              {
                insert: {
                  image: {
                    src: base64Image,
                    "data-type": "temp-image",
                    class: "uploaded",
                  },
                },
              },
            ],
            "user"
          );

          quill.setSelection(index + 1, 0);
          setAlert("success", "Image pasted successfully");

          quill.history.cutoff(); // 履歴の区切りを入れる
        };
        reader.readAsDataURL(file);
      }
    },
    [quill, dispatch]
  );

  // 画像ドロップの処理
  useEffect(() => {
    if (!quill) return;

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      const files = e.dataTransfer?.files;
      if (!files || files.length === 0) return;

      const file = files[0];
      if (file.size > 20 * 1024 * 1024) {
        setAlert("error", "File size exceeds 20MB. Please drop a smaller file.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Image = e.target?.result as string;
        const range = quill.getSelection(true);
        const index = range ? range.index : 0;

        quill.history.cutoff(); // 履歴の区切りを入れる

        quill.updateContents(
          [
            { retain: index },
            {
              insert: {
                image: {
                  src: base64Image,
                  "data-type": "temp-image",
                  class: "uploaded",
                },
              },
            },
          ],
          "user"
        );

        quill.setSelection(index + 1, 0);
        setAlert("success", "Image dropped successfully");

        quill.history.cutoff(); // 履歴の区切りを入れる
      };
      reader.readAsDataURL(file);
    };

    quill.root.addEventListener("drop", handleDrop);
    return () => {
      quill.root.removeEventListener("drop", handleDrop);
    };
  }, [quill, dispatch]);

  // 画像貼り付けのイベントリスナー
  useEffect(() => {
    if (!quill) return;

    const handlePaste = (e: ClipboardEvent) => {
      e.preventDefault();
      pasteImage(e);
    };

    quill.root.addEventListener("paste", handlePaste);
    return () => {
      quill.root.removeEventListener("paste", handlePaste);
    };
  }, [quill, pasteImage]);

  const publicStatus = useSelector((state: RootState) => state.file.publicStatus[fileUuid!]);
  const tags = useSelector((state: RootState) => state.file.tags[fileUuid!]);
  const language = useSelector((state: RootState) => state.file.language[fileUuid!]);
  const [saving, setSaving] = useState(false);

  // quillに画像src=data:imageが含まれているかどうか。
  const hasTempImage = () => {
    if (!quill) return false;
    const html = quill.root.innerHTML;
    const tempImageRegex = /<img src="data:image/g;
    return tempImageRegex.test(html);
  };

  useEffect(() => {
    dispatch(setUpdateThumbnail(false));
  }, [fileUuid]);

  // 保存処理
  const handleSave = async () => {
    try {
      setSaving(true);
      setSaveIcon(<CircularProgress size={20} />);
      dispatch(setFileText({ uuid: fileUuid!, content: quill?.getText() || "" }));
      dispatch(setUnsplash({ uuid: fileUuid!, url: "" }));
      dispatch(setGeneratedImage({ uuid: fileUuid!, url: "" }));
      setSavedPublicStatus(publicStatus);
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "X-CSRFToken": csrftoken!,
      };
      const data = {
        content: quill?.root.innerHTML,
        title: fileTitle,
        publicStatus: publicStatus,
        thumbnail: thumbnailImage,
        tags: tags,
        language: language,
        updateThumbnail: updateThumbnail,
      };
      await axios
        .patch("/api/v1/text-file/" + fileUuid, data, { headers: headers })
        .then((res) => {
          if (res.data) {
            setSaveIcon(<CheckIcon />);
            setTimeout(() => {
              setSaveIcon(<SaveIcon />);
            }, 3000);
            if (quill) {
              quill.root.innerHTML = res.data.content;
              dispatch(setFileText({ uuid: fileUuid!, content: quill.getText() }));
              dispatch(setThumbnailImage({ uuid: fileUuid!, url: res.data.thumbnail }));
              dispatch(setUpdateThumbnail(false));
              setAlert("success", "Saved successfully");
            }
          }
        })
        .catch((err) => {
          setAlert("error", "An error occurred during the save operation");
          console.log(err);
        });
      setSaving(false);
    } catch (error) {
      setSaving(false);
      console.error("An unknown error occurred:", error);
    }
  };

  // 保存トリガー
  useEffect(() => {
    if (saveTrigger) {
      handleSave();
      dispatch(setSaveTrigger(false));
    }
  }, [saveTrigger]);

  // ダウンロード処理
  const handleDownload = () => {
    const text = quill?.getText() || ""; // quillが存在しない、または getText が undefined を返した場合に空文字列を使用
    const element = document.createElement("a");
    const file = new Blob([text], { type: "text/plain" }); // text は常に文字列なので Blob コンストラクターで使用できる
    element.href = URL.createObjectURL(file);
    element.download = fileTitle || "file";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  // テキスト置換処理
  useEffect(() => {
    if (quill && selection && shouldReplace) {
      const { index, length, text } = selection;
      quill.deleteText(index, length);
      quill.insertText(index, text, "user");
      // 処理が完了したらトリガーをオフにする
      dispatch(setShouldReplace({ uuid: fileUuid!, shouldReplace: false }));
    }
  }, [selection, quill, shouldReplace]);

  const boxRef = useRef<HTMLDivElement>(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const { setAlert } = useAlert();
  const navigate = useCustomNavigate();

  // 削除確認ダイアログを開く
  const handleDeleteDialogOpen = () => {
    setDialogOpen(true);
  };

  // 削除
  const handleConfirmedDelete = async () => {
    try {
      setDeleting(true);
      const csrftoken = Cookies.get("csrftoken");
      const response = await fetch("/api/v1/text-file/" + fileUuid, {
        method: "DELETE",
        headers: {
          "X-CSRFToken": csrftoken!,
        },
      });

      if (!response.ok) {
        setAlert("error", t("library.text.delete.error"));
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
      navigate("/library/text");
      setDeleting(false);
      setAlert("success", t("library.text.delete.success"));
    } catch (error) {
      setDeleting(false);
      console.error("Error during the fetch operation:", error);
      setAlert("error", t("library.text.delete.error"));
    }
  };
  const actions = [
    { icon: saveIcon, name: t("textEditor.save"), action: handleSave },
    { icon: <DownloadIcon />, name: t("common.download"), action: handleDownload },
    {
      icon: deleting ? <CircularProgress size={20} color="error" /> : <DeleteIcon color="error" />,
      name: t("common.delete"),
      action: handleDeleteDialogOpen,
      disabled: deleting,
    },
    {
      icon: <LaunchIcon />,
      name: t("textEditor.preview"),
      action: () => {
        let url = `/${language}/notes-nebula/articles/${fileUuid}`;
        if (savedPublicStatus === 3 && publicUuid) {
          url = `/${language}/notes-nebula/articles/${fileUuid}/${publicUuid}`;
        }
        window.open(url, "_blank");
      },
    },
    { icon: <FolderIcon />, name: t("textEditor.library"), action: () => navigate("/library/text") },
  ];

  const [speedDialStyle, setSpeedDialStyle] = useState({});

  // フロートメニューの位置を設定
  useEffect(() => {
    const updateSpeedDialPosition = () => {
      const box = boxRef.current;
      if (box) {
        const rect = box.getBoundingClientRect();
        setSpeedDialStyle({
          position: "fixed",
          bottom: window.innerHeight - rect.bottom + 24,
          right: window.innerWidth - rect.right + 24,
        });
      }
    };

    const handleScrollEvent = () => {
      updateSpeedDialPosition();
    };

    const box = boxRef.current;
    if (box) {
      box.addEventListener("scroll", handleScrollEvent);

      // ResizeObserverをここで初期化
      const observer = new ResizeObserver(updateSpeedDialPosition);
      observer.observe(box);

      // 初期位置を設定
      updateSpeedDialPosition();

      // クリーンアップ関数でobserverを参照する
      return () => {
        box.removeEventListener("scroll", handleScrollEvent);
        observer.disconnect(); // ここで正しく参照可能
      };
    }
  }, []); // マウント時に一度だけ実行す

  interface GreyedTextRange {
    index: number;
    length: number;
  }

  const greyedTextRangeRef = useRef<GreyedTextRange | null>(null);
  const [kreaVerseStatus, setKreaVerseStatus] = useState<"generating" | "waiting" | "ready">("ready");

  const kreaVerse = useCallback(async () => {
    if (kreaVerseStatus !== "ready") return;
    const quill = quillRef.current;
    if (!quill) {
      console.error("Quill instance is not available");
      return;
    }

    // テキスト未入力時はSetAlert
    if (quill.getText().trim().length < 10) {
      setAlert("error", t("textEditor.kreaVerse.error"));
      return;
    }

    // ステータスを「generating」に設定して処理の開始を示す
    setKreaVerseStatus("generating");

    // 既存のグレーアウトテキストがあれば削除
    if (greyedTextRangeRef.current) {
      quill.deleteText(greyedTextRangeRef.current.index, greyedTextRangeRef.current.length, "user");
      removeEventListeners(); // イベントリスナーを削除
      greyedTextRangeRef.current = null; // 参照をリセット
    }

    // カーソル位置を取得
    let range = quill.getSelection(true);
    if (!range) {
      const currentPosition = quill.getSelection()?.index;
      if (typeof currentPosition !== "number") {
        console.error("Unable to determine cursor position");
        setKreaVerseStatus("ready"); // エラー時にステータスをリセット
        return;
      }
      range = { index: currentPosition, length: 0 }; // カーソル位置が見つかった場合、範囲を設定
    }

    const DESIRED_LENGTH = 500;
    const INITIAL_FETCH = 1000; // 最初に取得する文字数を増やす

    // カーソル前後のコンテンツを取得（より多くの文字を取得）
    const contentsBefore = quill.getContents(
      Math.max(0, range.index - INITIAL_FETCH),
      Math.min(INITIAL_FETCH, range.index)
    );
    const contentsAfter = quill.getContents(range.index, INITIAL_FETCH);

    // Delta形式をHTMLに変換
    const tempQuill = new Quill(document.createElement("div"));
    tempQuill.setContents(contentsBefore);
    let htmlBefore = tempQuill.root.innerHTML;
    tempQuill.setContents(contentsAfter);
    let htmlAfter = tempQuill.root.innerHTML;

    // link-cardを除外し、文字数を調整する関数
    function processHTML(html, desiredLength, isBefore = false) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const linkCards = doc.querySelectorAll("div.link-card");
      linkCards.forEach((card) => card.remove());

      let processedHTML = doc.body.innerHTML;

      // プレーンテキストに変換して文字数をカウント
      const tempElement = document.createElement("div");
      tempElement.innerHTML = processedHTML;
      let text = tempElement.textContent || tempElement.innerText;

      // 必要な長さに調整
      if (text.length > desiredLength) {
        if (isBefore) {
          // beforeCursorの場合、末尾から指定された文字数を取得
          text = text.slice(-desiredLength);
          // 最初の完全な単語から始める
          const firstSpace = text.indexOf(" ");
          if (firstSpace > 0) {
            text = text.slice(firstSpace + 1);
          }
        } else {
          // afterCursorの場合、先頭から指定された文字数を取得
          text = text.slice(0, desiredLength);
          // 最後の完全な単語で切る
          const lastSpace = text.lastIndexOf(" ");
          if (lastSpace > 0) {
            text = text.slice(0, lastSpace);
          }
        }
        tempElement.textContent = text;
        processedHTML = tempElement.innerHTML;
      }

      return processedHTML;
    }

    // link-cardを除外し、文字数を調整
    htmlBefore = processHTML(htmlBefore, DESIRED_LENGTH, true); // isBefore = true
    htmlAfter = processHTML(htmlAfter, DESIRED_LENGTH, false); // isBefore = false

    const csrftoken = Cookies.get("csrftoken");
    const headers = {
      "X-CSRFToken": csrftoken!, // CSRFトークンの取得
    };
    const data = {
      textBeforeCursor: htmlBefore,
      textAfterCursor: htmlAfter,
    };

    try {
      // サーバーにPOSTリクエストを送信
      const res = await axios.post("/api/v1/text-file/" + fileUuid + "/krea-verse", data, { headers: headers });
      if (res.data && res.data.message) {
        // 履歴操作を開始
        quill.history.cutoff();

        // グレーアウトされたテキストを挿入
        const htmlContent = `<span style="color: grey;">${res.data.message}</span>`;
        const delta = quill.clipboard.convert({ html: htmlContent });

        // 挿入操作をアトミックな変更として適用
        quill.updateContents(
          [{ retain: range.index }, { insert: delta.ops[0].insert, attributes: delta.ops[0].attributes }],
          "user"
        );

        // 挿入されたテキストの長さを保存
        const insertedLength = delta.length();
        greyedTextRangeRef.current = { index: range.index, length: insertedLength };

        // 履歴操作を終了
        quill.history.cutoff();

        // ステータスを「waiting」に設定し、ユーザーのアクション待ち
        setKreaVerseStatus("waiting");
        dispatch(setCreditTrigger(true));

        // キー入力と選択変更のイベントリスナーを追加
        quill.root.addEventListener("keydown", onKeyDown);
        quill.on("selection-change", onSelectionChange);

        // ブラウザフォーカスの外れを検知するためのイベントリスナーを追加
        window.addEventListener("blur", onWindowBlur);
        window.addEventListener("focus", onWindowFocus);
      }
    } catch (err) {
      setAlert("error", "An error occurred during the save operation"); // エラーメッセージを表示
      console.error(err);
      setKreaVerseStatus("ready"); // エラー時にステータスをリセット
    }

    // キー入力イベントハンドラー
    function onKeyDown(e) {
      if (!greyedTextRangeRef.current || !quill) return;

      if (e.key === "Tab" || e.key === "Enter") {
        e.preventDefault(); // TabやEnterのデフォルト動作を防止

        // Enterキーの場合、1文字削除してグレーアウトテキストを挿入
        if (e.key === "Enter" || e.key === "Tab") {
          quill.deleteText(greyedTextRangeRef.current.index, 1, "user");
        }

        // グレーアウトテキストを確定（色を通常に戻す）
        quill.formatText(
          greyedTextRangeRef.current.index,
          greyedTextRangeRef.current.length,
          "color",
          "inherit",
          "user"
        );

        // カーソルを挿入されたテキストの末尾に移動
        quill.setSelection(greyedTextRangeRef.current.index + greyedTextRangeRef.current.length, 0, "user");

        cleanup(); // クリーンアップ処理を実行

        // ステータスを「ready」に設定して処理完了を示す
        setKreaVerseStatus("ready");
      } else {
        // EnterやTab以外のキーが押された場合、グレーアウトテキストを破棄
        quill.deleteText(greyedTextRangeRef.current.index, greyedTextRangeRef.current.length, "user");
        cleanup(); // クリーンアップ処理を実行

        // ステータスを「ready」に設定
        setKreaVerseStatus("ready");
      }
    }

    // 選択範囲変更イベントハンドラー（フォーカスが外れた場合など）
    function onSelectionChange(range, oldRange, source) {
      if (!greyedTextRangeRef.current || !quill) return;

      if (range === null) {
        // フォーカスが失われた場合、グレーアウトテキストを破棄
        quill.deleteText(greyedTextRangeRef.current.index, greyedTextRangeRef.current.length, "user");
        cleanup(); // クリーンアップ処理を実行

        // ステータスを「ready」に設定
        setKreaVerseStatus("ready");
      }
    }

    // ブラウザからフォーカスが外れたときのイベントハンドラー
    function onWindowBlur() {
      if (!greyedTextRangeRef.current || !quill) return;

      // グレーアウトテキストを確定（色を通常に戻す）
      quill.formatText(greyedTextRangeRef.current.index, greyedTextRangeRef.current.length, "color", "inherit", "user");

      // カーソルを挿入されたテキストの末尾に移動
      quill.setSelection(greyedTextRangeRef.current.index + greyedTextRangeRef.current.length, 0, "user");
      cleanup(); // クリーンアップ処理を実行

      // ステータスを「ready」に設定
      setKreaVerseStatus("ready");
    }

    // ブラウザにフォーカスが戻ったときのイベントハンドラー
    function onWindowFocus() {
      if (kreaVerseStatus === "waiting") {
        // 必要に応じてフォーカスが戻ったときの処理を追加
        if (!greyedTextRangeRef.current || !quill) return;
        quill.formatText(
          greyedTextRangeRef.current.index,
          greyedTextRangeRef.current.length,
          "color",
          "inherit",
          "user"
        );

        // カーソルを挿入されたテキストの末尾に移動
        quill.setSelection(greyedTextRangeRef.current.index + greyedTextRangeRef.current.length, 0, "user");
        cleanup(); // クリーンアップ処理を実行
      }
    }

    function cleanup() {
      if (!quill) return;
      quill.root.removeEventListener("keydown", onKeyDown); // キー入力イベントリスナーを削除
      quill.off("selection-change", onSelectionChange); // 選択範囲変更イベントリスナーを削除
      window.removeEventListener("blur", onWindowBlur); // ブラウザのblurイベントリスナーを削除
      window.removeEventListener("focus", onWindowFocus); // ブラウザのfocusイベントリスナーを削除
      greyedTextRangeRef.current = null; // 参照をリセット
    }

    // イベントリスナーを削除する関数
    function removeEventListeners() {
      if (!quill) return;
      quill.root.removeEventListener("keydown", onKeyDown); // キー入力イベントリスナーを削除
      quill.off("selection-change", onSelectionChange); // 選択範囲変更イベントリスナーを削除
      window.removeEventListener("blur", onWindowBlur); // ブラウザのblurイベントリスナーを削除
      window.removeEventListener("focus", onWindowFocus); // ブラウザのfocusイベントリスナーを削除
    }
  }, [fileUuid]);

  return (
    <>
      <Fade in={true} timeout={1000}>
        <Box sx={{ borderRadius: 2, height: "100%", position: "relative" }}>
          {/* ロックするためのオーバーレイ */}
          <Fade in={saving && hasTempImage()} timeout={300}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 1,
                bgcolor: "rgba(0, 0, 0, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                transition: "all 0.3s",
              }}
            >
              <RowCenteredBox>
                <CircularProgress color="primary" />
                <Typography variant={"subtitle1"} color={"textPrimary"} sx={{ ml: 2 }} component={"span"}>
                  Save in progress...
                </Typography>
              </RowCenteredBox>
            </Box>
          </Fade>

          <Box
            ref={boxRef}
            sx={{
              borderRadius: 2,
              display: "flex",
              justifyContent: "center",
              position: "relative",
              height: "100%",
              overflowY: "auto",
            }}
          >
            <Box sx={{ width: "100%", display: "flex", flexDirection: "column", height: "100%" }}>
              {/* Editor */}
              <Box
                sx={{ backgroundColor: "background.paper", borderRadius: 2, width: "100%" }}
                display={"flex"}
                flexDirection={"column"}
              >
                <Box
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  display={"flex"}
                  gap={1}
                  sx={{}}
                >
                  <Box
                    sx={{
                      flexDirection: "column",
                      position: "sticky",
                      display: "flex",
                      mt: 2,
                      top: 16,
                      zIndex: 10,
                    }}
                  >
                    <CustomToolbar quill={quill} />
                    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                      <Tooltip
                        title={<span style={{ whiteSpace: "pre-line" }}>{t("textEditor.kreaVerse.description")}</span>}
                      >
                        <Slide in={true} timeout={1000} direction={"down"}>
                          <RowCenteredBox
                            sx={{
                              borderRadius: 1,
                              mt: 1,
                              px: 2,
                              py: 0.2,
                              width: "fit-content",
                              background: (theme) => alpha(theme.palette.background.custom2, 0.4),
                              backdropFilter: "blur(6px)",
                              cursor: "pointer",
                            }}
                          >
                            {kreaVerseStatus === "generating" ? (
                              <div>
                                <CircularProgress
                                  size={16}
                                  sx={{ mr: 1, color: (theme) => theme.palette.text.secondary }}
                                />
                                <GradientTypography variant={"button"}>
                                  {t("textEditor.kreaVerse.generating")}
                                </GradientTypography>
                              </div>
                            ) : (
                              <GradientTypography variant={"button"}>
                                {t(
                                  kreaVerseStatus === "ready"
                                    ? "textEditor.kreaVerse.ready"
                                    : "textEditor.kreaVerse.waiting"
                                )}
                              </GradientTypography>
                            )}
                          </RowCenteredBox>
                        </Slide>
                      </Tooltip>
                    </Box>
                  </Box>

                  {/* タイトル */}
                  <ColumnCenteredBox sx={{ maxWidth: 720 }}>
                    <TextField
                      variant={"standard"}
                      placeholder={"Title"}
                      autoComplete={"off"}
                      fullWidth
                      color={"primary"}
                      onChange={(e) => {
                        dispatch(setFileTitle({ uuid: fileUuid!, title: e.target.value }));
                      }}
                      sx={{
                        overflow: "hidden",
                        px: 2,
                        mt: 4,
                      }}
                      inputProps={{
                        className: "title-input",
                      }}
                      value={fileTitle || ""}
                    />
                    {/*  thumbnail*/}
                    {
                      thumbnailImage ? (
                        <Box
                          sx={{
                            position: "relative",
                            mt: 2,
                            width: "100%",
                            overflow: "hidden",
                            px: 2,
                            borderRadius: 2,
                          }}
                        >
                          <img
                            src={thumbnailImage}
                            alt="thumbnail"
                            style={{ width: "100%", height: "100%", borderRadius: 4 }}
                          />
                        </Box>
                      ) : null
                      // <Typography variant={"caption"} color={"text.secondary"} sx={{mt: 2, px: 2}}>
                      //   {t("textEditor.thumbnail")}
                      // </Typography>
                    }
                  </ColumnCenteredBox>

                  <Box
                    id="editor"
                    sx={{
                      fontSize: "1em",
                      maxWidth: 720,
                      width: "100%",
                      minHeight: "calc(100vh - 204px)",
                      pb: 4,
                      ".ql-editor": {
                        overflowY: "unset",
                      },
                    }}
                  ></Box>
                </Box>

                {/* 文字数カウント */}
                {fileText && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      position: "fixed",
                      bottom: 16,
                      zIndex: 1,
                      p: 1,
                      m: 2,
                      width: "fit-content",
                    }}
                  >
                    <KeyboardIcon sx={{ mr: 0.5, fontSize: 16 }} />
                    <Typography variant={"caption"}>{fileText.length - 1 || 0}</Typography>
                  </Box>
                )}
              </Box>
            </Box>

            {/* Speed Dial */}
            <Box sx={{ transform: "translateZ(0px)", ...speedDialStyle }}>
              <SpeedDial ariaLabel="SpeedDial basic example" icon={<SpeedDialIcon />}>
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={action.action}
                    FabProps={{
                      disabled: action.disabled,
                    }}
                  />
                ))}
              </SpeedDial>
            </Box>
          </Box>
        </Box>
      </Fade>

      {/* 削除確認ダイアログ */}
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>{t("library.text.delete.title")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("library.text.delete.description")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
            {t("common.cancel")}
          </Button>
          <Button
            color="error"
            onClick={() => {
              handleConfirmedDelete();
              setDialogOpen(false);
            }}
          >
            {t("common.delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
