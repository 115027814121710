import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useJunoImageGeneratorContext } from "../../../context/ImageGeneratorContext";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Crop169Icon from "@mui/icons-material/Crop169";
import IconButton from "@mui/material/IconButton";
import CropDinIcon from "@mui/icons-material/CropDin";
import SelectPublic from "./SelectPublic";
import { Hidden } from "@mui/material";

export default function ParamsDallE() {
  const { t } = useTranslation();
  const context = useJunoImageGeneratorContext();
  const marksDall = [{ value: 1024 }, { value: 1792 }];

  const handleAspectRatioChange = (aspect: number) => {
    if (aspect === 74) {
      context.setWidthPartial("DallE3", 1792);
      context.setHeightPartial("DallE3", 1024);
    }
    if (aspect === 47) {
      context.setWidthPartial("DallE3", 1024);
      context.setHeightPartial("DallE3", 1792);
    }
    if (aspect === 11) {
      context.setWidthPartial("DallE3", 1024);
      context.setHeightPartial("DallE3", 1024);
    }
  };

  const selectedAspectRatio = () => {
    if (context.width.DallE3 === 1024 && context.height.DallE3 === 1024) {
      return 11;
    }
    if (context.width.DallE3 === 1792 && context.height.DallE3 === 1024) {
      return 74;
    }
    if (context.width.DallE3 === 1024 && context.height.DallE3 === 1792) {
      return 47;
    }
  };

  return (
    <Stack spacing={2}>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
        <Typography variant={"body2"}>{t("juno.input.aspectRatio.title")}</Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={1}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(11)}
            color={selectedAspectRatio() === 11 ? "primary" : "default"}
          >
            <CropDinIcon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(11)} sx={{ cursor: "pointer" }}>
            1 : 1
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(74)}
            color={selectedAspectRatio() === 74 ? "primary" : "default"}
          >
            <Crop169Icon />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(74)} sx={{ cursor: "pointer" }}>
            7 : 4
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <IconButton
            onClick={() => handleAspectRatioChange(47)}
            color={selectedAspectRatio() === 47 ? "primary" : "default"}
          >
            <Crop169Icon sx={{ rotate: "90deg" }} />
          </IconButton>
          <Typography variant={"caption"} onClick={() => handleAspectRatioChange(47)} sx={{ cursor: "pointer" }}>
            4 : 7
          </Typography>
        </Box>
      </Box>

      {/* 横幅 */}
      <Hidden mdDown mdUp>
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
            <Typography variant={"body2"}>{t("juno.input.width")}</Typography>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", ml: 1 }} gap={2}>
            <Slider
              getAriaValueText={(value) => `${value}px`}
              defaultValue={1024}
              value={context.width.DallE3}
              onChange={(e, value) => context.setWidthPartial("DallE3", value as number)}
              step={null}
              marks={marksDall}
              min={1024}
              max={1792}
            />
            <Box
              sx={{
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <TextField
                size={"small"}
                sx={{ width: "100px" }}
                variant={"standard"}
                value={context.width.DallE3}
                onChange={(e) => context.setWidthPartial("DallE3", Number(e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        </Box>

        {/* 縦幅 */}
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1}>
            <Typography variant={"body2"}>{t("juno.input.height")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", ml: 1 }} gap={2}>
            <Slider
              getAriaValueText={(value) => `${value}px`}
              defaultValue={1024}
              value={context.height.DallE3}
              onChange={(e, value) => context.setHeightPartial("DallE3", value as number)}
              step={null}
              marks={marksDall}
              min={1024}
              max={1792}
            />
            <Box
              sx={{
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <TextField
                size={"small"}
                sx={{ width: "100px" }}
                variant={"standard"}
                value={context.height.DallE3}
                onChange={(e) => context.setHeightPartial("DallE3", Number(e.target.value))}
                InputProps={{
                  endAdornment: <InputAdornment position="end">px</InputAdornment>,
                  readOnly: true,
                }}
              />
            </Box>
          </Box>
        </Box>
      </Hidden>

      {/* スタイル */}
      <FormControl fullWidth>
        <InputLabel id="ai-engine-select-label">{t("juno.input.style.title")}</InputLabel>
        <Select
          size={"small"}
          value={context.styleDall}
          label={t("juno.input.style.title")}
          onChange={(event) => {
            const newValue = event.target.value;
            if (newValue === "vivid" || newValue === "natural") {
              context.setStyleDall(newValue);
            }
          }}
        >
          <MenuItem value="vivid">{t("juno.input.style.dalle3.vivid")}</MenuItem>
          <MenuItem value="natural">{t("juno.input.style.dalle3.natural")}</MenuItem>
        </Select>
      </FormControl>

      {/* 拡張プロンプト */}
      <Box>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={context.revisedPrompt} />}
            label={t("juno.input.dallE3RevisedPrompt")}
            onChange={(event, checked) => context.setRevisedPrompt(checked)}
          />
        </FormGroup>
      </Box>

      {/*  公開設定  */}
      <SelectPublic />
    </Stack>
  );
}
