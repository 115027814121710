import Box from "@mui/material/Box";
import * as React from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import { useVideoScriptContext } from "../../../../context/VideoScriptContext";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function VideoLength() {
  const { t } = useTranslation();
  const context = useVideoScriptContext();

  return (
    <>
      <Box>
        <Box display={"flex"} flexDirection={"row"}>
          <StyledTypography variant="body1" gutterBottom>
            {t("videoScript.length")}
          </StyledTypography>
          <Typography color={"error"}>&nbsp;*</Typography>
        </Box>
        <Box>
          <FormControl>
            <RadioGroup
              row
              value={context.videoLength}
              onChange={(event) => context.setVideoLength(JSON.parse(event.target.value))}
            >
              <FormControlLabel value={30} control={<Radio />} label={t("videoScript.short")} />
              <FormControlLabel value={60} control={<Radio />} label={t("videoScript.long")} />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
    </>
  );
}
