const videoScript = {
    library: 'ライブラリ',
    title: 'ショート動画\nスクリプト',
    description: 'YouTube, TikTok, Instagram などの動画コンテンツに最適化されたスクリプトを生成します。',
    target: 'ターゲット',
    targetPlaceholder: '（例）20〜30代 動画クリエイター',
    desiredVideo: '希望する動画の概要',
    desiredVideoPlaceholder: '（例）最新iPhoneカメラのすごい性能 5選',
    sns: {
        title: '対象 SNS',
        instagram: 'Instagram',
        tiktok: 'TikTok',
        youtube: 'YouTube',
        facebook: 'Facebook',
        x: 'X (Twitter)',
        linkedin: 'LinkedIn',
        pinterest: 'Pinterest',
        threads: 'Threads',
        other: 'その他',
    },
    length: '動画の長さ',
    short: '30秒',
    long: '60秒',
    source: '参考元',
    sourceDescription: '参考元を選択してください。',
    tone: 'テキストのトーン',
    additional: '追加情報',
    additionalPlaceholder: '従うべき注意点やガイドラインを入力してください。',
    srcType: {
        url: 'URL',
        urlPlaceholder: 'https://www.example.com',
        urlDescription: '参考にするためのWebサイトのURLを入力して下さい。',
        youtube: 'YouTube',
        youtubePlaceholder: 'https://www.youtube.com/watch?v=xxxxxxxxxxx ( We also support YouTube Shorts. )',
        youtubeDescription: '参考にするためのYouTubeのURLを入力して下さい。',
        text: 'テキスト',
        textPlaceholder: 'テキスト（最大4,000文字）',
        textDescription: '参考にするためのテキストを入力して下さい。',
        keyword: 'キーワード',
        keywordDescription: 'キーワードを元にTenak AI が文章を生成します。',
        keywordPlaceholder: '（例）iPhone カメラ 性能',
    },
    submit: 'ショート動画スクリプトを生成',
    error: {
        createHeading: 'キーワードを入力してから見出しを生成してください。',
        invalidYoutubeUrl: 'YouTubeのURLを正しく入力してください。',
        invalidUrl: 'URLを正しく入力してください。',
        invalidText: 'テキストを正しく入力してください。（最大4,000文字）',
        invalidContent: "参考元を選択・入力してください。",
        invalidKeywords: 'キーワードを正しく入力してください。',
        invalidSns: '対象SNSを選択してください。',
    },
}
export default videoScript;