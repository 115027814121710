import React from "react";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useJunoImageGeneratorContext } from "../../../context/ImageGeneratorContext";

const NegativePromptSaveModal = ({
  openNegativePromptSaveModal,
  setOpenNegativePromptSaveModal,
}: {
  openNegativePromptSaveModal: boolean;
  setOpenNegativePromptSaveModal: (value: boolean) => void;
}) => {
  const [negativePromptTitle, setNegativePromptTitle] = React.useState("");
  const { t } = useTranslation();
  const context = useJunoImageGeneratorContext();

  const saveNegativePrompt = async () => {
    try {
      const url = "/api/v1/juno/negative-prompt";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const data = {
        title: negativePromptTitle,
        prompt: context.negativePrompt,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });
      const json = await response.json();
      console.log(json);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Modal open={openNegativePromptSaveModal} onClose={() => setOpenNegativePromptSaveModal(false)}>
      <Box
        sx={{
          position: "absolute" as const,
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          maxWidth: 600,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant={"h6"} component={"p"}>
          {t("juno.input.negativePrompt")}
        </Typography>
        <Typography sx={{ mt: 2 }} variant={"body2"} component={"p"}>
          {t("juno.input.negativePromptSave")}
        </Typography>

        {/* title */}
        <TextField
          sx={{ mt: 2, width: "100%" }}
          label={t("juno.input.negativePromptTitle")}
          autoFocus
          variant={"outlined"}
          value={negativePromptTitle}
          onChange={(event) => setNegativePromptTitle(event.target.value)}
          inputProps={{ maxLength: 50 }}
        />

        {/* prompt */}
        <TextField
          sx={{ mt: 2, width: "100%" }}
          multiline
          minRows={4}
          maxRows={10}
          label={t("juno.input.negativePrompt")}
          value={context.negativePrompt}
          onChange={(event) => context.setNegativePrompt(event.target.value)}
        />

        {/* ボタン */}
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={() => setOpenNegativePromptSaveModal(false)} color={"primary"} variant={"outlined"}>
            {t("common.cancel")}
          </Button>
          <Button
            sx={{ ml: 2 }}
            onClick={() => {
              saveNegativePrompt();
              setOpenNegativePromptSaveModal(false);
            }}
            disabled={negativePromptTitle === "" || context.negativePrompt === ""}
            color={"primary"}
            variant={"contained"}
          >
            {t("common.save")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default NegativePromptSaveModal;