import React, { createContext, useContext, useState } from "react";

type NoticeContextType = {
  openNoticeTrigger: boolean;
  severity: "success" | "info" | "warning" | "error";
  message: string;
  triggerNotice: (severity: "success" | "info" | "warning" | "error", message: string) => void;
};

const NoticeContext = createContext<NoticeContextType | undefined>(undefined);

export const useNotice = () => {
  const context = useContext(NoticeContext);
  if (!context) {
    throw new Error("useNotice must be used within a NoticeProvider");
  }
  return context;
};

export const NoticeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [openNoticeTrigger, setOpenNoticeTrigger] = useState(false);
  const [severity, setSeverity] = useState<"success" | "info" | "warning" | "error">("info");
  const [message, setMessage] = useState("");

  const triggerNotice = (newSeverity: "success" | "info" | "warning" | "error", newMessage: string) => {
    setSeverity(newSeverity);
    setMessage(newMessage);
    setOpenNoticeTrigger((prev) => !prev);
  };

  return (
    <NoticeContext.Provider value={{ openNoticeTrigger, severity, message, triggerNotice }}>
      {children}
    </NoticeContext.Provider>
  );
};
