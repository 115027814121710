const voice = {
    title: 'Voice Generator',
    description: 'Tenak AI generates voice from text.',
    content: 'Text',
    submit: 'Generate Voice',
    speed: 'Speach Rate',
    pitch: 'Pitch',
    language: 'Language',
    voice: 'Voice',
    library: 'Library',
    typeDescription: 'About voice type',
    googleVoiceLabel: {
        neural: 'Premium',
        wavenet: 'Natural',
        studio: 'Studio (Preview)',
        standard: 'Standard',
        journey: 'Journey (Experimental)',
        news: 'News',
        polyglot: 'Polyglot',
    },
    openaiVoiceLabel: {
        description: 'Voice Sample Script',
        Alloy: 'Alloy',
        Echo: 'Echo',
        Fable: 'Fable',
        Onyx: 'Onyx',
        Nova: 'Nova',
        Shimmer: 'Shimmer',
    },
    voiceDescription: {
        neural: 'Provides the most natural and human-like voice quality. This type is based on deep learning technology and achieves a very high level of realism. (Neural2)',
        wavenet: 'Provides high-quality, natural-sounding voices. It uses Google\'s WaveNet technology to enable realistic speech synthesis. (Wavenet)',
        studio: 'Provides professional studio-quality voices. Designed for specific applications and scenarios.',
        standard: 'Uses traditional voice generation technology. Suitable for general purposes and meets basic voice synthesis needs.',
        journey: 'An experimental voice designed for specific stories or narratives. Offers a unique voice experience tailored to specific situations or themes.',
    },
    gender: {
        male: 'Male',
        female: 'Female',
    },
    provider: {
        title: 'AI ENGINE',
        google: 'Google',
        openai: 'OPEN AI'
    },
    message: {
        success: 'The voice has been generated. The voice is saved in the library.',
        error: 'Failed to generate the voice. Please try again later.',
    }
}
export default voice;