import BoltIcon from "@mui/icons-material/Bolt";
import { Box, Button, Fade, Paper, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CustomSection } from "../../components/common/CustomSection";
import { useCheckCredit } from "../../hooks/useCreditCheck";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { GradationButton } from "../../utils/gradationButton";
import useSubscription from "../../hooks/useSubscription";

const PlanAndBillings = () => {
  const { t } = useTranslation();
  const { fetchUserCredits, credits } = useCheckCredit();
  const [currentPlan, setCurrentPlan] = React.useState(null);
  const navigate = useCustomNavigate();
  const [cancelSubscription, setCancelSubscription] = React.useState(false);
  const [customerExists, setCustomerExists] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const { handleCreatePortal, getSubscription } = useSubscription();

  useEffect(() => {
    fetchUserCredits();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getSubscription();
        if (res.status === "success") {
          setCurrentPlan(res.subscription?.lookup_key);
          setCancelSubscription(res.subscription?.cancel_at);
          setCustomerExists(true);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Subscription data fetching failed", error);
        // エラーハンドリング: 失敗した場合の処理をここに記述
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Box component="div">
        <Typography component={"h3"} variant="h5" gutterBottom my={2}>
          {t("preferences.planAndBillings.title")}
        </Typography>

        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="300px">
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Fade in={!loading} timeout={500}>
            <Paper elevation={0} sx={{ width: "100%", height: "100%" }}>
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1">{t("preferences.planAndBillings.credit")}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography variant="subtitle1">{credits}</Typography>
                      <Button onClick={() => navigate("/pricing")} disableElevation color="info" sx={{ ml: 1 }}>
                        {t("preferences.planAndBillings.aboutCredit")}
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1">{t("preferences.planAndBillings.currentPlan")}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      <Typography
                        variant="button"
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          borderRadius: 1,
                          height: 36.5,
                          px: 1,
                          border: "1px solid",
                          borderColor: "text.secondary",
                          backgroundColor: "background.default",
                        }}
                      >
                        {currentPlan ? currentPlan : "Free"} Plan
                      </Typography>
                      {currentPlan !== "premium" && (
                        <GradationButton onClick={() => navigate("/pricing")} sx={{ ml: 1 }}>
                          <BoltIcon />
                          {t("preferences.planAndBillings.upgrade")}
                        </GradationButton>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>
              {currentPlan && currentPlan !== "free" && (
                <CustomSection>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={5}>
                      <Typography variant="subtitle1">{t("preferences.planAndBillings.cancel")}</Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "right",
                        }}
                      >
                        <Button
                          disableElevation
                          color={cancelSubscription ? "info" : "error"}
                          onClick={() => handleCreatePortal()}
                        >
                          {cancelSubscription
                            ? t("preferences.planAndBillings.reactivate")
                            : t("preferences.planAndBillings.cancel")}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </CustomSection>
              )}
              <CustomSection>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={5}>
                    <Typography variant="subtitle1">{t("preferences.planAndBillings.billings")}</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "right" }}>
                      {customerExists ? (
                        <Button disableElevation onClick={() => handleCreatePortal()}>
                          {t("preferences.planAndBillings.invoice")}
                        </Button>
                      ) : (
                        <Typography variant="body1">{t("preferences.planAndBillings.noInvoice")}</Typography>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </CustomSection>
            </Paper>
          </Fade>
        )}
      </Box>
    </>
  );
};

export default PlanAndBillings;
