import FolderIcon from "@mui/icons-material/Folder";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, Fade, InputAdornment, Paper, TextField, Typography, useTheme } from "@mui/material";
import axios from "axios";
import dagre from "dagre";
import Cookies from "js-cookie";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Connection,
  Controls,
  Edge,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
  MiniMap,
  Node,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  Position,
  ReactFlowInstance,
  reconnectEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { uiHeight } from "../../utils/uiHeight";
import Generating from "../TextFile/components/Generating";
import CustomNode from "./components/CustomNode";
import DownloadFlow from "./components/DownloadFlow";
import MindmapMenu from "./components/MindmapMenu";
import Toolbar from "./components/Toolbar";
import { useMindMap } from "../../context/MindmapContext";

const nodeTypes = { custom: CustomNode };

const MindMapEditor = () => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");
  const { mindMapUuid } = useParams();
  const [status, setStatus] = useState<number>();
  const navigate = useCustomNavigate();
  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);
  const { setAlert } = useAlert();
  const theme = useTheme();
  const reactFlowWrapper = useRef<HTMLDivElement | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance | null>(null);
  const [noPosition, setNoPosition] = useState(false);
  const snapGrid = [10, 10];
  const [isDownloadFlowVisible, setIsDownloadFlowVisible] = useState(false);
  const edgeUpdateSuccessful = useRef(true);
  const [layoutDirection] = useState("LR");
  const [edgeType, setEdgeType] = useState("default");
  const [animation, setAnimation] = useState(false);
  const mindMapContext = useMindMap();

  const nodeDefaults = useMemo(
    // ノードのデフォルトの配置位置を定義
    () => ({
      sourcePosition: Position.Right,
      targetPosition: Position.Left,
    }),
    []
  );

  const onLoad = useCallback((instance: ReactFlowInstance) => {
    // ReactFlowインスタンスをセットし、全体を表示にフィットさせる
    setReactFlowInstance(instance);
    instance?.fitView();
  }, []);

  useEffect(() => {
    // ノードに位置が設定されていない場合、自動レイアウトを実行し、初期保存を行う
    if (noPosition && nodes[0]?.data.size) {
      const updateNodes = autoLayout();
      initialSave(updateNodes);
      setNoPosition(false);
    }
  }, [noPosition, nodes]);

  useEffect(() => {
    // ノード削除アクションがトリガーされた場合にノードを削除
    if (mindMapContext.actionTrigger.node_action === "delete") {
      onDelete(mindMapContext.actionTrigger.id);
    }
  }, [mindMapContext.actionTrigger.triggerFetch]);

  const getMindMap = useCallback(async () => {
    // MindMapデータをAPIから取得し、状態を更新する
    try {
      const res = await axios.get(`/api/v1/mind-map/${mindMapUuid}`);
      const { data } = res;

      if (data) {
        setTitle(data.title);
        setStatus(data.status);

        if (data.nodes.length === 0) {
          // ノードが存在しない場合、デフォルトのノードを設定
          const defaultNode = {
            id: "1",
            type: "custom",
            position: { x: 0, y: 0 },
            data: {
              label: "Hello, World!",
              color: "",
              size: { width: 200, height: 100 },
              links: [],
              images: [],
            },
            width: 200,
            height: 100,
            ...nodeDefaults,
          };
          setNodes([defaultNode]);
        } else {
          // ノードが存在する場合、ノードとエッジを設定し、位置情報がない場合は自動レイアウトを行う
          setNodes(data.nodes);
          if (data.nodes.length > 0 && !data.nodes[0].position.x && !data.nodes[0].position.y) {
            setNoPosition(true);
          }
        }
        setEdges(data.edges);
        setEdgeType(data.edges[0]?.type || "default");
        setAnimation(data.edges[0]?.animated || false);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  }, [mindMapUuid, nodeDefaults]);

  useEffect(() => {
    // MindMapデータの取得を初期化する
    getMindMap();
  }, [mindMapUuid, getMindMap]);

  const onNodesChange: OnNodesChange = useCallback(
    // ノードの変更を適用
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    // エッジの変更を適用
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  const onConnect: OnConnect = useCallback(
    // ノード間の接続を処理
    (connection) => setEdges((eds) => addEdge(connection, eds)),
    [setEdges]
  );

  const onNodesDelete = useCallback(
    // ノードを削除したときに、それに関連するエッジを処理
    (deleted: Node[]) => {
      setEdges((prevEdges) =>
        deleted.reduce((acc, node) => {
          const incomers = getIncomers(node, nodes, prevEdges);
          const outgoes = getOutgoers(node, nodes, prevEdges);
          const connectedEdges = getConnectedEdges([node], prevEdges);

          const remainingEdges = acc.filter((edge) => !connectedEdges.includes(edge));

          // 親子関係がある場合に新しいエッジを作成
          const createdEdges = incomers.flatMap(({ id: source }) =>
            outgoes.map(({ id: target }) => ({
              id: `${source}->${target}`,
              source,
              target,
              animated: animation,
              type: edgeType,
            }))
          );

          return [...remainingEdges, ...createdEdges];
        }, prevEdges)
      );
    },
    [nodes, edges]
  );

  const onEdgeUpdateStart = useCallback(() => {
    // エッジの更新開始をフラグ
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback(
    // エッジの更新を処理
    (oldEdge: Edge, newConnection: Connection) => {
      edgeUpdateSuccessful.current = true;
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    },
    []
  );

  const onEdgeUpdateEnd = useCallback(
    // エッジの更新が完了した後の処理を行う
    (_, edge: Edge) => {
      if (!edgeUpdateSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }
    },
    []
  );

  const onDelete = (id: string) => {
    // ノードを削除し、関連するエッジを処理
    if (nodes.length === 1) {
      setAlert("error", t("mindmap.alert.notDeletedNode"));
      return;
    }

    const nodeToDelete = nodes.find((node) => node.id === id);
    if (!nodeToDelete) return;

    const incomers = getIncomers(nodeToDelete, nodes, edges);
    const outgoers = getOutgoers(nodeToDelete, nodes, edges);

    // 削除するノードに関連するエッジを削除
    const updatedEdges = edges.filter((edge) => edge.source !== id && edge.target !== id);

    // 親子関係がある場合に新しいエッジを作成
    const createdEdges = incomers.flatMap(({ id: source }) =>
      outgoers.map(({ id: target }) => ({
        id: `${source}->${target}`,
        source,
        target,
        animated: animation,
        type: edgeType,
      }))
    );

    // 新しいエッジを追加してエッジ状態を更新
    setEdges([...updatedEdges, ...createdEdges]);

    // ノードを削除
    setNodes(nodes.filter((node) => node.id !== id));
  };

  useEffect(() => {
    // MindMapの生成中に5秒毎にデータを取得
    if (status !== 3) return;

    const timer = setInterval(() => {
      getMindMap();
    }, 5000);

    return () => clearInterval(timer);
  }, [status]);

  const handleSave = async () => {
    // MindMapを保存する関数
    try {
      const url = `/api/v1/mind-map/${mindMapUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = { "X-CSRFToken": csrftoken! };
      const data = { title, nodes, edges };
      const res = await axios.patch(url, data, { headers });

      if (res.data) {
        setAlert("success", t("file.saveFile"));
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const initialSave = async (initialNodes) => {
    // 初回保存し、ノードの位置を更新する関数
    try {
      const url = `/api/v1/mind-map/${mindMapUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = { "X-CSRFToken": csrftoken! };
      const data = { title, nodes: initialNodes, edges };
      await axios.patch(url, data, { headers });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const autoLayout = () => {
    // ノードとエッジを基に自動レイアウトを行う関数
    const g = new dagre.graphlib.Graph();
    g.setGraph({ rankdir: layoutDirection, ranksep: 100, nodesep: 20 });
    g.setDefaultEdgeLabel(() => ({}));

    nodes.forEach((node) => {
      const { width, height } = node?.data?.size;
      g.setNode(node.id, { width, height });
    });

    edges.forEach((edge) => {
      g.setEdge(edge.source, edge.target);
    });

    try {
      dagre.layout(g);

      const updatedNodes = nodes.map((node) => {
        const nodeInfo = g.node(node.id);
        return {
          ...node,
          position: {
            x: nodeInfo.x - nodeInfo.width / 2,
            y: nodeInfo.y - nodeInfo.height / 2,
          },
        };
      });

      setNodes(updatedNodes);

      setTimeout(() => {
        reactFlowInstance?.fitView();
        setAlert("success", t("mindmap.alert.autoLayout"));
      }, 200);

      return updatedNodes;
    } catch (error) {
      reactFlowInstance?.fitView();
      console.error("Error during auto layout:", error);
      setAlert("error", t("mindmap.alert.autoLayoutError"));
    }
  };

  return (
    <>
      <Meta title={t("mindmap.editor")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <DownloadFlow nodes={nodes} edges={edges} isVisible={isDownloadFlowVisible} nodeTypes={nodeTypes} />
      <Fade in={true} timeout={1000}>
        <Box>
          {status !== 3 ? (
            <Box component="div">
              <DrawerHeader />
              <Box sx={{ maxWidth: "100%!important", mx: { xs: 0, md: 2 } }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mb: 1,
                    whiteSpace: "nowrap",
                    gap: 1,
                  }}
                >
                  <TextField
                    variant="standard"
                    value={title}
                    fullWidth
                    onChange={(e) => setTitle(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Typography variant={"subtitle1"} sx={{ mr: 1 }} color={"primary"}>
                            Title:
                          </Typography>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", sm: "row" },
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: { xs: "none", sm: "flex" },
                        alignItems: "center",
                      }}
                    >
                      <Button
                        disableElevation
                        onClick={() => navigate("/library/mind-maps")}
                        variant={"outlined"}
                        sx={{ minWidth: "140px" }}
                        startIcon={<FolderIcon fontSize={"small"} />}
                      >
                        <Typography variant={"button"}>{t("mindmap.library")}</Typography>
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                        sx={{ ml: 1, alignItems: "center", display: "flex" }}
                        startIcon={<SaveIcon fontSize={"small"} />}
                      >
                        {t("common.save")}
                      </Button>
                    </Box>
                    <MindmapMenu
                      handleSave={handleSave}
                      title={title}
                      setIsDownloadFlowVisible={setIsDownloadFlowVisible}
                      nodes={nodes}
                    />
                  </Box>
                </Box>
                <Paper
                  elevation={theme.palette.mode === "dark" ? 1 : 0}
                  sx={{
                    width: "100%",
                    height: {
                      xs: "100vh",
                      md: `calc(${uiHeight()} - 64px)`,
                      position: "relative",
                    },
                    background: "background.paper",
                  }}
                >
                  <Toolbar
                    autoLayout={autoLayout}
                    nodes={nodes}
                    setNodes={setNodes}
                    edges={edges}
                    setEdges={setEdges}
                    nodeDefaults={nodeDefaults}
                    edgeType={edgeType}
                    setEdgeType={setEdgeType}
                    animation={animation}
                    setAnimation={setAnimation}
                  />
                  <div ref={reactFlowWrapper} style={{ width: "100%", height: "100%" }}>
                    <ReactFlow
                      id="reactflow"
                      nodes={nodes}
                      nodeTypes={nodeTypes}
                      edges={edges}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onEdgeUpdate={onEdgeUpdate}
                      onNodesDelete={onNodesDelete}
                      onEdgeUpdateStart={onEdgeUpdateStart}
                      onEdgeUpdateEnd={onEdgeUpdateEnd}
                      onConnect={onConnect}
                      onInit={onLoad}
                      fitView
                      maxZoom={1.5}
                      minZoom={0.1}
                      snapToGrid={true}
                      snapGrid={[snapGrid[0], snapGrid[1]]}
                      proOptions={{ hideAttribution: true }}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <MiniMap nodeStrokeWidth={3} />
                      <Background />
                      <Controls />
                    </ReactFlow>
                  </div>
                </Paper>
              </Box>
            </Box>
          ) : (
            <Generating />
          )}
        </Box>
      </Fade>
    </>
  );
};

export default MindMapEditor;
