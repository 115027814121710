import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Fade, Grid, Hidden, Paper, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import SearchBox from "./components/SearchBox";
import UserBox from "./components/UserBox";
import AnswerBox from "./components/AnswerBox";
import InputBox from "./components/InputBox";
import { setProcessing } from "../../redux/slices/browsingSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import UrlBox from "./components/UrlBox";
import Top from "./components/Top";
import Dialogs from "./components/Dialogs";
import Typography from "@mui/material/Typography";
import GoogleIcon from "@mui/icons-material/Google";
import { setCreditTrigger } from "../../redux/slices/triggerSlice";
import { useCheckCredit } from "../../hooks/useCreditCheck";

import { scrollToBottom } from "../../utils/utils";
import Meta from "../../components/common/Meta";

const Browsing: React.FC = () => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const { workspaceUuid } = useParams();
  const browsingMode = useSelector((state: RootState) => state.browsing.browsingMode[workspaceUuid!] || false);
  const [chatResponse, setChatResponse] = useState("");
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [sidebarData, setSidebarData] = useState<any>();
  const [processingUuid, setProcessingUuid] = useState(null);
  const processing = useSelector((state: RootState) => state.browsing.processing[workspaceUuid!]);
  const dispatch = useDispatch();
  const streamControllerRef = useRef<ReadableStreamDefaultController | null>(null);
  const theme = useTheme();

  // 会話履歴を取得
  const makeHistory = async () => {
    try {
      const res = await axios.get("/api/v1/workspace/" + workspaceUuid + "/history");
      if (res.data) {
        setHistoryData(res.data.history);
        setSidebarData(res.data.sidebar && res.data.sidebar[0]);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  function makeHistoryElement(uuid: string, type: number, content: any) {
    if (type === 1) {
      return <UserBox id={uuid} content={content} sendMessage={sendMessage} />;
    }
    if (type === 3) {
      return <AnswerBox id={uuid} processingId={processingUuid} content={content} />;
    }
    return null;
  }

  function makeSidebarElement(type?: number, content?: any) {
    if (type === 2 && browsingMode) {
      const parsedContent = content ? JSON.parse(content) : "";
      return <SearchBox content={parsedContent} />;
    }
    if (type === 4 && browsingMode) {
      const parsedContent = content ? JSON.parse(content) : "";
      return <UrlBox content={parsedContent} />;
    } else {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" color="textSecondary" mb={2} fontWeight={800} sx={{ maxWidth: 400 }}>
            {t("browsing.sidebar.preSearchTitle")}
          </Typography>
          <GoogleIcon sx={{ fontSize: 36, mb: 2, width: 300 }} color={"success"} />
          <Typography variant="body2" color="textSecondary" sx={{ maxWidth: 400 }}>
            {t("browsing.sidebar.preSearchDescription")}
          </Typography>
        </Box>
      );
    }
    return null;
  }

  useEffect(() => {
    setSidebarData({ type: 0, content: "" });
    makeHistory();
    setChatResponse("");
  }, [workspaceUuid]);

  const stopStream = () => {
    // クライアント側のストリーム停止
    try {
      if (streamControllerRef.current && processing) {
        // streamControllerRef.current.close();
      }
      dispatch(setProcessing({ workspaceUuid: workspaceUuid!, processing: false }));
      setProcessingUuid(null);
      console.log("Stream manually stopped");
      dispatch(setCreditTrigger(true));
    } catch (e) {
      console.log(e);
    }
  };

  // メッセージの送信
  const sendMessage = async (message: string, parentId: string | null = null) => {
    try {
      if (!(await checkCredit())) return;

      // historyからparentIdを含む新しい会話履歴を全て削除
      if (parentId) {
        setHistoryData((prevData) => {
          // parentIdに一致する要素のインデックスを見つける
          const index = prevData.findIndex((item) => item.uuid === parentId);

          // 見つかったインデックスより前の要素のみを保持する
          const newHistoryData = index === -1 ? prevData : prevData.slice(0, index + 1);

          // 最後の配列を書き換える
          newHistoryData[newHistoryData.length - 1] = { uuid: "userMessage", type: 1, content: message };

          return newHistoryData;
        });
      } else {
        // 編集ではない場合、会話履歴に追加
        setHistoryData((prevData) => [...prevData, { uuid: "userMessage", type: 1, content: message }]);
      }

      dispatch(setProcessing({ workspaceUuid: workspaceUuid!, processing: true }));
      scrollToBottom(200);
      let newAIMessage = true;
      setChatResponse("");

      const url = "/api/v1/workspace/" + workspaceUuid + "/create";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      if (browsingMode) {
        const latestSearch = {
          type: 2,
          content: "",
        };
        setSidebarData(latestSearch);
      }

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ query: message, browsing_mode: browsingMode, parent_id: parentId }),
      });

      if (!response.ok) {
        throw new Error("ReadableStream not supported in this browser.");
      }

      if (response.body !== null) {
        let res = "";
        const reader = response.body.getReader();
        new ReadableStream({
          start(controller) {
            streamControllerRef.current = controller;
            console.log("Stream start");

            function push() {
              reader.read().then(({ done, value }) => {
                if (done) {
                  controller.close();
                  return;
                }
                const text = new TextDecoder().decode(value);

                // \n\n でデータを分割し、各部分を解析する
                const jsonParts = text.split("\n\n");
                for (const part of jsonParts) {
                  if (part.trim() === "") continue; // 空のデータをスキップ
                  const parsedData = JSON.parse(part);
                  // メッセージ追加
                  if (parsedData["data"] && parsedData["data"]["type"] === 1) {
                    setHistoryData((prevData) => {
                      return prevData.map((item) => {
                        if (item.uuid === "userMessage") {
                          item.uuid = parsedData["data"]["uuid"];
                        }
                        return item;
                      });
                    });
                  }
                  // 検索結果追加
                  if (parsedData["data"] && parsedData["data"]["type"] === 2) {
                    if (parsedData["data"]["search_results"]) {
                      setSidebarData({
                        type: 2,
                        content: JSON.stringify(parsedData["data"]["search_results"]),
                      });
                    } else {
                      setSidebarData({
                        type: 2,
                        content: JSON.stringify({}),
                      });
                    }
                  }
                  // AI返答追加
                  if (parsedData["data"] && parsedData["data"]["type"] === 3) {
                    res = res + parsedData["data"]["content"];
                    setChatResponse((prevData) => prevData + parsedData["data"]["content"]);
                    const uuid = parsedData["data"]["uuid"];
                    setProcessingUuid(uuid);

                    if (newAIMessage) {
                      const latestAIMessage = {
                        uuid: uuid,
                        type: 3,
                        content: chatResponse,
                      };
                      setHistoryData((prevHistoryData) => [...prevHistoryData, latestAIMessage]);
                      newAIMessage = false;
                    } else {
                      updateHistoryItemContent(uuid, res);
                    }
                  }
                  // URL返答追加
                  if (parsedData["data"] && parsedData["data"]["type"] === 4) {
                    setSidebarData({
                      type: 4,
                      content: JSON.stringify(parsedData["data"]["url_contents"]),
                    });
                  }
                  // メッセージの終了
                  if (parsedData["data"] && parsedData["data"]["end"]) {
                    console.log("Stream complete");
                    setProcessingUuid(null);
                    dispatch(setProcessing({ workspaceUuid: workspaceUuid!, processing: false }));
                    controller.close();
                    setTimeout(() => {
                      dispatch(setCreditTrigger(true));
                    }, 1000);
                    return;
                  }
                }
                controller.enqueue(value);
                push();
              });
            }

            push();
          },
        });
      }
    } catch (error) {
      dispatch(setProcessing({ workspaceUuid: workspaceUuid!, processing: false }));
      setChatResponse(t("browsingAI:chatbot.error"));
      makeHistory();
      console.log("An error occurred while sending the message:", error);
    }
  };

  // HistoryUpdate
  const updateHistoryItemContent = (uuid: string, newContent: any) => {
    setHistoryData((prevData) => {
      return prevData.map((item) => {
        if (item.uuid === uuid) {
          return {
            ...item,
            content: newContent,
          };
        }
        return item;
      });
    });
  };

  const historyRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Meta title={t("browsing.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container
        maxWidth={historyData.length > 0 && browsingMode ? "lg" : "md"}
        sx={{ transition: "all 0.3s ease-in-out" }}
      >
        {/* input message */}
        <Top />
        {historyData.length === 0 && (
          <InputBox
            sendMessage={sendMessage}
            historyData={historyData}
            stopStreamController={stopStream}
            historyRef={undefined}
          />
        )}

        <Box sx={{ width: "100%", transition: "all 0.3s ease-in-out" }} maxWidth={1152}>
          <Dialogs historyExists={historyData.length > 0} setHistoryData={setHistoryData} />
        </Box>

        {/* Histories */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={browsingMode ? 8 : 12} width={"100%"}>
            <Box ref={historyRef}>
              {historyData.map((historyItem, index) => (
                <React.Fragment key={historyItem.uuid}>
                  <Fade in={true} timeout={1000}>
                    <div>{makeHistoryElement(historyItem.uuid, historyItem.type, historyItem.content)}</div>
                  </Fade>
                </React.Fragment>
              ))}
              {historyData.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <Box sx={{ display: "flex", justifyContent: "right", width: "100%" }}>
                    <Typography variant="caption" color="textSecondary" textAlign={"left"}>
                      {t("browsing.caution")}
                    </Typography>
                  </Box>
                  <Box sx={{ height: 146 }} />
                  <InputBox
                    sendMessage={sendMessage}
                    historyRef={historyRef}
                    historyData={historyData}
                    stopStreamController={stopStream}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          {/*  サイドバー  */}
          <Hidden smDown>
            {browsingMode && (
              <Fade in={browsingMode} timeout={1000}>
                <Grid item xs={12} md={4}>
                  {historyData.length > 0 && (
                    <Box
                      sx={{
                        position: { xs: "static", sm: "sticky" },
                        top: 56,
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                        width: "100%",
                        mb: { xs: 19, sm: 0 },
                      }}
                    >
                      <Paper
                        elevation={theme.palette.mode === "dark" ? 1 : 0}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: 4,
                          height: `calc(100vh - 64px)`,
                          backgroundColor: theme.palette.mode === "dark" ? "background.paper" : "#fff",
                          transition: "all 0.3s ease-in-out",
                        }}
                      >
                        {makeSidebarElement(sidebarData?.type, sidebarData?.content)}
                      </Paper>
                    </Box>
                  )}
                </Grid>
              </Fade>
            )}
          </Hidden>
        </Grid>
      </Container>
    </>
  );
};

export default Browsing;
