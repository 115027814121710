// 文字の切り抜き
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

type MaskedTextProps = {
  imageurl: string;
};
export const MaskedText = styled(Typography)<MaskedTextProps>(({imageurl, theme}) => ({
        backgroundClip: 'text',
        webkitBackgroundClip: 'text',
        color: 'transparent',
        backgroundImage: `url(${imageurl})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundAttachment: 'fixed',
    })
);