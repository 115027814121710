const template = {
  title: "Tenak AI Templates",
  description: "Powerful and quick templates provided by Tenak AI",
  rankings: "TOP 5 Templates",
  all: "All Templates",
  label: {
    promptGenerator: {
      title: "Prompt Generator",
      description:
        "Generate optimal prompts just by entering your goal. The generated prompts can be used by pasting them into browsing.",
      arg1: "Goal",
      arg1Placeholder: "How to become a programmer",
    },
    imagePromptGenerator: {
      title: "Image Prompt Generator",
      description:
        "Generate optimal prompts just by entering your theme. The generated prompts can be used in STUDIO JUNO. (SD3 or higher, DALL-E3, FLUX.1 recommended)",
      arg1: "Theme",
      arg1Placeholder: "e.g. Magic Forest",
    },
    simpleExplanation: {
      title: "Simplify Complex Sentences",
      description:
        "Explain complex sentences containing technical terms in a way that even middle school students can understand.",
      arg1: "Sentence",
      arg1Placeholder: "Enter technical sentences, etc.",
    },
    emailCreation: {
      title: "Email",
      description: "Create email content tailored to the user.",
      arg1: "Recipient",
      arg1Placeholder: "Customer",
      arg2: "Email Content",
      arg2Placeholder: "Want to apologize for an order mistake",
    },
    rephrase: {
      title: "Rewriting Text",
      description: "Rewrite text to create a new version from a different perspective.",
      arg1: "Text",
      arg1Placeholder: "Input text",
      arg2: "Rewrite Style",
      arg2Placeholder: "Persuasive tone. ",
    },
    translation: {
      title: "AI Translation",
      description:
        "Unlike traditional translation, it understands the context and translates sentences into other languages, accommodating free translation.",
      arg1: "Translate to",
      arg1Placeholder: "Japanese",
      arg2: "Text",
      arg2Placeholder: "Input text",
    },
    ideaGeneration: {
      title: "Idea Generation (SCAMPER)",
      description: "Generate diverse ideas free from preconceived notions.",
      arg1: "Ideas for",
      arg1Placeholder: "Example: Monetizing a tool that generates text using AI",
    },
    copyWriting: {
      title: "Copywriting",
      description: "Create copywriting for products and services.",
      arg1: "Product/Service Overview",
      arg1Placeholder: "e.g., Monetizing a tool that generates text using AI",
    },
    taskAssignment: {
      title: "Task assignment and planning",
      description: "Task assignment and planning for your objective.",
      arg1: "Objective",
      arg1Placeholder: "e.g. Launching a new product",
    },
    landingPage: {
      title: "Landing Page Configuration",
      description: "Create a structure for a landing page.",
      arg1: "Target Audience",
      arg2: "Product/Service Name",
      arg3: "Product/Service Overview",
      arg1Placeholder: "e.g. 20-30 year old writers",
      arg2Placeholder: "e.g. Tenak AI",
      arg3Placeholder: "e.g. A tool for generating text with AI",
    },
    landingPageProblemSolution: {
      title: "Landing Page Problem-Solving Section",
      description: "Create a LP section that addresses what issues are solved and how they are solved for the product.",
      arg1: "Target Audience",
      arg2: "Product/Service Overview",
      arg1Placeholder: "e.g. Writers in their 20s to 30s",
      arg2Placeholder: "e.g. Tenak AI is a text generation tool utilizing AI.",
    },
    landingPageFAQ: {
      title: "Landing Page\nFAQ Section",
      description: "Create an FAQ section for the product's LP.",
      arg1: "Target Audience",
      arg2: "Product Overview",
      arg1Placeholder: "e.g. 20s-30s Writers",
      arg2Placeholder: "e.g. A tool that generates text with AI",
    },
    landingPageReview: {
      title: "Landing Page\nUser Review\nSection",
      description: "Create a user review section for the product's LP.",
      arg1: "Target Audience",
      arg2: "Product Overview",
      arg1Placeholder: "e.g. 20s-30s Writers",
      arg2Placeholder: "e.g. A tool that generates text with AI",
    },
    persona: {
      title: "Persona Customer Journey",
      description: "Create personas and customer journeys.",
      arg1: "Product/Service Overview",
      arg2: "Objective",
      arg1Placeholder: "e.g. Tenak AI is a text generation tool utilizing AI.",
      arg2Placeholder: "e.g. Subscription registration by freelance writers",
    },
    findUserProblem: {
      title: "Finding User Problems",
      description: "Find user problems and create solutions.",
      arg1: "Product/Service Overview",
      arg1Placeholder: "e.g. A writing tool called Tenak AI",
    },
    perceptionFlowModel: {
      title: "Perception Flow Marketing",
      description: "Building a marketing strategy using the perception flow model.",
      arg1: "Target audience and current situation",
      arg2: "Product/Service Overview",
      arg1Placeholder: "e.g. Creators who are using competitor products but are not satisfied.",
      arg2Placeholder: "e.g. A writing tool called Tenak AI",
    },
    problemSolving: {
      title: "Problem Solving",
      description: "Clarification of the problem and steps towards its resolution.",
      arg1: "Target Audience",
      arg2: "Problem",
      arg1Placeholder: "e.g. 20-30 year old writers",
      arg2Placeholder: "e.g. Troublesome to write articles",
    },
    goal: {
      title: "Process to Dreams, Goals",
      description: "Concretely create the necessary process for dreams and goals.",
      arg1: "Target Audience",
      arg2: "Dream/Goal",
      arg1Placeholder: "e.g. 20-30 year old programming beginners",
      arg2Placeholder: "e.g. Want to work at Google",
    },
    ideaReview: {
      title: "Idea Review by Experts",
      description: "Various experts review ideas from different perspectives.",
      arg1: "Idea",
      arg1Placeholder: "e.g., Starting a YouTube channel to introduce movies",
    },
    swotAnalysis: {
      id: "swotAnalysis",
      title: "SWOT Analysis",
      description: "Create a SWOT analysis (Strengths, Weaknesses, Opportunities, Threats) from the given text.",
      arg1: "Target",
      arg2: "Text",
      arg1Placeholder: "e.g., Writers in their 20s-30s",
      arg2Placeholder: "e.g., A writing tool called Tenak AI developed by Diocode Inc.",
    },
    prepMethod: {
      id: "prepMethod",
      title: "Text Creation Using the PREP Method",
      description: "Create a text using the PREP method on the given theme. Useful for SNS posts and blog articles.",
      arg1: "Target",
      arg2: "Theme",
      arg1Placeholder: "e.g., Writers in their 20s-30s",
      arg2Placeholder: "e.g., Introduction of a new writing tool",
    },
    brandName: {
      id: "brandName",
      title: "Brand Name",
      description: "Come up with an attractive and memorable brand name based on the provided information.",
      arg1: "Target",
      arg2: "Provided Information",
      arg1Placeholder: "e.g., Writers in their 20s-30s",
      arg2Placeholder: "e.g., Introduction of a new writing tool",
    },
    presentationOutline: {
      id: "presentationOutline",
      title: "Presentation Outline",
      description:
        "Create an effective presentation outline based on the given text. Useful for PowerPoint or Keynote.",
      arg1: "Target",
      arg2: "Text",
      arg1Placeholder: "e.g., Writers in their 20s-30s",
      arg2Placeholder: "e.g., Introduction of a new writing tool",
    },
    pressRelease: {
      title: "Press Release",
      description: "Create press release articles for products and services.",
      arg1: "Product Overview",
      arg1Placeholder: "Example: A writing tool called Tenak AI developed by Diocode Co., Ltd.",
    },
    marketingStrategy: {
      title: "Marketing Strategy",
      description: "Create a marketing strategy for your product or service.",
      arg1: "Target Audience",
      arg1Placeholder: "e.g., Writers in their 20s to 30s",
      arg2: "Product/Service Overview",
      arg2Placeholder: "e.g., Tenak AI, a writing tool developed by Diocode Inc.",
    },
    dailyReport: {
      title: "Daily Report",
      description: "Create a daily report.",
      arg1: "Work Content",
      arg2: "Template",
      arg1Placeholder: "e.g., Development of new features",
      arg2Placeholder: "Enter a template for the daily report if you have one.",
    },
    conversation: {
      title: "Convert Text to Conversation",
      description: "Create a conversation script.",
      arg1: "Text",
      arg1Placeholder: "Enter the text you want to convert to conversation.",
    },
    //////////////////////////
    // Social Media
    //////////////////////////
    snsAd: {
      title: "SNS Advertisement",
      description: "Create an SNS advertisement.",
      arg1: "Target Audience",
      arg2: "Product/Service Overview",
      arg3: "Ad Objective",
      arg1Placeholder: "e.g., Instagram users, 20-30 year old writers",
      arg2Placeholder: "e.g., A writing tool called Tenak AI developed by Diocode Inc.",
      arg3Placeholder: "e.g., User acquisition",
    },
    socialMediaStrategy: {
      title: "Social Media Strategy",
      description: "Create a strategy for social media.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg3: "Purpose of SNS",
      arg1Placeholder: "e.g., Writers in their 20s to 30s",
      arg2Placeholder: "e.g., Introduction of writing tools on Instagram",
      arg3Placeholder: "e.g., User acquisition",
    },
    youtubeComposition: {
      title: "YouTube Video Composition",
      description: "Produce a composition for approximately 10-minute YouTube videos.",
      arg1: "Target Audience",
      arg2: "Channel Overview",
      arg3: "Desired Video",
      arg1Placeholder: "e.g. Photographers",
      arg2Placeholder: "e.g. Explaining camera explanations and retouching methods",
      arg3Placeholder: "e.g. Performance and review of the new camera xxx",
    },
    youtubeShorts: {
      title: "YouTube Shorts Configuration",
      description: "Create a layout for YouTube videos about 30 seconds in length.",
      arg1: "Target Audience",
      arg2: "Channel Summary",
      arg1Placeholder: "e.g. Photographer",
      arg2Placeholder: "e.g. A channel that mainly discusses camera reviews",
    },
    youtubeTitleDescription: {
      title: "YouTube Video Title \nand Description",
      description: "Create titles and descriptions for YouTube videos.",
      arg1: "Target Audience",
      arg2: "Channel Overview",
      arg3: "Video Content",
      arg1Placeholder: "e.g. Photographers",
      arg2Placeholder: "e.g. A channel that mainly discusses camera reviews",
      arg3Placeholder: "e.g. Review of the new camera xxx",
    },
    instagramPost: {
      title: "Instagram Post Content",
      description: "Create post ideas, scripts, and tags for posting on Instagram.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
    instagramReel: {
      title: "Instagram Reels Configuration",
      description: "Create reel ideas, scripts, and tags for posting on Instagram.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
    instagramStory: {
      title: "Instagram Story",
      description: "Create story ideas and scripts for Instagram.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Illustrators in their 20s-30s",
      arg2Placeholder: "e.g. Posting illustrations only",
    },
    instagramCaption: {
      title: "Instagram Caption",
      description: "Create captions (descriptions) for posts on Instagram.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg3: "About Photo",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon",
      arg3Placeholder: "e.g. A photo of a new hair color",
    },
    instagramProfile: {
      title: "Instagram Profile",
      description: "Create a profile for an Instagram account.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
    tiktokIdeas: {
      title: "TikTok\nIdeas",
      description: "Generate ideas for posting on TikTok.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    tiktokComposition: {
      title: "TikTok\nVideo Structure",
      description: "Create the structure for TikTok videos.",
      arg1: "Target Audience",
      arg2: "Channel Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    tiktokCaption: {
      title: "TikTok\nCaption",
      description: "Create captions (descriptions) for TikTok posts.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    tiktokProfile: {
      title: "TikTok\nProfile",
      description: "Create a profile for your TikTok account.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    threadsPost: {
      title: "Threads\nPost Content",
      description: "Generate content for posting on Threads.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    threadsMultiPost: {
      title: "Threads\nPost Content\n(Thread Format)",
      description:
        "Generate content for posting on Threads. Due to character limits, the content is formatted as a thread.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
      arg3: "Post Overview",
      arg3Placeholder: "e.g., New product introduction",
    },
    threadsProfile: {
      title: "Threads\nProfile",
      description: "Create a profile for your Threads account.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    xPost: {
      title: "X Post Content",
      description: "Generate content for posting on X (Twitter).",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    xMultiPost: {
      title: "X Post Content\n(Thread Format)",
      description:
        "Generate content for posting on X (Twitter). Due to character limits, the content is formatted as a thread.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
      arg3: "Post Overview",
      arg3Placeholder: "e.g., New product introduction",
    },
    xProfile: {
      title: "X Profile",
      description: "Create a profile for your X (Twitter) account.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g., Women in their 20s to 30s",
      arg2Placeholder: "e.g., Salon Owners",
    },
    facebookPost: {
      title: "Facebook Post Content",
      description: "Create ideas, scripts, and tags for posting on Facebook.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
    facebookStory: {
      title: "Facebook Stories",
      description: "Create story ideas and scripts for Facebook.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Illustrators in their 20s-30s",
      arg2Placeholder: "e.g. Posting illustrations only",
    },
    facebookReel: {
      title: "Facebook Reels Configuration",
      description: "Create reel ideas, scripts, and tags for posting on Facebook.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
    facebookCaption: {
      title: "Facebook Caption",
      description: "Create captions (descriptions) for posts on Facebook.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
    facebookProfile: {
      title: "Facebook Profile",
      description: "Create a profile for a Facebook account.",
      arg1: "Target Audience",
      arg2: "Account Overview",
      arg1Placeholder: "e.g. Women in their 20s-30s",
      arg2Placeholder: "e.g. Salon owners",
    },
  },
  inputDescription: "Please enter the necessary information below.",
  url: "Reference URL (Supports YouTube.)",
  urlPlaceholder: "e.g. https://www.example.com",
  result: "TEXT",
  createFile: "Save to Library",
  additionalInstructions: "additional instructions",
  additionalInstructionsPlaceholder: "Please enter any guidelines or notes to follow.",
};
export default template;
