import InfoIcon from "@mui/icons-material/Info";
import { Divider, IconButton, Paper } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const AboutCredit = () => {
  const { t } = useTranslation();

  return (
    <Paper elevation={1} sx={{ py: 8, boxShadow: 0, borderRadius: 0 }}>
      {/* クレジットについて */}
      <Container maxWidth="md">
        <Box>
          <Typography variant={"h4"} gutterBottom>
            {t("pricing.aboutCredit.title")}
          </Typography>
          <Typography paragraph sx={{ my: 4 }}>
            {t("pricing.aboutCredit.description")}
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2} justifyContent={"center"} px={1}>
          <Grid item xs={3} md={2}>
            <Typography>{t("pricing.aboutCredit.text.title")}</Typography>
          </Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.text.description")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>
              0.1 (1K tokens)
              <Tooltip title={t("pricing.aboutCredit.text.tooltip")}>
                <IconButton size={"small"} sx={{ ml: 1 }}>
                  <InfoIcon fontSize={"small"} />
                </IconButton>
              </Tooltip>
            </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        {/* 画像生成 */}
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}>
            <Typography>{t("pricing.aboutCredit.image.title")}</Typography>
          </Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description1")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>15 / req</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description2")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>4 / req</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description3")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>8.2 / req</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description4")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>10 / req</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description5")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>6.3 / img</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description6")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>3.2 / img</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description7")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>0.4 / img</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description8")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>4.4 / img</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description9")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>6.3 / img</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description10")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>10 / img</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description20")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>0.25 / 1MP </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1} mb={2}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description21")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>2.5 / 1MP </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.image.description22")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>2.8 / 1MP </Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        {/* 音声生成 */}
        <Grid container spacing={2} justifyContent={"center"} px={1}>
          <Grid item xs={3} md={2}>
            <Typography>{t("pricing.aboutCredit.voice.title")}</Typography>
          </Grid>
          <Grid item xs={7} md={7} mb={2}>
            <Typography>{t("pricing.aboutCredit.voice.description1")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>2</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"} px={1}>
          <Grid item xs={3} md={2}></Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.voice.description2")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>3.75</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        {/* Mindmap生成 */}
        <Grid container spacing={2} justifyContent={"center"} px={1}>
          <Grid item xs={3} md={2}>
            <Typography>{t("pricing.aboutCredit.mindmap.title")}</Typography>
          </Grid>
          <Grid item xs={7} md={7}>
            <Typography>{t("pricing.aboutCredit.mindmap.description")}</Typography>
          </Grid>
          <Grid item xs={2} md={3}>
            <Typography textAlign={"right"}>0</Typography>
          </Grid>
        </Grid>

        <Divider sx={{ mt: 2, mb: 1 }} />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Typography variant={"caption"}>{t("pricing.aboutCredit.annotation")}</Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export default AboutCredit;
