import React from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Box from "@mui/material/Box";

const Top = () => {
  return (
    <>
      {/* セクション1*/}
      <ColumnCenteredBox component={"section"} sx={{ width: "100%" }}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: { xs: 400, md: "100vh" },
            maxHeight: "1080px",
            mb: 0,
            overflow: "hidden",
          }}
        >
          {/* 背景動画 */}
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              transform: "translate(-50%, -50%)",
              zIndex: -1,
            }}
          >
            <source src="/videos/juno/top.mp4" type="video/mp4" />
            <img src="/images/juno/top.jpg" alt="juno" />
          </video>
        </Box>
      </ColumnCenteredBox>
    </>
  );
};
export default Top;