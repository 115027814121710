import junoTemplate from "./junoTemplate";

const juno = {
  title: "Studio Juno",
  description: "画像生成AI",
  explore: "探検",
  goToGenerator: "画像生成",
  goToQuickGenerator: "クイックAI テンプレート",
  myGallery: "マイギャラリー",
  likes: "Likes",
  searchResult: "検索結果：",
  noResult: "検索結果が見つかりませんでした。（正しい英語で入力しているか確認してください）",
  menu: {
    title: "MENU",
    txt2img: "テキストから\n画像生成",
    img2img: "画像から\n画像生成",
    upscale: "アップスケール",
  },
  input: {
    engine: {
      title: "AI ENGINE",
      description: "AIエンジンを選択してください。",
      DallE3: "DALL·E 3",
      DallE3Description:
        "柔軟なプロンプトで、希望のが画像を簡単に生成でき、短い英単語の入力も可能です。初心者向けですが、最もクレジットを消費しやすいです。",
      Flux1Pro: "FLUX.1 Pro",
      Flux1ProDescription:
        "FLUX.1シリーズの中で高解像度の画像生成が可能で、プロンプトの柔軟性が高い。また、MidjourneyV6やSD Ultraよりも高解像度の画像生成が可能とされています。",
      Flux1Dev: "FLUX.1 Dev",
      Flux1DevDescription: "FLUX.1 Proよりも画質が劣るが、FLUX.1 Proよりも高速に画像生成が可能です。",
      Flux1Schnell: "FLUX.1 Schnell",
      Flux1SchnellDescription: "FLUX.1 Devよりも画質が劣るが、FLUX.1 Devよりも高速に画像生成が可能です。",
      Flux1RealismLoRA: "FLUX.1 with Realism LoRA",
      Flux1RealismLoRADescription: "FLUX.1シリーズののリアリズムモデルです。リアリズムを重視した画像生成が可能です。",
      Ideogram2: "Ideogram 2.0",
      Ideogram2Description:
        "文字入れが得意なモデル。マジックプロンプトを有効にすると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
      Ideogram2Turbo: "Ideogram 2 Turbo",
      Ideogram2TurboDescription:
        "Ideogram 2.0 を調整し、速度を向上させたモデル。マジックプロンプトを有効にすると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
      SD: "Stable Diffusion",
      SDDescription:
        "プロンプトの知識が必要となり、正確に入力しないと画像が破綻しやすく、難易度が高いです。しかし、モデルの種類が多く、他には出力できない種類の画像生成が可能となります。",
      SDXL: "Stable Diffusion XL",
      SDXLDescription:
        "従来のStable Diffusionよりも高解像度の画像生成が可能です。また、SDよりもプロンプトが柔軟になりましたが、モデルの種類がまだ少ないです。",
      AuraSR: "Aura SR",
      AuraSRDescription:
        "従来のアップスケーラーと異なり、次世代のアップスケーリング技術を使用し、元画像の類似性を保持しながら、画像の解像度を向上させます。",
      ClarityUpscaler: "Clarity Upscaler",
      ClarityUpscalerDescription:
        "元画像の類似性を保持しながら、画像を高画質化し、創造性を上げてそエンハンサーとしても素晴らしい性能を発揮します。",
      CreativeUpscaler: "Creative Upscaler",
      CreativeUpscalerDescription:
        "元画像をアップスケールし、欠落した部分を新しく生成することで、画像の解像度を向上させます。最大4,194,304px(約2,048pxの正方形)まで拡大可能です",
      SD3: "Stable Diffusion 3 Large",
      SD3Description: "Stable Diffusionの最新バージョンです。プロンプトに忠実で、画像に対する文字入れを得意とします。",
      SDUltra: "Stable Image Ultra",
      SDUltraDescription:
        "Stable Diffusion 3 を含む最も高度なモデルから作成された Ultra は、タイポグラフィ、複雑な構成、ダイナミックな照明、鮮やかな色彩、芸術作品の全体的なまとまりと構造に優れています。",
      RealESRGAN: "Real-ESRGAN",
    },
    aspectRatio: {
      title: "アスペクト比",
      custom: "カスタム",
    },
    width: "横幅",
    height: "縦幅",
    step: "ステップ数",
    sample: "生成画像数",
    sampleDescription:
      "'横幅'、または'縦幅'が {{px}}px を超える場合、生成される枚数は1枚に制限されます。複数枚生成したい場合、{{px}}px 未満で生成してから、アップスケールすることを推奨します。",
    models: "モデル",
    SDList: "SD モデルリスト",
    listDescription: "モデルを選択してください。",
    modelSearch: "モデルを検索",
    SDXLList: "SDXL モデルリスト",
    nsfwFilter: "制限フィルター",
    recommendedModelsOnly: "人気モデルのみ",
    caution:
      "コミュニティモデルは、一部のユーザーが作成したモデルです。品質が保証されていないため、ご利用の際はご注意ください。また、ライセンスを必ず確認してください。",
    loras: "LoRA",
    loraList: "LoRA リスト",
    loraDescription: "LoRAを選択してください。",
    loraSearch: "LoRAを検索",
    loraStrength: "LoRAの影響度",
    selectedLora: "選択されたLoRA",
    resolution: "高解像度の補正（自動適応）",
    resolutionCaution: "生成枚数が1枚に制限されます。",
    resolutionTooltip:
      "高解像度の補正を適用すると、生成された画像の解像度が向上します。横幅か縦幅の何れかが {{px}}px 以上の場合、自動的に適応されます。",
    guidanceScale: "プロンプトの重視度",
    seedSD: "シード値（同モデルのみ有効）",
    dallE3RevisedPrompt: "プロンプトをAI拡張",
    style: {
      title: "スタイル",
      dalle3: {
        vivid: "鮮やか（デフォルト）",
        natural: "ナチュラル",
      },
      ideogram: {
        general: "一般",
        realistic: "リアル",
        design: "デザイン",
        anime: "アニメ",
        render3d: "3Dレンダリング",
      },
    },
    basicSettings: "基本設定",
    advancedSettings: "詳細設定",
    negativePrompt: "ネガティブプロンプト",
    negativePromptHistory: "ネガティブプロンプト",
    negativePromptPlaceholder: "例: worst quality, nsfw, bad anatomy, out of focus",
    negativePromptDescription: "画像に含みたくない要素を指定してください。",
    negativePromptAI: "ネガティブプロンプトをAIに生成させる",
    negativePromptTitle: "プリセット名",
    negativePromptSave: "ネガティブプロンプトを保存します。",
    negativePromptList: "保存済みネガティブプロンプト",
    delete: {
      title: "ネガティブプロンプトを削除しますか？",
      description: "保存済みのネガティブプロンプトを削除します。この操作は取り消せません。",
      success: "ネガティブプロンプトが削除されました。",
    },
    image: "元画像",
    imageStrength: "元画像の影響度",
    alert: {
      imageType: "ファイルはJPGまたはPNG形式である必要があります。",
      imageSize: "ファイルサイズは10MB以下である必要があります。",
    },
    scale: "スケール",
    scaleInfo: "アップスケール後の拡大倍率",
    creativity: "創造性",
    creativityInfo: "アップスケール時、どの程度の創造性を許容するか指定できます。",
    detail: "テクスチャ",
    detailInfo: "アップスケール時、ディテールの強さを指定できます。",
    resemblance: "元画像の維持度",
    resemblanceInfo: "アップスケールで、どの程度元の画像に寄せるかを指定できます。",
    uploadSuccess: "画像がアップロードされました。",
    uploadError: {
      size: "ファイルサイズは {{size}}MB 以下である必要があります。",
      type: "ファイルは JPG または PNG 形式である必要があります。",
      scale: "画像の長辺は 2048px 以下である必要があります。",
    },
    filtered: "BLOCKED",
    filteredDescription: "ポリシーに違反している可能性があるため、表示されません。",
    show: "表示中 - ポリシーに違反している可能性がある画像",
    hide: "非表示中 - ポリシーに違反している可能性がある画像",
    publicStatus: "公開設定",
    publicDescription:
      "非公開設定はStandardプラン以上で利用可能です。公開を指定していても、非公開に変更される場合があります。",
    public: "公開",
    private: "非公開",
  },
  history: {
    title: "最新の10件",
    input: "テキストを入力（英語推奨）",
    dice: "画像生成用のテキストのサンプルをランダムで生成します。* 不正行為防止の為、0.1クレジットを消費します。",
    improve:
      "入力されたテキスト（キーワード）からプロンプトを生成。また、英語に翻訳。（SD非推奨）* 不正行為防止の為、0.1クレジットを消費します。",
    prompt: "プロンプト",
    size: "サイズ",
    upscale: "アップスケール",
    img2img: "画像から生成",
    useParams: "再生成",
    download: ".PNG 最高画質",
    generating: "Generating Images",
    generatingDescription: "画像生成中です。しばらくお待ちください。\nページを離れても生成は続行されます。",
    delete: {
      title: "履歴を削除しますか？",
      description: "履歴を削除すると、履歴のすべてのデータが削除されます。この操作は取り消せません。",
      success: "履歴が削除されました。",
    },
    ip: {
      title: "画像プロンプト",
      tooltip:
        "画像プロンプトを使用すると、画像生成の際にプロンプトを大幅に省略し、画像プロンプトに沿った画像を生成することができます。",
      description:
        "画像をプロンプトとして扱います。これにより、プロンプトを大幅に省略し、画像プロンプトに沿った画像を生成。「画像から画像生成」の場合、2つの画像をMixしたような画像を生成することも可能。（IP Adapter）",
      strength: "画像プロンプトの影響度 (IP)",
    },
    optionalPromptPlaceholder: "画像のプロンプトを入力してください（オプション）",
    notNeededPlaceholder: "画像のプロンプトは不要です",
    promptPlaceholder: "画像のプロンプトを入力してください",
  },
  quick: junoTemplate,
  message: {
    delete: "画像が削除されました。",
  },
  error: {
    noCredit: "クレジットが不足しています。",
    upgrade: "このモデルを使用するには「ベーシックプラン」以上が必要です。",
  },
  validation: {
    model: "モデルを選択してください。",
    image: "画像をアップロードしてください。",
    prompt: "1,000文字以内でプロンプトを入力してください。",
    negativePrompt: "1,000文字以内でネガティブプロンプトを入力してください。",
  },
};
export default juno;
