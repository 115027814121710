// AlertContext.js
import React, { createContext, useContext, useState } from "react";
import Cookies from "js-cookie";

const LoginModalContext = createContext({
  activeModal: null as "login" | "passwordReset" | "signup" | null,
  setActiveModal: (modal: "login" | "passwordReset" | "signup" | null) => {},
  setRequestPathToCookie: (path: string) => {},
  getRequestPathFromCookie: (): string => "",
});

export const useLoginModalContext = () => useContext(LoginModalContext);

export const LoginModalProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [activeModal, setActiveModal] = useState<'login' | 'passwordReset' | 'signup' | null>(null);

  const setRequestPathToCookie = (path: string) => {
    // pathが/en,/jaを含む場合はその文字列を除外
    path = path.replace(/\/(en|ja)/, "");
    Cookies.set("requestPath", path, {expires: 1});
  }

  const getRequestPathFromCookie = () => {
    return Cookies.get("requestPath") || "";
  }

  return (
    <LoginModalContext.Provider value={{activeModal, setActiveModal, setRequestPathToCookie, getRequestPathFromCookie}}>
      {children}
    </LoginModalContext.Provider>
  );
};
