import "reactflow/dist/style.css";
import React from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Cookies from "js-cookie";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Paper, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import Button from "@mui/material/Button";
import LinkIcon from "@mui/icons-material/Link";

interface ShareModalProps {
  mindMapUuid: string;
  open: boolean;
  handleClose: () => void;
}

const ShareModal = ({ mindMapUuid, open, handleClose }: ShareModalProps) => {
  const { t } = useTranslation();
  const [isPublic, setIsPublic] = React.useState(false);
  const [url, setUrl] = React.useState("");
  const domain = window.location.origin;
  const path = window.location.pathname;

  // Fetch share status
  const fetchShareStatus = async () => {
    try {
      const url = "/api/v1/mind-map/" + mindMapUuid + "/share";
      const res = await axios.get(url);
      if (res.status === 200) {
        setIsPublic(res.data.public);
        const public_url = domain + path + "/" + res.data.public_uuid;
        setUrl(public_url);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const handleShare = async (isPublic: boolean) => {
    try {
      const url = "/api/v1/mind-map/" + mindMapUuid + "/share";
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      };
      const data = {
        public: isPublic,
      };
      const res = await axios.patch(url, data, { headers: headers });
      if (res.status === 200) {
        setIsPublic(isPublic);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  React.useEffect(() => {
    fetchShareStatus();
  }, [open]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: 600 },
        }}
      >
        <Paper sx={{ p: 4 }}>
          <Typography variant={"h6"} sx={{ mb: 4 }}>
            {t("mindmap.share.title")}
          </Typography>
          <Typography variant={"body1"} sx={{ mb: 2 }}>
            {t("mindmap.share.description")}
          </Typography>

          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isPublic} onChange={(e) => handleShare(e.target.checked)} name="public" />}
              label={t("mindmap.share.public")}
            />
          </FormGroup>

          {isPublic && (
            <>
              <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
                <LinkIcon sx={{ mr: 1 }} />
                <Typography variant={"subtitle1"} component={"p"}>
                  {t("mindmap.share.link")}
                </Typography>
              </Box>
              <TextField
                fullWidth
                value={url}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <Box ml={1}>
                      <CopyButtonOrIcon displayType={"icon"} textToCopy={url} size={"small"} />
                    </Box>
                  ),
                }}
              ></TextField>
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
            <Button disableElevation onClick={handleClose}>
              {t("common.close")}
            </Button>
          </Box>
        </Paper>
      </Box>
    </Modal>
  );
};
export default ShareModal;