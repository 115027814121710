// トリガーが必要な場合に使用するSlice
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TriggerState = {
  drawerWorkspaceTrigger: boolean;
  drawerCreditTrigger: boolean;
};

const initialState: TriggerState = {
    drawerWorkspaceTrigger: false,
    drawerCreditTrigger: false,
};

const triggerSlice = createSlice({
    name: 'trigger',
    initialState,
    reducers: {
        setWorkspaceTrigger: (state, action: PayloadAction<boolean>) => {
            state.drawerWorkspaceTrigger = action.payload;
        },
        setCreditTrigger: (state, action: PayloadAction<boolean>) => {
            state.drawerCreditTrigger = action.payload;
        }
    },
});

export const {setWorkspaceTrigger, setCreditTrigger} = triggerSlice.actions;
export const triggerReducer = triggerSlice.reducer;
