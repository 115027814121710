import React, { useEffect, useState } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/FileCopy";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";

type Props = {
  displayType: "button" | "icon";
  textToCopy: string;
  variant?: "text" | "outlined" | "contained";
  size?: "small" | "medium" | "large";
  color?: string;
};

const CopyButtonOrIcon: React.FC<Props> = ({ displayType, textToCopy, variant, size, color }) => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => setIsCopied(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  const copyText = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        console.log("Text successfully copied");
        setIsCopied(true);
      })
      .catch((err) => {
        console.log("Unable to copy text", err);
      });
  };

  if (displayType === "button") {
    return (
      <Button disableElevation onClick={copyText} variant={variant} size={size} sx={{ color: color }}>
        <Typography variant={"button"} sx={{ display: "flex", alignItems: "center" }}>
          {isCopied ? (
            <CheckIcon fontSize={"small"} sx={{ mr: 1 }} />
          ) : (
            <ContentCopyIcon fontSize={"small"} sx={{ mr: 1 }} />
          )}
          {t("common.copy")}
        </Typography>
      </Button>
    );
  }

  return (
    <Tooltip title={t("common.copy")}>
      <IconButton size={size} onClick={copyText} sx={{ color: color }}>
        {isCopied ? <CheckIcon fontSize={size} /> : <ContentCopyIcon fontSize={size} />}
      </IconButton>
    </Tooltip>
  );
};

export default CopyButtonOrIcon;
