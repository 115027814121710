import AddLinkIcon from "@mui/icons-material/AddLink";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EditNoteIcon from "@mui/icons-material/EditNote";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { useVideoScriptContext } from "../../../../context/VideoScriptContext";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function Source() {
  const { t } = useTranslation();
  const context = useVideoScriptContext();

  return (
    <>
      {/* ソース */}
      <Box>
        <Box display={"flex"} flexDirection={"row"}>
          <StyledTypography variant="body1" gutterBottom>
            {t("videoScript.source")}
          </StyledTypography>
          <Typography color={"error"}>&nbsp;*</Typography>
        </Box>
        <FormControl sx={{ mb: 1 }}>
          <RadioGroup row value={context.srcType} onChange={(event) => context.setSrcType(event.target.value)}>
            <FormControlLabel value="url" control={<Radio />} label={t("videoScript.srcType.url")} />
            <FormControlLabel value="youtube" control={<Radio />} label={t("videoScript.srcType.youtube")} />
            <FormControlLabel value="text" control={<Radio />} label={t("videoScript.srcType.text")} />
            <FormControlLabel value="keyword" control={<Radio />} label={t("videoScript.srcType.keyword")} />
          </RadioGroup>
        </FormControl>
        {context.srcType === "keyword" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <AutoAwesomeIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("videoScript.srcType.keywordDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("videoScript.srcType.keyword")}
              autoComplete={"off"}
              placeholder={t("videoScript.srcType.keywordPlaceholder")}
              variant="standard"
              value={context.content}
              onChange={(e) => context.setContent(e.target.value)}
              fullWidth
            />
          </>
        )}
        {context.srcType === "url" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <AddLinkIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("videoScript.srcType.urlDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("videoScript.srcType.url")}
              placeholder={t("videoScript.srcType.urlPlaceholder")}
              autoComplete={"off"}
              variant="standard"
              value={context.content}
              onChange={(e) => context.setContent(e.target.value)}
              fullWidth
            />
          </>
        )}
        {context.srcType === "youtube" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={1}>
              <YouTubeIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("videoScript.srcType.youtubeDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("videoScript.srcType.url")}
              autoComplete={"off"}
              placeholder={t("videoScript.srcType.youtubePlaceholder")}
              variant="standard"
              value={context.content}
              onChange={(e) => context.setContent(e.target.value)}
              fullWidth
            />
          </>
        )}
        {context.srcType === "text" && (
          <>
            <Box display={"flex"} alignItems={"center"} mb={2}>
              <EditNoteIcon sx={{ mr: 1 }} color={"secondary"} fontSize={"small"} />
              <Typography variant={"body2"}>{t("videoScript.srcType.textDescription")}</Typography>
            </Box>
            <TextField
              id="outlined-multiline-static"
              label={t("videoScript.srcType.text")}
              placeholder={t("videoScript.srcType.textPlaceholder")}
              autoComplete={"off"}
              variant="outlined"
              multiline
              rows={8}
              value={context.content}
              onChange={(e) => context.setContent(e.target.value)}
              fullWidth
            />
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Typography variant={"body2"}>{context.content.length}/4,000</Typography>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
