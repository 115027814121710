import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import { alpha, ButtonBase, CircularProgress, Divider } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import "reactflow/dist/style.css";
import { useAlert } from "../../../context/AlertContext";
import { useCheckCredit } from "../../../hooks/useCreditCheck";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useMindMap } from "../../../context/MindmapContext";
import { v4 as uuidv4 } from "uuid";

interface ToolbarProps {
  autoLayout: () => void;
  nodes: any;
  setNodes: any;
  edges: any;
  setEdges: any;
  nodeDefaults: any;
  edgeType: string;
  setEdgeType: any;
  animation: boolean;
  setAnimation: any;
}

const Toolbar = ({
  autoLayout,
  nodes,
  setNodes,
  edges,
  setEdges,
  nodeDefaults,
  edgeType,
  setEdgeType,
  animation,
  setAnimation,
}: ToolbarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { checkCredit } = useCheckCredit();
  const { setAlert } = useAlert();
  const { mindMapUuid } = useParams();
  const [nodeAdding, setNodeAdding] = useState(false);
  const mindMapContext = useMindMap();

  const createAddNode = async (id: string) => {
    setNodeAdding(true);
    if (!(await checkCredit())) return;
    // 先祖ノードのlabelを全て取得

    // labelの一行目、もしくは一文目のみを取得する関数
    // 改行がなければ. や。で一文目と判断
    const getFirstLine = (label: string): string => {
      const firstLine = label.split("\n")[0];
      const firstSentence = firstLine.split(".").length > 1 ? firstLine.split(".")[0] : firstLine.split("。")[0];
      return firstSentence;
    };

    // nodeのIDとラベルの一分目を持った配列を返す
    const nodeParams = [] as { id: string; label: string }[];
    nodes.forEach((node) => {
      nodeParams.push({ id: node.id, label: getFirstLine(node.data.label) });
    });

    const edgeParams = [] as { source: string; target: string; type: string; animated: boolean }[];
    edges.forEach((edge) => {
      edgeParams.push({ source: edge.source, target: edge.target, type: edge.type, animated: edge.animated });
    });

    // post
    axios.defaults.withCredentials = true;
    const csrftoken = Cookies.get("csrftoken");

    const config = {
      headers: { "X-CSRFToken": csrftoken },
    };
    const data = {
      nodes: nodeParams,
      edges: edgeParams,
      targetNodeId: id,
    };

    const url = "/api/v1/mind-map/";

    try {
      const res = await axios.post(url + mindMapUuid + "/additional-nodes", data, config);
      if (res.data.status === "success") {
        const labels = res.data.data;
        await addNodes(id, labels);
        setNodeAdding(false);
        setAlert("success", t("mindmap.alert.createNodes"));
      }
    } catch (error) {
      console.error("API call failed:", error);
      setAlert("error", t("mindmap.notice.error"));
      setNodeAdding(false);
    }
  };

  // AIによるnodeの追加
  const addNodes = async (id: string, labels: string[]) => {
    const selected_node_position = nodes.find((node) => node.id === id)?.position;
    labels.forEach((label: string, index: number) => {
      const newNode = {
        id: `${nodes.length + index + 1}`,
        type: "custom",
        position: {
          x: (selected_node_position?.x || 0) + 500,
          y: (selected_node_position?.y || 0) + index * 200,
        },
        data: { label: label["label"], color: "", links: [], images: [] },
        ...nodeDefaults,
      };
      setNodes((prevNodes) => [...prevNodes, newNode]);

      const newEdge = {
        id: `e${edges.length + index + 1}`,
        source: id,
        target: newNode.id,
        animated: animation,
        type: edgeType,
      };
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    });
  };

  // ノードを追加する関数
  const addNode = (id: string) => {
    const selected_node_position = nodes.find((node) => node.id === id)?.position;
    const childCount = edges.filter((edge) => edge.source === id).length;
    const newNode = {
      id: uuidv4(),
      type: "custom",
      position: {
        x: (selected_node_position?.x || 0) + 500,
        y: (selected_node_position?.y || 0) + childCount * 100,
      },
      data: { label: `NEW ${nodes.length + 1}`, color: "", links: [], images: [] },
      ...nodeDefaults,
    };
    setNodes((prevNodes) => [...prevNodes, newNode]);

    // 新しいエッジを作成する前に、既存のエッジが存在しないか確認
    const isEdgeExists = edges.some((edge) => edge.source === id && edge.target === newNode.id);

    if (!isEdgeExists) {
      const newEdge = {
        id: uuidv4(),
        source: id,
        target: newNode.id,
        animated: animation,
        type: edgeType,
      };
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    }

    setAlert("success", t("mindmap.alert.addNode"));
  };

  useEffect(() => {
    if (mindMapContext.actionTrigger.node_action === "add") {
      addNode(mindMapContext.selectedNode);
    }
  }, [mindMapContext.actionTrigger.triggerFetch]);

  const changeAllEdgeType = (event: SelectChangeEvent<string>) => {
    const newEdgeType = event.target.value;
    setEdgeType(newEdgeType);
    const newEdges = edges.map((edge: any) => ({
      ...edge,
      type: newEdgeType,
    }));
    setEdges(newEdges);
  };

  const changeAllEdgeAnimation = (event: SelectChangeEvent<string>) => {
    const newAnimation = event.target.value === "true";
    setAnimation(newAnimation);
    const newEdges = edges.map((edge: any) => ({
      ...edge,
      animated: newAnimation,
    }));
    setEdges(newEdges);
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          left: 10,
          top: 10,
          display: "flex",
          borderRadius: 4,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          bgcolor: alpha(theme.palette.background.custom2, 0.2),
          backdropFilter: "blur(4px)",
          overflow: "hidden",
        }}
      >
        <ButtonBase
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            autoLayout();
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.primary.main },
          }}
        >
          <Grid3x3Icon sx={{ mr: 1 }} fontSize={"small"} />
          <Typography variant={"button"}>{t("mindmap.autoLayout")}</Typography>
        </ButtonBase>
        <Divider orientation="vertical" flexItem />
        <ButtonBase
          color="primary"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            addNode(mindMapContext.selectedNode);
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.primary.main },
          }}
        >
          <AddIcon sx={{ mr: 1 }} fontSize={"small"} />
          <Typography variant={"button"}>{t("mindmap.addNode")}</Typography>
        </ButtonBase>
        <Divider orientation="vertical" flexItem />
        <ButtonBase
          color="primary"
          disabled={nodeAdding}
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            createAddNode(mindMapContext.selectedNode).then();
          }}
          sx={{
            zIndex: 1000,
            py: 1,
            px: 2,
            transition: "color 0.2s ease-in-out",
            "&:hover": { color: theme.palette.primary.main },
          }}
        >
          {nodeAdding ? (
            <CircularProgress size={20} sx={{ mr: 1 }} />
          ) : (
            <AutoAwesomeIcon sx={{ mr: 1 }} fontSize={"small"} />
          )}
          <Typography variant={"button"}>{t("mindmap.createNodes")}</Typography>
        </ButtonBase>
      </Box>
      <Box
        sx={{
          position: "absolute",
          right: 10,
          top: 10,
          display: "flex",
          borderRadius: 1,
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
          bgcolor: alpha(theme.palette.background.custom2, 0.2),
          backdropFilter: "blur(4px)",
          overflow: "hidden",
          p: 1,
          gap: 1,
        }}
      >
        <FormControl sx={{ width: 200 }} variant="outlined">
          <InputLabel id="edge-type">{t("mindmap.edgeTypes.title")}</InputLabel>
          <Select
            labelId="edge-type"
            id="edge"
            value={edgeType}
            onChange={changeAllEdgeType}
            label={t("mindmap.edgeTypes.title")}
            size="small"
            variant="outlined"
          >
            <MenuItem value="default">{t("mindmap.edgeTypes.default")}</MenuItem>
            <MenuItem value="straight">{t("mindmap.edgeTypes.straight")}</MenuItem>
            <MenuItem value="step">{t("mindmap.edgeTypes.step")}</MenuItem>
            <MenuItem value="smoothstep">{t("mindmap.edgeTypes.smooth")}</MenuItem>
            {/*<MenuItem value="simplebezier">Bezier</MenuItem>*/}
          </Select>
        </FormControl>
        <FormControl sx={{ width: 200 }} variant="outlined">
          <InputLabel id="Animation">{t("mindmap.animation.title")}</InputLabel>
          <Select
            labelId="Animation"
            id="animation-select"
            value={`${animation}`}
            onChange={changeAllEdgeAnimation}
            label={t("mindmap.animation.title")}
            size="small"
          >
            <MenuItem value={"true"}>{t("mindmap.animation.enable")}</MenuItem>
            <MenuItem value={"false"}>{t("mindmap.animation.disable")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};
export default Toolbar;
