import React, { useState } from "react";
import Box from "@mui/material/Box";
import GridOnIcon from "@mui/icons-material/GridOn";
import Popover from "@mui/material/Popover";
import { useTheme } from "@mui/material/styles";
import Quill from "quill";

type Range = {
  index: number;
  length: number;
};

const Table: React.FC<{ quill: Quill | null }> = ({ quill }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoveredRows, setHoveredRows] = useState(0);
  const [hoveredColumns, setHoveredColumns] = useState(0);
  const [savedRange, setSavedRange] = useState<Range>({ index: 0, length: 0 });
  const theme = useTheme();
  const maxRows = 6;
  const maxColumns = 6;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    // カーソル位置を保存
    if (quill) {
      const range = quill.getSelection();
      if (range) {
        setSavedRange(range);
      }
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCellClick = (row: number, column: number) => {
    if (quill) {
      quill.setSelection(savedRange); // カーソル位置を再設定
      insertTable(row, column);
    }
    handleClose();
  };

  const handleCellMouseOver = (row: number, column: number) => {
    setHoveredRows(row);
    setHoveredColumns(column);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const insertTable = (rows: number, columns: number) => {
    const tableModule: any = quill!.getModule("table-better");
    tableModule.insertTable(rows, columns);
  };

  return (
    <div>
      <button aria-describedby={id} onClick={handleClick}>
        <GridOnIcon fontSize={"small"} sx={{ color: theme.palette.text.primary }} className={"ql-fill"} />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1, maxWidth: "220px" }}>
          <div style={{ display: "grid", gridTemplateColumns: `repeat(${maxColumns}, 15px)`, gap: "2px" }}>
            {Array.from({ length: maxRows * maxColumns }).map((_, index) => {
              const row = Math.floor(index / maxColumns) + 1;
              const column = (index % maxColumns) + 1;
              return (
                <div
                  key={index}
                  style={{
                    width: "15px",
                    height: "15px",
                    backgroundColor:
                      row <= hoveredRows && column <= hoveredColumns
                        ? theme.palette.primary.main
                        : theme.palette.grey[600],
                    cursor: "pointer",
                  }}
                  onClick={() => handleCellClick(row, column)}
                  onMouseOver={() => handleCellMouseOver(row, column)}
                />
              );
            })}
          </div>
        </Box>
      </Popover>
    </div>
  );
};

export default Table;
