import { ButtonProps } from "@mui/material/Button";
import { keyframes, styled } from "@mui/material/styles";
import { Button } from "@mui/material";

interface GradationButtonProps extends ButtonProps {
  to?: string;
}

// ボーダーの点灯アニメーションを定義
const flashAnimation = keyframes`
    0% {
        opacity: 0.4;
    }
    25% {
        opacity: 0.1;
    }
    50% {
        opacity: 0.4;
    }
    75% {
        opacity: 0.2;
    }
    100% {
        opacity: 0;
    } `;

export const GradationButton = styled(Button)<GradationButtonProps>(({ theme }) => ({
  position: "relative",
  zIndex: 0,
  color: "white",
  background: theme.custom.gradient.main,
  overflow: "hidden",
  cursor: "pointer",
  boxShadow: "none",
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: theme.custom.gradient.dark,
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
    zIndex: -1,
  },
  "&:after": {
    content: '""',
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    borderRadius: "inherit",
    border: "1px solid white",
    opacity: 0,
    boxSizing: "border-box",
    transition: "opacity 0.5s ease-in-out",
  },
  "&:hover": {
    boxShadow: "none",
    "&:before": {
      opacity: 1,
    },
    "&:after": {
      animation: `${flashAnimation} 0.5s ease-out`,
    },
  },
}));