import ArticleIcon from "@mui/icons-material/Article";
import { Avatar } from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import Skeleton from "@mui/material/Skeleton";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import { customLink, extractTextFromHTML, localDate } from "../../../utils/utils";

interface ArticleCardProps {
  article: any;
  loadedImages: any[];
}

const ArticleCard = ({ article, loadedImages }: ArticleCardProps) => {
  const location = useLocation();
  const theme = useTheme();
  const lang = location.pathname.split("/")[1];

  return (
    <>
      <Link href={customLink(`/notes-nebula/articles/${article["uuid"]}`)} underline={"none"}>
        <Card
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
          elevation={theme.palette.mode === "light" ? 0 : 1}
        >
          {/* サムネイル */}
          {article["thumbnail"] ? (
            <>
              {loadedImages.includes(article["uuid"]) ? (
                <CardMedia
                  image={`${article["thumbnail"]}`}
                  sx={{
                    height: 120,
                    background: theme.custom.gradient.main,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                />
              ) : (
                <Skeleton variant="rectangular" width={"100%"} height={120} />
              )}
            </>
          ) : (
            <Box
              sx={{
                height: 120,
                background: theme.custom.gradient.main,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <ArticleIcon sx={{ color: "white" }} />
              <Typography
                variant={"h1"}
                component={"p"}
                sx={{
                  position: "absolute",
                  fontSize: "8rem!important;",
                  whiteSpace: "nowrap",
                  color: alpha(theme.palette.common.white, 0.03),
                }}
              >
                {article["title"]}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: "flex", flexDirection: "column", p: 1.5, pb: 1 }}>
            {/* タイトル */}
            <Typography
              variant="subtitle2"
              component="h5"
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                height: "2.8rem",
                mb: 1,
              }}
            >
              {article["title"]}
            </Typography>
            {/*説明*/}
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                textOverflow: "ellipsis",
                height: "3.6rem",
                mb: 2,
              }}
            >
              {extractTextFromHTML(article["content"].replace(/#|\*|--|\|/g, ""))}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              {article["avatar"] ? (
                <Avatar sx={{ width: 32, height: 32, mr: 1 }} src={article["avatar"] + "avatar"} />
              ) : (
                <Avatar color="primary" sx={{ width: 32, height: 32 }}>
                  {article["user"].slice(0, 2)}
                </Avatar>
              )}
              <Box flexGrow={1} />
              <Box sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                <Typography variant="caption" color="text.secondary">
                  @{article["user"]}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  {localDate(article["created_at"], lang)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Card>
      </Link>
    </>
  );
};
export default ArticleCard;
