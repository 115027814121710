import * as React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ValidationTextField from "../common/ValidationTextField";
import { validateLogin } from "../../utils/validation";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import EmailIcon from "@mui/icons-material/Email";
import { useLoginModalContext } from "../../context/LoginModalContext";
import Link from "@mui/material/Link";
import LaunchIcon from "@mui/icons-material/Launch";
import { lighten, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GoogleButton from "./GoogleButton";
import { customLink } from "../../utils/utils";

export default function SignupModal() {
  const { t } = useTranslation();
  const context = useLoginModalContext();
  const [isRevealPassword, setIsRevealPassword] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [isSignupSuccess, setIsSignupSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  type Inputs = { email: string; password: string };
  const theme = useTheme();
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const validationRules = validateLogin(t);

  const handleSignup = async (data: any) => {
    const { email, password } = data;
    try {
      setLoading(true);
      context.setRequestPathToCookie(window.location.pathname);
      const username = "";
      // ユーザー登録のリクエストを行う
      const response = await axios.post(
        "/api/v1/users/signup",
        {
          username,
          email,
          password,
        },
        { withCredentials: true }
      );
      const data = response.data;

      if (response.status === 200 && data.success) {
        setIsSignupSuccess(true); // ユーザー作成成功時に状態を更新
      } else {
        const messages = response.data.message;
        const combinedMessage = Object.keys(messages)
          .map((key) => {
            return messages[key].join(", ");
          })
          .join("; ");
        setErrorMessage(combinedMessage);
      }
      setLoading(false);
    } catch (error: any) {
      if (error.response) {
        console.error("An unknown error occurred:", error.response);
        setErrorMessage(`An unknown error occurred: ${error.response.data.message}`);
      }
      context.setRequestPathToCookie("");
      setLoading(false);
    }
  };

  const handleClose = () => {
    context.setActiveModal(null);
    setIsSignupSuccess(false);
    reset();
    setErrorMessage("");
  };

  const handleTogglePasswordVisibility = () => {
    setIsRevealPassword((prev) => !prev);
  };

  return (
    <Modal open={context.activeModal === "signup"} onClose={handleClose}>
      {isSignupSuccess ? ( // ユーザー作成が成功した場合の表示
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" component="div" gutterBottom textAlign={"center"} fontWeight={600} mb={2}>
            {t("top.signup")}
          </Typography>
          <EmailIcon sx={{ fontSize: 40 }} />
          <Typography variant="body1" mt={2} gutterBottom>
            {t("top.check_email")}
          </Typography>
          <Button disableElevation variant="contained" onClick={handleClose} sx={{ marginTop: "15px" }}>
            {t("common.close")}
          </Button>
        </Box>
      ) : (
        // 通常のサインアップフォーム
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            maxWidth: 400,
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflow: "auto",
            maxHeight: "100vh",
          }}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>

          <Typography variant="h5" component="div" gutterBottom textAlign={"center"} fontWeight={600}>
            {t("top.signup")}
          </Typography>
          <img src="/images/logo/logo.png" width={"35%"} alt="logo" />
          <form onSubmit={handleSubmit(handleSignup)} noValidate>
            <Controller
              name="email"
              control={control}
              rules={validationRules.email}
              render={({ field }) => (
                <ValidationTextField
                  label="E-mail"
                  type="email"
                  value={field.value}
                  helperText={errors.email?.message ?? ""}
                  error={errors.email !== undefined}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  tooltipMessage={t("top.tooltip.email")}
                  fullWidth
                  sx={{ mb: 2 }}
                  autoComplete="email"
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={validationRules.password}
              render={({ field }) => (
                <ValidationTextField
                  label="Password"
                  type="password"
                  value={field.value}
                  helperText={errors.password?.message ?? ""}
                  error={errors.password !== undefined}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  isRevealPassword={isRevealPassword}
                  onTogglePasswordVisibility={handleTogglePasswordVisibility}
                  tooltipMessage={t("top.tooltip.password")}
                  fullWidth
                  autoComplete="new-password"
                  sx={{ mb: 2 }}
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                my: 1,
                p: 2,
                bgcolor: lighten(theme.palette.background.default, 0.1),
                borderRadius: 2,
              }}
            >
              <Typography variant="caption" color="textSecondary">
                {t("top.agree")}
              </Typography>
              <Box sx={{ display: "flex", my: 1, flexDirection: { xs: "column", sm: "row" } }}>
                <Link href={customLink("/terms-of-service")} sx={{ mr: 2 }} target={"_blank"}>
                  <Button
                    disableElevation
                    variant={"text"}
                    color={"primary"}
                    endIcon={<LaunchIcon fontSize={"small"} />}
                  >
                    {t("preferences.misc.termsOfService")}
                  </Button>
                </Link>
                <Link href={customLink("/privacy-policy")} target={"_blank"}>
                  <Button
                    disableElevation
                    variant={"text"}
                    color={"primary"}
                    endIcon={<LaunchIcon fontSize={"small"} />}
                  >
                    {t("preferences.misc.privacyPolicy")}
                  </Button>
                </Link>
              </Box>
            </Box>
            <Button
              disableElevation
              size="large"
              variant="contained"
              type={"submit"}
              sx={{ marginTop: 2, width: "100%" }}
              disabled={loading}
            >
              {t("top.signup")}
            </Button>
          </form>
          <Box mt={2} flexDirection="row" display="flex" justifyContent="center">
            {errorMessage && (
              <Typography variant="body2" color="error">
                {errorMessage}
              </Typography>
            )}
          </Box>
          <GoogleButton handleClose={handleClose} />
        </Box>
      )}
    </Modal>
  );
}
