import AddBoxIcon from "@mui/icons-material/AddBox";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import axios from "axios";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";
import { setWorkspaceTrigger } from "../../../redux/slices/triggerSlice";
import { RootState } from "../../../redux/store";
import { useNotice } from "../../../context/NoticeContext";

type WorkspaceType = {
  name: string;
  path: string;
};

const BrowsingList = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const [workspaceLimit, setWorkspaceLimit] = React.useState(true);
  const updatedWorkspace = useSelector((state: RootState) => state.browsing.triggerFetch);
  const { drawerWorkspaceTrigger } = useSelector((state: RootState) => state.trigger);
  const navigate = useCustomNavigate();
  const { triggerNotice } = useNotice();

  const newWorkspace = async () => {
    try {
      axios.defaults.withCredentials = true;
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };
      const res = await axios.post("/api/v1/workspace/", {}, config);
      workspaces.push(res.data);
      setOpenBrowsing(true);
      get_workspaces().then(() => {
        triggerNotice("success", t("browsing.createWorkspace"));
      });
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  useEffect(() => {
    get_workspaces();
  }, [updatedWorkspace]);

  useEffect(() => {
    if (drawerWorkspaceTrigger) {
      newWorkspace();
      dispatch(setWorkspaceTrigger(false));
    }
  }, [drawerWorkspaceTrigger]);

  const [workspaces, setWorkspaces] = useState<WorkspaceType[]>([]);
  const dispatch = useDispatch();

  const get_workspaces = async () => {
    try {
      const res = await axios.get("/api/v1/workspace/");
      if (res.data.length > 4) {
        setWorkspaceLimit(true);
      } else {
        setWorkspaceLimit(false);
      }
      setWorkspaces(res.data);
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const [openBrowsing, setOpenBrowsing] = React.useState(false);

  return (
    <Paper
      onMouseOver={() => setOpenBrowsing(true)}
      onMouseLeave={() => setOpenBrowsing(false)}
      onClick={() => setOpenBrowsing(!openBrowsing)}
      sx={{
        borderRadius: 1,
        py: 0.5,
        backgroundColor: (theme) => alpha(theme.palette.action.selected, 0.05),
      }}
      elevation={0}
    >
      <List disablePadding dense>
        <ListItemButton
          sx={{
            "&:hover": {
              backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
            },
          }}
        >
          <ListItemIcon>
            <SearchIcon fontSize={"small"} color={"primary"} />
          </ListItemIcon>
          <ListItemText sx={{ mr: 2 }}>
            <Typography variant={"body2"} component={"span"}>
              {t("drawer.browsing")}
            </Typography>
          </ListItemText>

          <Box display="flex" alignItems="center">
            <IconButton
              color="primary"
              size="small"
              disabled={workspaceLimit}
              sx={{ mr: 1 }}
              onClick={(event) => {
                event.stopPropagation();
                newWorkspace();
              }}
            >
              <AddBoxIcon fontSize={"small"} />
            </IconButton>
          </Box>
          {openBrowsing ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={openBrowsing} timeout="auto" unmountOnExit>
          <List disablePadding component="div" dense>
            {workspaces.map((workspace) => (
              <ListItemButton
                key={workspace.path}
                onClick={() => navigate(workspace.path)}
                selected={location.pathname.split("/")[3] === workspace.path.split("/")[2]}
                sx={{
                  pl: 4,
                  "&:hover": {
                    backgroundColor: (theme) => alpha(theme.palette.primary.light, 0.1),
                  },
                }}
              >
                <ListItemIcon>
                  <ChatBubbleOutlineIcon fontSize={"small"} color={"secondary"} />
                </ListItemIcon>
                <ListItemText
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    maskImage: "linear-gradient(to left, transparent 1%, black 30%)",
                  }}
                >
                  <Typography
                    variant={"body2"}
                    component={"span"}
                    fontWeight={location.pathname.split("/")[3] === workspace.path.split("/")[2] ? 600 : 400}
                  >
                    {workspace.name}
                  </Typography>
                </ListItemText>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      </List>
    </Paper>
  );
};
export default BrowsingList;
