import React, { useState } from "react";
import { Box, Button, Fade, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { useDarkMode } from "../../context/DarkModeContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { changeUILanguage } from "../../components/layout/Header/LanguageSwitcher";
import { loadUserLanguageFromCookie } from "../../i18n/i18n";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import Cookies from "js-cookie";
import axios from "axios";
import ai_language from "../../json/ai_languages.json";
import countries from "../../json/countries.json";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useNavigate } from "react-router-dom";
import { CustomSection } from "../../components/common/CustomSection";

const Settings = () => {
  const { t } = useTranslation();
  const { darkMode, toggleDarkMode } = useDarkMode();

  const aiLanguagesList = ai_language;
  const countriesList = countries;

  const { user, refreshUser } = useLoginStatus();

  const [uiLanguage, setUiLanguage] = useState(loadUserLanguageFromCookie);
  const [aiLanguage, setAiLanguage] = useState(user?.ai_language || "");
  const [country, setCountry] = useState(user?.country || "");
  const navigate = useNavigate();

  const handleUILanguageChange = (lang: string) => {
    changeUILanguage(lang);
    setUiLanguage(lang);
    navigate(`/${lang}/preferences/settings`);
  };

  const handleAILanguageChange = (lang: string) => {
    setAiLanguage(lang);

    const csrftoken = Cookies.get("csrftoken");
    const config = { headers: { "X-CSRFToken": csrftoken } };

    axios
      .patch("/api/v1/users/user", { ai_language: lang }, config)
      .then((_) => {
        refreshUser();
      })
      .catch((error) => {
        console.error("Error setting language:", error);
      });
  };

  const handleCountryChange = (country: string) => {
    setCountry(country);

    const csrftoken = Cookies.get("csrftoken");
    const config = { headers: { "X-CSRFToken": csrftoken } };

    axios
      .patch("/api/v1/users/user", { country: country }, config)
      .then((_) => {
        refreshUser();
      })
      .catch((error) => {
        console.error("Error setting language:", error);
      });
  };

  return (
    <>
      <Box component="div">
        <Typography component={"h3"} variant="h5" gutterBottom my={2}>
          {t("preferences.settings.title")}
        </Typography>
        <Fade in={true} timeout={500}>
          <Paper elevation={0} sx={{ width: "100%", height: "100%" }}>
            <CustomSection>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography variant="subtitle1">{t("preferences.settings.appearance")}</Typography>
                </Grid>
                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"flex-end"}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Button disableElevation onClick={toggleDarkMode}>
                      {darkMode ? "Dark" : "Light"}&nbsp;{darkMode ? <DarkModeIcon /> : <LightModeIcon />}
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </CustomSection>
            <CustomSection>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography variant="subtitle1">{t("preferences.settings.uiLanguage")}</Typography>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("preferences.settings.uiLanguageDescription")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"flex-end"}>
                  <Select
                    value={uiLanguage}
                    onChange={(event) => {
                      handleUILanguageChange(event.target.value as string);
                    }}
                    fullWidth
                  >
                    <MenuItem value="ja">Japanese (日本語)</MenuItem>
                    <MenuItem value="en">English (English)</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </CustomSection>

            {/* AI言語 */}
            <CustomSection>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography variant="subtitle1">{t("preferences.settings.aiLanguage")}</Typography>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("preferences.settings.aiLanguageDescription")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"flex-end"}>
                  <Select
                    value={aiLanguage}
                    onChange={(event) => handleAILanguageChange(event.target.value as string)}
                    fullWidth
                  >
                    {aiLanguagesList.map((language) => (
                      <MenuItem key={language.language_code} value={language.language_code}>
                        {language.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </CustomSection>

            {/* 国 */}
            <CustomSection>
              <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} sm={8}>
                  <Typography variant="subtitle1">{t("preferences.settings.country")}</Typography>
                  <Typography variant="body2" color={"text.primary"}>
                    {t("preferences.settings.countryDescription")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} display={"flex"} justifyContent={"flex-end"}>
                  <Select
                    value={country}
                    onChange={(event) => handleCountryChange(event.target.value as string)}
                    sx={{ minWidth: 150 }}
                    fullWidth
                  >
                    {countriesList.map((country) => (
                      <MenuItem key={country.country_code} value={country.country_code}>
                        {country.country_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </CustomSection>
          </Paper>
        </Fade>
      </Box>
    </>
  );
};

export default Settings;
