import React, { createContext, useContext, useState } from "react";

// Contextの型定義
type SlideContextType = {
    content: string;
    setContent: React.Dispatch<React.SetStateAction<string>>;
    tone: string;
    setTone: React.Dispatch<React.SetStateAction<string>>;
    headings: string;
    setHeadings: React.Dispatch<React.SetStateAction<string>>;
    srcType: string;
    setSrcType: React.Dispatch<React.SetStateAction<string>>;
    additionalText: string;
    setAdditionalText: React.Dispatch<React.SetStateAction<string>>;
    target: string;
    setTarget: React.Dispatch<React.SetStateAction<string>>;
};

// Contextの作成
const SlideContext = createContext<SlideContextType | undefined>(undefined);

// プロバイダコンポーネント
export const SlideProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({children}) => {
    const [content, setContent] = useState<string>('');
    const [tone, setTone] = useState('');
    const [headings, setHeadings] = useState<string>('');
    const [additionalText, setAdditionalText] = useState<string>('');
    const [srcType, setSrcType] = useState<string>('url');
    const [target, setTarget] = useState<string>('');

    return (
        <SlideContext.Provider value={{
            content,
            setContent,
            tone,
            setTone,
            headings,
            setHeadings,
            additionalText,
            setAdditionalText,
            srcType,
            setSrcType,
            target,
            setTarget
        }}>
            {children}
        </SlideContext.Provider>
    );
};

// コンテキストを使用するためのカスタムフック
export const useSlideContext = () => {
    const context = useContext(SlideContext);
    if (context === undefined) {
        throw new Error('useSlide must be used within an SlideProvider');
    }
    return context;
};

export default SlideContext;
