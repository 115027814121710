const dashboard = {
    title: "Dashboard",
    greeting: "Hello, {{name}}.",
    welcome: "Welcome to Tenak AI.",
    search: "Search, Chat",
    addWorkspace: "Create Workspace",
    text: "Create Document",
    addText: "Blank document",
    addSlide: "Blank slide",
    addMindmap: "Blank mind map",
    image: "Image",
    mindmap: "Create Mindmap / Brainstorming",
    voice: "Voice",
    misc: "MISC AI",
    library: "Library",
}
export default dashboard;