import LoginIcon from "@mui/icons-material/Login";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { alpha } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import FadeInSection from "../../../components/common/FadeInSection";
import Meta from "../../../components/common/Meta";
import { useLoginModalContext } from "../../../context/LoginModalContext";
import { ColumnCenteredBox } from "../../../utils/styledBox";
import Browsing from "./components/Browsing";
import Editor from "./components/Editor";
import Misc from "./components/Misc";
import Seamless from "./components/Seamless";
import WhatIs from "./components/WhatIs";
import WritingAI from "./components/WritingAI";
import MindMap from "./components/MindMap";
import { GradationButton } from "../../../utils/gradationButton";
import { MindMapProvider } from "../../../context/MindmapContext";
import { useLocation } from "react-router-dom";

const Top = () => {
  const { t } = useTranslation();
  const { setActiveModal } = useLoginModalContext();
  const handleLogin = () => {
    setActiveModal("login");
  };
  const location = useLocation();

  // 現在のパスに基づいてメタタグを設定
  const meta = [
    { name: "description", content: t("meta.description") },
    // en か ja 以外なら noindex を適用
    {
      name: "robots",
      content:
        location.pathname.startsWith("/en") || location.pathname.startsWith("/ja")
          ? "index, follow"
          : "noindex, nofollow",
    },
  ];

  return (
    <>
      <Meta meta={meta} />

      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: { xs: 800, md: "100vh" },
          maxHeight: "1080px",
          mb: 0,
          overflow: "hidden",
        }}
      >
        {/* 背景動画 */}
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: -1,
          }}
        >
          <source src="/videos/top/FVZO_animation.mp4" type="video/mp4" />
          <img src="/images/top/fvzo.png" alt="FVZO" />
        </video>

        {/* コンテンツ */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            width: "100%",
            textAlign: "center",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            backdropFilter: "blur(0px)",
            zIndex: 1,
          }}
        >
          <Container maxWidth="lg">
            <Typography variant="h1" gutterBottom fontWeight={800}>
              {t("top.fv.welcome")}
            </Typography>
            <Typography variant="h4" component={"h2"} gutterBottom fontWeight={600}>
              {t("top.fv.catchCopy")}
            </Typography>
            <Typography variant="subtitle1" sx={{ mb: 4 }} gutterBottom fontWeight={600}>
              {t("top.fv.description")}
            </Typography>
            <Button
              disableElevation
              variant="contained"
              color="primary"
              onClick={handleLogin}
              size={"large"}
              startIcon={<LoginIcon />}
            >
              {t("top.login")}
            </Button>
          </Container>
        </Box>
      </Box>

      <ColumnCenteredBox
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.secondary.contrastText, 0.9),
          backdropFilter: "blur(20px)",
        })}
      >
        <FadeInSection>
          <WhatIs />
        </FadeInSection>
      </ColumnCenteredBox>

      <ColumnCenteredBox
        sx={(theme) => ({
          backgroundColor: theme.palette.background.custom1,
        })}
      >
        <Container maxWidth="lg">
          <Grid container spacing={2} my={4}>
            <Grid item xs={12} md={6}>
              <FadeInSection>
                <ColumnCenteredBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                    overflow: "hidden",
                  })}
                >
                  <Browsing />
                </ColumnCenteredBox>
              </FadeInSection>
            </Grid>
            <Grid item xs={12} md={6}>
              <FadeInSection>
                <ColumnCenteredBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                    overflow: "hidden",
                  })}
                >
                  <Editor />
                </ColumnCenteredBox>
              </FadeInSection>
            </Grid>
            <Grid item xs={12} md={12}>
              <FadeInSection>
                <ColumnCenteredBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                  })}
                >
                  <Seamless />
                </ColumnCenteredBox>
              </FadeInSection>
            </Grid>
            <Grid item xs={12} md={12}>
              <FadeInSection>
                <ColumnCenteredBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                  })}
                >
                  <MindMapProvider>
                    <MindMap />
                  </MindMapProvider>
                </ColumnCenteredBox>
              </FadeInSection>
            </Grid>
            <Grid item xs={12} md={8}>
              <FadeInSection>
                <ColumnCenteredBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                  })}
                >
                  <WritingAI />
                </ColumnCenteredBox>
              </FadeInSection>
            </Grid>
            <Grid item xs={12} md={4}>
              <FadeInSection>
                <ColumnCenteredBox
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.paper,
                    borderRadius: 2,
                    height: "100%",
                  })}
                >
                  <Misc />
                  <Box flexGrow={1} />
                </ColumnCenteredBox>
              </FadeInSection>
            </Grid>
          </Grid>
        </Container>
      </ColumnCenteredBox>
      <ColumnCenteredBox>
        <FadeInSection>
          <ColumnCenteredBox sx={{ py: { xs: 4, sm: 8 }, px: 2 }} gap={4}>
            <ColumnCenteredBox>
              <Typography variant={"h4"} component={"h2"} fontFamily={"'Noto Serif JP', roboto"} gutterBottom>
                {t("top.fv.cta.title")}
              </Typography>
              <Typography>{t("top.fv.cta.description")}</Typography>
            </ColumnCenteredBox>
            <img src={"/images/logo/logoZI-small.png"} alt={"tenakai"} style={{ width: "64px", height: "64px" }} />
            <Box display={"flex"} justifyContent={"center"} flexDirection={{ xs: "column", md: "row" }} gap={2}>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => setActiveModal("login")}
                sx={{ width: 200 }}
                startIcon={<LoginIcon />}
              >
                {t("top.fv.cta.loginButton")}
              </Button>
              <GradationButton startIcon={<LoginIcon />} sx={{ width: 200 }} onClick={() => setActiveModal("signup")}>
                {t("top.fv.cta.signupButton")}
              </GradationButton>
            </Box>
          </ColumnCenteredBox>
        </FadeInSection>
      </ColumnCenteredBox>
    </>
  );
};

export default Top;
