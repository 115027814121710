import i18n from "i18next";
import Cookies from "js-cookie";
import { initReactI18next } from "react-i18next";
import { english } from "./english/english";
import { japanese } from "./japanese/japanese";

export const loadUserLanguageFromCookie = () => {
  const lang_path = window.location.pathname.split("/")[1];
  if (lang_path === "ja" || lang_path === "en") {
    Cookies.set("ui_language", lang_path, { expires: 180 });
    return lang_path;
  } else {
    const LANG_COOKIE_KEY = "ui_language";
    const language = Cookies.get(LANG_COOKIE_KEY);
    return language || "en"; // クッキーが存在しない場合はデフォルトの言語を返す
  }
};

i18n.use(initReactI18next).init({
  resources: {
    en: english,
    ja: japanese,
  },
  lng: loadUserLanguageFromCookie(), // デフォルト言語
  fallbackLng: "en",
  debug: false,
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
