const file = {
    title: "ファイル",
    deleteFile: {
        title: 'ファイルを削除しますか？',
        description: 'ファイルを削除すると、ファイル内のすべてのデータが削除されます。この操作は取り消せません。',
        success: 'ファイルが削除されました。',
    },
    saveFile: 'ファイルを保存しました。',
    createFile: 'ファイルを作成しました。',
    alert: {
        title: '文章が確認されませんでした。',
        description: 'テキストエディター内の変更したい文章をドラッグしてからボタンを押してください。',
    }

}
export default file;