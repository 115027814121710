// rootReducer.ts
import { combineReducers } from "@reduxjs/toolkit";
import { browsingReducer } from "./browsingSlice";
import { FileReducer } from "./fileSlice";
import { triggerReducer } from "./triggerSlice";

const rootReducer = combineReducers({
  browsing: browsingReducer,
  file: FileReducer,
  trigger: triggerReducer,
});

export default rootReducer;
