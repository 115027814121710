import React, { ReactNode } from "react";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { Container, Paper } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkIcon from "@mui/icons-material/Link";
import ArticleIcon from "@mui/icons-material/Article";
import TitleIcon from "@mui/icons-material/Title";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Grow from "@mui/material/Grow";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import FolderIcon from "@mui/icons-material/Folder";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import { useDispatch } from "react-redux";
import { setCreditTrigger } from "../../redux/slices/triggerSlice";
import { useCheckCredit } from "../../hooks/useCreditCheck";
import { useTheme } from "@mui/system";
import { GradationButton } from "../../utils/gradationButton";
import { StyledTypography } from "../../utils/styledTypography";
import Meta from "../../components/common/Meta";
import { useAlert } from "../../context/AlertContext";
import { ColumnCenteredBox } from "../../utils/styledBox";
import { GradientTypography } from "../../utils/gradientTypography";

const MindMapGenerator = () => {
  const { t } = useTranslation();
  const { checkCredit } = useCheckCredit();
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useCustomNavigate();
  const [generating, setGenerating] = React.useState(false);
  const dispatch = useDispatch();
  const theme = useTheme();
  const { setAlert } = useAlert();

  const getDomainFromUrl = (url: string): string => {
    try {
      const { hostname } = new URL(url);
      return hostname;
    } catch (e) {
      return "";
    }
  };

  const urlRegex = /^https?:\/\/.+/;
  const titleFormSchema = z.object({
    title: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(100, { message: t("validation.maxLength", { max: 100 }) }),
    additional: z.string().max(400, { message: t("validation.maxLength", { max: 400 }) }),
  });
  const textFormSchema = z.object({
    text: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(8000, { message: t("validation.maxLength", { max: "8,000" }) }),
    additional: z.string().max(400, { message: t("validation.maxLength", { max: 400 }) }),
  });
  const youtubeFormSchema = z.object({
    youtube: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(2000, { message: t("validation.maxLength", { max: "2,000" }) })
      .refine((url) => urlRegex.test(url) && getDomainFromUrl(url)?.includes("youtube"), {
        message: "Invalid YouTube URL format.",
      }),
    additional: z.string().max(400, { message: t("validation.maxLength", { max: 400 }) }),
  });

  const urlFormSchema = z.object({
    url: z
      .string()
      .min(1, { message: t("validation.required") })
      .max(2000, { message: t("validation.maxLength", { max: "2,000" }) })
      .refine((url) => urlRegex.test(url) && !getDomainFromUrl(url)?.includes("youtube"), {
        message: t("validation.invalidURL"),
      }),
    additional: z.string().max(400, { message: t("validation.maxLength", { max: 400 }) }),
  });

  type TabDataProps = {
    title: string;
    description: string;
    placeholder: string;
    inputLabel: string;
    name: "title" | "text" | "youtube" | "url";
    icon: JSX.Element;
    submitType: string;
  };

  const tabsData: TabDataProps[] = [
    {
      title: "mindmap.titleToMindMap",
      description: "mindmap.titleToMindMapDescription",
      placeholder: "mindmap.titlePlaceholder",
      inputLabel: "mindmap.titleInput",
      name: "title",
      icon: <TitleIcon />,
      submitType: "title",
    },
    {
      title: "mindmap.URLToMindMap",
      description: "mindmap.URLToMindMapDescription",
      placeholder: "mindmap.urlPlaceholder",
      inputLabel: "mindmap.URL",
      name: "url",
      icon: <LinkIcon />,
      submitType: "url",
    },
    {
      title: "mindmap.YouTubeToMindMap",
      description: "mindmap.YouTubeToMindMapDescription",
      placeholder: "mindmap.youtubePlaceholder",
      inputLabel: "mindmap.URL",
      name: "youtube",
      icon: <YouTubeIcon />,
      submitType: "youtube",
    },
    {
      title: "mindmap.TextToMindMap",
      description: "mindmap.TextToMindMapDescription",
      placeholder: "mindmap.textPlaceholder",
      inputLabel: "mindmap.TextToMindMap",
      name: "text",
      icon: <ArticleIcon />,
      submitType: "text",
    },
  ];

  const schemaMapping: { [key: string]: any } = {
    title: titleFormSchema,
    text: textFormSchema,
    youtube: youtubeFormSchema,
    url: urlFormSchema,
  };

  const currentSchema = schemaMapping[tabsData[tabValue].submitType] || urlFormSchema;

  type FormData = z.infer<typeof currentSchema>;
  const {
    handleSubmit,
    watch,
    getValues,
    register,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: zodResolver(currentSchema),
  });

  // Submit
  const sendMessage = async (data: any) => {
    try {
      if (!(await checkCredit())) return;
      setGenerating(true);
      setAlert("info", t("common.generatingMessage"));
      const url = "/api/v1/mind-map/";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ data }),
      });
      const responseData = await response.json();
      if (responseData.status === "success") {
        setGenerating(false);
        dispatch(setCreditTrigger(true));
        navigate("/mind-map/" + responseData.uuid);
      } else {
        setAlert("error", responseData.message);
        setGenerating(false);
      }
    } catch (e) {
      console.error(e);
      setAlert("error", t("common.error.somethingWentWrong"));
      setGenerating(false);
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box component="div">{children}</Box>}
      </div>
    );
  }

  function tabProps(index: number) {
    return { id: `simple-tab-${index}`, "aria-controls": `simple-tabpanel-${index}` };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => setTabValue(newValue);

  type CenteredBoxProps = { children: ReactNode };
  const CenteredBox: React.FC<CenteredBoxProps> = ({ children }) => (
    <Box sx={{ justifyContent: "center", display: "flex", my: 2, alignItems: "center" }}>{children}</Box>
  );

  const maxLength = (submitType: string) => {
    switch (submitType) {
      case "title":
        return 100;
      case "text":
        return 8000;
      case "additional":
        return 400;
      default:
        return 2000;
    }
  };

  return (
    <>
      <Meta title={t("mindmap.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Grow in={true}>
        <Container maxWidth={"md"}>
          <Box sx={{ height: "100%", mb: 2 }}>
            <DrawerHeader />
            <Paper elevation={theme.palette.mode === "dark" ? 1 : 0} sx={{ my: 4 }}>
              <Box
                sx={{
                  background: (theme) => theme.custom.gradient.main,
                  borderRadius: "4px 4px 0 0",
                  p: { xs: 1 },
                }}
              />
              <ColumnCenteredBox sx={{ position: "relative", p: { xs: 2, md: 4 }, width: "100%" }}>
                <GradientTypography variant="h5" component={"h2"} sx={{ position: "relative", mb: 2 }}>
                  {t("mindmap.title")}
                </GradientTypography>

                <Box sx={{ justifyContent: "center", display: "flex" }}>
                  <img alt={"logo"} src="/images/logo/logo.png" width={"75px"} />
                </Box>
                <CenteredBox>
                  <FlashOnIcon sx={{ mr: 1 }} color={"secondary"} />
                  <Typography variant={"body1"} gutterBottom>
                    {t("mindmap.description")}
                  </Typography>
                </CenteredBox>
                <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4, width: "100%" }}>
                  <Tabs value={tabValue} onChange={handleChange} centered variant="fullWidth">
                    {tabsData.map((tab, index) => (
                      <Tab icon={tab.icon} label={t(tab.title)} {...tabProps(index)} key={index} />
                    ))}
                  </Tabs>
                </Box>
                <Box sx={{ width: "100%" }}>
                  {tabsData.map((tab, index) => (
                    <CustomTabPanel value={tabValue} index={index} key={index}>
                      <CenteredBox>
                        <StyledTypography variant={"body1"} gutterBottom>
                          {t(tab.description)}
                        </StyledTypography>
                      </CenteredBox>
                      <Box>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit((data) =>
                              sendMessage({
                                ...data,
                                submitType: tab.submitType,
                              })
                            )(e);
                          }}
                          noValidate
                        >
                          <TextField
                            {...register(tab.name, {})}
                            sx={{ mb: 2 }}
                            fullWidth
                            autoFocus
                            autoComplete={"off"}
                            multiline={tab.submitType === "text"}
                            rows={tab.submitType === "text" ? 10 : 1}
                            placeholder={t(tab.placeholder)}
                            label={t(tab.inputLabel)}
                            error={!!errors[tab.name]}
                            helperText={(errors[tab.name]?.message as string) || ""}
                            inputProps={{
                              maxLength: maxLength(tab.submitType),
                            }}
                          />
                          <TextField
                            {...register("additional", {})}
                            sx={{ mb: 2 }}
                            fullWidth
                            multiline
                            minRows={3}
                            maxRows={8}
                            error={!!errors["additional"]}
                            placeholder={t("mindmap.additionalPlaceholder")}
                            label={t("mindmap.additional")}
                            inputProps={{
                              maxLength: maxLength("additional"),
                            }}
                          />
                          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Button onClick={() => navigate("/library/mind-maps")} variant={"text"} sx={{ mr: 2 }}>
                              <FolderIcon sx={{ mr: 1 }} />
                              <Typography variant={"button"}>{t("mindmap.library")}</Typography>
                            </Button>

                            <GradationButton type={"submit"} disabled={generating}>
                              {generating && (
                                <>
                                  <Typography variant={"button"}>{"Generating..."}</Typography>
                                  <CircularProgress size={20} sx={{ ml: 1 }} />
                                </>
                              )}
                              {!generating && (
                                <>
                                  <AutoAwesomeIcon sx={{ mr: 1 }} />
                                  <Typography variant={"button"}>{t("mindmap.submit")}</Typography>
                                </>
                              )}
                            </GradationButton>
                          </Box>
                        </form>
                      </Box>
                    </CustomTabPanel>
                  ))}
                </Box>
              </ColumnCenteredBox>
            </Paper>
          </Box>
        </Container>
      </Grow>
    </>
  );
};
export default MindMapGenerator;
