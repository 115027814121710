const meta = {
  title: "Tenak AI",
  keywords: "Tenak AI, tenak.ai, テナクAI, テナク, Studio Juno, Notes Nebula, Nebula",
  description:
    "Tenak AIは、ライティングAI、画像生成AI、音声生成AIを提供するコンテンツ制作プラットフォームです。クリエイターの効率的で高品質な制作をサポートします。",
  notesNebula: {
    title: "Notes Nebula",
    keywords: "Tenak AI, Notes Nebula, ノーツネビュラ, ノート, ネビュラ",
    description:
      "Notes Nebulaは、Tenak AIのAIテキストエディターで執筆した記事を簡単に公開できるプラットフォームです。あなたのアイデアを世界に発信しましょう。",
  },
  juno: {
    title: "STUDIO JUNO",
    keywords: "Tenak AI, STUDIO JUNO, ジュノー, スタジオジュノー, 画像生成AI",
    description:
      "STUDIO JUNOは、多様な画像生成AIを一つのプラットフォームで使用できる、Tenak AIの画像生成サービスです。あなたのビジョンを形にします。",
  },
  pricing: {
    title: "プランと価格",
    description:
      "Tenak AIの各サービスの価格プランをご紹介します。あなたのニーズに合わせて最適なプランをお選びいただけます。",
  },
};

export default meta;
