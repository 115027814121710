// 見出しの左に線を引く
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";

export const StyledTypography = styled(Typography)(({ theme }) => ({
  position: "relative",
  paddingLeft: "15px",

  "&:before": {
    content: '""',
    position: "absolute",
    left: "0px", // 左に10pxのスペースを追加
    top: 0,
    bottom: 0,
    width: "6px",
    height: "60%",
    margin: "auto",
    backgroundColor: theme.palette.primary.main,
    borderRadius: "20px",
  },
}));