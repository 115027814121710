import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useLoginStatus } from "../context/LoginStatusContext";

const useSubscription = () => {
  const [isFree, setIsFree] = useState(true);
  const { isLoggedIn } = useLoginStatus();

  const getSubscription = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const res = await axios.get("/api/v1/users/plan", config);
      return res.data;
    } catch (error) {
      console.error("Failed to obtain customer:", error);
      return undefined;
    }
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    const checkPlan = async () => {
      const res = await getSubscription();
      setIsFree(res.subscription?.lookup_key === "free");
    };
    checkPlan();
  }, []);

  const handleCreatePortal = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const config = { headers: { "X-CSRFToken": csrftoken } };
      const res = await axios.get("/api/v1/users/stripe-portal", config);
      window.location.href = res.data.url;
    } catch (error) {
      console.error("Failed to cancel subscription:", error);
    }
  };

  return { isFree, getSubscription, handleCreatePortal };
};

export default useSubscription;
