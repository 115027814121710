import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useFileSidebarContext } from "../../../../context/FileSidebarContext";
import { RootState } from "../../../../redux/store";

interface SidebarInputTextFieldProps {
  label?: string;
}

// サイドバーのテキストフィールド
const SidebarInputTextField = ({ label }: SidebarInputTextFieldProps) => {
  const { t } = useTranslation();
  const { originalText, setOriginalText } = useFileSidebarContext();
  const { fileUuid } = useParams();
  const selection = useSelector((state: RootState) => state.file.selection)[fileUuid!] || { text: "" };
  const { selectedButton } = useFileSidebarContext();
  const textFieldRef = React.useRef<HTMLInputElement>(null);
  const [rows, setRows] = useState(1);
  const [autoInsert, setAutoInsert] = useState(true);

  // カーソル位置に選択範囲挿入
  const handleInsertSelection = () => {
    const textField = textFieldRef.current;
    if (!textField) return;
    if (selection.text.trim() === "") return;

    let textToInsert = selection.text.trim();

    if (textToInsert.length > 4000) {
      textToInsert = textToInsert.slice(0, 4000);
    }

    setOriginalText(textToInsert);
  };

  // 選択範囲が変わったときに挿入
  useEffect(() => {
    if (autoInsert) {
      handleInsertSelection();
    }
  }, [selection, autoInsert]);

  const handleToggleAutoInsert = (event: React.MouseEvent) => {
    event.preventDefault(); // onBlurを発生させないようにする
    setAutoInsert(!autoInsert);
  };

  const handleClearText = (event: React.MouseEvent) => {
    event.preventDefault(); // onBlurを発生させないようにする
    setOriginalText("");
  };

  const handleFocus = () => {
    setRows(8);
  };

  const handleBlur = () => {
    setRows(1);
  };

  return (
    <Box>
      <TextField
        maxRows={rows}
        multiline
        ref={textFieldRef}
        value={originalText}
        variant={"outlined"}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={(e) => {
          setOriginalText(e.target.value);
        }}
        label={label ? label : t("textEditor.sidebar.selection")}
        sx={{
          width: "100%",
          transition: "all 300ms ease-in-out",
          "& .MuiInputBase-root": {
            transition: "height 300ms ease-in-out",
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Tooltip title={t("textEditor.sidebar.copyFromSelection")}>
                <span>
                  <IconButton onMouseDown={handleToggleAutoInsert}>
                    {autoInsert ? <CheckBoxIcon fontSize={"small"} /> : <CheckBoxOutlineBlankIcon fontSize={"small"} />}
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title={t("textEditor.sidebar.clear")}>
                <span>
                  <IconButton onMouseDown={handleClearText}>
                    <DeleteIcon fontSize={"small"} color={"error"} />
                  </IconButton>
                </span>
              </Tooltip>
            </InputAdornment>
          ),
          inputProps: {
            maxLength: 4000,
          },
        }}
      />

      <Box display={"flex"} justifyContent={"flex-end"} alignItems={"center"} sx={{ width: "100%" }} mb={1}>
        <Typography variant={"caption"} color={originalText.length === 4000 ? "error" : "textSecondary"} mr={1}>
          {`${originalText.length} / 4000`}
        </Typography>
      </Box>
    </Box>
  );
};
export default SidebarInputTextField;
