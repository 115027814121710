import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DrawerHeader } from "../../../components/layout/Drawer/DrawerMain";
import PreferencesRouter from "../../../routes/PreferencesRouter";
import Meta from "../../../components/common/Meta";

export default function Layout() {
  const { t } = useTranslation();
  const location = useLocation();

  const currentTab = (() => {
    switch (location.pathname.split("/").pop()) {
      case "account":
        return 0;
      case "plan-and-billings":
        return 1;
      case "settings":
        return 2;
      case "misc":
        return 3;
      default:
        return 0;
    }
  })();

  return (
    <>
      <Meta title={t("preferences.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <Container maxWidth="md">
        <DrawerHeader />
        <Typography component={"h2"} variant="h4" gutterBottom my={2}>
          {t("preferences.title")}
        </Typography>
        <Box component="div" sx={{ marginTop: { xs: 1, sm: 2 } }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={currentTab} scrollButtons="auto" variant="scrollable">
              <Tab label={t("preferences.account.title")} component={Link} to="account" />
              <Tab label={t("preferences.planAndBillings.title")} component={Link} to="plan-and-billings" />
              <Tab label={t("preferences.settings.title")} component={Link} to="settings" />
              <Tab label={t("preferences.misc.title")} component={Link} to="misc" />
            </Tabs>
          </Box>

          <PreferencesRouter />
        </Box>
      </Container>
    </>
  );
}