import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Meta from "../../components/common/Meta";
import { FileSidebarProvider } from "../../context/FileSidebarContext";
import { setFileType } from "../../redux/slices/fileSlice";
import { RootState } from "../../redux/store";
import { uiHeight } from "../../utils/uiHeight";
import Generating from "./components/Generating";
import Sidebar from "./components/Sidebar";
import SlideEditor from "./components/SlideEditor";
import TextEditor from "./components/TextEditor/TextEditor";

function TextFile() {
  const { fileUuid } = useParams();
  const [status, setStatus] = useState(3);
  const dispatch = useDispatch();
  const type = useSelector((state: RootState) => state.file.fileType)[fileUuid!] || 1;
  const { t } = useTranslation();

  const getTextFile = async () => {
    try {
      if (!fileUuid) return;
      const res = await axios.get("/api/v1/text-file/" + fileUuid);
      if (res.data) {
        dispatch(setFileType({ uuid: fileUuid, type: res.data.type }));
        return res.data;
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const fetchTextFile = async () => {
    const res = await getTextFile();
    if (res) {
      setStatus(res.status);
    }
  };

  // 最初のuseEffect
  useEffect(() => {
    fetchTextFile();
  }, [fileUuid]);

  // 生成中は5秒毎に取得
  useEffect(() => {
    if (status !== 3) {
      return;
    }

    const timer = setInterval(() => {
      fetchTextFile();
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, [status]);

  return (
    <>
      <Meta title={t("textEditor.title")} meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <DrawerHeader />
      <Box sx={{ borderRadius: 0, height: uiHeight }}>
        {status !== 3 && (
          <Box key={fileUuid} height={"100%"} px={2}>
            <Grid container spacing={2} justifyContent="center" height={"100%"}>
              <Grid item xs={12} md={7.2} height={"100%"}>
                {type === 1 && <TextEditor />}
                {type === 2 && <SlideEditor />}
              </Grid>
              <Grid item xs={12} md={4.8}>
                <FileSidebarProvider>
                  <Sidebar />
                </FileSidebarProvider>
              </Grid>
            </Grid>
          </Box>
        )}
        {status === 3 && <Generating />}
      </Box>
    </>
  );
}

export default TextFile;
