import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, IconButton } from "@mui/material";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import Tooltip from "@mui/material/Tooltip";
import { SiFacebook, SiHatenabookmark, SiLine, SiWhatsapp } from "react-icons/si";
import {
  FacebookShareButton,
  HatenaShareButton,
  LineShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { loadUserLanguageFromCookie } from "../../../i18n/i18n";
import XIcon from "@mui/icons-material/X";
import Typography from "@mui/material/Typography";

const ActionButton = ({ title: articleTitle }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const fileUuid = location.pathname.split("/")[4];
  const lang = loadUserLanguageFromCookie();
  const publicUuid = location.pathname.split("/")[5] || "";
  const [isLiked, setIsLiked] = useState(false);
  const url = window.location.href;

  // いいね
  const handleLikeClick = async () => {
    try {
      const url = `/api/v1/notes-nebula/like`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify({ uuid: fileUuid }),
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
    } catch (e) {
      console.error(e);
    }
  };

  // いいねの状態を取得
  const getLike = async () => {
    try {
      const url = `/api/v1/notes-nebula/like?uuid=${fileUuid}`;
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const result = await response.json();
      setIsLiked(result.is_liked);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getLike();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "row", md: "column" },
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        mb: 2,
      }}
    >
      <Tooltip title={t("notesNebula.article.like")} placement="left">
        <IconButton onClick={handleLikeClick}>
          {isLiked ? <FavoriteIcon color="error" /> : <FavoriteBorderIcon />}
        </IconButton>
      </Tooltip>
      {/*  Twitter  */}
      <Tooltip title={t("notesNebula.article.x")} placement="left">
        <div>
          <TwitterShareButton url={url} title={articleTitle} hashtags={["TenakAI", "NotesNebula"]}>
            <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
              <XIcon />
            </Typography>
          </TwitterShareButton>
        </div>
      </Tooltip>
      {/*  Facebook  */}
      <Tooltip title={t("notesNebula.article.facebook")} placement="left">
        <div>
          <FacebookShareButton url={url}>
            <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
              <SiFacebook />
            </Typography>
          </FacebookShareButton>
        </div>
      </Tooltip>

      {/* Whatsapp*/}
      <Tooltip title={t("notesNebula.article.whatsapp")} placement="left">
        <div>
          <WhatsappShareButton url={url} title={articleTitle}>
            <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
              <SiWhatsapp />
            </Typography>
          </WhatsappShareButton>
        </div>
      </Tooltip>

      {/*  はてなブックマーク  */}
      {lang === "ja" && (
        <Tooltip title={t("notesNebula.article.hatena")} placement="left">
          <div>
            <HatenaShareButton url={url} title={articleTitle}>
              <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
                <SiHatenabookmark />
              </Typography>
            </HatenaShareButton>
          </div>
        </Tooltip>
      )}

      {/*  LINE  */}
      {lang === "ja" && (
        <Tooltip title={t("notesNebula.article.line")} placement="left">
          <div>
            <LineShareButton url={url} title={articleTitle}>
              <Typography sx={{ fontSize: "24px" }} color={"textSecondary"}>
                <SiLine />
              </Typography>
            </LineShareButton>
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

export default ActionButton;
