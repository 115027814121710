import React, { useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import { useNotice } from "../../context/NoticeContext";

function TransitionRight(props: any) {
  return <Slide {...props} direction="right" />;
}

function Notice() {
  const { openNoticeTrigger, severity, message } = useNotice();
  const [open, setOpen] = useState(false);
  const [transition] = useState(() => TransitionRight);

  useEffect(() => {
    if (message === "") {
      return;
    }
    setOpen(true);
  }, [openNoticeTrigger]);

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      TransitionComponent={transition}
      TransitionProps={{ timeout: { enter: 1500, exit: 1500 } }}
    >
      <Alert severity={severity}>{message}</Alert>
    </Snackbar>
  );
}

export default Notice;
