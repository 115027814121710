import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material";

const FAQ = () => {
  const { t } = useTranslation();
  const faq = 5;
  const theme = useTheme();

  return (
    <>
      {/* クレジットについて */}
      <Container maxWidth="md" sx={{ my: 8 }}>
        <Box>
          <Typography variant={"h4"} gutterBottom>
            {t("pricing.faq.title")}
          </Typography>
          <Typography paragraph sx={{ my: 4 }}>
            {t("pricing.faq.description")}
          </Typography>
        </Box>
        {/* FAQ */}
        {[...Array(faq)].map((_, i) => (
          <Accordion key={i} elevation={theme.palette.mode === "dark" ? 1 : 0}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${i}-content`}
              id={`panel${i}-header`}
            >
              <Typography>{t(`pricing.faq.q${i + 1}`)}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t(`pricing.faq.a${i + 1}`)}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Container>
    </>
  );
};

export default FAQ;
