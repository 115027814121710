import React, { useEffect, useState } from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Skeleton } from "@mui/material";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

interface Image {
  url: string;
  title: string;
  description: string;
}

const Section3 = () => {
  const { t } = useTranslation();
  const [images, setImages] = useState<Image[]>([]);
  const [loadedImages, setLoadedImages] = useState<boolean[]>([]); // 画像読み込み状態を管理

  // 画像一覧取得 & 検索
  const fetchImageList = async () => {
    try {
      const url = "/api/v1/juno/images?page=1&num=12";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({ "Content-Type": "application/json", "X-CSRFToken": csrftoken! });
      const response = await fetch(url, { method: "GET", headers: headers });
      const newImages: Image[] = await response.json();
      setImages(newImages);
      setLoadedImages(new Array(newImages.length).fill(false)); // 初期状態はすべて未ロード
    } catch (e) {
      console.error(e);
    }
  };

  // 画像が読み込まれたら状態を更新
  const handleImageLoad = (index: number) => {
    setLoadedImages((prevState) => {
      const newLoadedImages = [...prevState];
      newLoadedImages[index] = true; // 該当インデックスの画像をロード済みに変更
      return newLoadedImages;
    });
  };

  useEffect(() => {
    fetchImageList();
  }, []);

  return (
    <>
      <Box component={"section"}>
        <Grid container spacing={{ xs: 2 }}>
          <Grid item xs={12}>
            <ColumnCenteredBox>
              <Typography
                variant={"h4"}
                component={"p"}
                fontWeight={"bold"}
                fontFamily={"'Noto Serif JP', roboto"}
                my={2}
                textAlign={"center"}
              >
                {t("aboutJuno.section3.title")}
              </Typography>
              <Typography
                variant={"h6"}
                component={"p"}
                fontWeight={"bold"}
                fontFamily={"'Noto Serif JP', roboto"}
                my={2}
                textAlign={"center"}
              >
                Gallery
              </Typography>
            </ColumnCenteredBox>
          </Grid>

          {/* 画像一覧 */}
          {images.map((image, index) => (
            <Grid item xs={4} md={3} key={index}>
              <ColumnCenteredBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* Skeletonを表示して、画像がロードされ次第Skeletonを非表示 */}
                {!loadedImages[index] && (
                  <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: 2, height: { xs: 120, md: 280 } }} />
                )}
                <Box
                  component="img"
                  src={`${image.url}w300`}
                  alt={image.title}
                  onLoad={() => handleImageLoad(index)} // 画像がロードされたら呼び出し
                  sx={{
                    display: loadedImages[index] ? "block" : "none", // 画像ロード時に表示
                    objectFit: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    height: { xs: 120, md: 280 },
                    width: "100%",
                    borderRadius: 2,
                    position: "relative",
                    overflow: "hidden",
                  }}
                />
              </ColumnCenteredBox>
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default Section3;
