// Workspaceの削除
import React from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { GradientTypography } from "../../../utils/gradientTypography";

export default function Top() {
  const { t } = useTranslation();

  return (
    <Box
      flexDirection={"column"}
      justifyContent={"center"}
      display={"flex"}
      alignItems={"center"}
      sx={{ marginTop: { xs: 10, sm: 14 } }}
    >
      <Box flexDirection={"row"} display={"flex"} alignItems={"baseline"}>
        <Box display={"flex"} sx={{}}>
          <Typography
            sx={{
              typography: { xs: "subtitle1", md: "h4" },
            }}
            component={"span"}
            fontWeight={800}
            textAlign={"center"}
          >
            {t("browsing.preTitle")}
          </Typography>
        </Box>
        <Box display={"flex"}>
          <GradientTypography
            variant="h2"
            textAlign={"center"}
            sx={{
              fontFamily: "'Pacifico',cursive",
              px: { xs: 1, md: 2 },
              fontWeight: 500,
            }}
          >
            {t("title2")}
          </GradientTypography>
        </Box>
      </Box>
      <Box my={{ xs: 1, md: 2 }}>
        <img alt={"logo"} src="/images/logo/logo.png" width={"100px"} />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1 }}>
        <SearchIcon />
        <Typography variant="body1" gutterBottom textAlign={"center"} ml={1}>
          {t("browsing.subtitle")}
        </Typography>
      </Box>
    </Box>
  );
}