export const aiEngineColor = (ai_engine) => {
  switch (ai_engine) {
    case "DallE3":
    case "DALL·E 3":
      return "#53ad96";
    case "SD":
    case "Stable Diffusion":
      return "#7753ad";
    case "SDXL":
    case "Stable Diffusion XL":
      return "#5365ad";
    case "SD3":
    case "Stable Diffusion 3":
      return "#377079";
    case "SDUltra":
    case "Stable Image Ultra":
      return "#b64a4a";
    case "AuraSR":
    case "Aura SR":
      return "#314b54";
    case "Flux1Pro":
    case "FLUX.1 Pro":
      return "#3b3b4f";
    case "Flux1Dev":
    case "FLUX.1 Dev":
      return "#443b4f";
    case "Flux1Schnell":
    case "FLUX.1 Schnell":
      return "#4f3b4f";
    case "Flux1RealismLoRA":
    case "FLUX.1 with Realism LoRA":
      return "#3b424f";
    case "Ideogram2":
    case "Ideogram 2.0":
      return "#186210";
    case "Ideogram2Turbo":
    case "Ideogram 2.0 Turbo":
      return "#607c51";
    default:
      return "#181818";
  }
};
