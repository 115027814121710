import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLoginStatus } from "../../context/LoginStatusContext";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "@mui/material/TextField";
import axios from "axios";
import Cookies from "js-cookie";
import ValidationTextField from "../../components/common/ValidationTextField";
import AvatarUpload from "./components/AvatarUpload";
import { useAlert } from "../../context/AlertContext";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import LogoutIcon from "@mui/icons-material/Logout";
import SaveIcon from "@mui/icons-material/Save";
import { CustomSection } from "../../components/common/CustomSection";
import { useNotice } from "../../context/NoticeContext";

const Account = () => {
  const { t } = useTranslation();
  const { user } = useLoginStatus();
  const [isEditingUserName, setIsEditingUserName] = useState(false);
  const [isEditingIntroduction, setIsEditingIntroduction] = useState(false);
  const [username, setUsername] = useState(user?.username);
  const [introduction, setIntroduction] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { setAlert } = useAlert();
  const { triggerNotice } = useNotice();

  const handleEditUserName = () => {
    setIsEditingUserName(true);
  };

  const handleEditIntroduction = () => {
    setIsEditingIntroduction(true);
  };

  const getUserIntroduction = async () => {
    try {
      const res = await axios.get("/api/v1/users/user");
      setIntroduction(res.data.introduction || "");
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getUserIntroduction();
  }, []);

  const handleIntroductionSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoadingIntroduction(true);
    try {
      const url = "/api/v1/users/update-introduction";
      axios.defaults.withCredentials = true;

      // CSRFトークンの取得
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };

      const response = await axios.patch(url, { introduction }, config);

      if (response.data.success) {
        console.log("Introduction updated successfully.");
        setIsEditingIntroduction(false);
        setIntroductionErrorMessage("");
      } else {
        console.log("Error updating introduction:", response.data.message);
      }
    } catch (error: any) {
      console.log("An error occurred while updating introduction:", error.response?.data);
      setIntroductionErrorMessage(error.response?.data.message.introduction[0]);
    }
    setIsLoadingIntroduction(false);
  };

  const handleIntroductionCancel = () => {
    getUserIntroduction();
    setIntroductionErrorMessage("");
    setIsEditingIntroduction(false);
  };

  const [isLoadingUsername, setIsLoadingUsername] = useState(false);
  const [isLoadingIntroduction, setIsLoadingIntroduction] = useState(false);
  const [usernameErrorMessage, setUsernameErrorMessage] = useState("");
  const [introductionErrorMessage, setIntroductionErrorMessage] = useState("");

  const handleUsernameSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoadingUsername(true);
    try {
      const url = "/api/v1/users/update-username";
      axios.defaults.withCredentials = true;

      // CSRFトークンの取得
      const csrftoken = Cookies.get("csrftoken");
      // リクエストヘッダーにCSRFトークンを追加
      const config = {
        headers: { "X-CSRFToken": csrftoken },
      };

      const response = await axios.patch(url, { username }, config);

      if (response.data.success) {
        console.log("Username updated successfully.");
        setIsEditingUserName(false);
        setUsernameErrorMessage("");
      } else {
        console.log("Error updating username:", response.data.message);
      }
    } catch (error: any) {
      console.log("An error occurred while updating username:", error.response?.data);
      setUsernameErrorMessage(error.response?.data.message.username[0]);
    }
    setIsLoadingUsername(false);
  };

  const handleUserNameCancel = () => {
    setUsername(user?.username);
    setUsernameErrorMessage("");
    setIsEditingUserName(false);
  };

  const loginStatus = useLoginStatus();

  const handleLogout = async () => {
    try {
      await axios.get("/api/v1/auth/logout");
      loginStatus.checkLoginStatus();
      triggerNotice("info", `You have successfully logged out.`);
      // navigate('');
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const handleDelete = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const res = await axios.delete("/api/v1/users/user", {
        headers: { "X-CSRFToken": csrftoken },
      });
      if (res.data.success) {
        loginStatus.checkLoginStatus();
        triggerNotice("info", `Your account has been deleted successfully.`);
      } else {
        setAlert("error", res.data.message);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  return (
    <>
      <Box component="div" mb={4}>
        <Typography component={"h3"} variant="h5" gutterBottom my={2}>
          {t("preferences.account.title")}
        </Typography>

        <Fade in={true} timeout={500}>
          <Paper elevation={0} sx={{ width: "100%" }}>
            <CustomSection>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">{t("preferences.account.username")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {isEditingUserName ? (
                    <form onSubmit={handleUsernameSubmit} style={{ width: "100%" }}>
                      <Box display={"flex"} flexDirection={"column"} alignItems={"baseline"} gap={1} width={"100%"}>
                        <ValidationTextField
                          label="Username"
                          type="username"
                          value={username ? username : ""}
                          variant={"standard"}
                          helperText={usernameErrorMessage}
                          error={usernameErrorMessage !== ""}
                          onChange={(e) => setUsername(e.target.value)}
                          autoFocus={true}
                          toolTipPlacement={"top"}
                          tooltipMessage={t("top.tooltip.username")}
                          fullWidth
                          inputProps={{ style: { textTransform: "lowercase" } }}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                            width: "100%",
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }} />
                          <Button
                            disableElevation
                            onClick={handleUserNameCancel}
                            variant="text"
                            color="primary"
                            size="small"
                            sx={{ minWidth: "75px" }}
                          >
                            {t("common.cancel")}
                          </Button>
                          <Button
                            disableElevation
                            type={"submit"}
                            variant="outlined"
                            color="primary"
                            startIcon={<SaveIcon />}
                            size="small"
                            disabled={isLoadingUsername}
                          >
                            {t("common.save")}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  ) : (
                    <Box flexDirection={"row"} sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                      <TextField variant={"standard"} disabled value={username} sx={{ width: "100%", marginLeft: 1 }} />
                      <IconButton onClick={handleEditUserName} size="small">
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CustomSection>

            {/* メールアドレス */}
            <CustomSection>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">{t("preferences.account.email")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="body1" mr={1}>
                    {user?.email}
                  </Typography>
                </Grid>
              </Grid>
            </CustomSection>

            {/* アバター */}
            <CustomSection>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">{t("preferences.account.avatar")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <AvatarUpload isAvatar={true} user={user} editable={true} />
                </Grid>
              </Grid>
            </CustomSection>

            {/* プロフィール文 */}
            <CustomSection>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">{t("preferences.account.introduction")}</Typography>
                  <Typography variant="body2">{t("preferences.account.introductionDescription")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  {isEditingIntroduction ? (
                    <form onSubmit={handleIntroductionSubmit} style={{ width: "100%" }}>
                      <Box display={"flex"} flexDirection={"column"} alignItems={"baseline"} gap={1} width={"100%"}>
                        <Box width={"100%"}>
                          <ValidationTextField
                            label="Introduction"
                            type="introduction"
                            value={introduction ? introduction : ""}
                            variant={"standard"}
                            helperText={introductionErrorMessage}
                            error={introductionErrorMessage !== ""}
                            onChange={(e) => setIntroduction(e.target.value)}
                            placeholder={t("preferences.account.introductionPlaceholder")}
                            autoFocus={true}
                            fullWidth
                            multiline
                            rows={5}
                            inputProps={{
                              maxLength: 500,
                            }}
                          />
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            {introduction.length}/500
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 1,
                            width: "100%",
                          }}
                        >
                          <Box sx={{ flexGrow: 1 }} />
                          <Button
                            disableElevation
                            onClick={handleIntroductionCancel}
                            variant="text"
                            color="primary"
                            size="small"
                            sx={{ minWidth: "75px" }}
                          >
                            {t("common.cancel")}
                          </Button>
                          <Button
                            disableElevation
                            type={"submit"}
                            variant="outlined"
                            color="primary"
                            startIcon={<SaveIcon />}
                            size="small"
                            disabled={isLoadingIntroduction}
                          >
                            {t("common.save")}
                          </Button>
                        </Box>
                      </Box>
                    </form>
                  ) : (
                    <Box flexDirection={"row"} sx={{ display: "flex", alignItems: "flex-end", width: "100%" }}>
                      <TextField
                        variant={"standard"}
                        disabled
                        multiline
                        rows={5}
                        value={introduction}
                        sx={{ width: "100%", marginLeft: 1 }}
                      />
                      <IconButton onClick={handleEditIntroduction} size="small">
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </CustomSection>

            {/* ログアウト */}
            <CustomSection>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">{t("top.logout")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    disableElevation
                    onClick={handleLogout}
                    variant="text"
                    sx={{ width: 180 }}
                    color="error"
                    startIcon={<LogoutIcon />}
                  >
                    {t("top.logout")}
                  </Button>
                </Grid>
              </Grid>
            </CustomSection>

            {/* アカウント削除 */}
            <CustomSection>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                <Grid item xs={12} md={6}>
                  <Typography variant="subtitle1">{t("top.delete")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    disableElevation
                    onClick={() => setOpenDeleteDialog(true)}
                    variant="contained"
                    sx={{ width: 180 }}
                    color="error"
                    startIcon={<DeleteIcon />}
                  >
                    {t("top.delete")}
                  </Button>
                </Grid>
              </Grid>
            </CustomSection>
          </Paper>
        </Fade>
      </Box>
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <Box sx={{ p: 2 }}>
          <DialogTitle>{t("top.delete")}</DialogTitle>
          <DialogContent>
            <Typography>{t("preferences.account.deleteDialog.description")}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              disableElevation
              onClick={() => setOpenDeleteDialog(false)}
              variant="text"
              color="primary"
              startIcon={<CancelIcon />}
            >
              {t("common.cancel")}
            </Button>
            <Button
              disableElevation
              onClick={handleDelete}
              variant="contained"
              color="error"
              startIcon={<DeleteIcon />}
            >
              {t("top.delete")}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default Account;
