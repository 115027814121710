import React from "react";
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { useTranslation } from "react-i18next";
import Grow from "@mui/material/Grow";
import { uiHeight } from "../../../utils/uiHeight";

function Generating() {
  const { t } = useTranslation();

  return (
    <Grow in={true} timeout={1000}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: uiHeight,
          flexDirection: "column",
        }}
      >
        <Typography variant={"h4"} component={"h2"} fontWeight={600}>
          Generating now...
        </Typography>
        <Box sx={{ width: "30%", my: 2 }}>
          <LinearProgress />
        </Box>
        <Typography variant={"subtitle1"}>{t("textEditor.processing.description")}</Typography>
      </Box>
    </Grow>
  );
}

export default Generating;
