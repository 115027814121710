import React from "react";
import { ColumnCenteredBox } from "../../../../utils/styledBox";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import ReactCompareImage from "react-compare-image";
import Skeleton from "@mui/material/Skeleton";
import { useTranslation } from "react-i18next";

const Section1 = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box component={"section"}>
        <Grid container spacing={{ xs: 2 }}>
          <Grid item xs={12}>
            <Typography
              variant={"h5"}
              component={"h3"}
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              mb={2}
              textAlign={"center"}
            >
              {t("aboutJuno.section1.title")}
            </Typography>
          </Grid>

          {/* t2i */}
          <Grid item xs={12} md={6}>
            <ColumnCenteredBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" component="h3" fontWeight={"bold"} fontFamily={"'Noto Serif JP', roboto"} mb={2}>
                {t("aboutJuno.section1.t2i.title")}
              </Typography>
              <Box
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  height: { xs: 300, md: 350 },
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <ReactCompareImage
                  leftImage="/images/juno/t2i-before.jpg"
                  rightImage="/images/juno/t2i-after.jpg"
                  leftImageAlt={"before"}
                  leftImageLabel={"Before"}
                  rightImageAlt={"after"}
                  rightImageLabel={"After"}
                  skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                />
              </Box>
              <Typography variant="body1" component="p">
                {t("aboutJuno.section1.t2i.description")}
              </Typography>
            </ColumnCenteredBox>
          </Grid>

          {/* i2i */}
          <Grid item xs={12} md={6}>
            <ColumnCenteredBox
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" component="h3" fontWeight={"bold"} fontFamily={"'Noto Serif JP', roboto"} mb={2}>
                {t("aboutJuno.section1.i2i.title")}
              </Typography>
              <Box
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  height: { xs: 300, md: 350 },
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  overflow: "hidden",
                }}
              >
                <ReactCompareImage
                  leftImage="/images/juno/i2i-before.jpg"
                  rightImage="/images/juno/i2i-after.jpg"
                  leftImageAlt={"before"}
                  leftImageLabel={"Before"}
                  rightImageAlt={"after"}
                  rightImageLabel={"After"}
                  skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                />
              </Box>
              <Typography variant="body1" component="p">
                {t("aboutJuno.section1.i2i.description")}
              </Typography>
            </ColumnCenteredBox>
          </Grid>

          {/* upscaler */}
          <Grid item xs={12}>
            <Typography
              variant="h6"
              component="h3"
              fontWeight={"bold"}
              fontFamily={"'Noto Serif JP', roboto"}
              textAlign={"center"}
              mb={2}
            >
              {t("aboutJuno.section1.upscale.title")}
            </Typography>

            <Grid container spacing={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    backgroundImage: "url(/images/juno/sample01.jpg)",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    width: "100%",
                    minHeight: 200,
                    height: "100%",
                    maxHeight: 350,
                    borderRadius: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <Box
                  sx={{
                    borderRadius: 2,
                    height: { xs: 200, md: 350 },
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                  }}
                >
                  <ReactCompareImage
                    leftImage="/images/juno/before01.jpg"
                    rightImage="/images/juno/after01.jpg"
                    leftImageAlt={"before"}
                    leftImageLabel={"Before"}
                    rightImageAlt={"after"}
                    rightImageLabel={"After"}
                    skeleton={<Skeleton variant="rectangular" width="100%" height={"100%"} />}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" component="p" textAlign={"center"}>
                  {t("aboutJuno.section1.upscale.description")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Section1;