import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { getFileDateName } from "../../../utils/utils";
import IconButton from "@mui/material/IconButton";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from "@mui/icons-material/Save";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { setFileText, setSelection, setShouldReplace, setSlides } from "../../../redux/slices/fileSlice";
import { Tooltip, useTheme } from "@mui/material";
import { RootState } from "../../../redux/store";
import { createSelector } from "reselect";
import CopyButtonOrIcon from "../../../components/common/CopyButtonOrIcon";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import { useAlert } from "../../../context/AlertContext";
import { uiHeight } from "../../../utils/uiHeight";
import { useCustomNavigate } from "../../../hooks/useCustomNavigate";

interface Slide {
  heading: string;
  text: string;
}

function SlideEditor() {
  const { t } = useTranslation();
  const { fileUuid } = useParams();
  const theme = useTheme();
  // const selectSlides = createSelector(
  //     (state: RootState, fileUuid: string) => state.file.slides[fileUuid] || [{heading: '', text: ''}],
  //     (slides) => slides
  // );
  const selectSlides = createSelector(
    (state: RootState, fileUuid: string) => state.file.slides[fileUuid],
    (slides) => (slides ? [...slides] : [])
  );

  const refs = useRef<HTMLInputElement[]>([]);
  const slides = useSelector((state: RootState) => selectSlides(state, fileUuid!));
  const selection = useSelector((state: RootState) => state.file.selection)[fileUuid!] || { text: "" };
  const dispatch = useDispatch();
  const shouldReplace = useSelector((state: RootState) => state.file.shouldReplace[fileUuid!]);
  const [saveIcon, setSaveIcon] = useState(<SaveIcon sx={{ mr: 1 }} fontSize={"small"} />);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { setAlert } = useAlert();
  const navigate = useCustomNavigate();

  const handleDeleteDialogOpen = () => {
    setDialogOpen(true);
  };

  // refs 配列を slides の数だけ初期化
  useEffect(() => {
    refs.current = refs.current.slice(0, slides.length);
  }, [slides]);

  // テキストフィールドのカーソル位置を更新
  const handleSelect = (index: number) => {
    const input = refs.current[index];
    if (input && input.selectionStart !== null && input.selectionEnd !== null) {
      const start = input.selectionStart;
      const end = input.selectionEnd;
      const length = end - start;
      const text = input.value.slice(start, end);
      dispatch(
        setSelection({
          uuid: fileUuid!,
          index: start,
          length: length,
          text: text,
          slide: index,
        })
      );
    }
  };

  // テキスト置換処理
  useEffect(() => {
    if (shouldReplace) {
      const { index, length, text, slide } = selection;
      console.log("shouldReplace", index, length, text, slide);
      if (index === undefined || length === undefined || text === undefined || slide === undefined) return;
      const slideNum = slide > 999 ? slide - 1000 : slide;
      const input = refs.current[slide || 0];
      console.log("input", input);
      if (!input) return;
      const before = input.value.slice(0, index);
      const after = input.value.slice(index + length);
      const newText = before + text + after;
      input.value = newText;
      const newSlides = [...slides];
      if (slide > 999) {
        // Indexが1000以上はスライドの見出しを更新
        newSlides[slideNum || 0] = { ...newSlides[slideNum || 0], heading: newText };
      } else {
        newSlides[slideNum || 0] = { ...newSlides[slideNum || 0], text: newText };
      }
      dispatch(
        setSlides({
          uuid: fileUuid!,
          slides: newSlides,
        })
      );
      // 処理が完了したらトリガーをオフにする
      dispatch(setShouldReplace({ uuid: fileUuid!, shouldReplace: false }));
    }
  }, [shouldReplace]);

  // スライドテキストを更新
  const setSlideText = () => {
    const text = slides
      .map((slide) => {
        return `${slide.heading}\n${slide.text}`;
      })
      .join("\n");
    dispatch(
      setFileText({
        uuid: fileUuid!,
        content: text,
      })
    );
    dispatch(
      setSlides({
        uuid: fileUuid!,
        slides: slides,
      })
    );
  };

  const getTextFile = async () => {
    try {
      if (!fileUuid) return;
      const res = await axios.get("/api/v1/text-file/" + fileUuid);
      if (res.data.status === 1) {
        const fixedJson = res.data.content?.replace(/'/g, '"');
        const jsonData = JSON.parse(fixedJson || '[{"heading":"", "text":""}]');
        dispatch(
          setSlides({
            uuid: fileUuid!,
            slides: jsonData,
          })
        );
        return res.data;
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    } finally {
    }
  };

  useEffect(() => {
    getTextFile().then();
  }, [fileUuid]);

  // useEffect(() => {
  //     setSlideText();
  // }, [slides]);

  const addSlide = (index: number) => {
    const newSlides = [...slides];
    newSlides.splice(index + 1, 0, { heading: "", text: "" });
    dispatch(
      setSlides({
        uuid: fileUuid!,
        slides: newSlides,
      })
    );
  };

  const removeSlide = (index: number) => {
    dispatch(
      setSlides({
        uuid: fileUuid!,
        slides: slides.filter((_, i) => i !== index),
      })
    );
  };

  const updateSlide = (index: number, field: keyof Slide, value: string) => {
    const newSlides = [...slides];

    // 新しいスライドオブジェクトを作成して更新
    const updatedSlide = { ...newSlides[index], [field]: value };

    // 更新されたスライドを配列に戻す
    newSlides[index] = updatedSlide;

    // 更新されたスライド配列をReduxステートに反映
    dispatch(
      setSlides({
        uuid: fileUuid!,
        slides: newSlides,
      })
    );
  };

  const moveSlide = (index: number, direction: "up" | "down") => {
    const newSlides = [...slides];
    if (direction === "up" && index > 0) {
      [newSlides[index], newSlides[index - 1]] = [newSlides[index - 1], newSlides[index]];
    } else if (direction === "down" && index < slides.length - 1) {
      [newSlides[index], newSlides[index + 1]] = [newSlides[index + 1], newSlides[index]];
    }
    dispatch(
      setSlides({
        uuid: fileUuid!,
        slides: newSlides,
      })
    );
  };

  const handleSave = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const headers = {
        "X-CSRFToken": csrftoken!,
      };
      const res = await axios.patch(
        "/api/v1/text-file/" + fileUuid,
        {
          title: slides[0].heading.trim() || "Untitled",
          content: JSON.stringify(slides),
          status: 1,
        },
        { headers }
      );

      if (res.data.status === 1) {
        setSaveIcon(<CheckIcon sx={{ mr: 1 }} fontSize={"small"} />);
        setTimeout(() => {
          setSaveIcon(<SaveIcon sx={{ mr: 1 }} fontSize={"small"} />);
        }, 3000);
      }
    } catch (error) {
      console.error("An unknown error occurred:", error);
    }
  };

  const downloadCSV = () => {
    const csv = slides
      .map((slide) => {
        return `${slide.heading},${slide.text}`;
      })
      .join("\n");
    const bom = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blob = new Blob([bom, csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "Tenak_AI_slides_" + getFileDateName() + ".csv";
    a.click();
    a.remove();
  };

  // 削除
  const handleConfirmedDelete = async () => {
    try {
      const csrftoken = Cookies.get("csrftoken");
      const response = await fetch("/api/v1/text-file/" + fileUuid, {
        method: "DELETE",
        headers: {
          "X-CSRFToken": csrftoken!,
        },
      });

      if (!response.ok) {
        setAlert("error", t("library.text.delete.error"));
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      await response.json();
      navigate("/library/text");
      setAlert("success", t("library.text.delete.success"));
    } catch (error) {
      console.error("Error during the fetch operation:", error);
      setAlert("error", t("library.text.delete.error"));
    }
  };

  return (
    <Fade in={true} timeout={1000}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          height: uiHeight,
        }}
      >
        <Box sx={{ mb: 2 }}>
          {slides.map((slide, index) => (
            <Paper key={index} sx={{ mb: 1, padding: 2 }} elevation={theme.palette.mode === "dark" ? 1 : 0}>
              <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: 1 }}>
                <Box
                  sx={{
                    mr: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6">SLIDE </Typography>
                  <Typography variant="h3" component={"p"} color="textSecondary">
                    {index + 1}{" "}
                  </Typography>
                  {index === 0 && (
                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                      {t("slide.coverPage")}
                    </Typography>
                  )}
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ mb: 2, width: "100%" }}>
                    <TextField
                      inputRef={(el) => (refs.current[index + 1000] = el)}
                      size={"small"}
                      variant="outlined"
                      label={index === 0 ? t("slide.titleInput") : t("slide.headingInput")}
                      fullWidth
                      value={slide.heading}
                      onChange={(e) => updateSlide(index, "heading", e.target.value)}
                      onBlur={setSlideText}
                      onSelect={() => handleSelect(index + 1000)}
                    />
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      inputRef={(el) => (refs.current[index] = el)}
                      size={"small"}
                      variant="outlined"
                      label={index === 0 ? t("slide.sloganInput") : t("slide.textInput")}
                      fullWidth
                      multiline
                      minRows={3}
                      value={slide.text}
                      onChange={(e) => updateSlide(index, "text", e.target.value)}
                      onBlur={setSlideText}
                      onSelect={() => handleSelect(index)}
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Tooltip title={t("slide.moveUp")} placement={"right"}>
                    <span>
                      <IconButton color={"primary"} onClick={() => moveSlide(index, "up")} disabled={index === 0}>
                        <ArrowUpwardIcon fontSize={"small"} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t("slide.moveDown")} placement={"right"}>
                    <span>
                      <IconButton
                        color={"primary"}
                        onClick={() => moveSlide(index, "down")}
                        disabled={index + 1 === slides.length}
                      >
                        <ArrowDownwardIcon fontSize={"small"} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t("slide.addSlide")} placement={"right"}>
                    <span>
                      <IconButton color={"primary"} onClick={() => addSlide(index)}>
                        <AddIcon fontSize={"small"} />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t("slide.deleteSlide")} placement={"right"}>
                    <span>
                      <IconButton color={"primary"} onClick={() => removeSlide(index)} disabled={slides.length === 1}>
                        <RemoveIcon fontSize={"small"} />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          ))}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}>
            <Button
              disableElevation
              variant={"outlined"}
              color={"primary"}
              sx={{ width: "auto" }}
              onClick={downloadCSV}
            >
              <Typography variant={"button"} sx={{ display: "flex", alignItems: "center" }}>
                <DownloadIcon fontSize={"small"} sx={{ mr: 1 }} />
                {t("slide.download")}
              </Typography>
            </Button>
            <CopyButtonOrIcon
              textToCopy={slides
                .map((slide) => {
                  return `- ${slide.heading}\n${slide.text}\n`;
                })
                .join("\n")}
              displayType={"button"}
              variant={"outlined"}
            />
            <Button
              disableElevation
              variant={"contained"}
              color={"error"}
              sx={{ width: "auto" }}
              onClick={handleDeleteDialogOpen}
            >
              <Typography variant={"button"} sx={{ display: "flex", alignItems: "center" }}>
                <DeleteIcon />
                {t("common.delete")}
              </Typography>
            </Button>
            <Button
              disableElevation
              variant={"contained"}
              color={"primary"}
              sx={{ width: "auto" }}
              onClick={handleSave}
            >
              <Typography variant={"button"} sx={{ display: "flex", alignItems: "center" }}>
                {saveIcon}
                {t("common.save")}
              </Typography>
            </Button>
          </Box>

          {/* 削除確認ダイアログ */}
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>{t("library.text.delete.title")}</DialogTitle>
            <DialogContent>
              <DialogContentText>{t("library.text.delete.description")}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button disableElevation onClick={() => setDialogOpen(false)} color="primary">
                {t("common.cancel")}
              </Button>
              <Button
                color="error"
                onClick={() => {
                  handleConfirmedDelete().then();
                  setDialogOpen(false);
                }}
              >
                {t("common.delete")}
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Fade>
  );
}

export default React.memo(SlideEditor);
