import { Grid, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { StyledTypography } from "../../../utils/styledTypography";
import ArticleCard from "./ArticleCard";

const Latest = ({ limit }: { limit: number }) => {
  const { t } = useTranslation();
  const [latestArticles, setLatestArticles] = useState([] as any);
  const location = useLocation();
  const lang = location.pathname.split("/")[1];

  const get_articles = async () => {
    try {
      const res = await axios.get("/api/v1/notes-nebula/?type=latest&lang=" + lang + "&limit=" + limit);
      setLatestArticles(res.data.data.latest_articles);

      res.data.data.latest_articles.map((item: any) => {
        if (item["thumbnail"]) {
          const img = new Image();
          img.src = item["thumbnail"];
          img.onload = () => {
            setLoadedImages((loadedImages) => [...loadedImages, item["uuid"]]);
          };
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    get_articles();
  }, [location.pathname]);

  const [loadedImages, setLoadedImages] = useState([] as any);

  return (
    <>
      <StyledTypography variant={"h6"} sx={{ mb: 2 }}>
        {t("notesNebula.latest")}
      </StyledTypography>
      <Grid container spacing={2}>
        {latestArticles.length > 0 ? (
          latestArticles.map((item: any, index: number) => (
            <Grid item xs={12} md={4} lg={2.4} key={index}>
              <ArticleCard article={item} loadedImages={loadedImages} />
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle1" sx={{ my: 2, ml: 4 }}>
            No articles found.
          </Typography>
        )}
      </Grid>
    </>
  );
};
export default Latest;
