import axios from "axios";
import Cookies from "js-cookie";
import React, { createContext, useContext, useState } from "react";
import { useCustomNavigate } from "../hooks/useCustomNavigate";
import { getFileDateName } from "../utils/utils";

export type AiEngineList =
  | "DallE3"
  | "SD"
  | "SDXL"
  | "SD3"
  | "SDUltra"
  | "Flux1Pro"
  | "Flux1Dev"
  | "Flux1Schnell"
  | "Flux1RealismLoRA"
  | "Ideogram2"
  | "Ideogram2Turbo"
  | "AuraSR"
  | "ClarityUpscaler"
  | "CreativeUpscaler";

export type ImageInfo = {
  uuid: string;
  request_uuid: string;
  request_status: string;
  url: string;
  params: string;
  safe_level: number;
  created_at: string;
  is_public: boolean;
  r2_image: string;
};

// Contextの型定義
type JunoImageGeneratorContextType = {
  width: { [key in AiEngineList]?: number };
  setWidthPartial: (key: AiEngineList, value: number) => void;
  height: { [key in AiEngineList]?: number };
  setHeightPartial: (key: AiEngineList, value: number) => void;
  sample: Record<AiEngineList, number | undefined>;
  setSample: React.Dispatch<React.SetStateAction<Record<AiEngineList, number | undefined>>>;
  modelSD: any;
  setModelSD: React.Dispatch<
    React.SetStateAction<{
      name: string;
      id: string;
      screenShot: string;
    }>
  >;
  modelSDXL: any;
  setModelSDXL: React.Dispatch<
    React.SetStateAction<{
      name: string;
      id: string;
      screenShot: string;
    }>
  >;
  loraSD: any;
  setLoraSD: React.Dispatch<
    React.SetStateAction<
      | {
          name: string;
          id: string;
          screenShot: string;
          strength: number;
        }[]
      | []
    >
  >;
  step: { [key in AiEngineList]?: number };
  setStep: React.Dispatch<React.SetStateAction<{ [key in AiEngineList]?: number }>>;
  guidanceScaleSD: number;
  setGuidanceScaleSD: React.Dispatch<React.SetStateAction<number>>;
  guidanceScaleFlux: number;
  setGuidanceScaleFlux: React.Dispatch<React.SetStateAction<number>>;
  image: string | null;
  setImage: React.Dispatch<React.SetStateAction<string | null>>;
  imagePrompt: string | null;
  setImagePrompt: React.Dispatch<React.SetStateAction<string | null>>;
  imagePromptStrength: number;
  setImagePromptStrength: React.Dispatch<React.SetStateAction<number>>;
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  prompt: string;
  setPrompt: React.Dispatch<React.SetStateAction<string>>;
  styleDall: "vivid" | "natural";
  setStyleDall: React.Dispatch<React.SetStateAction<"vivid" | "natural">>;
  styleIdeogram: "GENERAL" | "REALISTIC" | "DESIGN" | "ANIME" | "RENDER_3D";
  setStyleIdeogram: React.Dispatch<React.SetStateAction<"GENERAL" | "REALISTIC" | "DESIGN" | "ANIME" | "RENDER_3D">>;
  revisedPrompt: boolean;
  setRevisedPrompt: React.Dispatch<React.SetStateAction<boolean>>;
  negativePrompt: string;
  setNegativePrompt: React.Dispatch<React.SetStateAction<string>>;
  seedSD: number;
  setSeedSD: React.Dispatch<React.SetStateAction<number>>;
  seedFlux: number;
  setSeedFlux: React.Dispatch<React.SetStateAction<number>>;
  seedIdeogram: number;
  setSeedIdeogram: React.Dispatch<React.SetStateAction<number>>;
  refreshTrigger: number;
  setRefreshTrigger: React.Dispatch<React.SetStateAction<number>>;
  imageStrength: number;
  setImageStrength: React.Dispatch<React.SetStateAction<number>>;
  originalAspectRatio: number | null;
  setOriginalAspectRatio: React.Dispatch<React.SetStateAction<number | null>>;
  upscaleFactor: { [key in AiEngineList]: number | undefined };
  setUpscaleFactor: React.Dispatch<React.SetStateAction<{ [key in AiEngineList]: number | undefined }>>;
  upscaleDetail: { [key in AiEngineList]: number | undefined };
  setUpscaleDetail: React.Dispatch<React.SetStateAction<{ [key in AiEngineList]: number | undefined }>>;
  upscaleCreativity: { [key in AiEngineList]: number | undefined };
  setUpscaleCreativity: React.Dispatch<React.SetStateAction<{ [key in AiEngineList]: number | undefined }>>;
  upscaleResemblance: { [key in AiEngineList]: number | undefined };
  setUpscaleResemblance: React.Dispatch<React.SetStateAction<{ [key in AiEngineList]: number | undefined }>>;
  uuidParent: string;
  setUuidParent: React.Dispatch<React.SetStateAction<string>>;
  filtered: boolean;
  setFiltered: React.Dispatch<React.SetStateAction<boolean>>;
  isPublic: boolean;
  setIsPublic: React.Dispatch<React.SetStateAction<boolean>>;
  aspectRatioSD3: string;
  setAspectRatioSD3: React.Dispatch<React.SetStateAction<string>>;
  aspectRatioFlux: string;
  setAspectRatioFlux: React.Dispatch<React.SetStateAction<string>>;
  aspectRatioIdeogram: string;
  setAspectRatioIdeogram: React.Dispatch<React.SetStateAction<string>>;
  // メソッド
  handleSetSameParams: (params: any) => void;
  handleImg2Img: (image_uuid: string, params: string) => void;
  handleUpscale: (image_uuid: string) => void;
  handleDownload: (image_uuid: string) => void;
  fetchHistory: () => Promise<[ImageInfo][]>;
  fetchQueuedImages: (imageRequestUuid: string, action: string) => Promise<[ImageInfo][]>;
};

// Contextの作成
const ImageGeneratorContext = createContext<JunoImageGeneratorContextType | undefined>(undefined);

// プロバイダコンポーネント
export const JunoImageProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  type ParamsState = { [key in AiEngineList]: number | undefined };
  const navigate = useCustomNavigate();

  const [width, setWidth] = useState<ParamsState>({
    DallE3: 1024,
    SD: 768,
    SDXL: 1024,
  } as ParamsState);

  const [height, setHeight] = useState<ParamsState>({
    DallE3: 1024,
    SD: 768,
    SDXL: 1024,
  } as ParamsState);
  const setWidthPartial = (key: AiEngineList, value: number) => {
    setWidth({ ...width, [key]: value });
  };
  const setHeightPartial = (key: AiEngineList, value: number) => {
    setHeight({ ...height, [key]: value });
  };
  const [sample, setSample] = useState<Record<AiEngineList, number | undefined>>({
    DallE3: 1,
    SD: 1,
    SDXL: 1,
    SD3: 1,
    SDUltra: 1,
    Flux1Pro: 1,
    Flux1Dev: 1,
    Flux1Schnell: 1,
    Flux1RealismLoRA: 1,
    Ideogram2: undefined,
    Ideogram2Turbo: undefined,
    AuraSR: undefined,
    ClarityUpscaler: undefined,
    CreativeUpscaler: undefined,
  });

  const [modelSD, setModelSD] = useState<any>(null);
  const [modelSDXL, setModelSDXL] = useState<any>(null);
  const [loraSD, setLoraSD] = useState<any>([]);
  const [guidanceScaleSD, setGuidanceScaleSD] = useState<number>(6.5);
  const [guidanceScaleFlux, setGuidanceScaleFlux] = useState<number>(3.5);
  const [image, setImage] = useState<string | null>(null);
  const [imagePrompt, setImagePrompt] = useState<string | null>(null);
  const [imagePromptStrength, setImagePromptStrength] = useState<number>(0.35);
  const [processing, setProcessing] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("");
  const [styleDall, setStyleDall] = useState<JunoImageGeneratorContextType["styleDall"]>("vivid");
  const [styleIdeogram, setStyleIdeogram] = useState<JunoImageGeneratorContextType["styleIdeogram"]>("GENERAL");
  const [revisedPrompt, setRevisedPrompt] = useState<boolean>(true);
  const [negativePrompt, setNegativePrompt] = useState<string>("");
  const [imageStrength, setImageStrength] = useState<number>(0.35);
  const [originalAspectRatio, setOriginalAspectRatio] = useState<number | null>(null);
  const [uuidParent, setUuidParent] = useState<string>("");
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [filtered, setFiltered] = useState<boolean>(true);
  const [isPublic, setIsPublic] = React.useState(true);
  const [aspectRatioSD3, setAspectRatioSD3] = useState<string>("1:1");
  const [aspectRatioFlux, setAspectRatioFlux] = useState<string>("1:1");
  const [aspectRatioIdeogram, setAspectRatioIdeogram] = useState<string>("1:1");
  const [step, setStep] = useState<{ [key in AiEngineList]?: number }>({
    SD: 20,
    SDXL: 20,
    Flux1Pro: 28,
    Flux1Dev: 28,
    Flux1Schnell: 4,
    Flux1RealismLoRA: 28,
  });
  const [upscaleFactor, setUpscaleFactor] = useState<ParamsState>({
    AuraSR: 4,
    ClarityUpscaler: 2,
    CreativeUpscaler: 1,
  } as ParamsState);
  const [upscaleDetail, setUpscaleDetail] = useState<ParamsState>({
    CreativeUpscaler: 1,
  } as ParamsState);
  const [upscaleCreativity, setUpscaleCreativity] = useState<ParamsState>({
    AuraSR: 1,
    ClarityUpscaler: 0.35,
    CreativeUpscaler: 0.5,
  } as ParamsState);

  const [upscaleResemblance, setUpscaleResemblance] = useState<ParamsState>({
    ClarityUpscaler: 0.6,
    CreativeUpscaler: 0.25,
  } as ParamsState);

  // パラメータを再利用
  const handleSetSameParams = (params: any) => {
    console.log(params);
    setPrompt(params.prompt);
    if (params.ai_engine === "SD" || params.ai_engine === "SDXL") {
      if (params.width) setWidthPartial(params.ai_engine, params.width);
      if (params.height) setHeightPartial(params.ai_engine, params.height);
      if (params.step) setStep({ ...step, [params.ai_engine]: params.step });
      if (params.guidance_scale) setGuidanceScaleSD(params.guidance_scale);
      // if (params.seed) setSeedSD(params.seed);
      if (params.negative_prompt) setNegativePrompt(params.negative_prompt);
      if (params.lora) setLoraSD(params.lora);
      if (params.sample) setSample({ ...sample, [params.ai_engine]: params.sample });
      else setSample({ ...sample, [params.ai_engine]: 1 });
      if (params.image_strength) setImageStrength(params.image_strength);
      if (params.ai_engine === "SD") {
        setModelSD(params.model);
      } else setModelSDXL(params.model);
      setRevisedPrompt(false);
    }
    if (params.ai_engine === "DallE3") {
      setWidthPartial("DallE3", params?.width || 1024);
      setHeightPartial("DallE3", params?.height || 1024);
      setStyleDall(params?.style || "vivid");
      setRevisedPrompt(false);
      setPrompt(params.prompt);
    }
    if (params.ai_engine === "SD3" || params.ai_engine === "SDUltra") {
      setAspectRatioSD3(params?.aspect_ratio || "1:1");
      if (params.negative_prompt) setNegativePrompt(params.negative_prompt);
      // setSeedSD(params.seed || 0);
    }
    if (
      params.ai_engine === "Flux1Pro" ||
      params.ai_engine === "Flux1Dev" ||
      params.ai_engine === "Flux1Schnell" ||
      params.ai_engine === "Flux1RealismLoRA"
    ) {
      setAspectRatioFlux(params?.aspect_ratio || "1:1");
      setSeedFlux(params.seed || 0);
    }
    if (params.ai_engine === "Ideogram2" || params.ai_engine === "Ideogram2Turbo") {
      setAspectRatioIdeogram(params?.aspect_ratio || "1:1");
      setSeedIdeogram(params.seed || 0);
      setStyleIdeogram(params?.style || "GENERAL");
    }

    navigate(`/juno/generator?menu=${params.action}&ai_engine=${params.ai_engine}`);
  };

  // 画像をダウンロードしてImg2Imgにセット
  const handleImg2Img = async (image_uuid: string, params: any) => {
    try {
      setUuidParent(image_uuid);
      handleSetSameParams(params);
      const response = await axios.get("/api/v1/juno/image/" + image_uuid, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
      };
      if (
        params.ai_engine === "SD" ||
        params.ai_engine === "SDXL" ||
        params.ai_engine === "Flux1Dev" ||
        params.ai_engine === "Ideogram2" ||
        params.ai_engine === "Ideogram2Turbo"
      ) {
        navigate(`/juno/generator?menu=img2img&ai_engine=${params.ai_engine}`);
      } else {
        navigate(`/juno/generator?menu=img2img&ai_engine=SD3`);
      }
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  // 画像をダウンロードしてUpscaleにセット
  const handleUpscale = async (image_uuid: string) => {
    try {
      setUuidParent(image_uuid);
      const response = await axios.get("/api/v1/juno/image/" + image_uuid, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });

      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result as string;
        setImage(base64data);
      };
      navigate(`/juno/generator?menu=upscale&ai_engine=RealESRGAN`);
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
    }
  };

  // 画像をダウンロード
  const handleDownload = async (image_uuid: string) => {
    try {
      const response = await axios.get("/api/v1/juno/image/" + image_uuid, { responseType: "blob" });
      const blob = new Blob([response.data], { type: response.data.type });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "juno-" + getFileDateName() + ".png";
      a.click();
      return { success: true };
    } catch (error) {
      console.error("画像の取得に失敗しました:", error);
      return { success: false };
    }
  };

  // 画像一覧取得
  const fetchHistory = async () => {
    try {
      const url = "/api/v1/juno/generated-images";
      const csrftoken = Cookies.get("csrftoken");
      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      const res_json = await response.json();
      return res_json; // 正常な応答を返す
    } catch (e) {
      console.error(e);
      return []; // エラー時に空の配列を返す
    }
  };

  const fetchQueuedImages = async (imageRequestUuid: string, action: string) => {
    try {
      const url = "/api/v1/juno/queued-images?uuid=" + imageRequestUuid + "&action=" + action;
      const csrftoken = Cookies.get("csrftoken");

      const headers = new Headers({
        "Content-Type": "application/json",
        "X-CSRFToken": csrftoken!,
      });

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const res_json = await response.json();
      return res_json;
    } catch (e) {
      console.error(e);
      return [];
    }
  };

  const [seedSD, setSeedSD] = useState<number>(0);
  const [seedFlux, setSeedFlux] = useState<number>(0);
  const [seedIdeogram, setSeedIdeogram] = useState<number>(0);

  return (
    <ImageGeneratorContext.Provider
      value={{
        // サイズ
        width,
        setWidthPartial: (key, value) => setWidth((prev) => ({ ...prev, [key]: value })),
        height,
        setHeightPartial: (key, value) => setHeight((prev) => ({ ...prev, [key]: value })),

        // サンプル数
        sample,
        setSample,

        // model
        modelSD,
        setModelSD,
        modelSDXL,
        setModelSDXL,
        loraSD,
        setLoraSD,

        // CFGスケール
        guidanceScaleSD,
        setGuidanceScaleSD,

        // FluxのCFGスケール
        guidanceScaleFlux,
        setGuidanceScaleFlux,

        // アップスケールとImg2Imgの画像
        image,
        setImage,
        imageStrength,
        setImageStrength,
        originalAspectRatio,
        setOriginalAspectRatio,

        // アップスケールの倍率
        upscaleFactor,
        setUpscaleFactor,

        // アップスケールのディテール
        upscaleDetail,
        setUpscaleDetail,

        // アップスケールのクリエイティビティ
        upscaleCreativity,
        setUpscaleCreativity,

        // アップスケールのリセンブランス
        upscaleResemblance,
        setUpscaleResemblance,

        // IP Adapter
        imagePrompt,
        setImagePrompt,
        imagePromptStrength,
        setImagePromptStrength,

        // 画像処理中かどうか
        processing,
        setProcessing,

        // プロンプト
        prompt,
        setPrompt,
        negativePrompt,
        setNegativePrompt,

        // DALL-Eのスタイル
        styleDall,
        setStyleDall,

        // Ideogramのスタイル
        styleIdeogram,
        setStyleIdeogram,

        // プロンプトの拡張
        revisedPrompt,
        setRevisedPrompt,

        // シード
        seedSD: seedSD,
        setSeedSD: setSeedSD,

        // Fluxのシード
        seedFlux: seedFlux,
        setSeedFlux: setSeedFlux,

        // Ideogramのシード
        seedIdeogram: seedIdeogram,
        setSeedIdeogram,

        // History リフレッシュトリガー(値問わず変更するだけでリフレッシュされる)
        refreshTrigger,
        setRefreshTrigger,

        // アップスケール・ImgToImgの元画像のGenerated Image UUID
        uuidParent,
        setUuidParent,

        // フィルター
        filtered,
        setFiltered,

        // 公開
        isPublic,
        setIsPublic,

        // SD3のアスペクト比
        aspectRatioSD3,
        setAspectRatioSD3,

        // Fluxのアスペクト比
        aspectRatioFlux,
        setAspectRatioFlux,

        // Ideogramのアスペクト比
        aspectRatioIdeogram,
        setAspectRatioIdeogram,

        // ステップ
        step,
        setStep,

        // メソッド
        fetchHistory: fetchHistory,
        fetchQueuedImages: fetchQueuedImages,
        handleSetSameParams: handleSetSameParams,
        handleImg2Img: handleImg2Img,
        handleUpscale: handleUpscale,
        handleDownload: handleDownload,
      }}
    >
      {children}
    </ImageGeneratorContext.Provider>
  );
};

// コンシューマコンポーネント
export const useJunoImageGeneratorContext = (): JunoImageGeneratorContextType => {
  const context = useContext(ImageGeneratorContext);
  if (!context) throw new Error("useIoImageContext must be used within a IoImageProvider");
  return context;
};
