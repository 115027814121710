const library = {
    voice: {
        title: 'Voice',
        description: 'Latest 30',
        name: 'File ID',
        speakingRate: 'Speed',
        seconds: 'Seconds',
        pitch: 'Pitch',
        language: 'Language',
        text: 'Text',
        voice_name: 'Voice',
        created_at: 'Created Date',
        action: 'Action',
        size: 'Size',
        delete: {
            title: 'Are you sure you want to delete the voice file?',
            description: 'Deleting the voice file will remove all data within the file. This action cannot be undone.',
            success: 'The voice file has been deleted.',
            error: 'Failed to delete the voice file.',
        },
    },
    mindmap: {
        title: 'Mind Map',
        create: 'Add an empty mind map',
        generator: 'Mind Map Generator',
        message: {
            create: 'Created an empty mind map.',
        }
    },
    text: {
        title: 'Text & Carousel',
        fileTitle: 'Title',
        create: 'Add an empty file',
        type: {
            title: 'File Type',
            text: 'Text',
            slide: 'Carousel',
        },
        publicStatus: {
            title: 'Public Status',
            public: 'Public',
            private: 'Private',
            shared: 'Shared',
        },
        createdAt: 'Created Date',
        updatedAt: 'Updated Date',
        action: 'Action',
        delete: {
            title: 'Are you sure you want to delete the text (slide)?',
            description: 'Deleting the text (slide) will remove all data within the file. This action cannot be undone.',
            success: 'The text (slide) has been deleted.',
            error: 'Failed to delete the text (slide).',
        },
        message: {
            create: 'Created an empty text (slide).',
        }
    }
}
export default library;