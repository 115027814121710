import InfoIcon from "@mui/icons-material/Info";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useJunoImageGeneratorContext } from "../../../context/ImageGeneratorContext";
import { ColumnBox } from "../../../utils/styledBox";
import ImageUpload from "./ImageUpload";
import SelectPublic from "./SelectPublic";

export default function ParamsUpscale() {
  const { t } = useTranslation();
  const context = useJunoImageGeneratorContext();
  const params = new URLSearchParams(window.location.search);
  const menu = params.get("menu") || "txt2img";
  const ai_engine = params.get("ai_engine") || "DallE3";
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // アップロード時に画像の横幅を取得
  useEffect(() => {
    if (context.image) {
      const img = new Image();
      img.src = context.image;
    }
  }, [context.image]);

  const handleSliderChange = (event: Event, value: number | number[]) => {
    context.setUpscaleFactor((prev) => ({ ...prev, [ai_engine]: value as number }));
  };

  useEffect(() => {
    context.setPrompt("");
  }, [ai_engine]);

  // ClarityUpscalerの最大値を返す
  const maxFactor = () => {
    if (ai_engine === "CreativeUpscaler") {
      if (context.image) {
        const img = new Image();
        img.src = context.image;
        img.onload = () => {
          setWidth(img.width);
          setHeight(img.height);
        };
        // 4194304px　を超えない範囲の最大値、小数点第一位まで
        return Math.floor(Math.min(2048 / width, 2048 / height) * 10) / 10;
      } else {
        return 2;
      }
    } else {
      return 4;
    }
  };

  useEffect(() => {
    const factor = ai_engine === "AuraSR" ? 4 : ai_engine === "CreativeUpscaler" ? 1 : 1;
    context.setUpscaleFactor((prev) => ({ ...prev, [ai_engine]: factor }));
  }, [context.image]);

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 2 }}>
        <SettingsSuggestIcon sx={{ mr: 1 }} />
        <Typography variant={"subtitle1"} component={"p"}>
          {t("juno.input.basicSettings")}
        </Typography>
      </Box>

      <Box mb={2}>
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={1} mb={1}>
          <Typography variant={"body2"}>{t("juno.input.image")}</Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} alignItems={"center"} sx={{ mb: 1 }}>
          <ImageUpload />
        </Box>
      </Box>

      {/* upscale サイズ */}
      {menu === "upscale" && (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Typography variant={"body2"} sx={{ mr: 1 }}>
              {t("juno.input.scale")}
            </Typography>
            <Tooltip title={t("juno.input.scaleInfo")} placement="right">
              <IconButton size={"small"}>
                <InfoIcon fontSize={"small"} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
            <Slider
              defaultValue={1}
              value={context.upscaleFactor[ai_engine]}
              onChange={handleSliderChange}
              min={1}
              max={maxFactor()}
              step={0.1}
              disabled={ai_engine === "AuraSR"}
            />
            <Box
              sx={{
                width: "120px",
                bgcolor: "rgba(0, 0, 0, 0.1)",
                px: 1,
                py: 0.5,
                justifyContent: "center",
                display: "flex",
                borderRadius: "5px",
              }}
            >
              <Typography variant={"body2"}>{context.upscaleFactor[ai_engine]} x</Typography>
            </Box>
          </Box>

          {ai_engine === "ClarityUpscaler" && (
            // creativity Slider
            <ColumnBox>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.creativity")}
                  </Typography>
                  <Tooltip title={t("juno.input.creativityInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={0.35}
                    value={context.upscaleCreativity[ai_engine]}
                    onChange={(event, value) =>
                      context.setUpscaleCreativity((prev) => ({ ...prev, [ai_engine]: value as number }))
                    }
                    min={0}
                    max={1}
                    step={0.1}
                  />
                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{context.upscaleCreativity[ai_engine]}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.resemblance")}
                  </Typography>
                  <Tooltip title={t("juno.input.resemblanceInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={0.6}
                    value={context.upscaleResemblance[ai_engine]}
                    onChange={(event, value) =>
                      context.setUpscaleResemblance((prev) => ({ ...prev, [ai_engine]: value as number }))
                    }
                    min={0.1}
                    max={1}
                    step={0.1}
                  />
                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{context.upscaleResemblance[ai_engine]}</Typography>
                  </Box>
                </Box>
              </Box>
            </ColumnBox>
          )}

          {ai_engine === "CreativeUpscaler" && (
            <ColumnBox>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.creativity")}
                  </Typography>
                  <Tooltip title={t("juno.input.creativityInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={0.35}
                    value={context.upscaleCreativity[ai_engine]}
                    onChange={(event, value) =>
                      context.setUpscaleCreativity((prev) => ({ ...prev, [ai_engine]: value as number }))
                    }
                    min={0}
                    max={1}
                    step={0.1}
                  />

                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{context.upscaleCreativity[ai_engine]}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.detail")}
                  </Typography>
                  <Tooltip title={t("juno.input.detailInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={1}
                    value={context.upscaleDetail[ai_engine]}
                    onChange={(event, value) =>
                      context.setUpscaleDetail((prev) => ({ ...prev, [ai_engine]: value as number }))
                    }
                    min={0}
                    max={5}
                    step={0.1}
                  />
                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{context.upscaleDetail[ai_engine]}</Typography>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Typography variant={"body2"} sx={{ mr: 1 }}>
                    {t("juno.input.resemblance")}
                  </Typography>
                  <Tooltip title={t("juno.input.resemblanceInfo")} placement="right">
                    <IconButton size={"small"}>
                      <InfoIcon fontSize={"small"} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", pb: 2 }} gap={2}>
                  <Slider
                    defaultValue={4}
                    value={context.upscaleResemblance[ai_engine]}
                    onChange={(event, value) =>
                      context.setUpscaleResemblance((prev) => ({ ...prev, [ai_engine]: value as number }))
                    }
                    min={ai_engine === "CreativeUpscaler" ? 0 : 0}
                    max={ai_engine === "CreativeUpscaler" ? 3 : 1}
                    step={0.1}
                  />

                  <Box
                    sx={{
                      width: "120px",
                      bgcolor: "rgba(0, 0, 0, 0.1)",
                      px: 1,
                      py: 0.5,
                      justifyContent: "center",
                      display: "flex",
                      borderRadius: "5px",
                    }}
                  >
                    <Typography variant={"body2"}>{context.upscaleResemblance[ai_engine]}</Typography>
                  </Box>
                </Box>
              </Box>
            </ColumnBox>
          )}

          {context.uuidParent && <SelectPublic />}
        </Box>
      )}
    </>
  );
}
