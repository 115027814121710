import { Route, Routes } from "react-router-dom";
import Explore from "../pages/Juno/Explore";
import ImageGenerator from "../pages/Juno/ImageGenerator";
import GalleryAndLikes from "../pages/Juno/GalleryAndLikes";
import ImageTemplates from "../pages/Juno/ImageTemplates";
import React from "react";

const JunoRouter = () => {
  return (
    <Routes>
      <Route path="" element={<Explore />} />
      <Route path="generator" element={<ImageGenerator />} />
      <Route path="gallery" element={<GalleryAndLikes />} />
      <Route path="likes" element={<GalleryAndLikes />} />
      <Route path="quick-generator" element={<ImageTemplates />} />
    </Routes>
  );
};
export default JunoRouter;