import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

type MetaProps = {
  title?: string;
  meta?: { name?: string; property?: string; content: string }[];
  route?: string;
  lang?: string;
};

const Meta = ({ title, meta, route, lang }: MetaProps) => {
  const { t } = useTranslation();
  const findMeta = (key: string) => meta?.find((item) => item.name === key || item.property === key)?.content;
  const titleRoute = route ? route : t("meta.title");
  const path = window.location.pathname;
  const pathWithoutLang = path.replace(/^\/(en|ja)/, "");
  const isArticle = pathWithoutLang.includes("/notes-nebula/articles/");

  return (
    <>
      {isArticle ? (
        <Helmet>
          <link rel="canonical" href={`https://tenak.ai/${lang}${pathWithoutLang}`} hrefLang="x-default" />
          <link rel="alternate" href={`https://tenak.ai/ja${pathWithoutLang}`} hrefLang="ja" />
          <link rel="alternate" href={`https://tenak.ai/en${pathWithoutLang}`} hrefLang="en" />
        </Helmet>
      ) : (
        <Helmet>
          <link rel="canonical" href={`https://tenak.ai/en${pathWithoutLang}`} hrefLang="x-default" />
          <link rel="alternate" href={`https://tenak.ai/en${pathWithoutLang}`} hrefLang="en" />
          <link rel="alternate" href={`https://tenak.ai/ja${pathWithoutLang}`} hrefLang="ja" />
        </Helmet>
      )}
      <Helmet>
        {meta &&
          meta.map((item, index) =>
            item.name ? (
              <meta key={index} name={item.name} content={item.content} />
            ) : item.property ? (
              <meta key={index} property={item.property} content={item.content} />
            ) : null
          )}
        {title && <title>{title + " - " + titleRoute}</title>}
        {!title && <title>Tenak AI</title>}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="copyright" content={t("company")} />
        {!findMeta("author") && <meta name="author" content={t("company")} />}
        {!findMeta("keywords") && <meta name="keywords" content={t("meta.keywords")} />}
        {!findMeta("description") && <meta name="description" content={t("meta.description")} />}
        {!findMeta("robots") && <meta name="robots" content="noindex, nofollow" />}
      </Helmet>
    </>
  );
};

export default Meta;
