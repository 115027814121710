import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";

interface TextContextProps {
  additionalText: string;
  setAdditionalText: React.Dispatch<React.SetStateAction<string>>;
  originalText: string;
  setOriginalText: React.Dispatch<React.SetStateAction<string>>;
  selectedTone: string;
  setSelectedTone: React.Dispatch<React.SetStateAction<string>>;
  selectedSns: string;
  setSelectedSns: React.Dispatch<React.SetStateAction<string>>;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  selectedButton: string;
  setSelectedButton: React.Dispatch<React.SetStateAction<string>>;
  fileUuid: string | undefined; // この行を追加
  processing: boolean;
  setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  customMessage: string;
  setCustomMessage: React.Dispatch<React.SetStateAction<string>>;
  inputRef: React.RefObject<HTMLInputElement>;
  aiEngine: string;
  setAiEngine: React.Dispatch<React.SetStateAction<string>>;
  generatedImageURL: string;
  setGeneratedImageURL: React.Dispatch<React.SetStateAction<string>>;
}

const FileSidebarContext = createContext<TextContextProps | null>(null);

export const useFileSidebarContext = () => {
  const context = useContext(FileSidebarContext);
  if (!context) {
    throw new Error("FileSidebarContext must be used within a TextProvider");
  }
  return context;
};

interface FileSidebarProviderProps {
  children: React.ReactNode;
}

export const FileSidebarProvider = ({ children }: FileSidebarProviderProps) => {
  const [additionalText, setAdditionalText] = useState<string>("");
  const [originalText, setOriginalText] = useState<string>("");
  const [selectedTone, setSelectedTone] = useState<string>("");
  const [selectedSns, setSelectedSns] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [selectedButton, setSelectedButton] = useState<string>("");
  const [processing, setProcessing] = useState<boolean>(false);
  const [customMessage, setCustomMessage] = useState<string>("");
  const { fileUuid } = useParams() || "";
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [aiEngine, setAiEngine] = useState<string>("Flux1Schnell");
  const [generatedImageURL, setGeneratedImageURL] = useState<string>("");

  return (
    <FileSidebarContext.Provider
      value={{
        additionalText,
        setAdditionalText,
        originalText,
        setOriginalText,
        selectedTone,
        setSelectedTone,
        selectedSns,
        setSelectedSns,
        language,
        setLanguage,
        selectedButton,
        setSelectedButton,
        fileUuid,
        processing,
        setProcessing,
        inputRef,
        customMessage,
        setCustomMessage,
        aiEngine,
        setAiEngine,
        generatedImageURL,
        setGeneratedImageURL,
      }}
    >
      {children}
    </FileSidebarContext.Provider>
  );
};
