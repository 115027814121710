// src/utils/validation.ts
import { TFunction } from "i18next";

export const validateLogin = (t: TFunction<"translation", undefined>) => ({
  username: {
    required: t("validation.required"),
    minLength: { value: 8, message: t("validation.minLength", { min: 8 }) },
    maxLength: { value: 16, message: t("validation.maxLength", { max: 16 }) },
    pattern: { value: /^[a-z0-9_]{4,16}$/, message: t("top.tooltip.username") },
  },
  email: {
    required: t("validation.required"),
    minLength: { value: 8, message: t("validation.minLength", { min: 8 }) },
    maxLength: { value: 320, message: t("validation.maxLength", { max: 320 }) },
    pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: t("top.tooltip.email") },
  },
  password: {
    required: t("validation.required"),
    pattern: {
      value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
      message: t("top.tooltip.password"),
    },
  },
});

export const validateUrl = (url: string) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    return !!pattern.test(url);
}

export const validateYoutubeUrl = (url: string) => {
    const pattern = new RegExp('^(https?://)?(www.youtube.com|youtu.be)/.+$');
    return !!pattern.test(url);
}