import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { SnsType, useVideoScriptContext } from "../../../../context/VideoScriptContext";
import file_sidebar from "../../../../json/file_sidebar.json";
import { StyledTypography } from "../../../../utils/styledTypography";

export default function SNS() {
  const { t } = useTranslation();
  const context = useVideoScriptContext();
  const snsList = file_sidebar.snsList;

  return (
    <Box>
      <Box display={"flex"} flexDirection={"row"} mb={1}>
        <StyledTypography variant="body1" gutterBottom>
          {t("videoScript.sns.title")}
        </StyledTypography>
        <Typography color={"error"}>&nbsp;*</Typography>
      </Box>
      <Select
        variant="standard"
        fullWidth
        multiple
        value={context.sns}
        onChange={(e) => context.setSns(e.target.value as SnsType[])}
        renderValue={(selected) =>
          Array.isArray(selected) ? (selected as string[]).map((value) => t(`videoScript.sns.${value}`)).join(", ") : ""
        }
      >
        {snsList.map((item, index) => (
          <MenuItem value={item} key={index}>
            <Checkbox checked={context.sns.includes(item as SnsType)} />
            {t(`videoScript.sns.${item}`)}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
}
