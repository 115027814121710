import React, { useState } from "react";
import { Avatar, Box, Paper, TextField, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Skeleton from "@mui/material/Skeleton";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import { useAvatar } from "../../../context/AvatarContext";
import { useLoginStatus } from "../../../context/LoginStatusContext";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { useParams } from "react-router-dom";

interface UserBoxProps {
  id?: string;
  content: string;
  sendMessage: (message: string, parentId: string | null) => Promise<void>;
}

export default function UserBox({ id, content, sendMessage }: UserBoxProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const { avatarUrl, isAvatarExist } = useAvatar();
  const { workspaceUuid } = useParams();
  const sendOnCtrlEnter = useSelector((state: RootState) => state.browsing.sendOnCtrlEnter[workspaceUuid!]);
  const processing = useSelector((state: RootState) => state.browsing.processing[workspaceUuid!]);
  const { user } = useLoginStatus();
  const [isEditing, setIsEditing] = useState(false);
  const [editQuery, setEditQuery] = useState(content);
  const [editError, setEditError] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
    setEditQuery(content);
  };

  const handleSaveEdit = () => {
    setIsEditing(false);
    if (!id) return;
    sendMessage(editQuery, id);
  };

  const handleSaveEditCancel = () => {
    setIsEditing(false);
    setEditQuery("");
  };

  const handleEditKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      !processing &&
      sendOnCtrlEnter &&
      ((event.ctrlKey && event.key === "Enter") || (event.metaKey && event.key === "Enter"))
    ) {
      setIsEditing(false);
      handleSaveEdit();
    }
  };

  const handleEditQueryChange = (value: string) => {
    setEditQuery(value);
    if (value.length > 10000) {
      setEditError(true);
    } else {
      setEditError(false);
    }
  };

  return (
    <Paper
      data-id={id}
      elevation={theme.palette.mode === "dark" ? 2 : 0}
      sx={{
        px: { xs: 2, sm: 3 },
        pt: 2,
        pb: 2,
        mb: 1,
        transition: "all 0.3s ease",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        {isAvatarExist === null || isAvatarExist === undefined ? (
          <Skeleton variant="rectangular" width={32} height={32} />
        ) : isAvatarExist ? (
          <Avatar alt="User avatar" src={avatarUrl ? avatarUrl[0] : ""} sx={{ width: 32, height: 32 }} />
        ) : (
          <Avatar color="primary" sx={{ width: 32, height: 32 }}>
            {user?.username.slice(0, 2)}
          </Avatar>
        )}
        <Typography variant="subtitle1" component={"p"} sx={{ ml: 2 }} color={"textSecondary"}>
          You
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
        {!isEditing && (
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <IconButton onClick={handleEdit}>
              <EditIcon fontSize={"small"} />
            </IconButton>
          </Box>
        )}
      </Box>
      {!isEditing ? (
        // 編集中じゃない場合
        <Typography
          variant="body1"
          gutterBottom
          textAlign="left"
          sx={{ whiteSpace: "pre-line", mt: 2, wordBreak: "break-all" }}
        >
          {content}
        </Typography>
      ) : (
        // 編集中の場合
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <TextField
            value={editQuery}
            sx={{ flex: 1, mb: 2, mt: 2 }}
            maxRows={20}
            multiline
            onChange={(e) => handleEditQueryChange(e.target.value)}
            onKeyDown={handleEditKeyDown}
            inputProps={{ maxLength: 10000 }}
            error={editError}
          />
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "right", gap: 1 }}>
            <Button color={"primary"} variant={"outlined"} sx={{ width: "120px" }} onClick={handleSaveEditCancel}>
              {t("common.cancel")}
            </Button>
            <Button
              disableElevation
              color={"primary"}
              variant={"contained"}
              sx={{ width: "120px" }}
              onClick={handleSaveEdit}
              disabled={processing}
            >
              {t("common.refresh")}
            </Button>
          </Box>
        </Box>
      )}
    </Paper>
  );
}