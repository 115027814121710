import React from "react";
import { useParams } from "react-router-dom";
import Container from "@mui/material/Container";
import { DrawerHeader } from "../../components/layout/Drawer/DrawerMain";
import Button from "@mui/material/Button";
import { ColumnCenteredBox } from "../../utils/styledBox";
import axios from "axios";
import { useAlert } from "../../context/AlertContext";
import { Controller, useForm } from "react-hook-form";
import ValidationTextField from "../../components/common/ValidationTextField";
import { validateLogin } from "../../utils/validation";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import Meta from "../../components/common/Meta";

type Inputs = { password: string };
const PasswordReset = () => {
  const { uid, token } = useParams();
  const { setAlert } = useAlert();
  const [isRevealPassword, setIsRevealPassword] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useCustomNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    defaultValues: {
      password: "",
    },
  });

  const validationRules = validateLogin(t);

  const handleTogglePasswordVisibility = () => {
    setIsRevealPassword((prev) => !prev);
  };

  const handleSignup = async (data: any) => {
    const { password } = data;

    try {
      // パスワードリセットのリクエストを送信
      const response = await axios.patch("/api/v1/users/reset-password", {
        password,
        uid,
        token,
      });
      const data = response.data;

      if (response.status === 200 && data.success) {
        setAlert("success", t("top.resetSuccess"));
        navigate("");
      } else {
        setAlert("error", t("top.resetError"));
      }
    } catch (error: any) {
      if (error.response) {
        setAlert("error", "An unknown error occurred");
      }
    }
  };
  const meta = [
    { name: "description", content: "" },
    { name: "robots", content: "noindex, nofollow" },
  ];

  return (
    <>
      <Meta meta={meta} />
      <Container maxWidth="sm">
        <DrawerHeader />
        <ColumnCenteredBox mt={4}>
          <Typography variant="h4" component={"h2"} sx={{ mb: 4 }}>
            {t("top.resetPassword")}
          </Typography>
          <form onSubmit={handleSubmit(handleSignup)} noValidate style={{ width: 400 }}>
            <Controller
              name="password"
              control={control}
              rules={validationRules.password}
              render={({ field }) => (
                <ValidationTextField
                  label="Password"
                  type="password"
                  value={field.value}
                  helperText={errors.password?.message ?? ""}
                  error={errors.password !== undefined}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  isRevealPassword={isRevealPassword}
                  onTogglePasswordVisibility={handleTogglePasswordVisibility}
                  tooltipMessage={t("top.tooltip.password")}
                  fullWidth
                  autoComplete="new-password"
                  sx={{ mb: 1 }}
                />
              )}
            />
            <Button
              disableElevation
              size="large"
              variant="contained"
              type={"submit"}
              sx={{ marginTop: 2, width: "100%" }}
            >
              {t("top.resetPassword")}
            </Button>
          </form>
        </ColumnCenteredBox>
      </Container>
    </>
  );
};

export default PasswordReset;
