import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ValidationTextField from "../common/ValidationTextField";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import LockResetIcon from "@mui/icons-material/LockReset";
import { validateLogin } from "../../utils/validation";
import { useLoginModalContext } from "../../context/LoginModalContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function PasswordResetModal() {
  const { t } = useTranslation();
  const context = useLoginModalContext();
  const [errorMessage, setErrorMessage] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  type Inputs = { email: string };
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    defaultValues: { email: "" },
  });

  const validationRules = validateLogin(t);

  const handleResetPassword: SubmitHandler<Inputs> = async (data: Inputs) => {
    setErrorMessage("");
    setSuccessMessage("");
    setLoading(true);

    try {
      const response = await axios.post(
        "/api/v1/users/reset-password",
        {
          email: data.email,
        },
        { withCredentials: true }
      );

      if (response.status === 200 && response.data.success) {
        setSuccessMessage(response.data.message);
        setIsSuccess(true);
        setLoading(false);
      } else {
        setErrorMessage(response.data.message);
        setLoading(false);
      }
    } catch (error: any) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
      } else if (error.request) {
        setErrorMessage("No response received from server.");
      } else {
        setErrorMessage(error.message);
      }
      setLoading(false);
    }
  };

  const handleClose = () => {
    context.setActiveModal(null);
    setErrorMessage("");
    setSuccessMessage("");
    setIsSuccess(false);
    reset();
  };

  return (
    <Modal open={context.activeModal === "passwordReset"} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton aria-label="close" onClick={handleClose} sx={{ position: "absolute", right: 8, top: 8 }}>
          <CloseIcon />
        </IconButton>
        <Box
          component={"form"}
          onSubmit={handleSubmit(handleResetPassword)}
          justifyContent="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          noValidate
          gap={2}
        >
          <Typography variant="h5" component="div" gutterBottom textAlign={"center"} fontWeight={600}>
            {t("top.resetPassword")}
          </Typography>
          {isSuccess ? (
            <Stack spacing={3} flexDirection="column" justifyContent="flex-end" display="flex" alignItems={"center"}>
              <LockResetIcon sx={{ marginTop: 2, fontSize: 40 }} />
              <Typography variant="body2">{successMessage}</Typography>
              <Button disableElevation variant="contained" onClick={handleClose}>
                {t("common.close")}
              </Button>
            </Stack>
          ) : (
            <>
              <Controller
                name="email"
                control={control}
                rules={validationRules.email}
                render={({ field }) => (
                  <ValidationTextField
                    label="E-mail"
                    type="email"
                    value={field.value}
                    helperText={errors.email?.message ?? ""}
                    error={errors.email !== undefined}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    tooltipMessage={t("top.tooltip.resetPassword")}
                    autoComplete="email"
                    fullWidth
                  />
                )}
              />
              <Stack mt={1} flexDirection="row" justifyContent="flex-end" display="flex">
                <Button
                  disableElevation
                  variant="text"
                  sx={{ marginRight: 1 }}
                  onClick={() => context.setActiveModal(null)}
                >
                  {t("common.cancel")}
                </Button>
                <Button disableElevation variant="contained" type={"submit"} disabled={loading}>
                  {t("top.resetPassword")}
                </Button>
              </Stack>
              {errorMessage && (
                <Typography variant="body2" color="error" mt={2}>
                  {errorMessage}
                </Typography>
              )}
              {successMessage && (
                <Typography variant="body2" mt={2}>
                  {successMessage}
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
