import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useCustomNavigate } from "../../hooks/useCustomNavigate";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";
import { loadUserLanguageFromCookie } from "../../i18n/i18n";
import { useLoginStatus } from "../../context/LoginStatusContext";
import { useNotice } from "../../context/NoticeContext";
import { useLoginModalContext } from "../../context/LoginModalContext";

export default function GoogleButton({ handleClose }: { handleClose: () => void }) {
  const { t } = useTranslation();
  const navigate = useCustomNavigate();
  const loginStatus = useLoginStatus();
  const context = useLoginModalContext();
  const { triggerNotice } = useNotice();

  const LineWithText = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    "&::before, &::after": {
      content: '""',
      flex: 1,
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: "0em",
      marginRight: "0.5em",
      marginLeft: "0.5em",
    },
  }));

  const handleGoogleLogin = async (credentialResponse: CredentialResponse) => {
    context.setRequestPathToCookie(window.location.pathname);
    const response = await axios.post("/api/v1/auth/google-login", {
      credentialResponse,
    });
    if (response.status === 200 && response.data.success) {
      loginStatus.checkLoginStatus().then((res) => {
        navigate(context.getRequestPathFromCookie());
        context.setRequestPathToCookie("");
        handleClose();
        triggerNotice("success", `Hello, @${res.user?.username} ! You have successfully logged in.`);
      });
    }
  };

  return (
    <Box>
      <LineWithText sx={{ mt: 2 }}>
        <Typography variant="body2" component="div" gutterBottom>
          {t("common.or")}
        </Typography>
      </LineWithText>

      <Box mt={2} justifyContent="center" display="flex" flexDirection="column">
        <Typography variant="subtitle1" component="div" mb={2} align={"center"}>
          {t("top.googleLogin")}
        </Typography>

        <GoogleLogin
          onSuccess={(credentialResponse) => handleGoogleLogin(credentialResponse)}
          onError={() => console.log("Google Login failed")}
          type="standard"
          theme="filled_black"
          logo_alignment="left"
          size="large"
          shape="rectangular"
          width="332px"
          text="continue_with"
          locale={loadUserLanguageFromCookie()}
        />
      </Box>
    </Box>
  );
}
